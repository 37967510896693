import React from "react"
import styled from "styled-components"
import logo from "../images/in_logo_750.jpg"

const IconFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 60px;
  width: 60px;
  border-radius: 100%;
  border: solid 1px #6c6c6c;

  margin-top: 20px;
  overflow: hidden;
  background-color: #ffffff;
`

const AuthenticationIcon = () => (
  <IconFrame>
    <div style={{ marginTop: 4 }} className="icon">
      <img src={logo} alt="logo" style={{ height: 40 }} />
    </div>
  </IconFrame>
)

export default AuthenticationIcon
