import styled from "styled-components"
import React, { useState, useEffect } from "react"
import TextButton from "../common/TextButton"
import SpecialTerm from "./SpecialTerm"
import { addThousandSeparator } from "../../util/addThousandSeparator"
import lodash from "lodash"
import { getCurrency } from "../../util/getCurrency"

/**
 * Creates a component for editing a delivery.
 * @param {object} props
 * @param {InFlow.Delivery} props.delivery The delivery to be used
 * @param {InFlow.OnChangeDelivery} props.onChange - Function that adds the changes to the deliveries.
 * @param {function} props.t Translation
 * @param {{label: string, value: string}[]} props.specialTermTags The special term tags
 * @param {string} props.deliveryId The id of the delivery
 * @param {string} props.editingId The id of the delivery being edited
 * @param {function} props.setEditingId Function to set the id of the delivery being edited
 * @param {boolean} props.readOnly If the component is read only
 * @param {boolean} props.isMaintenance If the delivery is maintenance
 * @param {string} props.category The category of the application
 * @param {InFlow.DeliveryItems} [props.deliveries] The deliveries in the application
 * @param {function} [props.setDeliverySpecialTerms] The special terms of the delivery
 * @param {boolean} props.isDisabledEditingSpecialTerm If the editing of the special term is disabled
 * @param {function} props.setIsDisabledEditingSpecialTerm Function to set if the editing of the special term is disabled
 * @returns {JSX.Element}
 */
const DeliveryComponent = ({
  delivery,
  onChange,
  t,
  specialTermTags,
  deliveryId,
  editingId = null,
  setEditingId = null,
  readOnly = false,
  isMaintenance,
  category,
  deliveries,
  setDeliverySpecialTerms,
  isDisabledEditingSpecialTerm,
  setIsDisabledEditingSpecialTerm,
}) => {
  /**
   * When clicking on the "show-special-terms-button-text"-button (+ vis særvilkår)
   */

  /** @type {InFlow.SpecialTerms} */
  const specialTerms = delivery.specialTerms
  const {
    userAdded,
    mandatory,
    specialTermsInEdit,
    specialTermsSortedByPriority,
  } = specialTerms
  const onAddSpecialTerm = () => {
    // Adding a term to be filled in by the SpecialTerm component
    const newTerm = {
      termText: "",
      disabled: false,
    }
    const newDelivery = lodash.cloneDeep(delivery)
    newDelivery.specialTerms.specialTermsInEdit = newTerm
    onChange({ deliveryId, data: newDelivery })

    setEditingId(deliveryId)
  }

  /**
   * When adding a special term to multiple deliveries
   * @param {Set<string>} deliveryIdsToAdd
   * @param {InFlow.SpecialTerm} finishedSpecialTerm
   * @returns {void}
   */
  const handleAddMultipleSpecialTerms = (
    deliveryIdsToAdd,
    finishedSpecialTerm
  ) => {
    deliveryIdsToAdd.forEach((deliveryId) => {
      const cloned = lodash.cloneDeep(deliveries[deliveryId])
      cloned.id = deliveryId
      addEditTermNew(cloned, finishedSpecialTerm)
      setDeliveryIdsToAdd([])
    })
  }

  /**
   * When adding a special term
   * @param {InFlow.SpecialTerm} finishedSpecialTerm
   * @param {InFlow.Delivery} deliveryToUpdate
   * @returns {void}
   */
  const addEditTermNew = (deliveryToUpdate, finishedSpecialTerm) => {
    const newDelivery = lodash.cloneDeep(deliveryToUpdate)
    newDelivery.specialTerms.userAdded.push(finishedSpecialTerm)
    delete newDelivery.specialTerms.specialTermsInEdit

    // It is only missing when i edit a term, not when I add a new term on top of the existing ones
    const deliverySortedSpecialTerms =
      newDelivery.specialTerms?.specialTermsSortedByPriority ?? []

    // Update sortedSpecialTerms
    const updatedSortedSpecialTerms = [
      ...(deliverySortedSpecialTerms ?? []),
      {
        ...finishedSpecialTerm,
        userAdded: true,
        priority: deliverySortedSpecialTerms.length,
      },
    ]

    // Ensure newDev.specialTerms.userAdded is in sync with updatedSortedSpecialTerms
    newDelivery.specialTerms.userAdded = updatedSortedSpecialTerms.filter(
      (term) => term.userAdded
    )

    if (deliveryId === deliveryToUpdate.id) {
      setSortedSpecialTerms(updatedSortedSpecialTerms)
    }
    newDelivery.specialTerms.specialTermsSortedByPriority =
      updatedSortedSpecialTerms

    //TODO: Fix bug when having multiple deliveries and adding manual specialTerms WITHOUT any edit changes first
    if (setDeliverySpecialTerms) {
      setDeliverySpecialTerms((prevDeliverySpecialTerms) => {
        return [
          ...prevDeliverySpecialTerms.filter(
            (prevDelivery) =>
              prevDelivery.deliveryId &&
              prevDelivery.deliveryId !== newDelivery.id
          ),
          {
            deliveryId: newDelivery.id,
            specialTerms: newDelivery.specialTerms,
          },
        ]
      })
    }
    onChange({
      deliveryId,
      data: newDelivery,
    })
    setEditingId(null)
  }

  /**
   * When adding a special term
   * @param {InFlow.SpecialTerm} finishedSpecialTerm
   * @returns {void}
   */
  const addEditTerm = (finishedSpecialTerm) => {
    const currentDeliveryIdsToAdd = [...deliveryIdsToAdd, deliveryId]

    if (currentDeliveryIdsToAdd.length > 0) {
      handleAddMultipleSpecialTerms(
        new Set([...deliveryIdsToAdd, deliveryId]),
        finishedSpecialTerm
      )
    }
  }

  /**
   * When deleting a special term
   * @param {string} termToRemove // The term.term property to remove. We use term as key because it is unique, eg "1.1.1 Ny kontant egenkapital ved lån"
   * @returns {void}
   */
  const onDelete = (termToRemove) => {
    const newDev = lodash.cloneDeep(delivery)

    const newSpecialTermsSortedByPriority = sortedSpecialTerms.filter(
      (term) => term.term !== termToRemove
    )
    const newUserAddedSpecialTerms = newSpecialTermsSortedByPriority.filter(
      (term) => term.userAdded
    )

    setSortedSpecialTerms((prevSortedSpecialTerms) =>
      prevSortedSpecialTerms.filter((term) => term.term !== termToRemove)
    )
    onChange({
      deliveryId,
      data: {
        ...newDev,
        specialTerms: {
          ...newDev.specialTerms,
          specialTermsSortedByPriority: newSpecialTermsSortedByPriority,
          userAdded: newUserAddedSpecialTerms,
        },
      },
    })
  }

  const onCancel = () => {
    const newDev = lodash.cloneDeep(delivery)
    newDev.specialTerms.specialTermsInEdit = null
    onChange({ deliveryId, data: newDev })

    setEditingId(null)
  }

  const currency = getCurrency(category)
  const currencySymbol = currency === "NOK" ? "kr" : currency
  const [draggedItem, setDraggedItem] = useState(null)
  const [dragDirection, setDragDirection] = useState(null)
  const [deliveryIdsToAdd, setDeliveryIdsToAdd] = useState([])

  /**
   * When dragging a special term
   * @param {React.DragEvent<HTMLDivElement>} e
   * @param {InFlow.SpecialTerm} term
   * @returns {void}
   */
  const handleDragStart = (e, term, termIndex) => {
    setDraggedItem({
      ...term,
      termIndex,
    })
    e.dataTransfer.effectAllowed = "move"

    // Clone the element being dragged
    /** @type {HTMLElement} */
    const originalElement = e.target
    /** @type {HTMLElement} */
    const clone = originalElement.cloneNode(true)

    // Style the cloned element
    clone.style.backgroundColor = "var(--flow-color-grey3)" // Colors.Grey3
    clone.style.width = "35vw"
    clone.style.height = "12vw"

    // Ensure the clone is absolutely positioned and offscreen
    clone.style.position = "absolute"
    clone.style.top = "-9999px"
    clone.style.left = "-9999px"

    // Append it to the document body (required for drag image)
    document.body.appendChild(clone)
    // Set the cloned element as the drag image
    e.dataTransfer.setDragImage(clone, 0, 0)

    // Remove the cloned element when the drag ends
    e.target.addEventListener("dragend", () => {
      clone.remove()
    })
  }

  /**
   * When dragging over a special term
   * @param {React.DragEvent<HTMLDivElement} e
   * @returns {void}
   */
  const handleDragOver = (e, index) => {
    e.preventDefault()
    setDragOverIndex(index)

    if (draggedItem) {
      // Implement your logic to handle the drag over event
      const direction = index > draggedItem.termIndex ? "down" : "up"
      setDragDirection(direction)
    }

    e.dataTransfer.dropEffect = "move"
  }

  const mandatoryTermsWithPriority = getMandatoryTermsWithPriority(
    mandatory,
    userAdded
  )
  /** @type {InFlow.SpecialTerm[]} */
  const sortedItemsWithPriority = specialTermsSortedByPriority
    ? specialTermsSortedByPriority
    : sortItemsWithPriority(mandatoryTermsWithPriority, userAdded)

  const [sortedSpecialTerms, setSortedSpecialTerms] = useState(
    sortedItemsWithPriority
  )

  useEffect(() => {
    onChange({
      deliveryId,
      data: {
        ...delivery,
        specialTerms: {
          ...delivery.specialTerms,
          specialTermsSortedByPriority: sortedSpecialTerms,
        },
      },
    })
  }, [])

  useEffect(() => {
    const sortedSpecialTermsForDelivery =
      delivery.specialTerms.specialTermsSortedByPriority
    if (sortedSpecialTermsForDelivery) {
      setSortedSpecialTerms(sortedSpecialTermsForDelivery)
    }
  }, [delivery])

  const [dragOverIndex, setDragOverIndex] = useState(null)

  /**
   * When dropping a special term
   * @param {React.DragEvent<HTMLDivElement>} e
   * @param {number} targetTermIndex
   * @returns {void}
   */
  const handleDrop = (e, targetTermIndex) => {
    e.preventDefault()

    if (!draggedItem) {
      return
    }
    const draggedItemIndex = sortedSpecialTerms.findIndex(
      (term) => term.term === draggedItem.term
    )

    // Create a new array without the dragged item
    const newSortedTerms = sortedSpecialTerms.filter(
      (term, index) => index !== draggedItemIndex
    )
    const isDraggingUp = draggedItemIndex > targetTermIndex

    if (isDraggingUp) {
      // We want to add borderTop instead of borderBottom
      //setDragOverIndex(targetTermIndex - 1)
      newSortedTerms.splice(targetTermIndex, 0, draggedItem)
    }
    // Insert the dragged item at the target index
    // If the dragged item is at start, the index should be 0
    else if (targetTermIndex === 1 && draggedItemIndex === 0) {
      //newSortedTerms.unshift(draggedItem)
      //newSortedTerms.splice(0, 0, draggedItem)
      newSortedTerms.splice(targetTermIndex, 0, draggedItem)
    }
    // If the dragged item is at end, the index should be list.length - 1
    else if (targetTermIndex === sortedSpecialTerms.length - 1) {
      newSortedTerms.push(draggedItem)
    } else {
      newSortedTerms.splice(targetTermIndex, 0, draggedItem)
    }
    const newSortedTermsWithPriority = newSortedTerms.map((term, index) => ({
      ...term,
      priority: index,
    }))

    // Update the state with the new order
    setSortedSpecialTerms(newSortedTermsWithPriority)
    setDraggedItem(null)
    const draggedElement = document.querySelector(".dragging")
    if (draggedElement) {
      draggedElement.classList.remove("dragging")
      draggedElement.style.border = "none"
      draggedElement.style.backgroundColor = "grey"
    }
    const newDev = lodash.cloneDeep(delivery)
    newDev.specialTerms.specialTermsSortedByPriority =
      newSortedTermsWithPriority
    // Update mandatory terms with priority and userAdded terms with priority
    newDev.specialTerms.mandatory = newSortedTermsWithPriority.filter(
      (term) => !term.userAdded
    )
    newDev.specialTerms.userAdded = newSortedTermsWithPriority.filter(
      (term) => term.userAdded
    )

    onChange({ deliveryId, data: newDev })
  }

  /**
   * @param {string} newTermText
   * @param {string} termId
   * @param {boolean} isUserAdded
   * @returns {void}
   */
  const editTermText = (newTermText, termId, isUserAdded) => {
    const newDelivery = lodash.cloneDeep(delivery)

    let newUserAddedList = newDelivery.specialTerms.userAdded
    let newMandatoryList = newDelivery.specialTerms.mandatory

    const deliverySortedSpecialTerms =
      newDelivery.specialTerms?.specialTermsSortedByPriority ?? []

    const sortedTermToEdit = deliverySortedSpecialTerms.find((x) => {
      return x.term === termId
    })

    if (isUserAdded) {
      // Manually added
      const specialTermToEditUserAdded =
        newDelivery.specialTerms.userAdded.find((x) => x.term === termId)
      if (!specialTermToEditUserAdded && !sortedTermToEdit) {
        console.error("Could not find term to edit userAdded")
        return
      }
      const specialTermToEditUserAddedWithNewTermText = {
        ...specialTermToEditUserAdded,
        termText: newTermText,
      }

      // Add the new termText to the userAdded term
      newUserAddedList = newDelivery.specialTerms.userAdded.map((term) => {
        if (term.term === termId) {
          return specialTermToEditUserAddedWithNewTermText
        }
        return term
      })
    }

    if (!isUserAdded) {
      // Mandatory added
      const specialTermToEditMandatory =
        newDelivery.specialTerms.mandatory.find((x) => {
          return x.term === termId
        })

      if (!specialTermToEditMandatory && !sortedTermToEdit) {
        console.error("Could not find term to edit mandatory")
        return
      }

      const specialTermToEditMandatoryWithNewTermText = {
        ...specialTermToEditMandatory,
        termText: newTermText,
      }

      // Add the new termText to the mandatory term
      newMandatoryList = newDelivery.specialTerms.mandatory.map((term) => {
        if (term.term === termId) {
          return specialTermToEditMandatoryWithNewTermText
        }
        return term
      })
    }

    // Add the new termText to the sorted term
    const newSortedByPriorityList = deliverySortedSpecialTerms.map((term) => {
      if (term.term === termId) {
        return {
          ...term,
          termText: newTermText,
        }
      }
      return term
    })

    //Update both
    /** @type {InFlow.SpecialTerms} */
    const newDeliverySpecialTerms = {
      ...newDelivery.specialTerms,
      mandatory: newMandatoryList,
      userAdded: newUserAddedList,
      specialTermsSortedByPriority: newSortedByPriorityList,
    }

    // Update newDev with the updated specialTerms
    const updatedDelivery = {
      ...newDelivery,
      specialTerms: newDeliverySpecialTerms,
    }

    // Oppdater newDev.specialTerms -> få med ny termText
    // Oppdater deliverySpecialTerms
    setDeliverySpecialTerms((prevDeliverySpecialTerms) => {
      return [
        ...[...prevDeliverySpecialTerms].filter(
          (dev) => dev.deliveryId && dev.deliveryId !== deliveryId
        ),
        {
          deliveryId: deliveryId,
          specialTerms: newDeliverySpecialTerms,
        },
      ].filter((dev) => dev.specialTerms)
    })

    onChange({ deliveryId, data: updatedDelivery })
    setIsDisabledEditingSpecialTerm(true)
  }

  const isDragEnabled =
    !readOnly && !specialTermsInEdit && isDisabledEditingSpecialTerm

  const isEditMode =
    readOnly || specialTermsInEdit || editingId || !isDragEnabled // !specialTermsInEdit && !readOnly && !editingId && !isDragEnabled &&

  return (
    <>
      <Box isDragDisabled={!isDragEnabled}>
        <ProductTitle>
          {t(delivery.productName)}{" "}
          {isMaintenance
            ? ""
            : `${currencySymbol} ${addThousandSeparator(delivery.amount)}`}
        </ProductTitle>
        {sortedSpecialTerms.map((term, i) => {
          return (
            <DraggableItem
              key={term.term}
              isDragDisabled={!isDragEnabled}
              draggable={isDragEnabled}
              onDragStart={(e) => handleDragStart(e, term, i)}
              onDragOver={!readOnly ? (e) => handleDragOver(e, i) : undefined}
              onDrop={(e) => handleDrop(e, i)}
              draggedItem={draggedItem}
              dragDirection={dragDirection}
              dragOverIndex={dragOverIndex}
              i={i}
            >
              <SpecialTerm
                specialTerm={term}
                disabled={true}
                deletable={term.userAdded && !readOnly}
                onDelete={
                  term.userAdded && !readOnly
                    ? () => onDelete(term.term)
                    : undefined
                }
                categories={specialTermTags}
                t={t}
                setIsDisabledEditingSpecialTerm={
                  setIsDisabledEditingSpecialTerm
                }
                isEditMode={isEditMode}
                editTermText={editTermText}
              />
            </DraggableItem>
          )
        })}

        {/* Denne tilsier at hvis du er i edit mode (altså legge inn særvilkår så kan du ikke drag and drop) */}
        {specialTermsInEdit && (
          <div
            draggable={!readOnly && !specialTermsInEdit}
            onDragStart={(e) => handleDragStart(e, deliveryId)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, deliveryId)}
          >
            <SpecialTerm
              key={"S"}
              specialTerm={specialTermsInEdit}
              onDelete={onDelete}
              onAdd={addEditTerm}
              onCancel={onCancel}
              onChange={onChange}
              categories={specialTermTags}
              t={t}
              deliveries={deliveries}
              deliveryIdsToAdd={deliveryIdsToAdd}
              setDeliveryIdsToAdd={setDeliveryIdsToAdd}
              currentDeliveryId={deliveryId}
              editTermText={editTermText}
              isDisabledEditingSpecialTerm={isDisabledEditingSpecialTerm}
              setIsDisabledEditingSpecialTerm={setIsDisabledEditingSpecialTerm}
            />
          </div>
        )}
      </Box>
      {!isEditMode && (
        <TextButton onClick={onAddSpecialTerm}>
          {t("show-special-terms-button-text")}
        </TextButton>
      )}
    </>
  )
}

/**
 * @typedef {object} DraggableItemProps
 * @property {boolean} isDragDisabled
 * @property {object} draggedItem
 * @property {string} dragDirection
 * @property {number} dragOverIndex
 * @property {number} i
 */

export default DeliveryComponent

const Box = styled.div`
  color: black;
  background-color: var(--flow-color-grey4);
  padding: 10px;
  border-radius: 5px;
  :hover {
    cursor: ${({ isDragDisabled }) => (isDragDisabled ? "default" : "grab")};
    background-color: ${({ isDragDisabled }) =>
      isDragDisabled ? "none" : "var(--flow-color-grey3)"};
  }
`
const ProductTitle = styled.div`
  color: black;
  font-size: 15px;
`

/**
 * @type {import('styled-components').StyledComponent<'div', any, DraggableItemProps, never>}
 */

// isDragDisabled is a boolean that determines if the item can be dragged
// isDragEnabled is used to check if we are not in readOnly mode and not in edit mode
// TODO: Figure out why when we try to drag the item outside the allowed area, it adds a border to the item bottom or top??
const DraggableItem = styled.div`
  padding: 10px;
  transition: transform 0.2s ease;

  border-bottom: ${({ draggedItem, dragDirection, dragOverIndex, i }) =>
    draggedItem && dragDirection === "down" && dragOverIndex === i
      ? `0.15vw solid var(--flow-color-sea)`
      : "none"};
  border-top: ${({ draggedItem, dragDirection, dragOverIndex, i }) =>
    draggedItem && dragDirection === "up" && dragOverIndex === i
      ? `0.15vw solid var(--flow-color-sea)`
      : "none"};
  margin-top: ${({ dragOverIndex, i }) =>
    dragOverIndex === i ? "0.8vw" : "0px"};
`

const sortItemsWithPriority = (mandatoryTermsWithPriority, userAdded) => {
  return [
    ...(mandatoryTermsWithPriority
      ? mandatoryTermsWithPriority.map((term) => ({
          ...term,
          userAdded: false,
        }))
      : []),
    ...(userAdded
      ? userAdded.map((term) => ({ ...term, userAdded: true }))
      : []),
  ]
    .map((term, index) => ({
      ...term,
      priority: term.priority ?? index,
    }))
    .sort((a, b) => a.priority - b.priority)
}

/**
 * @param {InFlow.SpecialTerm[]} mandatory
 * @param {InFlow.SpecialTerm[]} userAdded
 * @returns {InFlow.SpecialTerm[]}
 */
const getMandatoryTermsWithPriority = (mandatory, userAdded) => {
  const userAddedPriorities = userAdded.map((term) => term.priority)
  // Sort the priorities
  userAddedPriorities.sort((a, b) => a - b)
  // Find the smallest missing positive integer
  let mandatoryPriority = 0
  for (let i = 0; i < userAddedPriorities.length; i++) {
    if (userAddedPriorities[i] === mandatoryPriority) {
      mandatoryPriority++
    } else if (userAddedPriorities[i] > mandatoryPriority) {
      break
    }
  }
  const mandatoryTermsWithPriority =
    mandatory?.length > 0
      ? mandatory.map((term, index) => ({
          ...term,
          priority: mandatoryPriority + index,
        }))
      : []

  return mandatoryTermsWithPriority
}
