import React from "react"
import Collapsible from "../components/common/Collapsible"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
      task: props.task,
      closeTask: props.close,
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div style={{ margin: "15px", marginTop: "25px" }}>
          <Collapsible isOpen={true} header={<b>Vis feilmelding</b>}>
            <div style={{ whiteSpace: "pre-wrap", paddingLeft: "30px" }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </div>
          </Collapsible>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
