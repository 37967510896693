import * as yup from "yup"
import { object, string } from "yup"

const baseValidator = yup.string().typeError("type-error").nullable()
const baseBoolValidator = yup.bool().typeError("type-error").nullable()

const requiredBasedOnField = (field, condition, element) => {
  return element.when(field, {
    is: condition,
    then: element.required("required-error").nullable(),
    otherwise: (s) => s,
  })
}
const requiredBasedOnTwoFields = (
  field1,
  condition1,
  field2,
  condition2,
  element
) => {
  return element.when([field1, field2], {
    is: (field1, field2) => field1 === condition1 && field2 === condition2,
    then: element.required("required-error").nullable(),
    otherwise: (s) => s,
  })
}

const requiredBasedOnFieldLength = (
  field,
  condition,
  element,
  length,
  errorMessage
) => {
  return element.when(field, {
    is: condition,
    then: element
      .length(length, errorMessage)
      .required("required-error")
      .nullable(),
    otherwise: (s) => s,
  })
}

const requiredBasedOnTwoFieldsLenght = (
  field1,
  condition1,
  field2,
  condition2,
  element,
  length,
  errorMessage
) => {
  return element.when([field1, field2], {
    is: (field1, field2) => field1 === condition1 && field2 === condition2,
    then: element
      .length(length, errorMessage)
      .required("required-error")
      .nullable(),
    otherwise: (s) => s,
  })
}

const ssn = yup.string().nullable()
const organizationNumber = string().nullable()
const fetchDataFromBisnode = baseBoolValidator
const fetchDataFromAmbita = baseBoolValidator
const fetchDataFromPopulationRegistry = baseBoolValidator

const email = string().email("email-must-be-valid-email-error").nullable()
const mobile = baseValidator
const organizationCountry = baseValidator
const name = baseValidator
const additionalInfo = string().typeError("type-error").nullable()
const scope = baseValidator.required("required-error")
const type = baseValidator.required("required-error")
const spouseSsn = ssn

export const stakeholderValidationSchema = object({
  ssn: requiredBasedOnFieldLength(
    "type",
    "person",
    ssn,
    11,
    "ssn-must-be-11-digits-error"
  ),
  name: name.required("required-error"),
  email: requiredBasedOnField("type", "person", email),
  organizationNumber: requiredBasedOnTwoFieldsLenght(
    "type",
    "organization",
    "scope",
    "domestic",
    organizationNumber,
    9,
    "organization-number-must-be-9-digits-error"
  ),
  contactPersonName: requiredBasedOnField("type", "organization", name),
  contactPersonEmail: requiredBasedOnField("type", "organization", email),
  organizationCountry: requiredBasedOnField(
    "scope",
    "international",
    organizationCountry
  ),
  fetchDataFromBisnode: requiredBasedOnField(
    "scope",
    "domestic",
    fetchDataFromBisnode
  ),
  fetchDataFromAmbita: requiredBasedOnField(
    "scope",
    "domestic",
    fetchDataFromAmbita
  ),
  fetchDataFromPopulationRegistry: requiredBasedOnField(
    "type",
    "person",
    fetchDataFromPopulationRegistry
  ),
  additionalInfo: requiredBasedOnField(
    "scope",
    "international",
    additionalInfo
  ),
  spouseSsn: requiredBasedOnFieldLength(
    "isMarried",
    true,
    spouseSsn,
    11,
    "ssn-must-be-11-digits-error"
  ),
  scope,
  type,
})

export const stakeholderDefaultValues = {
  organizationCountry: null,
  ssn: null,
  email: null,
  organizationNumber: null,
  contactPersonName: null,
  contactPersonEmail: null,
  name: null,
  fetchDataFromBisnode: false,
  fetchDataFromAmbita: false,
  type: "person",
  scope: "domestic",
  isSpouse: false,
  fetchDataFromPopulationRegistry: false,
  isMarried: false,
  spouseSsn: "",
}
