import React from "react"
import styled from "styled-components"
import { Collapsible } from "@stacc/flow-ui-components"
import { withTranslation } from "react-i18next"
import { isValid, parseISO } from "date-fns"
import formatWithLocale from "../../util/dateFormatter"

const isNumber = (val) => typeof val === "number"

const sortPred = ([aKey, aVal], [bKey, bVal]) => {
  if (isNumber(aKey) || isNumber(bKey)) return 0
  const objSort =
    (typeof aVal === "object" ? 1 : 0) - (typeof bVal === "object" ? 1 : 0)
  if (objSort !== 0) return objSort
  // Then by key
  if (aKey > bKey) return 1
  if (bKey > aKey) return -1
  return 0
}

const DefaultHeader = ({ text, fontSize }) => {
  return <HeaderStyle fontSize={fontSize}>{text}</HeaderStyle>
}

const formatItem = (key, val) => {
  if (key !== "projectMunicipality" && key !== "projectCounty") {
    const dateVal = parseISO(val)
    if (isValid(dateVal)) return formatWithLocale(dateVal, "dd.MM.yyyy HH:mm")
  }
  return val
}

const CollapsibleObject = ({
  item,
  header,
  hideEmpty,
  isRoot = true,
  t,
  fontSize,
  borderBottom,
}) => {
  const elements = []
  const entries = Object.entries(item)
  // Order object by object, then by key
  entries.sort(sortPred)
  for (const [key, val] of entries) {
    if (hideEmpty && !val) continue
    if (typeof val === "object") {
      elements.push(
        <Collapsible
          key={key}
          header={
            (typeof header === "function" && (
              <HeaderStyle>
                {header({ item: val, text: t(key), t })}
              </HeaderStyle>
            )) || <DefaultHeader item={val} text={t(key)} fontSize={fontSize} />
          }
        >
          <CollapsibleObject
            hideEmpty={hideEmpty}
            header={header}
            t={t}
            isRoot={false}
            item={val}
            fontSize={fontSize}
          />
        </Collapsible>
      )

      continue
    }

    const formattedVal = formatItem(key, val)

    elements.push(
      <CollapsibleItem key={key}>
        {t(key)}: {formattedVal}
      </CollapsibleItem>
    )
  }

  if (isRoot) {
    return (
      <>
        {elements.map((x, id) => (
          <RootWrapper key={id} borderBottom={borderBottom}>
            {x}
          </RootWrapper>
        ))}
      </>
    )
  }

  return <>{elements}</>
}

const CollapsibleItem = styled.div`
  margin-left: 20px;
  font-size: 14px;
  padding: 5px 0;
`

const HeaderStyle = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  padding: 10px 0;
  color: black;
  font-weight: 500;
`

const RootWrapper = styled.div`
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : `1px solid var(--flow-color-grey3)`};
  font-size: 16px;
  color: black;
  font-weight: 500;
`

export default withTranslation()(CollapsibleObject)
