// In some instances, deeply nested NaN values are converted to null.
const removeEmptyFromArray = (arr) => {
  return arr
    .filter((item) => item != null)
    .map((item) => (item === Object(item) ? removeEmpty(item) : item))
}

const removeEmptyFromObject = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  )
}

export const removeEmpty = (object) => {
  if (Array.isArray(object)) {
    return removeEmptyFromArray(object)
  } else if (object === Object(object)) {
    return removeEmptyFromObject(object)
  } else {
    return object
  }
}
