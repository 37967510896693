import * as yup from "yup"
import { object, string } from "yup"

const baseValidator = yup
  .string()
  .typeError("type-error")
  .nullable()
  .required("required-error")

export const editSecurityTextValidationSchema = object({
  title: baseValidator,
  subtitle: baseValidator,
  text: baseValidator,
})
