import React, { useEffect } from "react"
import Notice from "../../common/Notice"
import styled from "styled-components"
import { useState } from "react"
import EditStakeholder from "./EditStakeholder"
import DisplayStakeholder from "./DisplayStakeholder"
import { ProjectColors } from "../../common/style/ProjectColors"
import {
  stakeholderDefaultValues,
  stakeholderValidationSchema,
} from "./Stakeholder.validationSchema"
import { useForm, FormProvider, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

const StakeholderCard = ({
  stakeholder,
  t,
  children,
  readonly,
  onChange: onChangeProp,
  color,
  onAdd,
  onCancel,
  isCreditChecking,
  maritalStatus,
  allowDeletion,
  addStakeholderError,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
    register,
    ...rest
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(stakeholderValidationSchema),
    defaultValues: {
      ...stakeholderDefaultValues,
      ...stakeholder,
    },
  })

  const editing = watch("editing")
  const isNew = !!stakeholder.new
  const isEditing = !readonly && editing

  useEffect(() => {
    reset({ ...stakeholderDefaultValues, ...stakeholder })
  }, [stakeholder])

  const startEdit = () => {
    setValue("editing", true)
    onChangeProp({ ...stakeholder, editing: true })
  }

  const cancelEdit = () => {
    reset({ ...stakeholder, editing: false })
    onChangeProp({ ...stakeholder, editing: false })
  }
  const handleFormSubmit = (values) => {
    if (values.new) {
      reset({ ...values, editing: false })
      onAdd({ ...values, editing: false })
    } else {
      reset({ ...values, editing: false })
      onChangeProp({ ...values, editing: false })
    }
  }

  return (
    <StakeholderCardContainer>
      <Notice backgroundColor={color}>
        {!isEditing && !isNew ? (
          <DisplayStakeholder
            t={t}
            stakeholder={stakeholder}
            key={stakeholder}
            hasEdit
            onEdit={startEdit}
            color={ProjectColors.Sea97}
          />
        ) : (
          <FormProvider
            handleSubmit={handleSubmit}
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            {...rest}
          >
            <form
              id={`stakeholderForm_${stakeholder.id}`}
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <EditStakeholder
                key={stakeholder}
                stakeholder={stakeholder}
                t={t}
                onCancel={() => (isNew ? onCancel() : cancelEdit())}
                onDelete={onCancel}
                isCreditChecking={isCreditChecking}
                mainApplicantIsMarried={maritalStatus}
                allowDeletion={allowDeletion}
                addStakeholderError={addStakeholderError}
              />
            </form>
          </FormProvider>
        )}
        {!isEditing && (
          <div className="stakeholder-card-content">{children}</div>
        )}
      </Notice>
    </StakeholderCardContainer>
  )
}

const StakeholderCardContainer = styled.div`
  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0px 10px;
    align-items: flex-start;
    flex: 1;
  }

  .top-section {
    display: flex;
    flex-direction: row;
    margin: 10px 10px;
  }

  .edit-card {
    width: 100%;
    padding: 10px 10px;
  }

  .content-item {
    min-width: 200px;
  }

  .full-width {
    width: 100%;
  }

  .edit-form {
    margin-top: 10px;
    display: flex;
    width: 650px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .edit-form > * {
    width: calc(50% - 20px);
  }

  .options {
    margin-top: 10px;
  }

  .options > div {
    cursor: pointer;
  }

  .icon {
    padding-top: 10px;
  }

  .person-content {
    display: grid;
    padding: 10px 10px;
    grid-template-columns: minmax(200px, auto) 180px minmax(200px, auto) 200px;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    gap: 10px;
  }

  .edit-content {
    display: grid;
    grid-template-columns: 200px minmax(200px, auto);
    align-items: center;
    gap: 10px;
  }

  .organization-content {
    display: grid;
    padding: 10px 10px;
    grid-template-columns: minmax(200px, auto) 200px;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    gap: 10px;
  }

  .stakeholder-card-content {
    margin-left: 65px;
    margin-bottom: 10px;
  }
`

export default StakeholderCard
