import React, { useState } from "react"
import Heading from "../common/Heading"
import DownloadFileButton from "../common/DownloadFileButton"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { addThousandSeparator } from "../../util/addThousandSeparator"

const DepotControl = ({
  t,
  currentAgreement,
  relevantDocuments,
  decisionPdf,
  insurancePdf,
}) => {
  return (
    <>
      <Heading>{t("SecurityDeal")}</Heading>
      <Content>
        <table>
          <tbody>
            <ContentTr>
              <BoldTd>
                {t("SecurityDeal")} {t(currentAgreement?.agreementType)}
              </BoldTd>
              <BoldTd>
                {t("PantAmount")}
                {addThousandSeparator(currentAgreement?.collateralAmount)}
              </BoldTd>
            </ContentTr>
            {currentAgreement?.collateralObject.map((y, i) => (
              <ContentTr key={i}>
                <BoldTd>
                  {t("PantObject")}
                  {currentAgreement?.productCode === "Pant i fast eiendom"
                    ? t(y?.collateralObjectCadastre)
                    : t(currentAgreement?.productCode)}
                </BoldTd>
                <BoldTd>
                  {t("Hjemmelshaver")}
                  {y?.collateralObjectCopyRightHolder?.map((holders, i) => (
                    <div key={i}>{holders}</div>
                  ))}
                </BoldTd>
                <BoldTd>
                  {t("Value")} {addThousandSeparator(y?.collateralObjectsValue)}
                </BoldTd>
              </ContentTr>
            ))}
            {currentAgreement?.collateralObjectSecurity?.map((security, i) => (
              <ContentTr key={i}>
                <BoldTd>
                  {t("SecuredFor")} {security?.productName}
                </BoldTd>
                <BoldTd>
                  {t("Financing")}{" "}
                  {addThousandSeparator(security?.productAmount)}
                </BoldTd>
                <BoldTd>
                  {t("safetyRisk")} {security?.safetyRisk}
                </BoldTd>
              </ContentTr>
            ))}
          </tbody>
        </table>
      </Content>

      <div>
        <Heading>{t("offer-document")}</Heading>
        <Row>
          {relevantDocuments[0] && (
            <DownloadFileButton
              title={t(relevantDocuments[0]?.filename)}
              fileId={relevantDocuments[0]?.storeID}
            />
          )}
        </Row>
        <Heading>{t("decision-document")}</Heading>
        <Row>
          {decisionPdf
            .filter((x) => !!x)
            .map((x, i) => (
              <DownloadFileButton
                title={t(x?.filename)}
                fileId={x?.storeID}
                key={i}
              />
            ))}
        </Row>
        {insurancePdf.length > 0 && (
          <div>
            <Heading>{t("insurance-document")}</Heading>
            <Row>
              {insurancePdf
                .filter((x) => !!x)
                .map((x, i) => (
                  <DownloadFileButton
                    title={t(x?.filename)}
                    fileId={x?.storeID}
                    key={i}
                  />
                ))}
            </Row>
          </div>
        )}
      </div>
    </>
  )
}

const BoldTd = styled.td`
  ${"" /*   font-weight: bold; */}
`

const Row = styled.div`
  display: flex;
  gap: 15px;
`

const Content = styled.div`
  display: flex;
  padding: 10px;
  background-color: #f5f9ff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`

const ContentTr = styled.tr`
  display: flex;
  padding: 10px;
  background-color: #f5f9ff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`

export default withTranslation()(DepotControl)
