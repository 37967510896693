import React from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import axios from "axios"
import { SecondaryButton } from "@stacc/flow-ui-components"
import { Icons } from "@stacc/flow-ui-components"

const FileInTabButton = ({ storeId, title, t, buttonHeight = "40px" }) => {
  const openFile = async () => {
    try {
      const response = await axios.get(`/api/files/${storeId}`, {
        responseType: "blob",
      })

      const file = new Blob([response.data], { type: "application/pdf" })
      const fileURL = URL.createObjectURL(file)
      const pdfWindow = window.open()
      pdfWindow.location.href = fileURL
    } catch (error) {
      console.error(error)
      return { error }
    }
  }

  return (
    <ApplicationBtnDiv buttonHeight={buttonHeight}>
      <SecondaryButton onClick={() => openFile()}>
        <ContentBtnDiv>
          <Icons.FilePdf color="var(--flow-color-grey3)" />
          <div className="content-btn-div-children">{t(title)}</div>
        </ContentBtnDiv>
      </SecondaryButton>
    </ApplicationBtnDiv>
  )
}

const ContentBtnDiv = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 10px;

  .content-btn-div-children {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const ApplicationBtnDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 40px;

  button {
    height: ${(props) => props.buttonHeight};
  }
`

export default withTranslation()(FileInTabButton)
