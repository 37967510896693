import axios from "axios"

const TitleCategoryString =
  "01. Obligatorisk innledningstekst for sikkerhetsvilkår"

const getCategories = async () => {
  const res = await axios.get("/api/security-category/")
  return res?.data || []
}

const getSubcategories = async (categoryGuid) => {
  if (!categoryGuid) {
    console.error("Tried to fetch subcategories without a categoryGuid")
    return []
  }
  const res = await axios.get(`/api/security-category/${categoryGuid}/`)
  return res?.data || []
}

const getDetails = async (subcategoryGuid) => {
  if (!subcategoryGuid) {
    console.error("Tried to fetch details without a subcategoryGuid")
    return []
  }
  const res = await axios.get(`/api/security-details/${subcategoryGuid}/`)
  return res?.data || []
}

export { getCategories, getSubcategories, getDetails, TitleCategoryString }
