import React from "react"
import CollapsibleObject from "../../common/CollapsibleObject"
import styled from "styled-components"
import { FontStyles } from "@stacc/flow-ui-components"
import formatWithLocale from "../../../util/dateFormatter"
import DownloadButton from "../../common/DownloadFileButton"

const FormattedAnnouncements = ({ annoucements, t }) => {
  return (
    <div>
      {annoucements?.map((x, i) => (
        <div key={i}>
          {t(x.type)}:{" "}
          {[x.date?.day, x.date?.month, x.date?.year]
            .filter((x) => !!x)
            .join(".")}
        </div>
      ))}
    </div>
  )
}

const BisnodeCreditInformationBox = ({
  creditCheckDate,
  currentCreditRating,
  paymentRemarks,
  auditorsReservation,
  annoucements,
  dataWithOutDate = {},
  t,
  isPerson = false,
  file,
  name = "",
}) => {
  if (isPerson) {
    return (
      <>
        {" "}
        <BisnodeHiglightPart>
          <Column>
            <p>{t("name")}:</p>
            <p>{t("date")}:</p>
            <p>{t("credit-check-pdf")}:</p>
          </Column>
          <Column>
            <p>{name}</p>
            <p>
              {creditCheckDate
                ? formatWithLocale(creditCheckDate, "dd.MM.yyyy HH:mm")
                : "-"}
            </p>
            <StyledDownload>
              <DownloadButton
                title={t(file?.filename)}
                fileId={file?.storeID}
                key={file?.storeID}
              />
            </StyledDownload>
          </Column>
        </BisnodeHiglightPart>
      </>
    )
  } else {
    return (
      <>
        {" "}
        <BisnodeHiglightPart>
          <Column>
            <p>{t("date")}:</p>
            <p>{t("score")}:</p>
            <p>{t("score-description")}:</p>
            <p>{t("paymentRemarks")}:</p>
            <p>{t("auditorsreservation")}:</p>
            <p>{t("annoucements")}:</p>
          </Column>
          <Column>
            <ColumnValue>
              {creditCheckDate
                ? formatWithLocale(creditCheckDate, "dd.MM.yyyy HH:mm")
                : "-"}
            </ColumnValue>
            <ColumnValue>{currentCreditRating?.code ?? "-"}</ColumnValue>
            <ColumnValue>{currentCreditRating?.description ?? "-"}</ColumnValue>
            <CollapsibleObjectStyle>
              {paymentRemarks && Object.keys(paymentRemarks).length > 0 ? (
                <CollapsibleObject
                  item={paymentRemarks}
                  t={t}
                  fontSize={"14px"}
                  borderBottom={"none"}
                />
              ) : (
                <div style={{ paddingBottom: 15 }}>
                  <ColumnValue>{t("no-paymentRemarks")}</ColumnValue>
                </div>
              )}
            </CollapsibleObjectStyle>
            <CollapsibleObjectStyle>
              {auditorsReservation ? (
                <CollapsibleObject
                  item={auditorsReservation}
                  t={t}
                  fontSize={"14px"}
                  borderBottom={"none"}
                />
              ) : (
                <div style={{ paddingBottom: 15 }}>
                  <ColumnValue>{t("no-auditors")}</ColumnValue>
                </div>
              )}
            </CollapsibleObjectStyle>
            {annoucements ? (
              <FormattedAnnouncements annoucements={annoucements} t={t} />
            ) : (
              t("no-annoucements")
            )}
          </Column>
        </BisnodeHiglightPart>
        <FullReport>
          <LabelSpan>Full rapport</LabelSpan>
          <CollapsibleObject item={dataWithOutDate} t={t} />
        </FullReport>
      </>
    )
  }
}

const mapEvents = (events) => {
  if (events) {
    return events?.reduce((acc, curr) => {
      acc.push({ type: curr.type.description, date: curr.eventDate })
      return acc
    }, [])
  }
  return []
}

const BisnodeCreditLookup = ({ flow, t, deliveryId, task }) => {
  if (!flow) return <>{t("could-not-find-data")}</>

  const debtorInformation = flow.data?.raw?.bisnode?.currentCreditCheck
  if (!debtorInformation) return <>{t("could-not-find-data")}</>
  const isEnk =
    flow.data.raw.accountMaster.customerAccount.accountType === "Person"

  const paymentRemarks = debtorInformation?.risk?.paymentRemarks
  const annoucements = isEnk
    ? mapEvents(debtorInformation?.events?.events)
    : debtorInformation?.events
  const auditorsReservation =
    debtorInformation?.finance?.financialStatements?.auditorsReservation
  const creditCheckDate = debtorInformation?.dateOfCreditCheck
  const { dateOfCreditCheck, ...dataWithOutDate } = debtorInformation
  const currentCreditRating =
    debtorInformation?.risk?.creditRatings?.currentCreditRating

  const guarantors = task?.context?.relevantGuarantors
  const originalCreditCheckForEnk = flow.data?.files.find(
    (x) => x.documentType === "PersonCreditCheck"
  )

  const enkPersonCredit =
    flow.data?.raw?.bisnode?.personCreditPdf?.file || originalCreditCheckForEnk

  const enkLegalName =
    flow.data?.raw?.bisnode?.personCreditCheck?.consumerInformation?.legalName
      .fullName
  return (
    <>
      <BoxStyle>
        <LabelSpan>{t("applicant")}</LabelSpan>
        <BisnodeCreditInformationBox
          creditCheckDate={creditCheckDate}
          currentCreditRating={currentCreditRating}
          paymentRemarks={paymentRemarks}
          auditorsReservation={auditorsReservation}
          annoucements={annoucements}
          dataWithOutDate={dataWithOutDate}
          t={t}
        />
      </BoxStyle>

      {isEnk && (
        <BoxStyle>
          <LabelSpan>{t("enk-credit")}</LabelSpan>
          <BisnodeCreditInformationBox
            isPerson={true}
            t={t}
            file={enkPersonCredit}
            creditCheckDate={originalCreditCheckForEnk?.uploadTime}
            name={enkLegalName}
          />
        </BoxStyle>
      )}

      {guarantors?.map((guarantor) => {
        const hasCreditChecks = guarantor.creditChecks?.length > 0
        const isCoDebtor = guarantor.info.isCoDebtor

        let name = guarantor.info.name

        if (hasCreditChecks) {
          const debtorCreditCheck = guarantor.creditChecks?.at(-1)
          name = debtorCreditCheck.json.consumerInformation?.legalName?.fullName
          const paymentRemarks =
            debtorCreditCheck?.json?.risk?.payment?.paymentRemark?.number ?? {}
          const annoucements =
            mapEvents(debtorCreditCheck?.json?.events?.events) ?? []
          const auditorsReservation =
            debtorCreditCheck?.json.finance?.financialStatements
              ?.auditorsReservation
          const creditCheckDate = debtorCreditCheck?.date
          const currentCreditRating =
            debtorCreditCheck?.json.risk?.creditWorthiness ?? ""
          const { personId, creditBlocked, ...data } = debtorCreditCheck?.json
          const file = debtorCreditCheck?.file

          return (
            <BoxStyle>
              <LabelSpan>
                {isCoDebtor ? t("coDebtor") : t("guarantor")}
              </LabelSpan>
              <BisnodeCreditInformationBox
                creditCheckDate={creditCheckDate}
                currentCreditRating={currentCreditRating}
                paymentRemarks={paymentRemarks}
                auditorsReservation={auditorsReservation}
                annoucements={annoucements}
                dataWithOutDate={data}
                isPerson={guarantor.info.type === "person"}
                file={file}
                name={name}
                t={t}
              />
            </BoxStyle>
          )
        } else {
          return (
            <BoxStyle>
              <LabelSpan>
                {isCoDebtor ? t("coDebtor") : t("guarantor")}
              </LabelSpan>
              <>
                <BisnodeHiglightPart>
                  <Column>
                    <p>{t("name")}:</p>
                    <p>{t("credit-check-pdf")}:</p>
                  </Column>
                  <Column>
                    <p>{name}</p>
                    <p>{t("no-credit-check-available")}</p>
                  </Column>
                </BisnodeHiglightPart>
              </>
            </BoxStyle>
          )
        }
      })}
    </>
  )
}

export default BisnodeCreditLookup

const FullReport = styled.div`
  margin-top: 2em;
`

const LabelSpan = styled.div`
  ${FontStyles.Large};
`

const BisnodeHiglightPart = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1em;
`
const CollapsibleObjectStyle = styled.div`
  display: flex;
  margin-top: -10px;
`

const BoxStyle = styled.div`
  flex-direction: column;
  border-radius: 5px;
  background-color: var(--flow-color-sea-lightest);
  border: 1px solid var(--flow-color-sea-light);
  padding: 20px;
  margin-bottom: 1em;

  div:first-child {
    margin-left: 0em;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6em;
`

const ColumnValue = styled.p`
  min-height: 1.5em;
`
const StyledDownload = styled.div`
  color: var(--flow-color-grey1);
  padding: 0.1em 0.1em 0.1em 0;
  font-size: small;
  line-height: 0.8em;
  width: 100%;

  button {
    white-space: pre-wrap;
    width: 100%;
  }
  svg {
    display: none;
  }
`
