import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import CollapsibleObject from "../common/CollapsibleObject"
import formatWithLocale from "../../util/dateFormatter"

const ApplicationHeader = ({ item, text, t }) => {
  if (!item?.state) return <div>{text}</div>
  return (
    <div>
      [{t(item.state)} - {t(item.status)}] {item.applicationTypeShortName} -{" "}
      {item.projectName} - {t("application-date")}{" "}
      {formatWithLocale(item.submittedOn, "dd.MM.yyyy")}
    </div>
  )
}

const ApplicationHistory = ({ applications }) => {
  const { t } = useTranslation()
  const previousApplications = applications || []

  if (previousApplications?.length === 0) {
    return <Container>{t("no-previous-cases")}</Container>
  }

  previousApplications.sort(
    (a, b) => new Date(b.submittedOn) - new Date(a.submittedOn)
  )
  return (
    <Container>
      <CollapsibleObject
        hideEmpty
        header={ApplicationHeader}
        item={previousApplications}
      />
    </Container>
  )
}

const Container = styled.div`
  margin: 15px;
`

export default ApplicationHistory
