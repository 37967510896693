import styled from "styled-components"
import React, { useEffect, useState } from "react"
import { Select, TextArea } from "@stacc/flow-ui-components"
import { TransparentButton, SecondaryButton } from "@stacc/flow-ui-components"
import { Icons } from "@stacc/flow-ui-components"
import axios from "axios"
import IconButton from "../common/IconButton"
import EditSpecialTermText from "./EditSpecialTermText"

const getSpecialTermTags = async (category, setError) => {
  let listOfTerms = []
  try {
    const parameter = encodeURIComponent(`${category.label}`)
    const res = await axios.get(`/api/special-terms?tag=${parameter}`)
    const terms = res.data.map((term) => {
      return {
        label: term.title,
        value: term.title,
        userInstructions: term.userInstructions,
        termText: term.terms,
      }
    })
    listOfTerms = terms
  } catch (error) {
    setError(true)
    console.error("An error occured while fetching terms for a given category")
  }
  return listOfTerms
}

/**
 * @typedef {object} AddSpecialTermParams
 * @property {string} termText The text of the term
 * @property {boolean} disabled If the term is disabled
 * @property {string} userInstructions The user instructions for the term
 * @property {string} term The term
 * @property {string} category The category of the term
 */

/**
 * Component containing a special term in a delivery. The special term consists
 * of a category, a term, user instructions if any and the text of the term.
 * @param {object} props The properties of the SpecialTerm component
 * @param {InFlow.SpecialTerm} props.specialTerm Contains information about the term and variables for
 * determining if it is in edit (disabled) or can be deleted.
 * @param {function} props.onDelete Function triggered when deleting a special term
 * @param {(params: AddSpecialTermParams) => void} [props.onAdd] Function triggered when adding a special term in edit
 * @param {function} [props.onCancel] Function trigger when cancling the process of adding a new special term
 * @param {function} props.t Translation
 * @param {boolean} props.disabled If the special term is disabled
 * @param {boolean} props.deletable If the special term is deletable
 * @param {Array} [props.categories] The categories of the special terms
 * @param {InFlow.DeliveryItems} [props.deliveries] The deliveries in the application
 * @param {string[]} [props.deliveryIdsToAdd] The delivery ids to add the special term to
 * @param {function} [props.setDeliveryIdsToAdd] The function to set the delivery ids to add the special term to
 * @param {string} [props.currentDeliveryId] The current delivery id
 * @param {function} [props.setIsDisabledEditingSpecialTerm] The function to set if the special term is disabled
 * @param {(newTermText: string, termId: string, isUserAdded: boolean) => void} [props.editTermText] The function to set the special terms
 * @param {boolean} [props.isEditMode] If the special term is in edit mode
 * @returns {JSX.Element}
 */
const SpecialTerm = ({
  specialTerm,
  onDelete,
  onAdd,
  onCancel,
  editTermText,
  t,
  disabled,
  deletable,
  categories = [],
  deliveries = {},
  deliveryIdsToAdd,
  setDeliveryIdsToAdd,
  currentDeliveryId,
  setIsDisabledEditingSpecialTerm,
  isEditMode,
}) => {
  const Icon = Icons.Help
  const [listOfTerms, setListOfTerms] = useState(specialTerm.terms || [])
  const [currentTerm, setCurrentTerm] = useState(specialTerm)
  const [checkSpecialTermsError, setCheckSpecialTermsError] = useState(false)

  /** @type {[InFlow.SpecialTerm | null, React.Dispatch<React.SetStateAction<InFlow.SpecialTerm | null>>]} */
  const [termInEdit, setTermInEdit] = useState(null) // The term that is currently being edited term {InFlow.SpecialTerm}

  useEffect(() => {
    setCurrentTerm(specialTerm)
  }, [specialTerm])

  const onChangedCategory = async (e, value) => {
    // Start of by clearing the existing data to prevent confusion when waiting for the new
    setListOfTerms([])
    const category = categories.find((category) => {
      return category.value === value
    })
    setCurrentTerm({
      ...currentTerm,
      categoryLabel: category.label,
      category: value,
      term: null,
      userInstructions: null,
      termText: "",
    })
    const listOfTerms = await getSpecialTermTags(
      category,
      setCheckSpecialTermsError
    )
    setListOfTerms(listOfTerms)
  }
  const onChangedTerm = (e, value) => {
    const index = listOfTerms.findIndex((existing) => existing.value === value)
    setCurrentTerm({
      ...currentTerm,
      term: value,
      termLabel: listOfTerms[index].label,
      userInstructions: listOfTerms[index].userInstructions,
      termText: listOfTerms[index].termText,
    })
  }

  const onChangeText = (e) => {
    setCurrentTerm({
      ...currentTerm,
      termText: e.target.value,
    })

    e.target.style.height = `${e.target.scrollHeight}px`
  }

  const onAddSpecialTerm = () => {
    const { termText, disabled, categoryLabel, userInstructions, termLabel } =
      currentTerm
    onAdd({
      termText,
      disabled,
      userInstructions,
      term: termLabel,
      category: categoryLabel,
    })
    setDeliveryIdsToAdd([])
  }

  const handleSelectDelivery = (deliveryId, selectedDeliveryIds) => {
    // If deliveryId is already selected, remove it from the list
    if (deliveryIdsToAdd.includes(deliveryId)) {
      setDeliveryIdsToAdd(selectedDeliveryIds.filter((id) => id !== deliveryId))
    } else {
      setDeliveryIdsToAdd([...selectedDeliveryIds, deliveryId])
    }
  }

  const hasMultipleDeliveries = Object.keys(deliveries || {})?.length > 1

  return (
    <>
      <Divider />
      <FlexRow>
        {(!disabled && (
          <>
            <StyledSelect>
              <Select
                id="category"
                label={t("category")}
                onChange={onChangedCategory}
                options={categories || []}
                disabled={disabled}
                value={currentTerm?.category}
                showBlankOption={!disabled}
                width={"100%"}
              />
            </StyledSelect>
            <Divider />
            <StyledSelect>
              <Select
                id="term"
                label={t("term")}
                onChange={onChangedTerm}
                options={listOfTerms || []}
                disabled={disabled}
                value={currentTerm?.term}
                showBlankOption={!disabled}
                width={"100%"}
                min-width={"355px"}
              />
            </StyledSelect>
          </>
        )) || (
          <SpecialFlexRow>
            <>
              <GreyText>{t("CATEGORY")} </GreyText>
              <Bold>{currentTerm?.category}</Bold>
            </>
            <Divider />
            <>
              <GreyText>{t("TERM")} </GreyText> <Bold>{currentTerm?.term}</Bold>
            </>
            <Divider />
            {!currentTerm.userAdded && (
              <>
                {/* Når den er under endring */}
                {termInEdit && termInEdit.term === currentTerm.term ? (
                  <StyledIconButton
                    onClick={() => {
                      setTermInEdit(null)
                      setIsDisabledEditingSpecialTerm(true)
                    }}
                    icon={"Edit"}
                  />
                ) : (
                  <StyledIconButton
                    onClick={() => {
                      setTermInEdit(currentTerm)
                      setIsDisabledEditingSpecialTerm(false)
                    }}
                    disabled={isEditMode}
                    icon={"Edit"}
                  />
                )}
              </>
            )}

            {deletable && (
              <FlexRow>
                {currentTerm.userAdded && (
                  <>
                    {termInEdit && termInEdit.term === currentTerm.term ? (
                      <StyledIconButton
                        onClick={() => {
                          setTermInEdit(null)
                          setIsDisabledEditingSpecialTerm(true)
                        }}
                        icon={"Edit"}
                      />
                    ) : (
                      <StyledIconButton
                        disabled={isEditMode}
                        onClick={() => {
                          setTermInEdit(currentTerm)
                          setIsDisabledEditingSpecialTerm(false)
                        }}
                        icon={"Edit"}
                      />
                    )}
                    <StyledIconButton
                      disabled={isEditMode}
                      onClick={() => onDelete(specialTerm)}
                      icon={"Trashcan"}
                    />
                  </>
                )}
              </FlexRow>
            )}
          </SpecialFlexRow>
        )}
      </FlexRow>
      {currentTerm?.userInstructions && (
        <>
          <Divider />
          <FlexRow>
            <div>
              <Icon color="var(--flow-color-grey1)" size={20} />
            </div>
            <UserInstructions>{currentTerm?.userInstructions}</UserInstructions>
          </FlexRow>
        </>
      )}
      <Divider />
      {checkSpecialTermsError ? (
        <LoadingPage>
          <Icons.ErrorCircle />
          <div>
            {t("could-not-fetch-terms")} <br /> {t("close-and-try-again")}
          </div>
        </LoadingPage>
      ) : /* 
        When we have started editing a special term, we want to show the text area for EditSpecialTermText for the term
        */

      termInEdit && termInEdit.term === currentTerm.term ? (
        <>
          <EditSpecialTermText
            specialTerm={specialTerm}
            editTermText={(newTermText, selectedTerm) => {
              editTermText(newTermText, selectedTerm, specialTerm.userAdded)
              setTermInEdit(null)
            }}
            t={t}
            onCancel={() => {
              setTermInEdit(null)
              setIsDisabledEditingSpecialTerm(true)
            }}
          />
        </>
      ) : (
        <>
          <StyledTextArea
            name={"termTextArea"}
            onChange={(e) => onChangeText(e)}
            value={currentTerm.termText}
            disabled={disabled}
            placeholder={t("term-text-placeholder")}
          />
          {!disabled && (
            <>
              <StyledCheckBoxWrapper>
                {hasMultipleDeliveries && (
                  <Bold>{t("add-special-terms-multiple")}</Bold>
                )}
                {hasMultipleDeliveries &&
                  Object.keys(deliveries)
                    .filter((deliveryId) => deliveryId !== currentDeliveryId)
                    .map((deliveryId, i) => (
                      <>
                        <StyledCheckBox>
                          <input
                            onChange={(val) =>
                              handleSelectDelivery(deliveryId, deliveryIdsToAdd)
                            }
                            key={deliveryId}
                            type={"checkbox"}
                            checked={deliveryIdsToAdd?.includes(deliveryId)}
                            disabled={disabled}
                          />
                          <label>{deliveries[deliveryId]?.productName}</label>
                        </StyledCheckBox>
                      </>
                    ))}
              </StyledCheckBoxWrapper>
              <Divider />
              <FlexRow>
                <SecondaryButton
                  onClick={() => onAddSpecialTerm()}
                  disabled={!currentTerm.termText}
                >
                  {t("add-special-terms-button-text")}
                </SecondaryButton>
                <TransparentButton onClick={() => onCancel()}>
                  {t("cancel-special-terms-button-text")}
                </TransparentButton>
              </FlexRow>
            </>
          )}
        </>
      )}
    </>
  )
}

export default SpecialTerm

const UserInstructions = styled.div`
  display: flex;
  align-items: center;
  color: var(--flow-color-grey1);
  font-size: 13px;
`
const Bold = styled.div`
  font-weight: 500;
  white-space: nowrap;
`
const GreyText = styled.div`
  color: var(--flow-color-grey1);
`
const StyledSelect = styled.div`
  min-width: 355px;
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
`
// Used for adding space between the trashcan and the categories and terms.
const SpecialFlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;

  div:last-of-type {
    margin-left: auto;
  }
`
const Divider = styled.div`
  margin: 15px;
`
const StyledTextArea = styled(TextArea)`
  width: 100%;
  min-height: 140px;
  border-radius: 5px;
  background: white;
`
const LoadingPage = styled.div`
  display: flex;
  align-items: center;

  div {
    margin-left: 10px;
  }
`
const StyledCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  label {
    padding-left: 0.5em;
  }
  margin-top: 0.5em;
`
const StyledCheckBoxWrapper = styled.div`
  margin-top: 0.5em;
  padding: 0.8em 0em;
`
const StyledIconButton = styled(IconButton)`
  margin-left: auto;
  padding: 5px 0px;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
    color: var(--flow-color-grey1);
  }
`
