import React from "react"

import DisplayMessageTaskInfo from "../components/Annulment/DisplayMessageTaskInfo"

const CancelAccountCoreView = ({
  complete,
  task,
  flow,
  t,
  schema,
  save,
  user,
}) => {
  const deliveries = flow?.data?.deliveries || {}
  const delivery = deliveries[task?.variables?.delivery?.id || ""]
  const replaceWith = delivery?.productName || t(flow.data.application.category)
  const warningText = t("cancel-account-coreview-info-text").replaceAll(
    "[deliveryId]",
    replaceWith
  )
  return (
    <DisplayMessageTaskInfo
      task={task}
      flow={flow}
      t={t}
      schema={schema}
      save={save}
      user={user}
      withTextArea={false}
      isMessageTask={false}
      complete={complete}
      warningText={warningText}
    />
  )
}
export default CancelAccountCoreView
