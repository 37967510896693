import { addThousandSeparator } from "../../util/addThousandSeparator"
import _ from "lodash"
import { calculatePercentageOf } from "../../util/calculatePercentageOf"

export const validate = (
  caseInput = {},
  isProcessingSubsidyEffect,
  productConfiguration,
  t,
  agreements,
  skipValidation = false,
  participants = []
) => {
  const deliveries = caseInput?.activities?.reduce((acc, curr) => {
    curr?.products?.forEach((e) => {
      const productName = e?.product
      if (productName in acc) {
        acc[productName].amount += parseFloat(e?.amount)
        const previousSubs = acc[productName]?.subproducts

        e?.subproducts?.forEach((sub) => {
          if (sub.variable in previousSubs) {
            previousSubs[sub.variable].amount =
              parseFloat(previousSubs[sub.variable].amount) +
              parseFloat(sub.amount)
          } else {
            previousSubs[sub.variable] = _.cloneDeep(curr)
          }
        })
      } else {
        acc[productName] = {
          ...e,
          amount: parseFloat(e?.amount),
          subproducts: e?.subproducts?.reduce((prev, curr) => {
            prev[curr.variable] = _.cloneDeep(curr)

            return prev
          }, {}),
        }
      }
    })
    return acc
  }, {})

  const allProductTypes = Object.keys(deliveries || {}).filter(
    (e) => e !== "not-chosen" && e !== "noOption"
  )

  const errors = []

  // subsidyEffect request can not be in progress
  if (isProcessingSubsidyEffect) errors.push("is-processing-subsidy-effect")

  // All startDates are before endDates
  if (!skipValidation) {
    if (
      caseInput.activities
        .map((e) =>
          e?.products?.length > 0
            ? new Date(e?.activityStart) < new Date(e?.activityEnd)
            : true
        )
        .includes(false)
    ) {
      errors.push("invalid-dates")
    }
  }

  if (!skipValidation && !!participants) {
    // Validate participants
    for (const participant of participants) {
      if (participant.isNew) {
        errors.push("incomplete-participant")
      }

      if (
        !participant.id ||
        !participant.companyName ||
        !participant.role ||
        (participant.isForeignCompany && !participant.countryOfOrigin) ||
        (!participant.isForeignCompany && !participant.organizationNumber)
      ) {
        errors.push(
          t("participant-missing-data").replace(
            `<${t("participant")}>`,
            participant.companyName || participant.organizationNumber || ""
          )
        )
      }
    }
  }

  // Validate customFields
  if (!skipValidation) {
    Object.values(deliveries)
      ?.map((e) => {
        const productId = e.product
        return {
          ...e,
          ...agreements[productId],
        }
      })
      .filter((x) => x.productClass === "Loan")
      .forEach((x) => {
        const maturity = x.customFields.find(
          (field) => field.variable === "maturity"
        )
        const gracePeriod = x.customFields.find(
          (field) => field.variable === "gracePeriod"
        )
        const interestFreePeriod = x.customFields.find(
          (field) => field.variable === "interestFreePeriod"
        )

        const name = productConfiguration[x.product]?.displayName

        if (gracePeriod && gracePeriod.value >= maturity.value) {
          errors.push(
            `${name}: ${t("grace-period-longer-than-or-equal-maturity")}`
          )
        }
        if (interestFreePeriod && interestFreePeriod.value >= maturity.value) {
          errors.push(
            `${name}: ${t(
              "interest-free-period-longer-than-or-equal-maturity"
            )}`
          )
        }

        const durationString = (months) => {
          const years = Math.floor(months / 12)
          months = Math.floor(months) % 12

          if (months === 0) return `${years} år`
          if (years === 0) return `${months} måneder`
          return `${years} år ${months} måneder`
        }

        const formatFieldValue = (type, value) => {
          if (type === "duration") {
            return durationString(value)
          }
          return typeof value === "number" ? addThousandSeparator(value) : value
        }

        x.customFields.forEach((field) => {
          if (field.maximum !== undefined && field.maximum < field.value) {
            errors.push(
              `${name}: ${t("field-must-be-less-than", {
                field: t(field.variable),
                value: formatFieldValue(field.type, field.maximum),
              })}`
            )
          }
          if (field.minimum !== undefined && field.minimum > field.value) {
            errors.push(
              `${name}: ${t("field-must-be-greater-than", {
                field: t(field.variable),
                value: formatFieldValue(field.type, field.minimum),
              })}`
            )
          }
        })
      })
  }

  // subsidyEffect not null
  if (!skipValidation) {
    if (
      caseInput?.activities
        ?.map((e) =>
          e?.products?.map((p) => typeof p?.subsidyEffect === "number")
        )
        .flat()
        .filter((elem) => elem === false)?.length > 0
    ) {
      errors.push("missing-subsidy-effect")
    }
  }

  // fundingintensityCorrect
  if (
    caseInput?.activities
      ?.map((e) =>
        e?.products?.map(
          (p) => p?.fundingIntensity === p?.amount / e?.inputTotal
        )
      )
      .filter((elem) => elem === false)?.length > 0
  ) {
    errors.push("funding-intensity-wrong")
  }

  // at least one product chosen
  if (allProductTypes?.length <= 0) {
    errors.push("no-products")
  }

  // all products have productType
  if (allProductTypes?.includes("noOption")) {
    errors.push("choose-product-type")
  }

  // Amount is set for all products
  if (
    caseInput?.activities
      ?.map((x) =>
        Object.values(x?.products)?.filter(
          (e) => e?.amount === "0" || !e?.amount || e?.amount <= 0
        )
      )
      ?.flat()?.length > 0
  ) {
    errors.push("add-product-value")
  }

  // check for product duplicates
  if (
    caseInput?.activities
      ?.map((e) => e?.products?.map((x) => x?.product))
      ?.map((e) => new Set(e)?.size === e?.length)
      .includes(false)
  ) {
    errors.push("product-duplicates")
  }

  let guaranteeCount = 0

  /**
   * Validations for different deliveries:
   * - funding intensity is too high for product registry limit for product
   * - guarantee percentage is too high for product registry limit for product
   */
  caseInput?.activities?.forEach((x) =>
    Object.values(x?.products)?.forEach((e) => {
      if (e?.product in productConfiguration) {
        const product = productConfiguration[e.product]
        if (
          parseFloat(e?.fundingIntensity) >
          parseFloat(
            productConfiguration[e?.product].fundsDemandSmallerOrEqualPercentage
          )
        ) {
          errors.push(
            `${product.displayNameShort}: ${t("funding-intensity-too-high")} ${
              productConfiguration[e?.product]
                .fundsDemandSmallerOrEqualPercentage
            }%`
          )
        }

        if (product.productClass === "Guarantee") {
          // Check if any of the required fields are missing

          if (!parseInt(e.amount)) {
            errors.push(
              `${product.displayNameShort}: ${t("missing-guarantee-amount")}`
            )
          }
          if (!e.creditAmount) {
            errors.push(
              `${product.displayNameShort}: ${t(
                "missing-guarantee-credit-amount"
              )}`
            )
          }
          if (!e.maturity) {
            errors.push(
              `${product.displayNameShort}: ${t("missing-guarantee-maturity")}`
            )
          }

          guaranteeCount++
          const guaranteePercent = calculatePercentageOf(
            e.amount,
            e.creditAmount
          )
          // Check if the amount or percentage is too high
          const maxGuaranteePercent =
            Math.max(
              ...product.guaranteePercentages.map((item) => item.creditLimit)
            ) * 100

          if (guaranteePercent > maxGuaranteePercent) {
            errors.push(
              `${product.displayNameShort}: ${t(
                "guarantee-percentage-too-high"
              )} ${maxGuaranteePercent}%`
            )
          }
        }
      }
    })
  )

  if (guaranteeCount > 1) {
    errors.push("more-than-one-guarantee")
  }

  const validateDeliveryAmount = (
    deliveries,
    validationRules,
    rule,
    largerThan,
    errorMessage
  ) => {
    Object.keys(deliveries).forEach((key) => {
      if (key in validationRules) {
        if (rule in validationRules[key]) {
          let amount = parseFloat(deliveries[key].amount)
          let limit = parseFloat(validationRules[key][rule])
          let subproductsString = ""
          // ekstra validering dersom det er lagt til ekstra produkter
          if (rule === "amountUpperLimit") {
            const subproducts = deliveries[key]?.subproducts || {}
            const subproductsAmount = Object.keys(subproducts)?.reduce(
              (acc, curr) => {
                subproductsString += ` + ${
                  subproducts[curr].displayNameShort
                }: ${addThousandSeparator(subproducts[curr].amountUpperLimit)}`
                return acc + parseFloat(subproducts[curr].amount)
              },
              0
            )
            if (amount < subproductsAmount) {
              let name = key
              if (key in productConfiguration) {
                name = productConfiguration[key].displayNameShort
              }
              errors.push(
                `${t(name)}: ${t(
                  "subproducts-amount-higher-than-product-amount"
                )}`
              )
            }
            amount -= subproductsAmount
          }
          // Need to check limit because amountUpperLimit might not be set (no upper limit)
          if (limit && largerThan ? amount > limit : amount < limit) {
            let name = key
            if (key in productConfiguration) {
              name = productConfiguration[key].displayNameShort
            }
            errors.push(
              `${t(name)}: ${t(errorMessage)} ${addThousandSeparator(
                validationRules[key][rule]
              )}${subproductsString}`
            )
          }
        }
      }
    })
  }

  // amount too high
  validateDeliveryAmount(
    deliveries,
    productConfiguration,
    "amountUpperLimit",
    true,
    "amount-too-high"
  )

  // amount is too low
  validateDeliveryAmount(
    deliveries,
    productConfiguration,
    "amountLowerLimit",
    false,
    "amount-too-low"
  )

  // subproduct amount too high
  Object.keys(deliveries).forEach((key) => {
    if (key in productConfiguration) {
      if (productConfiguration[key]?.subproducts?.length > 0) {
        const subproducts = deliveries[key].subproducts
        Object.keys(subproducts).forEach((curr) => {
          if (subproducts[curr].amount > subproducts[curr].amountUpperLimit) {
            errors.push(
              `${t(key)}: ${t(subproducts[curr].displayNameShort)}: ${t(
                "amount-too-high"
              )} ${addThousandSeparator(subproducts[curr].amountUpperLimit)}`
            )
          }
        })
      }
    }
  })

  const isValid = errors?.length === 0

  return {
    isValid,
    errors,
  }
}
