import React from "react"
import { Date, Checkbox } from "@stacc/flow-ui-components"
import styled from "styled-components"
import ErrorText from "../common/ErrorText"

const GuarantorForm = ({ id, guarantor, onChange, errors, t }) => {
  const change = (id, value) => {
    if (!onChange || typeof onChange !== "function") return
    const updatedGuarantor = { ...guarantor }
    updatedGuarantor[id] = value
    onChange(updatedGuarantor)
  }

  return (
    <Row>
      <Column>
        <div>{t("confirm-guarantor-documents")}</div>
        <ErrorText error={errors[`signedBailDocument_${id}`]}>
          <Checkbox
            id={"signedBailDocument" + id}
            label={t("signedBailDocumentSigned")}
            name={"signedBailDocument" + id}
            onChange={(e, checked) => change("signedBailDocument", checked)}
            defaultChecked={guarantor.signedBailDocument}
            required
          />
        </ErrorText>
        {guarantor.type === "organization" && (
          <>
            <ErrorText error={errors[`signedBoardDecisionDocument_${id}`]}>
              <Checkbox
                id={"signedBoardDecisionDocument" + id}
                label={t("signedBoardDecisionDocumentSigned")}
                name={"signedBoardDecisionDocument" + id}
                onChange={(e, checked) =>
                  change("signedBoardDecisionDocument", checked)
                }
                defaultChecked={guarantor.signedBoardDecisionDocument}
                required
              />
            </ErrorText>
          </>
        )}
      </Column>
      {guarantor.type === "person" && (
        <div>
          <div>{t("setSignDate")}</div>
          <ErrorText error={errors[`signDate_${id}`]}>
            <Date
              name={"signDate" + id}
              onChange={(e) => change("signDate", e?.target?.value)}
              defaultValue={guarantor.signDate}
              required
            />
          </ErrorText>
        </div>
      )}
    </Row>
  )
}

export default GuarantorForm

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 10px;
`
