import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import Layout from "../components/common/Layout"
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components"

const UpdateCustomerEngagement = ({
  task,
  t,
  flow,
  save,
  trigger,
  schema,
  updateCase,
  close,
}) => {
  const [isProcessing, setProcessing] = useState(false)

  const handleComplete = (values) => {
    setProcessing(true)
    trigger(
      {},
      () => {
        setProcessing(false)
      },
      (err) => {
        console.error("Could not complete task", err)
        setProcessing(false)
      }
    )
  }

  return (
    <Layout forceHeight>
      <MainContainer>
        <div>{t("update-engagement-are-you-sure")}</div>
        <ButtonContainer>
          <PrimaryButton
            type="submit"
            isLoading={isProcessing}
            disabled={isProcessing}
            onClick={() => handleComplete()}
          >
            {t("yes")}
          </PrimaryButton>
          <SecondaryButtonModified
            type="button"
            disabled={isProcessing}
            onClick={() => close()}
          >
            {t("no")}
          </SecondaryButtonModified>
        </ButtonContainer>
      </MainContainer>
    </Layout>
  )
}

const MainContainer = styled.div`
  padding: 15px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
`

export default withTranslation()(UpdateCustomerEngagement)
