import React from "react"
import styled from "styled-components"
import { ProjectColors } from "../common/style/ProjectColors"

const InfoLine = ({ text }) => {
  return (
    <InfoLineWrapper>
      <p>{text}</p>
    </InfoLineWrapper>
  )
}
export default InfoLine

const InfoLineWrapper = styled.div`
  display: flex;
  background: ${ProjectColors.Slate95};
  p {
    display: flex;
    align-items: center;
    font-style: italic;
    padding: 10px;
    :first-letter {
      text-transform: capitalize;
    }
  }
`
