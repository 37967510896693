import { useCallback, useState } from "react"
import styled from "styled-components"
import renderField from "./renderField"
import { TransparentButton } from "@stacc/flow-ui-components"
import { Icons } from "../../common/style/Icons"
import { useFormContext } from "react-hook-form"
import { calculateSubsidyEffectCounterGuarantee } from "../../../util/calculateSubsidyEffect"

const updateFormValues = ({
  loanAmount,
  setValue,
  getValues,
  productConfiguration,
}) => {
  const loanDuration = getValues("bankInformation.loanDuration")
  const guaranteeAmount =
    (loanAmount * productConfiguration.guaranteePercentage) / 100.0
  const subsidyEffect = calculateSubsidyEffectCounterGuarantee(
    guaranteeAmount,
    Math.ceil(loanDuration / 12)
  )
  const commission =
    (guaranteeAmount * productConfiguration.commissionPercentage) / 100.0
  const establishmentFee =
    (guaranteeAmount * productConfiguration.establishmentFeePercentage) / 100.0

  // Update the product values in the form (as a hidden value)
  setValue("product.amount", Math.round(guaranteeAmount))
  setValue("product.subsidyEffect", Math.round(subsidyEffect))
  setValue("bankInformation.commission", Math.round(commission).toString())
  setValue(
    "bankInformation.establishmentFee",
    Math.round(establishmentFee).toString()
  )
}

const ControlledField = ({ field, setEditing, type }) => {
  const [bufferValue, setBufferValue] = useState(field.value)
  const { clearError, setValue, getValues, productConfiguration } =
    useFormContext()
  const onChange = useCallback(
    (value) => {
      setBufferValue(value)
    },
    [setBufferValue]
  )

  const handleOnChange = useCallback(
    (value) => {
      if (field.name === "bankInformation.loanSize") {
        updateFormValues({
          loanAmount: value,
          getValues,
          setValue,
          productConfiguration,
        })
      }
      if (type === "number" || type === "currency") {
        value = Math.round(value)
      }
      field.onChange(value)
      clearError(field.name)
      setEditing(false)
    },
    [
      field,
      type,
      setEditing,
      clearError,
      setValue,
      getValues,
      productConfiguration,
    ]
  )

  return (
    <FieldContainer>
      {renderField(type, { field, onChange, value: bufferValue })}
      <ButtonContainer>
        <Button
          type="button"
          onClick={() => {
            handleOnChange(bufferValue)
          }}
        >
          <Icons.Check color="var(--flow-color-grass-dark)" />
        </Button>
        <Button
          type="button"
          onClick={() => {
            setEditing(false)
          }}
        >
          <Icons.Remove color="var(--flow-color-ferrari)" />
        </Button>
      </ButtonContainer>
    </FieldContainer>
  )
}

export default ControlledField

const FieldContainer = styled.div`
  display: flex;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Button = styled(TransparentButton)`
  padding: 0;
  margin: 0;
  height: 18px;
`
