import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import RadioButton from "../RadioButton"

const FemaleInitiativeRadio = ({ t }) => {
  const { control, errors } = useFormContext()
  return (
    <Controller
      control={control}
      name={"femaleInitiativeRadio"}
      key={"femaleInitiativeRadio"}
      render={({ field: { name, onBlur, onChange, value } }) => (
        <RadioButton
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          name={name}
          errors={errors}
          t={t}
        />
      )}
    />
  )
}

export default FemaleInitiativeRadio
