import { useEffect } from "react"

// Imports themes to be used for f.ex notes app
export default function useInsightThemes() {
  useEffect(() => {
    if (!window?.System?.import) return
    window.System.import("@stacc/themes").then((a) => {
      a.initTheme(false, "stacc")
    })
  }, [])
}
