//id should match authority on user object, after exercised:
//name will be used for queue name and elements

const countiesConfiguration = [
  { name: "agder", countyNumber: ["42"], id: "478" },
  { name: "arktis", countyNumber: ["54", "22", "21", "55", "56"], id: "495" },
  { name: "innlandet", countyNumber: ["34"], id: "469" },
  { name: "more-og-romsdal", countyNumber: ["15"], id: "486" },
  { name: "nordland", countyNumber: ["18"], id: "492" },
  {
    name: "oslo-viken",
    countyNumber: ["03", "30", "31", "32", "33"],
    id: "466",
  },
  { name: "rogaland", countyNumber: ["11"], id: "480" },
  { name: "trondelag", countyNumber: ["50"], id: "489" },
  {
    name: "vestfold-og-telemark",
    countyNumber: ["38", "39", "40"],
    id: "477",
  },
  { name: "vestland", countyNumber: ["46"], id: "485" },
]

export { countiesConfiguration }
