export const addThousandSeparator = (inputNumber, allowZero = false) => {
  if (!inputNumber) {
    if (
      !allowZero ||
      inputNumber === undefined ||
      inputNumber === null ||
      isNaN(inputNumber)
    ) {
      return ""
    }
    if (allowZero && inputNumber === 0) {
      return "0"
    }
  }

  const str = inputNumber.toString()
  let decimalPosition = str.indexOf(".")
  let newStr = ""

  // If there is no decimal, check for a comma
  // toString() might add a comma instead of a decimal depending on the locale
  if (decimalPosition === -1) {
    decimalPosition = str.indexOf(",")
  }

  // If there is no decimal, set the decimalPosition to the length of the string
  if (decimalPosition === -1) {
    decimalPosition = str.length
  }

  // Iterate backwards over the string, adding a space and the number to the new string every 3 characters
  for (let i = decimalPosition - 1; i >= 0; i--) {
    if ((decimalPosition - i) % 3 === 0 && i !== 0) {
      newStr = ` ${str[i]}${newStr}`
    } else {
      newStr = `${str[i]}${newStr}`
    }
  }

  // Add the decimal part of the number to the new string
  if (str.slice(decimalPosition).length > 0) {
    newStr += str.slice(decimalPosition)
  }

  return newStr
}
