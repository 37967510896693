import React, { useState } from "react"
import styled from "styled-components"
import ErrorContext from "../../common/Error"
import ErrorText from "../../common/ErrorText"
import { TextArea, Text } from "@stacc/flow-ui-components"
import { Controller, useFormContext } from "react-hook-form"
import { ProjectColors } from "../../common/style/ProjectColors"
import Heading from "../../common/Heading"

const TextBoxInput = ({ fieldName, t, id = "", disabled }) => {
  const { control, errors } = useFormContext()
  return (
    <Controller
      name={fieldName}
      control={control}
      key={`${id}${fieldName}`}
      render={({ field: { name, key, onChange, value, onBlur, ...rest } }) => (
        <InputField>
          <ErrorText error={errors[name]?.message}>
            <Text
              disabled={disabled}
              label={t(name)}
              name={name}
              key={key}
              id={key}
              onChange={onChange}
              value={value}
            />
          </ErrorText>
        </InputField>
      )}
    />
  )
}

const TextAreaInput = ({
  fieldName,
  t,
  id = "",
  fullLine,
  placeholder = "",
  disabled = false,
  className = "",
}) => {
  const { control, errors } = useFormContext()
  return (
    <Controller
      name={fieldName}
      control={control}
      key={`${id}${fieldName}`}
      render={({ field: { name, key, onChange, value, onBlur, ...rest } }) => (
        <TextAreaWrapper className={className} fullLine={fullLine}>
          <InputField>
            <ErrorText error={errors[name]?.message}>
              <TextArea
                label={t(name)}
                name={name}
                key={key}
                id={key}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
              />
            </ErrorText>
          </InputField>
        </TextAreaWrapper>
      )}
    />
  )
}

const Select = ({
  onChange,
  onBlur,
  name,
  value,
  errors,
  t,
  options,
  disabled = false,
  ...rest
}) => {
  const error = errors[name]?.message
  return (
    <StyledDropdown>
      <Heading>{t(name)}</Heading>
      <select
        id={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value ?? ""}
        disabled={disabled}
      >
        <option key={`${name}noOption`} id={`${name}noOption`} value={null}>
          {t("choose")}
        </option>
        {options?.map((optionItem) => {
          return (
            <option
              key={optionItem.value}
              id={optionItem.value}
              value={optionItem?.value}
            >
              {optionItem?.name}
            </option>
          )
        })}
      </select>
      <ErrorContext t={t} error={error} />
    </StyledDropdown>
  )
}

const SelectInput = ({ fieldName, t, options, disabled, onChanged }) => {
  const { control, errors } = useFormContext()
  return (
    <Controller
      control={control}
      name={fieldName}
      key={fieldName}
      render={({ field: { name, onBlur, onChange, value } }) => (
        <Select
          onBlur={onBlur}
          onChange={(e) => {
            onChanged && onChanged(e)
            onChange(e)
          }}
          value={value}
          name={name}
          errors={errors}
          t={t}
          options={options}
          disabled={disabled}
        />
      )}
    />
  )
}

const InputField = styled.div`
  grid-column: ${(props) => props.fullLine && "1 / span 2"};
  padding: ${(props) => props.fullLine && "0 0 1em 0"};
  margin-right: 50px;
  margin-bottom: 10px;
`

const TextAreaWrapper = styled.div`
  display: grid;
  grid-column: ${(props) => props.fullLine && "1 / span 2"};
  padding: ${(props) => props.fullLine && "0 0 1em 0"};
  TextArea {
    width: 100%;
  }
`

const StyledDropdown = styled.div`
  select {
    padding: 3px 5px 3px 5px;
    border-radius: 5px;
    border: solid 1px ${ProjectColors.InputBorder};
    color: inherit;
    min-width: 18em;
  }
`

export { TextBoxInput, TextAreaInput, SelectInput }
