import React from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import Layout, { Context } from "../components/common/Layout"

import PrintAndSendToSignNew from "../components/printAndSendToSign/PrintAndSendToSignNew"
import PrintAndSendToSignOld from "../components/printAndSendToSign/PrintAndSendToSignOld"

const PrintAndSendToSign = ({ task, t, flow, schema, save, complete }) => {
  const deliveryId = task?.variables?.delivery?.id
  const delivery = flow.data?.deliveries[deliveryId]
  const mortgagors = task.defaults.relevantMortgagors
  const isMaintenance = flow.data?.deliveries?.vedlikehold ?? false
  const decisionTexts = flow?.data?.deliveries?.vedlikehold?.changes

  const guarantors =
    mortgagors?.map((x) => ({
      ...x,
      ...x.info,
      isSigningWithCollateral: true,
    })) ||
    delivery?.guarantors?.map((x) => ({
      ...x,
      ...x.info,
    })) ||
    []

  const isSigningWithCollateral = guarantors.some(
    (x) => x.isSigningWithCollateral
  )

  return (
    <Layout forceHeight>
      {isSigningWithCollateral ? (
        <PrintAndSendToSignNew
          t={t}
          guarantors={guarantors}
          task={task}
          schema={schema}
          save={save}
          complete={complete}
          flow={flow}
          isMaintenance={isMaintenance}
          decisionTexts={decisionTexts}
        />
      ) : (
        <PrintAndSendToSignOld
          t={t}
          guarantors={guarantors}
          task={task}
          schema={schema}
          save={save}
          complete={complete}
          flow={flow}
        />
      )}
    </Layout>
  )
}

export default withTranslation()(PrintAndSendToSign)
