import { useEffect, useState } from "react"

/**
 * Checks whther the app is currently initialized or not and updates when it is.
 * @param {Insight app instance} app
 * @returns whether the app is ready to be used
 */
export const useInsightAppInitialized = (app) => {
  const [isLoading, setLoading] = useState(false)
  const [isErrored, setErrored] = useState(false)
  useEffect(() => {
    setErrored(false)
    if (!app) {
      setLoading(false)
      return
    }
    setLoading(true)
    let retries = 0
    const interval = setInterval(async () => {
      let result = {}
      try {
        result = app.getCurrentState()
      } catch (e) {
        result.isInitialized = false
      }
      if (result?.isInitialized) {
        clearInterval(interval)
        setLoading(false)
      }
      // 15 seconds timeout
      if (retries > 20) {
        setLoading(false)
        setErrored(true)
        clearInterval(interval)
      }
      retries++
    }, 500)
    return () => {
      setLoading(false)
      clearInterval(interval)
    }
  }, [app])
  return [isLoading, isErrored]
}
