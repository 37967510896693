import React, { useState } from "react"
import styled from "styled-components"

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`

const TooltipIcon = styled.span`
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  border: 1px solid lightgray;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  width: 20px;
  height: 20px;
  color: #467eff;
  justify-content: center;
`

const TooltipBox = styled.div`
  position: absolute;
  width: 200px;
  top: 0;
  left: calc(100% + 5px);
  background-color: #467eff;
  color: white;
  padding: 1em;
  border-radius: 6px;
  z-index: 1;
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: visibility 0.2s, opacity 0.2s ease-in-out;
`

const Tooltip = ({ info }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <TooltipContainer>
      <TooltipIcon
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        ?
      </TooltipIcon>
      <TooltipBox isVisible={isVisible}>{info}</TooltipBox>
    </TooltipContainer>
  )
}

export default Tooltip
