import React from "react"
import SuffixedInput from "./SuffixedInput"
import styled from "styled-components"

const clamp = (value, min, max) => {
  if (value < min) return min
  if (value > max) return max
  return value
}

const DurationField = ({
  label,
  onChange,
  readonly,
  value,
  field: { id, key, name, onBlur },
}) => {
  return (
    <DurationStyle>
      <SuffixedInput
        type="number"
        suffix="år"
        width={60}
        name={name}
        id={id}
        readonly={readonly}
        label={label}
        key={key}
        onChange={(newValue) => {
          newValue = Number.parseInt(newValue) || 0
          const existingMonths = Math.round(value) % 12 || 0
          onChange(newValue * 12 + existingMonths)
        }}
        value={Math.floor(Math.round(value) / 12) || 0}
        field={{ id, key, name, onBlur }}
      />
      <SuffixedInput
        type="number"
        suffix="mnd"
        width={60}
        readonly={readonly}
        name={name}
        id={id}
        label={label}
        key={key}
        onChange={(newValue) => {
          newValue = clamp(Number.parseInt(newValue) || 0, 0, 11)
          const existingYears = Math.floor(Math.round(value) / 12) || 0
          onChange(existingYears * 12 + newValue)
        }}
        value={Math.round(value) % 12 || 0}
        field={{ id, key, name, onBlur }}
      />
    </DurationStyle>
  )
}

const DurationStyle = styled.div`
  display: flex;
`

export default DurationField
