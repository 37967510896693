import * as yup from "yup"
import { object, string } from "yup"

const baseValidator = yup
  .string()
  .typeError("type-error")
  .nullable()
  .required("required-error")

export const createSecurityTextValidationSchema = object({
  titleCategory: baseValidator,
  titleSubCategory: baseValidator,
  title: baseValidator,
  contentCategory: baseValidator,
  contentSubCategory: baseValidator,
  contentText: baseValidator,
  subtitle: baseValidator,
  text: baseValidator,
})

export const createSecurityTextDefaultValues = {
  titleCategory: null,
  titleSubCategory: null,
  title: null,
  contentCategory: null,
  contentSubCategory: null,
  contentText: null,
  subtitle: null,
  text: null,
}
