import React, { useState } from "react"
import styled from "styled-components"
import { Tabs } from "@stacc/flow-ui-components"
import { withTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components"
import BlueDot from "../components/common/BlueDot"
import Details from "../components/performFinancialCheck/Details"
import { getInitialState } from "./performFinancialCheck"
import AssessmentComments from "../components/common/AssessmentComments"

const SendGuaranteeDeclaration = ({
  task,
  flow,
  t,
  schema,
  save,
  complete,
  user,
}) => {
  const { data, defaults, variables } = task
  const deliveryId = task?.variables.delivery.id
  const currentDelivery = flow?.data?.deliveries[deliveryId]

  const [activeTab, setActiveTab] = useState("details")
  const [isProcessing, setProcessing] = useState(false)
  const [formData, setFormData] = useState(data || {})

  const thisDeliveryAsssessments = flow?.data?.assessmentComments.filter(
    (x) => !x.deliveryId || x.deliveryId === deliveryId
  )
  const { payoutRequestReceived, commitment } =
    currentDelivery.currentPayout || {}
  const { applicationSummary } = task.context
  const customerAccount = flow?.data.raw?.accountMaster?.customerAccount || {}
  const applicantName = customerAccount?.name
  const initialState = getInitialState(
    data?.stateData,
    defaults,
    variables,
    flow
  )

  initialState.payments = initialState.payments || [
    {
      customerAccountNumber: payoutRequestReceived?.customerAccountNumber,
      payoutName: applicantName,
      id: uuidv4(),
      payoutOrgNumber: flow.data.raw.accountMaster.customerAccount.publicId,
      isPerson:
        flow.data.raw.accountMaster.customerAccount.accountType === "Person",
      payoutApplicantOrThirdPerson: "applicant",
      payoutAmount: payoutRequestReceived?.payoutAmount,
    },
  ]

  const options = [
    {
      id: "details",
      title: t("details"),
      component: (
        <Details
          deliveryId={deliveryId}
          flow={flow}
          readonly={true}
          t={t}
          stateData={initialState}
        />
      ),
    },
    {
      id: "assessment",
      title: t("assessment-history"),
      children: thisDeliveryAsssessments.length ? (
        <BlueDot>{thisDeliveryAsssessments.length}</BlueDot>
      ) : undefined,
      component: (
        <AssessmentCommentsWrapper>
          <AssessmentComments comments={thisDeliveryAsssessments} t={t} />
        </AssessmentCommentsWrapper>
      ),
    },
  ]

  const handleComplete = () => {
    setProcessing(true)
    complete(
      {
        controlComment: formData.controlComment,
        controlledProducedAndArchived: formData.controlledProducedAndArchived,
        controlledSentToBankAndCopySentToCustomer:
          formData.controlledSentToBankAndCopySentToCustomer,
      },
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const handleSave = () => {
    setProcessing(true)
    save(
      {
        controlComment: formData.controlComment,
        controlledProducedAndArchived: formData.controlledProducedAndArchived,
        controlledSentToBankAndCopySentToCustomer:
          formData.controlledSentToBankAndCopySentToCustomer,
      },
      () => {
        setProcessing(false)
      }
    )
  }

  const allChecked =
    formData.controlledProducedAndArchived &&
    formData.controlledSentToBankAndCopySentToCustomer

  return (
    <Layout forceHeight>
      <MainContent>
        <Content>
          <Tabs options={options} onChange={(e) => setActiveTab(e)} />
          <TabContainer>
            {options.map((x, i) => (
              <div
                key={i}
                style={{ display: x.id === activeTab ? "block" : "none" }}
              >
                {x.component}
              </div>
            ))}
          </TabContainer>
        </Content>
      </MainContent>
      <Context flow={flow} context={applicationSummary}>
        <StyledForm>
          <ReactForm
            schema={schema}
            formData={formData}
            disabled={isProcessing}
            onChange={(values) => {
              setFormData({
                ...formData,
                controlComment: values.controlComment,
                controlledProducedAndArchived:
                  values.controlledProducedAndArchived,
                controlledSentToBankAndCopySentToCustomer:
                  values.controlledSentToBankAndCopySentToCustomer,
              })
            }}
          >
            <></>
          </ReactForm>
        </StyledForm>
        <ButtonContainer>
          <PrimaryButton
            type="submit"
            onClick={() => handleComplete()}
            disabled={isProcessing || !allChecked}
          >
            {t("complete")}
          </PrimaryButton>
          <SecondaryButton
            type="button"
            onClick={() => handleSave()}
            disabled={isProcessing}
          >
            {t("save")}
          </SecondaryButton>
        </ButtonContainer>
      </Context>
    </Layout>
  )
}

export default withTranslation()(SendGuaranteeDeclaration)

const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`
const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  overflow-y: scroll;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`

const Content = styled.div`
  flex: 1;
  padding: 10px 40px;
  overflow-y: auto;
`
const TabContainer = styled.div`
  margin: 20px 0;
  flex: 1;
`

const AssessmentCommentsWrapper = styled.div`
  padding: 10px 40px;
  overflow-y: auto;
`
