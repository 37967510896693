import * as yup from "yup"
import React, { useState } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components"
import { Date as FlowDate } from "@stacc/flow-ui-components"
import { handleSave } from "../components/BaseComponent/utils/mutations"
import InputWrapper from "../components/common/inputs/StyledInput"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { addThousandSeparator } from "../util/addThousandSeparator"
import DownloadFileButton from "../components/common/DownloadFileButton"
import ErrorContext from "../components/common/Error"
import _ from "lodash"
import { yupResolver } from "@hookform/resolvers/yup"
import { realtyLookup } from "../components/TermsAndSecurity/cadastreLookup"
import RegisterCollateralManualComponent from "../components/RegisterCollateralManual/RegisterCollateralManual"
import Tabs from "../components/common/Tabs"
import DisplaySecurityText from "../components/TermsAndSecurity/DisplaySecurityText"
import SpecialTermsTab from "../components/AnalyzeApplication/SpecialTermsTab"
import DecisionTextsList from "../components/maintenance/DecisionTextsList"
import BentoInput from "../components/common/inputs/bento/BentoInput"

const validationSchemaMoveables = yup.object().shape({
  dateTinglyst: yup.string().required("required-error").nullable(),
  diaryNumber: yup.string().required("required-error").nullable(),
  comment: yup.string().required("required-error").nullable(),
})
const validationSchemaRealEstate = yup.object().shape({
  dokumentAar: yup.string().nullable(),
  dokumentNr: yup.string().nullable(),
  rettsstiftelsesnummer: yup.string().nullable(),
  embete: yup.string().nullable(),
  dateTinglyst: yup.string().nullable(),
  comment: yup.string().required("required-error").nullable(),
})
const validationSchemaSikkerhet = yup.object().shape({
  comment: yup.string().required("required-error").nullable(),
})

const defaultValuesMoveables = {
  dateTinglyst: null,
  comment: null,
  diaryNumber: null,
}
const defaultValuesRealEstate = {
  dateTinglyst: null,
  comment: null,
  dokumentAar: null,
  dokumentNr: null,
  rettsstiftelsesnummer: null,
  embete: null,
}
const defaultSikkerhetsTekst = {
  comment: null,
  collateralSecurityTextComment: null,
}

const RegisterCollateralManual = ({
  task,
  flow,
  t,
  schema,
  save,
  complete,
  user,
}) => {
  const { data, context, variables } = task
  const currentUser = user?.profile?.name
  const pathCurrentCollateralElement = `deliveries.${variables.delivery.id}.collateral.manualTinglysing.${variables.collateralElement}`
  const {
    applicationSummary,
    oldCadastres = {},
    newCadastres = {},
    agreementData = {},
    relevantDocuments = [],
    manuallyAddedTexts = [],
  } = context
  const flowId = flow.flowId
  const [activeTab, setActiveTab] = useState("collateral")
  const [isLooking, setIsLooking] = useState([])
  const [updatedNewCadastres, setUpdatedNewCadastres] = useState(newCadastres)
  const [isProcessing, setProcessing] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const validate = (data) => {
    let errors = []
    if (!data?.comment) {
      errors.push(t("comment-missing"))
    }
    setValidationErrors(errors)
    return errors.length === 0
  }
  const {
    agreementType = "SECURITYTEXT",
    collateralAmount,
    collateralObject,
  } = agreementData
  const isRealEstate = agreementType === "REAL_ESTATE"
  const isMaintenance = flow?.data?.deliveries?.vedlikehold
  const handleComplete = (data) => {
    const newData = {
      ...data,
      ...data[variables?.collateralElement],
      agreementType: agreementType,
    }
    setProcessing(true)
    complete(
      newData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const getLookupCadastrePdf = async (cadastreKey) => {
    const lookedUp = await realtyLookup(
      "cadastrepdf",
      cadastreKey,
      flowId,
      [],
      pathCurrentCollateralElement + ".realty",
      pathCurrentCollateralElement,
      "start-lookup-cadastrepdf",
      variables.collateralElement,
      "manualTinglysing"
    )
    const file = {
      storedFile: lookedUp.data,
      timestamp: lookedUp.timestamp,
    }
    const copy = _.cloneDeep(updatedNewCadastres)
    if (lookedUp.cadastre in copy) {
      copy[lookedUp.cadastre].cadastrepdf.push(file)
    } else {
      copy[lookedUp.cadastre] = {
        cadastrepdf: [file],
        cadastre: [],
      }
    }
    setUpdatedNewCadastres(copy)
  }
  const getDefaultValues = (agreementType) => {
    if (agreementType === "REAL_ESTATE") {
      return defaultValuesRealEstate
    } else if (agreementType === "MOVEABLES") {
      return defaultValuesMoveables
    } else {
      return defaultSikkerhetsTekst
    }
  }
  const getYupConfig = (agreementType) => {
    if (agreementType === "REAL_ESTATE") {
      return validationSchemaRealEstate
    } else if (agreementType === "MOVEABLES") {
      return validationSchemaMoveables
    } else {
      return validationSchemaSikkerhet
    }
  }

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",

    resolver: yupResolver(getYupConfig(agreementType)),
    defaultValues: { ...getDefaultValues(agreementType), ...data },
  })

  const onSave = () => {
    const values = methods.getValues()
    handleSave(save, values, setProcessing)
  }
  const onFormChange = (value) => {
    methods.setValue("comment", value?.comment)
  }

  const { errors } = methods.formState

  const schemaData = [
    ...(isRealEstate
      ? [
          {
            title: t("dokumentAar"),
            value: (
              <InputWrapper>
                <Controller
                  name={`dokumentAar`}
                  control={methods.control}
                  key={`dokumentAar`}
                  render={({
                    field: { name, onChange, onBlur, value, ...rest },
                  }) => (
                    <BentoInput
                      name={name}
                      id={name}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorContext
                  t={t}
                  error={_.get(errors, `dokumentAar.message`, "")}
                />
              </InputWrapper>
            ),
          },
          {
            title: t("dokumentNr"),
            value: (
              <InputWrapper>
                <Controller
                  name={`dokumentNr`}
                  control={methods.control}
                  key={`dokumentNr`}
                  render={({
                    field: { name, onChange, onBlur, value, ...rest },
                  }) => (
                    <BentoInput
                      name={name}
                      id={name}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorContext
                  t={t}
                  error={_.get(errors, `dokumentNr.message`, "")}
                />
              </InputWrapper>
            ),
          },
          {
            title: t("rettsstiftelsesnummer"),
            value: (
              <InputWrapper>
                <Controller
                  name={`rettsstiftelsesnummer`}
                  control={methods.control}
                  key={`rettsstiftelsesnummer`}
                  render={({
                    field: { name, onChange, onBlur, value, ...rest },
                  }) => (
                    <BentoInput
                      name={name}
                      id={name}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorContext
                  t={t}
                  error={_.get(
                    methods.formState.errors,
                    `rettsstiftelsesnummer.message`,
                    ""
                  )}
                />
              </InputWrapper>
            ),
          },
          {
            title: t("embete"),
            value: (
              <InputWrapper>
                <Controller
                  name={`embete`}
                  control={methods.control}
                  key={`embete`}
                  render={({
                    field: { name, onChange, onBlur, value, ...rest },
                  }) => (
                    <BentoInput
                      name={name}
                      id={name}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorContext
                  t={t}
                  error={_.get(errors, `embete.message`, "")}
                />
              </InputWrapper>
            ),
          },
        ]
      : [
          {
            title: t("diaryNumber"),
            value: (
              <InputWrapper>
                <Controller
                  name={`diaryNumber`}
                  control={methods.control}
                  key={`diaryNumber`}
                  render={({
                    field: { name, onChange, onBlur, value, ...rest },
                  }) => (
                    <BentoInput
                      name={name}
                      id={name}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorContext
                  t={t}
                  error={_.get(errors, `diaryNumber.message`, "")}
                />
              </InputWrapper>
            ),
          },
        ]),
    {
      title: t("date-tinglyst"),
      value: (
        <>
          <Controller
            name={`dateTinglyst`}
            key={`dateTinglyst`}
            control={methods.control}
            render={({
              field: { name, onChange, onBlur, ref, value, ...rest },
            }) => (
              <StyledDate
                name={name}
                id={name}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <ErrorContext
            t={t}
            error={_.get(errors, `dateTinglyst.message`, "")}
          />
        </>
      ),
    },

    {
      title: t("type"),
      value: t(agreementType),
    },
    {
      title: t("PantAmount"),
      value: addThousandSeparator(collateralAmount),
    },
    {
      title: t("SecuredFor"),
      value: (
        <>
          {agreementData?.collateralObjectSecurity?.map((security, i) => (
            <div key={i}>{security?.productName}</div>
          ))}
        </>
      ),
    },
    {
      title: t("Financing"),
      value: (
        <>
          {agreementData?.collateralObjectSecurity?.map((security, i) => (
            <div key={i}>{addThousandSeparator(security?.productAmount)}</div>
          ))}
        </>
      ),
    },
    {
      title: t("safetyRisk"),
      value: (
        <>
          {agreementData?.collateralObjectSecurity?.map((security, i) => (
            <div key={i}>{security?.safetyRisk}</div>
          ))}
        </>
      ),
    },
    {
      title: t("pant-documents"),
      value: (
        <>
          {relevantDocuments
            .filter((x) => !!x)
            .map((x) => {
              return (
                <StyledDownload>
                  <DownloadFileButton
                    title={t(x?.filename)}
                    fileId={x?.storeID}
                    key={x?.storeID}
                  />
                </StyledDownload>
              )
            })}
        </>
      ),
    },
  ]

  const options = [
    {
      id: "collateral",
      title: "Manuell sikkerhetshåndtering",
      component: (
        <MainContent>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(handleComplete)}
              id="manualTinglysing"
            >
              <RegisterCollateralManualComponent
                schemaData={schemaData}
                collateralObjects={collateralObject}
                t={t}
                oldCadastres={oldCadastres}
                updatedNewCadastres={updatedNewCadastres}
                getLookupCadastrePdf={getLookupCadastrePdf}
                isLooking={isLooking}
                setIsLooking={setIsLooking}
                securityTexts={manuallyAddedTexts}
                isRealEstate={isRealEstate}
                agreementType={agreementType}
                currentUser={currentUser}
                agreementId={variables?.collateralElement}
              />
            </form>
          </FormProvider>
        </MainContent>
      ),
    },
    {
      id: "securityTexts",
      title: "Sikkerhetstekster",
      component: (
        <SecurityTextContainer>
          {flow.data?.deliveries[variables.delivery.id].securityTexts?.map(
            (securityText, securityTextIndex) => {
              return (
                <DisplaySecurityText
                  t={t}
                  key={securityTextIndex}
                  isEmpty={!securityText?.subtitle?.length}
                  isFirst={securityTextIndex === 0}
                  securityText={securityText}
                  userGenerated={securityText.userGenerated}
                  isReadOnly={true}
                />
              )
            }
          )}
        </SecurityTextContainer>
      ),
    },
    {
      id: "specialconditions",
      title: "Særvilkår",
      component: (
        <SpecialTermsTab
          deliveries={flow.data?.deliveries}
          onChange={() => {}}
          t={t}
          readOnly={true}
        />
      ),
    },
  ]
  //Legge til tabber dersom det er vedlikehold
  if (isMaintenance) {
    const decisionTexts = flow?.data?.deliveries?.vedlikehold?.changes

    options.push({
      id: "decisiontexts",
      title: t("decisionTexts"),
      component: (
        <DecisionStyle>
          <DecisionTextsList t={t} changes={decisionTexts} />
        </DecisionStyle>
      ),
    })
  }
  return (
    <Layout forceHeight>
      <BMAnalyzeContent>
        <Tabs options={options} onChange={(e) => setActiveTab(e)} />
      </BMAnalyzeContent>
      <Context flow={flow} context={applicationSummary}>
        <StyledForm>
          <ReactForm
            schema={{
              properties: {
                comment: {
                  type: "string",
                  title: "Intern kommentar",
                  uiSchema: {
                    "ui:widget": "textarea",
                  },
                },
              },
            }}
            disabled={isProcessing}
            onChange={(values) => onFormChange(values)}
            formData={data}
            onSubmit={(value) => handleComplete(value)}
          >
            <ErrorContext t={t} error={_.get(errors, `comment.message`, "")} />
            <ButtonContainer>
              <PrimaryButton
                type="submit"
                form="manualTinglysing"
                isLoading={isProcessing}
                disabled={isProcessing}
              >
                {t("complete")}
              </PrimaryButton>
              <SecondaryButtonModified
                type="button"
                onClick={() => onSave()}
                disabled={isProcessing}
              >
                {t("save")}
              </SecondaryButtonModified>
            </ButtonContainer>
          </ReactForm>
        </StyledForm>
      </Context>
    </Layout>
  )
}
export default withTranslation()(RegisterCollateralManual)
const MainContent = styled.div`
  height: 100%;
  flex: 1;
  padding: 2em;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0 em - 1 em 0em 1em;
`

const StyledDate = styled(FlowDate)`
  color: var(--flow-color-grey1);
  padding: 0.15em;
  font-size: small;
  line-height: 0.8em;
`

const StyledDownload = styled.div`
  color: var(--flow-color-grey1);
  padding: 0.1em 0.1em 0.1em 0;
  font-size: small;
  line-height: 0.8em;
  width: 100%;

  button {
    white-space: pre-wrap;
    width: 100%;
  }

  svg {
    display: none;
  }
`

const BMAnalyzeContent = styled.div`
  height: 100%;
  width: 150%;
  border-right: 1px solid #e4e2e2;
`
const SecurityTextContainer = styled.div`
  padding: 23px;
`
const DecisionStyle = styled.div`
  padding: 20px;
`
