import React, { Component } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { SecondaryButton } from "@stacc/flow-ui-components"
import { Icons } from "@stacc/flow-ui-components"

import { getFile, getFileMetadata } from "./services/files"
import downloadLink from "../../util/downloadLink"

const DownloadButtonStyled = styled(SecondaryButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ fontSize }) => fontSize};

  svg {
    padding-right: 1em;
  }
`

class DownloadButton extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onDownloadClick(e) {
    e.preventDefault()
    const { fileId } = this.props

    getFileMetadata(fileId).then((response) => {
      if (!response) {
        console.error("Error getting file meta data")
        return
      }

      const metadata = response.data
      const { filename } = metadata

      getFile(fileId).then((result) => {
        if (result && result.file) {
          return downloadLink(result.file, filename)
        }
        return null
      })
    })
  }

  render() {
    const { title, fontSize = "small" } = this.props
    return (
      <DownloadButtonStyled
        size="large"
        fontSize={fontSize}
        onClick={(e) => this.onDownloadClick(e)}
      >
        <Icons.FilePdf />
        {title}
      </DownloadButtonStyled>
    )
  }
}

DownloadButton.propTypes = {
  fileId: PropTypes.string.isRequired,
  title: PropTypes.string,
}

DownloadButton.defaultProps = {
  title: "Download file",
}

export default DownloadButton
