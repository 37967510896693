import React from "react"
import Notice from "../common/Notice"
import { Icons } from "../common/style/Icons"
import StyledBox from "../common/StyledBox"
import styled from "styled-components"
import { useState } from "react"
import EditGuarantor from "./EditGuarantor"
import { addThousandSeparator } from "../../util/addThousandSeparator"

const GuarantorIcon = ({ type }) => {
  switch (type) {
    case "person":
      return <Icons.GuarantorPerson size={36} />
    case "organization":
      return <Icons.GuarantorOrganization size={36} />
    default:
      return <></>
  }
}

const getFieldsForOldCases = (guarantor, t) => {
  const fields = [
    { title: "name", value: guarantor?.name },
    { title: "ssn", value: guarantor?.ssn },
    { title: "organizationNumber", value: guarantor?.organizationNumber },
    {
      title: "country",
      value:
        guarantor?.scope !== "domestic" ? guarantor?.organizationCountry : null,
    },
    { title: "email", value: guarantor?.email },
    { title: "mobile", value: guarantor?.mobile },
    {
      title: "denomination-in-nok",
      value: guarantor?.amount
        ? addThousandSeparator(guarantor?.amount)
        : t("not-set"),
      newLine: true,
    },
    {
      title: "address",
      value: guarantor?.address ? formatAddress(guarantor?.address) : null,
    },
    {
      title: "contactPersonName",
      value: guarantor?.contactPersonName,
    },
    {
      title: "contactPersonEmail",
      value: guarantor?.contactPersonEmail,
    },
    {
      title: "contactPersonMobile",
      value: guarantor?.contactPersonMobile,
    },
    {
      title: "additional info",
      value: guarantor?.additionalInfo,
      newLine: true,
      fullWidth: true,
      type: "textarea",
    },
  ].filter((x) => !!x.value)
  return fields
}

const getFieldsForNewCases = (guarantor, t) => {
  const fields = [
    { title: "name", value: guarantor?.name },
    { title: "ssn", value: guarantor?.ssn },
    { title: "organizationNumber", value: guarantor?.organizationNumber },
    {
      title: "country",
      value:
        guarantor?.scope !== "domestic" ? guarantor?.organizationCountry : null,
    },
    { title: "email", value: guarantor?.email },
    { title: "mobile", value: guarantor?.mobile },
    {
      title: "address",
      value: guarantor?.address ? formatAddress(guarantor?.address) : null,
    },
    {
      title: "contactPersonName",
      value: guarantor?.contactPersonName,
    },
    {
      title: "contactPersonEmail",
      value: guarantor?.contactPersonEmail,
    },
    {
      title: "contactPersonMobile",
      value: guarantor?.contactPersonMobile,
    },
    {
      title: "additional info",
      value: guarantor?.additionalInfo,
      newLine: true,
      fullWidth: true,
      type: "textarea",
    },
  ].filter((x) => !!x.value)
  return fields
}
const Item = ({ title, data }) =>
  (data && (
    <div>
      <StyledBox whiteSpace padding="0 10px" title={title} data={data} large />
    </div>
  )) || <div></div>

const formatAddress = (address) =>
  (address?.streetAddress || address?.street) +
  "\n" +
  address?.postalCode +
  " " +
  address?.town

const GuarantorCard = ({
  guarantor,
  t,
  children,
  readonly,
  onChange,
  isSigningWithCollateral,
}) => {
  let fields
  if (isSigningWithCollateral) {
    fields = getFieldsForNewCases(guarantor, t)
  } else {
    fields = getFieldsForOldCases(guarantor, t)
  }

  const [editGuarantor, setEditGuarantor] = useState(null)
  const isEditing = !readonly && editGuarantor

  if (isEditing) {
    fields = fields.filter((x) =>
      ["name", "ssn", "organizationNumber", "country"].includes(x.title)
    )
  }

  const startEdit = () => {
    const editGuarantor = { ...guarantor }
    editGuarantor.editing = true
    onChange(editGuarantor)
    setEditGuarantor(editGuarantor)
  }

  const cancelEdit = () => {
    setEditGuarantor(null)
    const oldGuarantor = { ...guarantor }
    oldGuarantor.editing = false
    onChange(oldGuarantor)
  }

  return (
    <GuarantorCardContainer>
      <Notice>
        <p>{t(guarantor.agreementType)}</p>
        <div className="top-section">
          <div className="icon">
            <GuarantorIcon type={guarantor.type} />
          </div>
          <div className="content">
            {fields.map((x, i) => (
              <div key={`content_${i}`}>
                {x.newLine && (
                  <div style={{ flexBasis: "100%", height: 0 }}></div>
                )}
                <div
                  key={i}
                  className={
                    x.fullWidth ? "content-item full-width" : "content-item"
                  }
                >
                  <Item key={i} title={t(x.title)} data={x.value} />{" "}
                </div>
              </div>
            ))}
          </div>
          {!isEditing && !readonly && (
            <div className="options">
              <div onClick={() => startEdit()}>
                <Icons.Edit color="var(--flow-color-sea)" size="26" />
              </div>
            </div>
          )}
        </div>
        {isEditing && (
          <div className="guarantor-card-content">
            <EditGuarantor
              schema={fieldSchemas}
              guarantor={editGuarantor}
              t={t}
              onChange={(x) => setEditGuarantor({ amount: "", ...x })}
              onCancel={() => cancelEdit()}
              onSave={() => {
                editGuarantor.editing = false
                onChange(editGuarantor)
                setEditGuarantor(null)
              }}
            />
          </div>
        )}
        {!isEditing && <div className="guarantor-card-content">{children}</div>}
      </Notice>
    </GuarantorCardContainer>
  )
}

const fieldSchemas = {
  person: [
    {
      key: "email",
      label: "email",
    },
    {
      key: "mobile",
      label: "phone",
    },
    {
      key: "amount",
      label: "denomination-in-nok",
      type: "currency",
    },
    {
      key: "perform-credit-check",
      label: "perform-credit-check",
      type: "perform-credit-check",
    },
  ],
  organizationDomestic: [
    {
      key: "contactPersonName",
      label: "contactPerson",
    },
    {
      key: "contactPersonMobile",
      label: "phone",
    },
    {
      key: "contactPersonEmail",
      label: "email",
    },
    {
      key: "amount",
      label: "denomination-in-nok",
      type: "currency",
    },
    {
      key: "perform-credit-check",
      label: "perform-credit-check",
      type: "perform-credit-check",
    },
  ],
  organizationInternational: [
    {
      key: "contactPersonName",
      label: "contactPerson",
    },
    {
      key: "contactPersonMobile",
      label: "phone",
    },
    {
      key: "contactPersonEmail",
      label: "email",
    },
    {
      key: "amount",
      label: "denomination-in-nok",
      type: "currency",
    },
    {
      key: "additionalInfo",
      label: "additional info",
      type: "textarea",
    },
  ],
}

const GuarantorCardContainer = styled.div`
  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0px 10px;
    align-items: flex-start;
    flex: 1;
  }

  .top-section {
    display: flex;
    flex-direction: row;
    margin: 10px 10px;
  }

  .edit-card {
    width: 100%;
    padding: 10px 10px;
  }

  .content-item {
    min-width: 200px;
  }

  .full-width {
    width: 100%;
  }

  .edit-form {
    margin-top: 10px;
    display: flex;
    width: 650px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .edit-form > * {
    width: calc(50% - 20px);
  }

  .options {
    margin-top: 10px;
  }

  .options > div {
    cursor: pointer;
  }

  .icon {
    padding-top: 10px;
  }

  .person-content {
    display: grid;
    padding: 10px 10px;
    grid-template-columns: minmax(200px, auto) 180px minmax(200px, auto) 200px;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    gap: 10px;
  }
  .edit-content {
    display: grid;
    grid-template-columns: 200px minmax(200px, auto);
    align-items: center;
    gap: 10px;
  }

  .organization-content {
    display: grid;
    padding: 10px 10px;
    grid-template-columns: minmax(200px, auto) 200px;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    gap: 10px;
  }

  .guarantor-card-content {
    margin-left: 65px;
    margin-bottom: 10px;
  }
`

export default GuarantorCard
