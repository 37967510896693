import { hasData } from "../../util/returnValue"

/**
 * Get the error message for the KAR payout
 * @param {string | null} paymentId
 * @param {InFlow.Delivery} delivery
 *
 * @returns {InFlow.KarCheckMessage}
 */
export const getKarErrorMessage = (delivery, paymentId) => {
  // If no paymentId we can fetch KAR-check from /deliveries/{deliveryId}/currentPayout/karCheck
  const karCheckByPaymentId =
    delivery?.currentPayout?.karCheck?.[paymentId]?.resultText

  // We know that a KAR-check has been performed in the beginning of the payment process, so if no KAR-check by paymentId, we can return the resultText
  if (!karCheckByPaymentId) {
    return delivery?.currentPayout?.karCheck.resultText
  }
  return karCheckByPaymentId
}

/**
 * @param {InFlow.PayoutCompleteData} data
 * @returns {string[]}
 */
export const getMissingChecklistItems = (data) => {
  let checkListItems = [
    "specialConditions",
    "projectAccounting",
    "navRegistered",
    "payoutPowerOfAttorney",
    "ongoingLoanManagement",
    "anticipatedDefaultAssessment",
    "innovationContractCollaborationAgreement",
  ]

  const finalPayoutChecklistItems = ["certificateOfCompletion", "finalReport"]

  if (data.payoutType === "finalPayout") {
    checkListItems = [...checkListItems, ...finalPayoutChecklistItems]
  } else if (data.payoutType !== "advancePayout") {
    checkListItems.push("progressReport")
  }

  const allowedValues = ["controlled", "not-relevant"]

  const missingCheckListItems = checkListItems.filter(
    (item) => !data[item] || !allowedValues.includes(data[item])
  )

  return missingCheckListItems
}

/**
 * @param {InFlow.PayoutCompleteData} data
 * @param {object} params
 * @param {InFlow.Commitment} params.commitment
 * @param {InFlow.StateData} params.stateData
 * @param {object} params.formData
 * @param {InFlow.Delivery} params.currentDelivery
 * @returns {{ message: string, error: boolean, missingCheckListItems: string[], errorMessages: string[], missingPayoutValues: string[] }}
 */
export const validatePayoutCompleteData = (data, params) => {
  const { payments, totalPayoutAmount, payoutDecision } = data

  const { commitment, stateData, formData, currentDelivery } = params

  const { missingRequiredFields } = validatePayoutFields(data)

  let errorMessages = []

  const missingCheckListItems = getMissingChecklistItems(data)

  const errors = handleValidation({
    stateData,
    formData,
    previousErrorMessages: {},
    currentDelivery,
    totalPayoutAmount,
    commitment,
  })

  const validationErrors = Object.keys(errors)

  const totalPayout = payments.reduce(
    (acc, { payoutAmount }) => acc + payoutAmount,
    0
  )

  // Check if the total payout amount matches the sum of the payouts
  if (totalPayout !== totalPayoutAmount) {
    errorMessages.push("total-payout-amount-fail")
  }

  if (payoutDecision !== "OK") {
    errorMessages.push("payout-decision-fail")
  }

  const allErrors = Array.from(new Set([...errorMessages, ...validationErrors]))

  if (errorMessages.length > 0) {
    return {
      message: "validation-failed",
      error: true,
      missingCheckListItems,
      errorMessages: allErrors,
      missingPayoutValues: missingRequiredFields,
    }
  }

  return {
    message: "Validation successful",
    error: false,
    missingCheckListItems,
    errorMessages,
    missingPayoutValues: missingRequiredFields,
  }
}

// Valideringssjekk
/**
 * @param {object} params
 * @param {InFlow.StateData} params.stateData
 * @param {InFlow.StateData} params.formData
 * @param {React.Dispatch<React.SetStateAction<InFlow.PayoutErrorMessages>>} params.setErrorMessages
 * @param {InFlow.Delivery} params.currentDelivery
 * @param {number} params.totalPayoutAmount
 * @param {InFlow.Commitment} params.commitment
 * @returns { void }
 */
export const performValidations = ({
  stateData,
  formData,
  setErrorMessages,
  currentDelivery,
  totalPayoutAmount,
  commitment,
}) => {
  setErrorMessages((previousErrorMessages) => {
    return handleValidation({
      stateData,
      formData,
      previousErrorMessages,
      currentDelivery,
      totalPayoutAmount,
      commitment,
    })
  })
}
/**
 * @param {InFlow.PayoutCompleteData} data
 * @returns {{ missingRequiredFields: string[], missingNotRequiredFields: string[], missingOtherFields: string[] }}
 */
export const validatePayoutFields = (data) => {
  // Add check if any of these values are null or undefined
  // Then we can console.error the missing values
  const payoutFields = [
    "payoutType",
    "payments",
    "totalPayoutAmount",
    "remainingAmount",
    "whoDidIt",
    "payoutDecision",
    "caseworker",
  ]

  const notRequiredFields = [
    "commentToCustomer",
    "commentToCaseOfficer",
    "mustCheckSigners",
  ]
  // We can also have other fields
  const otherFields = Object.keys(data).filter(
    (field) =>
      !payoutFields.includes(field) && !notRequiredFields.includes(field)
  )

  const missingRequiredFields = payoutFields.filter((field) => !data[field])

  const missingNotRequiredFields = otherFields.filter(
    (field) => !data[field] && !notRequiredFields.includes(field)
  )

  const missingOtherFields = otherFields.filter(
    (field) => !data[field] && notRequiredFields.includes(field)
  )

  if (missingRequiredFields.length > 0) {
    console.error("Missing required fields: ", missingRequiredFields)
  }

  if (missingNotRequiredFields.length > 0) {
    console.debug("Missing not required fields: ", missingNotRequiredFields)
  }

  if (missingOtherFields.length > 0) {
    console.debug("Missing other fields: ", missingOtherFields)
  }

  return {
    missingRequiredFields,
    missingNotRequiredFields,
    missingOtherFields,
  }
}

/**
 * @param {object} params
 * @param {InFlow.StateData} params.stateData
 * @param {InFlow.StateData} params.formData
 * @param {InFlow.PayoutErrorMessages} params.previousErrorMessages
 * @param {InFlow.Delivery} params.currentDelivery
 * @param {number} params.totalPayoutAmount
 * @param {InFlow.Commitment} params.commitment
 * @returns {InFlow.PayoutErrorMessages}
 */
const handleValidation = ({
  stateData,
  formData,
  previousErrorMessages,
  currentDelivery,
  totalPayoutAmount,
  commitment,
}) => {
  let newErrorState = { ...previousErrorMessages }

  const payments = stateData.payments.filter((x) => !!x) || []
  if (payments.length === 0) {
    newErrorState.hasMissingPayoutFields =
      "must-have-at-least-one-payout-and-fields-must-be-set"
  } else {
    const payoutErrors =
      payments.filter(
        (payment) =>
          Object.keys(payment).filter((x) => {
            const value = payment[x]
            if (x === "isPerson" && value !== null) return false
            if (x === "payoutAmount") {
              const isValidPayoutAmount =
                typeof value === "number" && isFinite(value)

              // Value 0 is allowed for finalPayout
              if (stateData.payoutType === "finalPayout") {
                return !isValidPayoutAmount
              }
              // But not otherwise
              return !isValidPayoutAmount || value === 0
            }
            return !hasData(value) || value === 0
          }).length > 0
      ).length > 0

    if (payoutErrors) {
      newErrorState.hasMissingPayoutFields =
        "must-have-at-least-one-payout-and-fields-must-be-set"
    } else {
      // We have multiple payments and we have filled out all data
      delete newErrorState.hasMissingPayoutFields
    }
  }

  // Sjekk dersom total beløp overstiger tilgjengelig beløp
  if (totalPayoutAmount > commitment.accountByAccountName.availableAmount) {
    newErrorState.availableAmountFail = "available-amount-fail"
  } else {
    delete newErrorState.availableAmountFail
  }

  // Egen feilmelding til saksbehandler dersom den mangler kommentar
  if (
    formData.payoutDecision === "MOREINFO" &&
    formData?.commentToCustomer === undefined
  ) {
    newErrorState.requiredComment = "comment-to-customer-required"
  } else {
    delete newErrorState.requiredComment
  }

  if (currentDelivery.productClass === "Guarantee") {
    const requiredFields = [
      "creditAmount",
      "guaranteeAmount",
      "guaranteePercent",
      "bankAddress",
      "loanAgreementNumber",
    ]

    if (
      !stateData.guarantee ||
      requiredFields.some((field) => !stateData.guarantee[field])
    ) {
      newErrorState.guarantee = "guarantee-required"
    } else {
      delete newErrorState.guarantee
    }
  } else {
    delete newErrorState.guarantee
  }

  // This will erase other validations, which is bad. But it's ok because
  // the only other validation is for the schema for the checklist, and
  // this is validated on attempted complete, so they'll get it later.
  return newErrorState
}
/** @type {InFlow.PayoutTypes[]} */
export const PAYOUT_TYPES = [
  "payoutType",
  "payoutAmount",
  "payoutName",
  "payoutOrgNumber",
  "payoutApplicantOrThirdPerson",
  "bicSwiftNumber",
]
/** @type {InFlow.PayoutRecipient[]} */
export const PAYOUT_RECIPIENT_TYPES = [
  "applicant",
  "thirdParty",
  "thirdPerson",
  "abroad",
]
