import styled from "styled-components"
import React from "react"
import { useState } from "react"
import AssessmentComments from "../common/AssessmentComments"
import InsightModule from "../insight/InsightModule"
import Tabs from "../common/Tabs"
import BlueDot from "../common/BlueDot"
import getInsightContextForCategory from "../../util/getInsightContextForCategory"

const DecisionTabs = ({
  insightData,
  flow,
  t,
  onAppChange,
  needAnalysis,
  loading,
}) => {
  const [activeTab, setActiveTab] = useState("insight-modul")
  const caseMemoOptions = {
    context: getInsightContextForCategory(
      flow.data.application.category,
      true,
      needAnalysis
    ),
  }
  const decisionCount = flow?.data?.assessmentComments?.length ?? 0
  const tabOptions = [
    {
      id: "insight-modul",
      title: t("recommendation"),
      component: (
        <InsightModule
          onAppChange={onAppChange}
          data={insightData}
          name={"@stacc/vue-casememo"}
          commonData={insightData?.commonData}
          options={caseMemoOptions}
        />
      ),
    },
    {
      id: "assessment-comments",
      title: t("assessment-history"),
      children: decisionCount ? <BlueDot>{decisionCount}</BlueDot> : undefined,
      component: (
        <AssessmentCommentsWrapper>
          <AssessmentComments
            comments={flow?.data?.assessmentComments}
            assignee={flow?.assignee?.name}
            t={t}
          />
        </AssessmentCommentsWrapper>
      ),
    },
  ]
  const tab = tabOptions.find((x) => x.id === activeTab)

  return (
    <Content>
      <Tabs
        defaultTab={"insight-modul"}
        loading={loading}
        options={tabOptions}
        onChange={(e) => setActiveTab(e)}
      />
    </Content>
  )
}

export default DecisionTabs

const Content = styled.div`
  flex: 1;
  height: 100%;
`
const AssessmentCommentsWrapper = styled.div`
  padding: 10px 40px;
`
