import styled from "styled-components"
import { useFormContext } from "react-hook-form"
import { v4 as uuidv4 } from "uuid"
import { PrimaryButton } from "@stacc/flow-ui-components"
import { ProjectColors } from "../../components/common/style/ProjectColors"
import TextButton from "../common/TextButton"
import CustomCheckListFormListItem from "./CheckListFormListItem"

const CustomCheckListForm = ({
  t,
  currentUser,
  readOnly = false,
  title,
  formId,
}) => {
  const { setValue, watch } = useFormContext()
  const checklistItems = watch(`${formId}`)

  const addChecklistItem = () => {
    const index = checklistItems?.length || 0
    setValue(`${formId}[${index}]`, {
      id: uuidv4(),
      checklistControlled: false,
      checklistControlledBy: "",
    })
    setValue(`${formId}`, [...checklistItems])
  }

  const removeChecklistItems = () => {
    setValue(`${formId}`, [])
  }

  return (
    <div
      style={{
        border: "1px solid #e4e2e2",
        borderRadius: "5px",
        padding: "1em",
      }}
    >
      {!readOnly && (
        <ButtonContainer>
          <PrimaryButton onClick={addChecklistItem}>
            {t("add-checklist-item")}
          </PrimaryButton>
          {checklistItems?.length > 0 && (
            <TextButton
              onClick={removeChecklistItems}
              style={{ marginTop: "1em" }}
            >
              {t("remove-all-checklist-items")}
            </TextButton>
          )}
        </ButtonContainer>
      )}
      <br />
      <CustomTable>
        <tbody>
          {checklistItems && (
            <Heading>{title ? title : t("checklist-documents")}</Heading>
          )}
          <TR>
            <TH>{t("documentation")}</TH>
            <TH>{t("controlled")}</TH>
            <TH>{t("isNotRelevant")}</TH>
            <TH>{t("controlledBy")}</TH>
            {!readOnly && <TH>{t("delete")}</TH>}
          </TR>

          {checklistItems?.map((item, i) => (
            <CustomCheckListFormListItem
              key={i}
              {...{
                item,
                index: i,
                currentUser,
                t,
                readOnly,
                formId,
              }}
            />
          ))}
        </tbody>
      </CustomTable>
    </div>
  )
}

const TR = styled.tr`
  &:nth-child(odd) {
    background-color: ${ProjectColors.Slate90};
  }
`

const TH = styled.th`
  font-weight: normal;
  padding: 12px;

  &:nth-child(1) {
    text-align: left;
  }

  &:nth-child(3) {
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`

const Heading = styled.p`
  font-size: 1em;
  color: black;
  margin-left: 1em;
`
const CustomTable = styled.table`
  width: 100%;
  font-size: small;
  border-collapse: collapse;
  margin: 0 0 1em 0;
`

export default CustomCheckListForm
