import React from "react"
import styled from "styled-components"
import { ProjectColors } from "../../common/style/ProjectColors"
import AddManualDecisionText from "./AddManualDecisionText"
import Change from "./Change"

const DecisionTexts = ({
  t,
  delivery,
  deliveryId,
  context,
  setProcessing,
  agreements,
  onChange,
  insightMappedStakeholder,
}) => {
  return (
    <Content>
      <UppercaseP>{t("changes")}</UppercaseP>
      {delivery.changes.length <= 0 && (
        <NoContent>
          <p>Ingen endringer er registert</p>
        </NoContent>
      )}
      {delivery.changes.map((change) => {
        if (change.type === "COLLATERALCOVERAGES") return
        return (
          <Change
            t={t}
            change={change}
            delivery={delivery}
            deliveryId={deliveryId}
            context={context}
            onChange={onChange}
            insightMappedStakeholder={insightMappedStakeholder}
            agreements={agreements}
          />
        )
      })}
      <AddManualDecisionText
        onChange={onChange}
        t={t}
        delivery={delivery}
        deliveryId={deliveryId}
        agreements={agreements}
      />
    </Content>
  )
}

const UppercaseP = styled.p`
  text-transform: uppercase;
`

const Content = styled.div`
  margin: 23px;
`

const NoContent = styled.div`
  padding: 14px;
  margin-top: 20px;
  background-color: ${ProjectColors.Slate90};
  border-radius: 10px;
`

export default DecisionTexts
