import * as yup from "yup"

const baseValidator = yup
  .string()
  .required("required-error")
  .typeError("type-error")
  .nullable()

const defaultSelect = baseValidator.test(
  "chooseValidOption",
  "required-error",
  (item) => !!item && item !== "Velg..."
)

const defaultMultiSelect = (type) => {
  const yupItem = yup.array().of(
    yup.object().shape({
      label: baseValidator,
      value: baseValidator,
    })
  )
  if (type) {
    return yupItem.when(type, {
      is: "yes",
      then: yup
        .array()
        .test("chooseValidOption", "required-error", (item) => item?.length > 0)
        .required("required-error")
        .nullable(),
      otherwise: (s) => s.nullable(),
    })
  } else {
    return yupItem
      .test("chooseValidOption", "required-error", (item) => item?.length > 0)
      .required("required-error")
      .nullable()
  }
}

export const uploadStatisticalDataValidationSchema = yup.object().shape({
  growthPotentialProject: defaultSelect,
  innovationLevel: defaultSelect,
  innovationType: defaultSelect,
  industryArea: defaultSelect,
  industry: yup
    .object()
    .typeError("type-error")
    .required("required-error")
    .nullable(),
  otherCharacteristics: defaultMultiSelect(),
  comment: yup.string().nullable(),
  numberEmployeesFemale: baseValidator,
  numberEmployeesMale: baseValidator,
  projectRiskRadio: baseValidator,
  femaleInitiativeRadio: baseValidator,
  femaleInitiative: defaultMultiSelect("femaleInitiativeRadio"),
})

export const uploadStatisticalDataValidationSchemaAgriculture = yup
  .object()
  .shape({
    growthPotentialProject: defaultSelect,
    innovationLevel: defaultSelect,
    innovationType: defaultSelect,
    industryArea: defaultSelect,
    internationalNationalOrientation: defaultSelect,
    industry: yup
      .object()
      .typeError("type-error")
      .required("required-error")
      .nullable(),
    otherCharacteristics: defaultMultiSelect(),
    comment: yup.string().nullable(),
    numberEmployeesFemale: baseValidator,
    numberEmployeesMale: baseValidator,
    projectRiskRadio: baseValidator,
    femaleInitiativeRadio: baseValidator,
    femaleInitiative: defaultMultiSelect("femaleInitiativeRadio"),

    /* projectProfitability: baseValidator,
    socioEconomicProfitability: baseValidator,
    levelOfInnovation: baseValidator,
    safety: baseValidator,
    triggeringEffect: baseValidator, */
  })

export const uploadStatisticalDataDefaultValues = {
  comment: null,
  projectRiskRadio: null,
  growthPotentialProject: null,
  innovationLevel: null,
  innovationType: null,
  industryArea: null,
  otherCharacteristics: null,
  femaleInitiativeRadio: null,
  numberEmployeesFemale: null,
  numberEmployeesMale: null,
  femaleInitiative: [],
  industry: null,
  projectProfitability: null,
  socioEconomicProfitability: null,
  levelOfInnovation: null,
  safety: null,
  triggeringEffect: null,
}
