import styled from "styled-components"
import { Collapsible } from "@stacc/flow-ui-components"
import formatWithLocale from "../../util/dateFormatter"

const CaseCommentThreads = ({ t, flow }) => {
  const comments = flow?.data?.integrityDueDilligence?.comments || []

  return (
    <ContentWrapper>
      <Title>{t("messages")}</Title>

      {comments.map((caseInfo, i) => {
        return (
          <CommentWrapper key={i}>
            <CommentColumn>
              <p>{caseInfo.isIddTeam ? t("idd-team") : t("caseworker")}</p>
            </CommentColumn>
            <CommentColumn>
              <p>{formatWithLocale(caseInfo?.commentTime)}</p>
              <p>
                {t("user")}: {caseInfo.user}
              </p>
            </CommentColumn>
            <CaseComment>
              <p>{caseInfo.comment}</p>
            </CaseComment>
          </CommentWrapper>
        )
      })}
      {comments.length === 0 && <div>{t("no-comments")}</div>}
    </ContentWrapper>
  )
}
const CaseComment = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 2;
  grid-row-end: 2;

  p {
    text-align: start;
    background-color: #f9fbff;
    font-size: 12px;
    border-radius: 1em;
    padding: 1em;
    white-space: pre-wrap;
  }
`

const ContentWrapper = styled.div`
  color: var(--flow-color-grey1);
`

const CommentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: [row1-start] 50px 1fr;
  background-color: var(--flow-color-sea-lighter);
  border: 1px solid var(--flow-color-grey4);
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  align-items: start;

  div:nth-child(2) {
    text-align: end;
  }
`

const Title = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 10px;
`

const CommentColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  margin-bottom: 0.5em;

  &:first-child {
    p {
      &:first-child {
        font-weight: bold;
      }
    }
  }

  &:nth-child(2) {
    color: var(--flow-color-sea);
  }
`

export default CaseCommentThreads
