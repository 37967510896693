import React from "react"

import {
  CheckboxComponent,
  RadioComponent,
} from "../TermsAndSecurity/stakeholders/InputComponents"
import { ProjectColors } from "../common/style/ProjectColors"
import styled from "styled-components"
import { useState, useMemo, useEffect } from "react"
import { SecondaryButton, TransparentButton } from "@stacc/flow-ui-components"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { signerValidationSchema } from "./schemas/Signer.schema"
import { Select } from "@stacc/flow-ui-components"
import InputField from "./inputs/InputField"

const AddSigner = ({ t, handleAdd, initialGuarantors }) => {
  const [adding, setAdding] = useState(false)
  const [selectedOption, setSelectedOption] = useState()
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    setValue,
    getValues,
    register,
    ...rest
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(signerValidationSchema),
    defaultValues: {
      email: "",
      type: "person",
      ssn: "",
      organizationNumber: "",
      isFetchingSignerDetails: false,
    },
  })
  const type = watch("type")
  const guarantorOptions = useMemo(() => {
    return Object.values(initialGuarantors).map((g) => ({
      label: g.info.name,
      value: g.info.id,
    }))
  }, [initialGuarantors])

  function onSelectedGuarantor(guarantorId) {
    if (!guarantorId) {
      setSelectedOption(null)
      reset()
      return
    }
    reset() // Reset the whole form to remove old values
    setSelectedOption(guarantorId)
    const guarantor = initialGuarantors[guarantorId]

    setValue("type", guarantor.info.type)
    setValue("name", guarantor.info.name)
    setValue("email", guarantor.info.email)

    if (guarantor.info.type === "person") {
      setValue("ssn", guarantor.info.ssn)
    } else {
      setValue("organizationNumber", guarantor.info.organizationNumber)
    }
  }

  // Somewhat of a hacky solution to fix issue with react-hook-form not updating the form when the type changes.
  // Going from selskap to person, or vice versa, will not update the ssn or organizationNumber.
  useEffect(() => {
    if (selectedOption !== null && selectedOption !== undefined) {
      const guarantor = initialGuarantors[selectedOption]
      if (type === "person") {
        setValue("ssn", guarantor.info.ssn)
      } else {
        setValue("organizationNumber", guarantor.info.organizationNumber)
      }
    }
  }, [type])

  function onSubmit(data) {
    data.id = data.type === "person" ? data.ssn : data.organizationNumber
    handleAdd(data)
    reset()
    setSelectedOption(null)
    setAdding(false)
  }

  function onCancelAdding() {
    reset()
    setSelectedOption(null)
    setAdding(false)
  }

  function canSubmitForm() {
    const email = watch("email")
    if (!email) return false
    const type = watch("type")
    if (!type) return false
    if (type === "organization") {
      const organizationNumber = watch("organizationNumber")
      if (!organizationNumber) return false
    } else {
      const ssn = watch("ssn")
      if (!ssn) return false
    }

    return true
  }

  return (
    <Container>
      {!adding ? (
        <AlignRight>
          <AddButton onClick={() => setAdding(!adding)}>
            + Legg til signatar
          </AddButton>
        </AlignRight>
      ) : (
        <AddContainer>
          <SelectContainer>
            <Select
              label="Bruk eksisterende interessent"
              options={guarantorOptions}
              onChange={(event, selected) => onSelectedGuarantor(selected)}
              showBlankOption={true}
              required={false}
              value={selectedOption}
            />
          </SelectContainer>
          <FormProvider
            handleSubmit={handleSubmit}
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            {...rest}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <RadioComponent
                fieldName={"type"}
                t={t}
                id={"new-signer"}
                fullLine={true}
                watch={watch}
              />
              <InputContainer>
                {type === "organization" ? (
                  <InputField t={t} name="organizationNumber" />
                ) : (
                  <InputField t={t} name="ssn" />
                )}
                <InputField t={t} name="name" />
                {/* <InputComponent fieldName="name" t={t} id={"new-signer"} /> */}
              </InputContainer>
              <InputContainer>
                <InputField t={t} name="email" />
              </InputContainer>
              <InputContainer>
                <CheckboxComponent
                  fieldName="isFetchingSignerDetails"
                  t={t}
                  id={"new-signer"}
                />
              </InputContainer>

              <ButtonContainer>
                <SecondaryButton disabled={!canSubmitForm()} type="submit">
                  {t("add")}
                </SecondaryButton>
                <TransparentButton onClick={onCancelAdding}>
                  {t("cancel")}
                </TransparentButton>
              </ButtonContainer>
            </form>
          </FormProvider>
        </AddContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const AddContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color ${ProjectColors.Sea97};
    padding: 15px;
    border-radius: 8px;
`
const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-column-gap: 0;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 10px;
`

const SelectContainer = styled.div`
  margin-bottom: 15px;
`

const AlignRight = styled.div`
  margin-left: auto;
`

const AddButton = styled.button`
  background-color: inherit;
  border: 2px solid #c7d8ff;
  border-radius: 4px;
  padding: 4px 14px;
  color: #467eff;
  cursor: pointer;

  &:hover {
    background-color: #ecf2ff;
    border: 2px solid #c7d8ff;
  }
`

export default AddSigner
