import { hasData } from "./returnValue"

export const splitName = (name, lastNameFirst) => {
  let firstName, middleName, lastName
  if (typeof name === "string") {
    const nameArr = name?.split(" ")
    lastName = (lastNameFirst ? nameArr.shift() : nameArr.pop()) || undefined
    firstName = nameArr.shift()
    middleName = nameArr?.join(" ") || undefined
  }
  return { firstName, middleName, lastName }
}

export const concatIfValues = (delimiter = " ", ...args) => {
  return (args || [])
    .filter((arg) => hasData(arg))
    .reduce((prev, item) => (prev ? prev + delimiter + item : item), "")
}
