import { FontStyles } from "@stacc/flow-ui-components"
import React from "react"
import styled from "styled-components"

// See staccx/bento -> Label.js
const Label = ({ className, children, ...rest }) => (
  <LabelElement className={className} {...rest}>
    {children}
  </LabelElement>
)

const LabelElement = styled.label`
  font-weight: 500;
  font-family: inherit;
  font-size: ${FontStyles.Normal};
  line-height: 1.2;
  padding-bottom: 0.5em;
  display: block;
`

export default Label
