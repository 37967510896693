import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"
import { useTranslation } from "react-i18next"

import { FontStyles } from "@stacc/flow-ui-components"
import { Collapsible } from "@stacc/flow-ui-components"
import DownloadFileButton from "./DownloadFileButton"

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  ${FontStyles.Small};

  > li {
    padding-bottom: ${(props) => (props.level > 0 ? 0 : "1.5em")};
    line-height: 20px;
  }
`

const Title = styled.div`
  ${FontStyles.NormalStrong};
  padding-bottom: 0.4em;
`

const KeyField = styled.span`
  display: inline-block;

  ::first-letter {
    text-transform: capitalize;
  }
`

const ValueField = styled.span`
  overflow-wrap: break-word;
`

const Header = styled.span`
  text-transform: capitalize;
`

const SingleObjectWrapper = styled.li`
  list-style: none;
  padding: 0;
  margin: 0;
`

const mapTaskContext = (key, value, metadata, level, t) => {
  if (value === undefined || value === null) {
    return null
  }
  const nextLevel = level + 1
  const valueIsArray = Array.isArray(value)
  const valueIsObject = typeof value === "object"
  const valueIsBoolean = typeof value === "boolean"
  const singleObject = level === 0 && typeof value !== "object"

  if (
    (valueIsArray && value.length < 1) ||
    (valueIsObject && Object.keys(value).length < 1)
  ) {
    return null
  }

  const valueMetadata = metadata && key ? metadata[key] : null

  if (valueIsArray) {
    const list = (
      <List level={level}>
        {value.map((el) => (
          <li key={uuidv4()}>
            <TaskContext
              data={el}
              metadata={valueMetadata}
              t={t}
              level={nextLevel}
            />
          </li>
        ))}
      </List>
    )

    if (level > 0) {
      return (
        <li key={uuidv4()}>
          <Collapsible
            isOpen={true}
            header={<Header>{t(key)}</Header>}
            title={t(key)}
          >
            {list}
          </Collapsible>
        </li>
      )
    }

    return (
      <li key={uuidv4()}>
        <Title>{t(key)}</Title>
        {list}
      </li>
    )
  }

  if (valueIsObject) {
    if (level > 0) {
      return (
        <li key={uuidv4()}>
          <Collapsible header={<Header>{t(key)}</Header>} title={t(key)}>
            <TaskContext
              data={value}
              metadata={valueMetadata}
              t={t}
              level={nextLevel}
            />
          </Collapsible>
        </li>
      )
    }

    return (
      <li key={uuidv4()}>
        <Title>{t(key)}</Title>
        <TaskContext
          data={value}
          metadata={valueMetadata}
          t={t}
          level={nextLevel}
        />
      </li>
    )
  }

  if (valueIsBoolean) {
    const displayValue = value ? "yes" : "no"
    return (
      <li key={uuidv4()}>
        {key && <KeyField>{t(key)}:</KeyField>}
        <ValueField>{` ${t(displayValue)}`}</ValueField>
      </li>
    )
  }

  if (singleObject) {
    return (
      <SingleObjectWrapper key={uuidv4()}>
        <Title>{t(key)}</Title>
        <ValueField>{t(value)}</ValueField>
      </SingleObjectWrapper>
    )
  }

  const widget = valueMetadata && valueMetadata["ui:widget"]
  if (widget && widget === "fileDownload") {
    return (
      <li key={uuidv4()}>
        <DownloadFileButton fileId={value} {...valueMetadata["ui:options"]} />
      </li>
    )
  }

  return (
    <li key={uuidv4()}>
      {key && <KeyField>{t(key)}:</KeyField>}
      <ValueField>{` ${t(value)}`}</ValueField>
    </li>
  )
}

const TaskContext = ({ data, metadata, level }) => {
  const { t } = useTranslation()
  if (Array.isArray(data) || typeof data === "object") {
    return (
      <List level={level}>
        {Object.keys(data).map((key) =>
          mapTaskContext(key, data[key], metadata, level, t)
        )}
      </List>
    )
  }

  return (
    <List level={level}>{mapTaskContext(null, data, metadata, level, t)}</List>
  )
}

TaskContext.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
  metadata: PropTypes.shape({}),
  level: PropTypes.number,
}

TaskContext.defaultProps = {
  metadata: null,
  level: 0,
}

export default TaskContext
