import React from "react"
import styled from "styled-components"
import TaskContext from "../common/TaskContext"

const MaintenanceTaskContext = ({ context, children }) => {
  return (
    <>
      <TaskContext data={context} />
      <ContextComponent>{children}</ContextComponent>
    </>
  )
}

const ContextComponent = styled.div`
  width: 100%;
`

export default MaintenanceTaskContext
