const ActivityType = [
  { name: "experimentalDevelopment", value: "experimentalDevelopment" },
  { name: "industrialResearch", value: "industrialResearch" },
  { name: "investmentImmaterial", value: "investmentImmaterial" },
  { name: "investmentMaterials", value: "investmentMaterials" },
  { name: "training", value: "training" },
  { name: "clusterManagement", value: "clusterManagement" },
  { name: "preStudy", value: "preStudy" },
  { name: "startup", value: "startup" },
  {
    name: "growthOfCapitalAndManagement",
    value: "growthOfCapitalAndManagement",
  },
  { name: "conferenceParticipation", value: "conferenceParticipation" },
  { name: "innovationCounselling", value: "innovationCounselling" },
  { name: "other", value: "other" },
]
const SupportRegime = [
  { name: "art-22-start-ups", value: "article22" },
  { name: "trivialSupport", value: "trivialSupport" },
]

const SupportRegimeExportPromotion = [
  { name: "trivialSupport", value: "trivialSupport" },
]

const SupportRegimeExtraordinaryFinancing = [
  {
    name: "Temporary Framework for state aid measures to support the economy following the aggression against Ukraine by Russia (the TCF)",
    value: "temporaryFramework",
  },
  { name: "trivialSupport", value: "trivialSupport" },
]
const SupportRegimeBioEnergi = [
  { name: "art-22-start-ups", value: "startUps" },
  { name: "art-25-researchAndDevelopment", value: "researchAndDevelopment" },
  {
    name: "art-29-processAndInnovation",
    value: "processAndInnovation",
  },
  { name: "art-31-training", value: "training" },
  {
    name: "art-36-increaseProtectionOfEnvironment",
    value: "increaseProtectionOfEnvironment",
  },
  {
    name: "art-37-earlyAdaptation",
    value: "earlyAdaptation",
  },
  {
    name: "art-38-energySaving",
    value: "energySaving",
  },
  {
    name: "art-40-highEfficientCogenerationPlant",
    value: "highEfficientCogenerationPlant",
  },
  { name: "art-41-renewableEnergy", value: "renewableEnergy" },
  {
    name: "art-46-sufficientDistrictHeatingAndCooling",
    value: "sufficientDistrictHeatingAndCooling",
  },
  {
    name: "art-47-recyclingAndReusing",
    value: "recyclingAndReusing",
  },
  { name: "art-49-studiesOfEnvironment", value: "studiesOfEnvironment" },
  { name: "trivialSupport", value: "trivialSupport" },
  {
    name: "exemptFromSupportBasisRules",
    value: "exemptFromSupportBasisRules",
  },
]

const SupportRegimeDtil = [
  { name: "art-14-regionalInvestment", value: "regionalInvestment" },
  { name: "art-17-smbInvestment", value: "smbInvestment" },
  { name: "art-41-renewableEnergy", value: "renewableEnergy" },
  {
    name: "art-46-sufficientDistrictHeatingAndCooling",
    value: "sufficientDistrictHeatingAndCooling",
  },
  { name: "trivialSupport", value: "trivialSupport" },
  {
    name: "exemptFromSupportBasisRules",
    value: "exemptFromSupportBasisRules",
  },
]

const GrowthPotentialProject = [
  { name: "Lavt vekstpotensial", value: "lowGrowthPotential" },
  { name: "Moderat vekstpotensial", value: "moderateGrowthPotential" },
  { name: "Høyt vekstpotensial", value: "highGrowthPotential" },
  { name: "Ikke vurdert", value: "notEvaluated" },
]

const InnovationLevel = [
  { name: "Innovasjon på bedriftsnivå", value: "innovationCompanyLevel" },
  { name: "Innovasjon på regionalt nivå", value: "innovationRegionalLevel" },
  { name: "Innovasjon på nasjonalt nivå", value: "innovationNationalLevel" },
  {
    name: "Innovasjon på internasjonalt nivå",
    value: "innovationInternationalLevel",
  },
  { name: "Ikke relevant", value: "notRelevant" },
]

const InnovationType = [
  {
    name: "1 - Produkt-/tjenesteinnovasjon",
    value: "innovationProductsAndServices",
  },
  { name: "2 - Prosessinnovasjon", value: "innovationProcess" },
  { name: "3 - Organisatorisk innovasjon", value: "innovationOrganizational" },
  { name: "4 - Markedsmessig innovasjon", value: "innovationMarket" },
  { name: "5 - Ikke relevant", value: "notRelevant" },
]

const IndustryArea = [
  { name: "S-001 Energi og miljø", value: "S-001" },
  { name: "S-002 Helse", value: "S-002" },
  { name: "S-005 Landbruk", value: "S-005" },
  { name: "S-006 Marin", value: "S-006" },
  { name: "S-007 Maritim", value: "S-007" },
  { name: "S-009 Reiseliv", value: "S-009" },
  { name: "S-010 Kreativ Næring", value: "S-010" },
  { name: "S-999 Øvrige områder", value: "S-999" },
]

const OtherCharacteristics = [
  {
    name: "IKT-relaterte prosjektet",
    value: "informationAndTechnology",
  },
  {
    name: "Samarbeidsprosjekter",
    value: "cooperationAndMultipleCompanies",
  },
  { name: "Økologisk landbruk", value: "organicFarming" },
  { name: "Havvind", value: "OffshoreWind" },
  { name: "Batteri", value: "battery" },
  { name: "Hydrogen", value: "hydrogen" },
  { name: "CO2-håndtering (CCSU)", value: "CCSU" },
  { name: "Bioøkonomi", value: "bioEconomy" },
  { name: "Ingen relevante kjennetegn", value: "noRelevantCharacteristics" },
]

const FemaleInitiative = [
  { name: "1 - Medeier eller oppstart", value: "1" },
  { name: "2 - Kompetanseøkning", value: "2" },
  { name: "3 - >30% av styre eller ledelse", value: "3" },
  { name: "4 - Øke kvinners deltakelse", value: "4" },
]

const InternationalNationalOrientation = [
  { name: "Internasjonalt rettet - Annet", value: "internationalOther" },
  { name: "Internasjonalt rettet - Eksport", value: "internationalExport" },
  { name: "Nasjonalt rettet", value: "national" },
]

const SupportRegimeDSF = [
  { name: "art-14-regionalInvestment", value: "regionalInvestment" },
  { name: "art-17-smbInvestment", value: "smbInvestment" },
  { name: "art-18-smbConsultantSupport", value: "smbConsultantSupport" },
  {
    name: "art-19-smbConferenceParticipation",
    value: "smbConferenceParticipation",
  },
  { name: "art-20-smbEtcProjects", value: "smbEtcProjects" },
  { name: "art-21-riskFinancingSupport", value: "riskFinancingSupport" },
  { name: "art-22-start-ups", value: "startUps" },
  { name: "art-25-researchAndDevelopment", value: "researchAndDevelopment" },
  { name: "art-26-researchInfrastructure", value: "researchInfrastructure" },
  { name: "art-27-clusterSupport", value: "clusterSupport" },
  { name: "art-28-smbInnovationSupport", value: "smbInnovationSupport" },
  {
    name: "art-29-processAndInnovation",
    value: "processAndInnovation",
  },
  {
    name: "art-30-fouFishingAndAquaCulture",
    value: "fouFishingAndAquaCulture",
  },
  { name: "art-31-training", value: "training" },
  {
    name: "art-36-increaseProtectionOfEnvironment",
    value: "increaseProtectionOfEnvironment",
  },
  {
    name: "art-37-earlyAdaptation",
    value: "earlyAdaptation",
  },
  { name: "art-38-energySaving", value: "energySaving" },
  {
    name: "art-40-highEfficientCogenerationPlant",
    value: "highEfficientCogenerationPlant",
  },
  { name: "art-41-renewableEnergy", value: "renewableEnergy" },
  {
    name: "art-45-revertPolutedAreas",
    value: "revertPolutedAreas",
  },
  {
    name: "art-46-sufficientDistrictHeatingAndCooling",
    value: "sufficientDistrictHeatingAndCooling",
  },
  {
    name: "art-47-recyclingAndReusing",
    value: "recyclingAndReusing",
  },
  { name: "art-48-energyInfrastructure", value: "energyInfrastructure" },
  { name: "art-49-studiesOfEnvironment", value: "studiesOfEnvironment" },
  {
    name: "art-53-cultureAndCulturalHeritage",
    value: "cultureAndCulturalHeritage",
  },
  { name: "art-54-audioVisualWorks", value: "audioVisualWorks" },
  {
    name: "art-55-sportAndRecreationalInfrastructure",
    value: "sportAndRecreationalInfrastructure",
  },
  { name: "art-56-localInfrastructure", value: "localInfrastructure" },
  { name: "trivialSupport", value: "trivialSupport" },
  {
    name: "exemptFromSupportBasisRules",
    value: "exemptFromSupportBasisRules",
  },
]

// Used for mapping support regimes that does not have translated name value (mostly from controlpanel)
const NotMappedSupportRegimes = [
  {
    name: "exemptFromSupportBasisRules",
    value: "articleNOTEØS",
  },
  { name: "art-19-smbConferenceParticipation", value: "article19" },
  { name: "art-20-smbEtcProjects", value: "article20" },
  { name: "art-25-researchAndDevelopment", value: "article25" },
  { name: "art-26-researchInfrastructure", value: "article26" },
  { name: "art-27-clusterSupport", value: "article27" },
  { name: "art-28-smbInnovationSupport", value: "article28" },
  {
    name: "art-29-processAndInnovation",
    value: "article29",
  },
  {
    name: "art-30-fouFishingAndAquaCulture",
    value: "article30",
  },
  { name: "art-31-training", value: "article31" },
  {
    name: "art-36-increaseProtectionOfEnvironment",
    value: "article36",
  },
  {
    name: "art-37-earlyAdaptation",
    value: "article37",
  },
  { name: "art-38-energySaving", value: "article38" },
  {
    name: "art-40-highEfficientCogenerationPlant",
    value: "article40",
  },
  { name: "art-41-renewableEnergy", value: "article41" },
  {
    name: "art-45-revertPolutedAreas",
    value: "article45",
  },
  {
    name: "art-56-e-4",
    value: "article564",
  },
  {
    name: "art-56-e-6",
    value: "article566",
  },
  {
    name: "art-56-e-7",
    value: "article567",
  },
  {
    name: "art-56-e-8",
    value: "article568",
  },
  {
    name: "art-56-e-9",
    value: "article569",
  },
  {
    name: "art-56-e-10",
    value: "article5610",
  },
]

const NoOption = { name: "choose", value: "noOption" }

const DropDownValues = {
  ActivityType,
  SupportRegime,
  NoOption,
  GrowthPotentialProject,
  InnovationLevel,
  InnovationType,
  IndustryArea,
  OtherCharacteristics,
  FemaleInitiative,
  InternationalNationalOrientation,
  SupportRegimeExtraordinaryFinancing,
  SupportRegimeBioEnergi,
  SupportRegimeExportPromotion,
  SupportRegimeDtil,
  SupportRegimeDSF,
  NotMappedSupportRegimes,
}

export { DropDownValues }
