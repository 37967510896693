import React from "react"
import { useFormContext, Controller } from "react-hook-form"
import TextAreaInput from "../../../common/inputs/TextAreaInput"

const ReceivablesJuneThroughSeptemberComment = ({ t }) => {
  const { showErrors, control, errors } = useFormContext()

  return (
    <Controller
      control={control}
      name={"receivablesJuneThroughSeptemberComment"}
      key={"receivablesJuneThroughSeptemberComment"}
      render={({ field: { name, onBlur, onChange, value } }) => (
        <TextAreaInput
          onChange={onChange}
          value={value}
          name={name}
          errors={errors}
          t={t}
          label={t(name)}
          showErrors={showErrors}
        />
      )}
    />
  )
}
export default ReceivablesJuneThroughSeptemberComment
