import React from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { Icons } from "../components/common/style/Icons"
import { PrimaryButton } from "@stacc/flow-ui-components"
import Layout, { Context } from "../components/common/Layout"

const SelectCustomerRelatedCompany = ({
  task,
  flow,
  t,
  schema,
  save,
  complete,
  user,
}) => {
  const { eligibleRelatedCompanies } = task.context

  const [isProcessing, setIsProcessing] = React.useState(false)
  const [selectedId, setSelectedId] = React.useState(undefined)

  const handleComplete = () => {
    setIsProcessing(true)
    complete(
      { selectedId },
      () => {},
      () => {
        console.error("Could not complete task")
        setIsProcessing(false)
      }
    )
  }

  const applicantName =
    task.context.applicationSummary?.applicant?.name
      ?.toLowerCase()
      ?.split(" ")
      ?.map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      ?.join(" ") ?? "ukjent"

  return (
    <Layout forceHeight>
      <MainContent>
        <CompanyContainer>
          <h3>For {applicantName}</h3>
          {eligibleRelatedCompanies.map((company) => (
            <EligibleRelatedCompany
              key={company.publicId}
              isSelected={selectedId === company.publicId}
              onClick={() => setSelectedId(company.publicId)}
            >
              <Icons.StakeholderOrganization size={28} />
              <TitleAndRoles>
                <h3>
                  {company.name} ({company.publicId})
                </h3>
                <p>
                  Roller: <b>{company.roles.join(", ")}</b>
                </p>
              </TitleAndRoles>
            </EligibleRelatedCompany>
          ))}
        </CompanyContainer>
      </MainContent>
      <Context flow={flow} context={task.context.applicationSummary}>
        <ButtonContainer>
          <PrimaryButton
            isLoading={isProcessing}
            disabled={!selectedId || isProcessing}
            onClick={handleComplete}
          >
            {t("complete")}
          </PrimaryButton>
        </ButtonContainer>
      </Context>
    </Layout>
  )
}

const MainContent = styled.div`
  flex: 1;
  border-right: 1px solid #e4e2e2;
  padding: 15px;
  overflow-y: scroll;
`

const CompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const TitleAndRoles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h3 {
    margin: 0;
  }
`

const EligibleRelatedCompany = styled.div`
  display: flex;
  padding: 1.25rem 1.25rem;
  gap: 1rem;
  align-items: center;
  border-radius: 8px;
  background-color: var(--flow-color-sea-lighter);
  border: solid 2px transparent; /* Add transparent border by default */
  ${({ isSelected }) =>
    isSelected
      ? `border-color: var(--flow-color-sea-dark);`
      : ""} /* Change only the color when selected */
  cursor: pointer;

  :hover {
    background-color: var(--flow-color-sea-light);
  }
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1em;
  height: 30px;
`

export default withTranslation()(SelectCustomerRelatedCompany)
