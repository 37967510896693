import axios from "axios"
const siServerProxy = axios.create({
  baseURL: "http://si-server-proxy/internal",
  timeout: 60000,
})
siServerProxy.interceptors.response.use((response) => response.data)

/**
 * @typedef CaseReportResponse
 * @property {Buffer} data
 */

/**
 * Request to get case report from Insight
 * @param {Object} data -  Input object
 * @param {string} data.customerId -  IN customerId
 * @param {string} data.flowId - flowId
 * @param {Object} data.data - data necessary to generate report
 * @param {string} data.locale - locale
 * @param {"Company"|"Person"=} data.customerType - Responsetype of request
 * @returns {Promise<CaseReportResponse>} pdf of case report
 */
export const getCaseReport = async ({
  customerId,
  flowId,
  data,
  locale,
  customerType = "Company",
}) => {
  // Get caseReport data first, then pass in the data to below url:
  let url = `/si_server_proxy/si_server_proxy/api/report/v1/casememo/in/admin/${customerId}/${flowId}`
  if (customerType === "Person") {
    url = `${url}/person`
  }

  if (locale) {
    url += `?locale=${locale}`
  }

  try {
    const res = await axios.post(
      url,
      {
        ...data,
      },
      {
        responseType: "blob",
      }
    )
    return res
  } catch (e) {
    console.error(
      "_common::requests::insightRequests::getCaseReport: Request failed when trying to fetch case report from insight",
      e
    )
    const newError = Error(
      "_common::requests::insightRequests::getCaseReport: Request failed when trying to fetch case report from insight"
    )
    newError.stack = e.stack
    throw newError
  }
}

/**
 * @typedef CaseReportDataResponse
 * @property {object} data
 * @property {object} data.reportData
 * @property {object} data.reportData.data
 */

/**
 * Request to get initial assessment report from Insight
 * @param {string} customerId -  IN customerId
 * @param {string} flowId - flowId
 * @param {object} data - data necessary to generate report
 * @param {string} locale - locale eg. nb_NO or en_US
 * @returns {Promise<CaseReportDataResponse>} json of initial assessment report
 */
export const getCaseReportData = async (customerId, flowId, data, locale) => {
  let url = `/si_server_proxy/si_server_proxy/api/report/v1/casememo/in/admin/${customerId}/${flowId}/data`
  if (locale) {
    url += `?locale=${locale}`
  }
  return await axios.post(url, data, {
    responseType: "application/json",
  })
}

/**
 * @typedef CaseReportData
 * @property {Insight.CaseOverview} customerId - IN customerId
 *
 */
