import React from "react"
import styled from "styled-components"
import BentoInput from "./bento/BentoInput"

const CurrencyField = ({
  onChange,
  value,
  field: { id, key, name, onBlur },
}) => {
  return (
    <StyledInput>
      <BentoInput
        name={name}
        id={name}
        mode="currency"
        value={value}
        inputMode="numeric"
        pattern={/[0-9]*/}
        type="text"
        onChange={(e) => onChange(e.rawValue)}
      />
    </StyledInput>
  )
}
export default CurrencyField

const StyledInput = styled.div`
  input {
    margin: 0;
    border: solid 1px var(--flow-color-grey2);
    width: 150px;
    border-radius: 0.4em;
    padding: 0.5em;
    font-size: 14px;
    line-height: 1.4em;
  }
`
