import React from "react"
import styled from "styled-components"
import { Date as FlowDate } from "@stacc/flow-ui-components"
import { Controller, useFormContext } from "react-hook-form"
import ErrorContext from "../../common/Error"

const Dates = ({ t, activityStart, activityEnd }) => {
  const { control } = useFormContext()
  return (
    <Content>
      <DatesWrapper>
        <Controller
          name={"activityStart"}
          control={control}
          render={({ field: { name, onChange, onBlur, ref, ...rest } }) => (
            <StyledDate
              name={"activityStart"}
              id={"startDate"}
              label={t("start-date")}
              onChange={onChange}
              onBlur={onBlur}
              {...rest}
            />
          )}
        />

        <Controller
          name={"activityEnd"}
          control={control}
          render={({ field: { name, onChange, onBlur, ref, ...rest } }) => (
            <StyledDate
              name={"activityEnd"}
              label={t("end-date")}
              id={"endDate"}
              onChange={onChange}
              onBlur={onBlur}
              {...rest}
            />
          )}
        />
      </DatesWrapper>
      <ErrorContext
        t={t}
        error={activityEnd < activityStart ? "start-before-end" : null}
      />
    </Content>
  )
}
export default Dates

const DatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: 4px;
  padding: 10px;

  label span {
    font-size: 14px;
  }
`
const StyledDate = styled(FlowDate)`
  color: inherit;
  padding: 0.15em;
  font-size: small;
  align-self: flex-end;
  line-height: 1em;
  margin-left: 0.3rem;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
