import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { PrimaryButton } from "@stacc/flow-ui-components"
import AvailableFramesTable from "./AvailableFramesTable"
import { Spinner } from "@stacc/flow-ui-components"
import ErrorText from "../../common/ErrorText"
import axios from "axios"

const AvailableFrames = ({
  availableFrames,
  t,
  flowId,
  onTrigger,
  updateCase,
}) => {
  const [loading, setLoading] = useState()
  const [timestamp, setTimestamp] = useState()
  const [error, setError] = useState(null)

  useEffect(() => {
    updateCase()
    const intervalId = setInterval(() => {
      //updates the flow
      updateCase()
    }, 5000)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  //Hente ut pending task slik at vi kan hente taskId
  const getTaskId = async (flowId) => {
    const res = await axios.get(
      `/api/flow/tasks?flowId=${flowId}&status=pending`
    )
    const tasks = res?.data

    if (tasks.length > 0) {
      const getAvailableFrameTask =
        tasks.find(
          (x) =>
            x.taskType === "get-frame-budget" &&
            x.taskCategory === "message-task"
        ) || []
      return getAvailableFrameTask.taskId
    }
  }

  const triggerMessageTask = async (flowId, formData) => {
    const taskId = await getTaskId(flowId)
    setLoading(true)
    onTrigger(
      taskId,
      formData,
      () => {
        //polling
        setTimestamp(new Date(availableFrames?.timestamp || 0))
      },

      (error) => {
        setError(t("could-not-fetch-data"))
      }
    )
  }

  useEffect(() => {
    if (timestamp < new Date(availableFrames?.timestamp || 0)) {
      setLoading(false)
    }
  }, [availableFrames?.timestamp, timestamp])

  return (
    <ContentWrapper>
      {loading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : error ? (
        <ErrorText error={error} t={t} />
      ) : (
        <AvailableFramesTable
          availableFrames={availableFrames?.mappedResult || []}
          t={t}
          timestamp={availableFrames?.timestamp}
        />
      )}

      <ButtonContainer>
        <PrimaryButton
          onClick={() => triggerMessageTask(flowId, {})}
          disabled={loading}
        >
          {t("get-updated-frame")}
        </PrimaryButton>
      </ButtonContainer>
    </ContentWrapper>
  )
}
export default AvailableFrames

const ContentWrapper = styled.div`
  color: var(--flow-color-grey1);
  padding: 0px 25px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0px;
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`
