import React from "react"
import styled from "styled-components"
import { ProjectColors } from "../style/ProjectColors"

const Dropdown = ({ children, width, extraordinaryFinancing = false }) => {
  return (
    <DropdownWrapper
      width={width}
      extraordinaryFinancing={extraordinaryFinancing}
    >
      {children}
    </DropdownWrapper>
  )
}
export default Dropdown

const DropdownWrapper = styled.div`
  select {
    padding: 3px 5px 3px 5px;
    border-radius: 5px;
    border: solid 1px ${ProjectColors.InputBorder};
    color: inherit;
    width: ${({ width }) => width || "unset"};
    max-width: ${({ extraordinaryFinancing, width }) =>
      width || extraordinaryFinancing ? "300px" : "160px;"};
  }
`
