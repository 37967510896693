import TextButton from "../common/TextButton"
import React from "react"
import EditSigner from "./EditSigner"

const AddSigner = ({ signRule, updateSelectedRule, t, isForeignAccount }) => {
  const onSubmit = (newPerson) => {
    const rule = { ...signRule }
    const personRolleKombinasjon = rule?.personRolleKombinasjon || []
    personRolleKombinasjon.push({
      navn: newPerson.name,
      ssn: newPerson.ssn,
      selected: true,
      isCustom: true,
      email: newPerson.email,
      isValid: true,
      rolle: {
        tekstforklaring: newPerson.position,
      },
    })
    rule.personRolleKombinasjon = personRolleKombinasjon
    delete rule.setPerson
    updateSelectedRule(rule)
  }

  const onChange = (newPerson) => {
    const rule = { ...signRule }
    rule.setPerson = newPerson
    updateSelectedRule(rule)
  }

  const cancel = () => {
    const rule = { ...signRule }
    delete rule.setPerson
    updateSelectedRule(rule)
  }

  return signRule.setPerson ? (
    <EditSigner
      isForeignAccount={isForeignAccount ?? false}
      title={t("add-signer")}
      person={signRule.setPerson}
      cancel={() => cancel()}
      onChange={onChange}
      onSubmit={(p) => onSubmit(p)}
      t={t}
    />
  ) : (
    <TextButton onClick={() => onChange({})}>+ {t("add-signer")}</TextButton>
  )
}

export default AddSigner
