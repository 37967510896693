export const hasData = (data) => {
  let result = !!data
  if (data && typeof data === "object") {
    result = Object.keys(data)?.length > 0
  }
  return result
}

//returns the first argument with a value, or null if no values
/**
 * @param  {...any} args
 * @returns {any}
 */
export const returnFirstArgWithValue = (...args) => {
  let value = null
  args?.forEach((item) => {
    if (hasData(item) && !hasData(value)) {
      value = item
    }
  })
  return value
}
