import React from "react"
import styled from "styled-components"
import TextButton from "../common/TextButton"
import { Spinner } from "@stacc/flow-ui-components"
import { hasData } from "../../util/checkIfValue"
import DownloadFileButton from "../common/DownloadFileButton"
import formatWithLocale from "../../util/dateFormatter"
import _ from "lodash"
import InformationLine from "../performFinancialCheck/detailsComponents/InformationLine"

const DownloadCadastre = ({ t, cadastre, showFallback = false }) => {
  return (
    <Item>
      {hasData(cadastre) ? (
        <StyledDownload>
          <DownloadFileButton
            title={
              t("cadastrepdf") + " " + formatWithLocale(cadastre?.timestamp)
            }
            fileId={cadastre?.storedFile?.storeID}
            key={cadastre?.storedFile?.storeID}
          />
        </StyledDownload>
      ) : (
        <>{showFallback && <Heading>{t("no-cadastrepdf-to-show")}</Heading>}</>
      )}
    </Item>
  )
}

const FetchCadastrePdf = ({
  oldCadastres,
  newCadastres,
  cadastreKey,
  getLookupCadastrePdf,
  setIsLooking,
  isLooking,
  t,
  readOnly = false,
  heading,
  checkedColor,
}) => {
  const formattedCadastre = cadastreKey?.replaceAll("/", "-")
  const oldCadastre = _.last(
    _.get(oldCadastres, `${formattedCadastre}.cadastrepdf`, [])
  )
  const newCadastre = _.last(
    _.get(newCadastres, `${formattedCadastre}.cadastrepdf`, [])
  )
  return (
    <CadastreWrapper>
      {heading && <p>{t(heading)}</p>}
      <InformationLine
        margin={"0 0 0 0"}
        padding={""}
        min={"12em"}
        max={"3fr"}
        gridColumnGap={"3em"}
        backgroundColor={checkedColor}
        data={[
          {
            title: t("cadastre"),
            value: cadastreKey,
          },
          {
            title: t("fetched-cadastrepdfs"),
            value: (
              <>
                <DownloadCadastre
                  cadastre={newCadastre}
                  t={t}
                  showFallback={readOnly}
                />
                {!readOnly && (
                  <TextButton
                    disabled={hasData(isLooking)}
                    paddingConfiguration={"2px 0 0 0"}
                    type="button"
                    onClick={async () => {
                      setIsLooking([...isLooking, formattedCadastre])
                      getLookupCadastrePdf(formattedCadastre).then(() =>
                        setIsLooking(
                          isLooking.filter((e) => e !== formattedCadastre)
                        )
                      )
                    }}
                  >
                    <SpinnerContainer>
                      {"+" + t("update-cadastrepdf")}
                      {isLooking.includes(formattedCadastre) && (
                        <Spinner size={"1.5em"} />
                      )}
                    </SpinnerContainer>
                  </TextButton>
                )}
              </>
            ),
          },
          {
            title: t("original-cadastrepdf"),
            value: (
              <DownloadCadastre
                cadastre={oldCadastre}
                t={t}
                showFallback={true}
              />
            ),
          },
        ]}
      />
    </CadastreWrapper>
  )
}
export default FetchCadastrePdf

const CadastreWrapper = styled.div`
  padding: 0 0 1em 10px;
`
const Heading = styled.p`
  font-size: small;
  color: black;
  padding-bottom: 0.5em;
`

const StyledDownload = styled.div`
  color: var(--flow-color-grey1);
  padding: 0.1em 0.1em 0.1em 0;
  font-size: small;
  line-height: 0.8em;
  width: 100%;

  button {
    white-space: pre-wrap;
    width: 100%;
  }
  svg {
    display: none;
  }
`
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;

  svg {
    padding-left: 1em;
  }
`
const Item = styled.div`
  display: flex;
  flex-direction: column;
`
