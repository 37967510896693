import React, { useState } from "react"
import ErrorContext from "../../common/Error"
import Heading from "../../common/Heading"
import styled from "styled-components"

const RadioButton = ({ onChange, name, value, errors, t }) => {
  const [current, currentSet] = useState(value)
  const error = errors[name]?.message
  const handleChange = (value) => {
    currentSet(value.target.value)
    onChange(value)
  }
  return (
    <div>
      <Heading>{t(name)}</Heading>
      <RadioGroup onChange={handleChange} name={name} value={value}>
        <StyledRadio key={`yes${current}`}>
          <input
            type={"radio"}
            value={"yes"}
            checked={current === "yes"}
            onChange={handleChange}
          />
          <p> {t("Yes")}</p>
        </StyledRadio>
        <StyledRadio key={`no${current}`}>
          <input
            type={"radio"}
            value={"no"}
            checked={current === "no"}
            onChange={handleChange}
          />
          <p>{t("No")}</p>
        </StyledRadio>
      </RadioGroup>
      <ErrorContext t={t} error={error} />
    </div>
  )
}

export default RadioButton

const StyledRadio = styled.label`
  display: flex;
  flex-direction: row;
  p {
    padding-left: 5px;
  }
  &:last-child {
    padding-left: 10px;
  }
`

const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;
`
