import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { DropDownValues } from "../../../common/tempData/DropDownValues"
import Select from "../Select"

const GrowthPotentialProjectContainer = ({ t }) => {
  const { control, errors } = useFormContext()
  const selectOptions = DropDownValues.GrowthPotentialProject
  return (
    <Controller
      control={control}
      name={"growthPotentialProject"}
      key={"growthPotentialProject"}
      render={({ field: { name, onBlur, onChange, value } }) => (
        <Select
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          name={name}
          errors={errors}
          t={t}
          selectOptions={selectOptions}
        />
      )}
    />
  )
}

export default GrowthPotentialProjectContainer
