import * as yup from "yup"

const baseValidator = yup
  .string()
  .required("required-error")
  .typeError("type-error")
  .nullable()
const baseValidatorNumber = yup
  .string()
  .required("required-error")
  .typeError("type-error")
  .nullable()

const products = yup.object().shape({
  product: baseValidator,
  amount: baseValidatorNumber,
  subsidyEffect: baseValidatorNumber,
  fundingIntensity: baseValidatorNumber,
  productType: baseValidator,
})

const conditional = (type) => {
  const yupItem = yup.string()

  if (type) {
    return yupItem.when(type, {
      is: "yes",
      then: yup.string().required("required-error").nullable(),
      otherwise: (s) => s.nullable(),
    })
  } else {
    return yupItem.required("required-error").nullable()
  }
}
export const evaluateBasisAdditionalProductValidationSchema = yup
  .object()
  .shape({
    supportRegime: baseValidator,
    existingCustomerRelationships: baseValidator,
    receivablesFebruaryThroughMay: baseValidator,
    receivablesJuneThroughSeptember: baseValidator,
    receivablesFebruaryThroughMayComment: conditional(
      "receivablesFebruaryThroughMay"
    ),
    receivablesJuneThroughSeptemberComment: conditional(
      "receivablesJuneThroughSeptember"
    ),
    products: products,
    updatedCost: baseValidatorNumber,
  })
export const applicationDefaultValues = {
  supportRegime: null,
  existingCustomerRelationships: null,
  receivablesFebruaryThroughMay: null,
  receivablesJuneThroughSeptember: null,
  receivablesFebruaryThroughMayComment: null,
  receivablesJuneThroughSeptemberComment: null,
  updatedCost: 0,
  products: {
    product: null,
    amount: "0",
    subsidyEffect: 0,
    fundingIntensity: 0,
    productType: null,
  },
}
