import capitalizeFirstLetter from "../../util/capitalizeFirstLetter"

const dataFields = [
  {
    key: "oneyearcowcount",
    category: "dairy-cow",
    header: "Antall årskyr",
    designation: "(stk)",
  },
  {
    key: "beefcattlecount",
    category: "suckler-cow",
    header: "Antall storfe slakt per år",
    designation: "(stk)",
  },
  {
    beforeKey: "yearlyMeatProductionToday",
    afterKey: "yearlyMeatProductionTomorrow",
    category: "suckler-cow",
    header: "Årlig produsert kjøtt i alt",
    designation: "(kg)",
  },
  {
    key: "barrowcount",
    category: "pork",
    header: "Antall slaktegris pr. år",
    designation: "(stk)",
  },
  {
    key: "oneyearsowcount",
    category: "pork",
    header: "Smågrisproduksjon (antall årspurker)",
    designation: "(stk)",
  },
  {
    key: "sowcount",
    category: "pork",
    header: "Kombinert svinehold (antall avlspurker)",
    designation: "(stk)",
  },
  {
    key: "winterfedcount",
    category: "sheep",
    header: "Vinterfôra sau",
    designation: "(stk)",
  },
  {
    key: "oneyeargoatcount",
    category: "goat",
    header: "Årsgeiter",
    designation: "(stk)",
  },
  {
    key: "chickencount",
    category: "chicken",
    header: "Antall kylling pr. år",
    designation: "(stk)",
  },
  {
    key: "hencount",
    category: "egg",
    header: "Antall høner pr. innsett",
    designation: "(stk)",
  },
  {
    key: "capasitygrain",
    category: "grain",
    header: "Kapasitet lager",
    designation: "(tonn)",
  },
  {
    key: "capasitypotato",
    category: "potatoes-vegetables",
    header: "Kapasitet lager",
    designation: "(tonn)",
  },
  {
    key: "area",
    category: "fruit-and-berries",
    header: "Antall dekar frukt og bær",
    designation: "(daa)",
  },
  {
    key: "livestockcount",
    category: "other-production",
    header: "Antall andre husdyr",
    designation: "(stk)",
  },
  {
    key: "greenhousearea",
    category: "other-production",
    header: "Antall kvadratmeter (veksthus)",
    designation: "(kvm)",
  },
  {
    key: "irrigationarea",
    category: "other-production",
    header: "Antall dekar (vanningsanlegg)",
    designation: "(daa)",
  },
]

const getRelevantFields = (category) => {
  return dataFields.filter((field) => field.category === category)
}

const mapRelevantFields = (category) => {
  const relevantFields = getRelevantFields(category)
  return relevantFields.map((field) => {
    if (field.key) {
      const beforeKey = `productiondata${capitalizeFirstLetter(
        field.key
      )}Before`
      const afterKey = `productiondata${capitalizeFirstLetter(field.key)}After`

      const { header, designation } = field
      return {
        beforeKey,
        afterKey,
        header,
        designation,
      }
    }

    if (field.beforeKey && field.afterKey) {
      const { beforeKey, afterKey, header, designation } = field
      return {
        beforeKey,
        afterKey,
        header,
        designation,
      }
    }

    console.error("No key found for field", field)
    return null
  })
}

export { getRelevantFields, mapRelevantFields }
