import React from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { useLanguage } from "../translate/useLanguage"

const Settings = ({ t, i18n }) => {
  const { language, setLanguage } = useLanguage(i18n, t)
  return (
    <Wrapper>
      <Layout>
        <SectionTitle>{t("settings")}</SectionTitle>
        <div>
          <ControlTitle>
            {t("language-header", { language: t(language) })}
          </ControlTitle>
          <LanguagesContainer>
            <LanguageContainer>
              <label>
                <RadioButton
                  onClick={() => setLanguage("en")}
                  type="radio"
                  id="lang-en"
                  name="languages"
                  value={false}
                  defaultChecked={language === "en"}
                />
                English
              </label>
            </LanguageContainer>
            <LanguageContainer>
              <label>
                <RadioButton
                  onClick={() => setLanguage("no")}
                  type="radio"
                  id="lang-no"
                  name="languages"
                  value="no"
                  defaultChecked={language === "no"}
                />
                Norsk
              </label>
            </LanguageContainer>
          </LanguagesContainer>
        </div>
      </Layout>
    </Wrapper>
  )
}

const Layout = styled.div`
  display: grid;
  grid-row-gap: 1.5rem;
`

const Wrapper = styled.div`
  padding: 2rem 4rem;
`

const SectionTitle = styled.h2`
  margin: 0;
  color: var(--flow-color-sea-dark);
  font-size: 24px;
`

const ControlTitle = styled.h3`
  font-weight: normal;
`

const LanguageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  &:nth-last-child(1) {
    margin-left: 1rem;
  }
`

const RadioButton = styled.input`
  transform: scale(1.8) translateX(4px);
  margin: 0 calc(1rem + 4px) 0 0;
`

const LanguagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
`

export default withTranslation("settings")(Settings)
