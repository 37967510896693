import React from "react"
import ErrorContext from "../../common/Error"
import Heading from "../../common/Heading"
import styled from "styled-components"
import { ProjectColors } from "../../common/style/ProjectColors"

const Select = ({
  onChange,
  onBlur,
  name,
  value,
  errors,
  t,
  selectOptions: options,
  ...rest
}) => {
  const error = errors[name]?.message
  return (
    <StyledDropdown>
      <Heading>{t(name)}</Heading>
      <select id={name} onChange={onChange} onBlur={onBlur} value={value ?? ""}>
        <option key={`${name}noOption`} id={`${name}noOption`} value={null}>
          {t("choose")}
        </option>
        {options?.map((optionItem) => {
          return (
            <option
              key={optionItem.value}
              id={optionItem.value}
              value={optionItem?.value}
            >
              {optionItem?.name}
            </option>
          )
        })}
      </select>
      <ErrorContext t={t} error={error} />
    </StyledDropdown>
  )
}

export default Select
const StyledDropdown = styled.div`
  select {
    padding: 3px 5px 3px 5px;
    border-radius: 5px;
    border: solid 1px ${ProjectColors.InputBorder};
    color: inherit;
    min-width: 18em;
  }
`
