import React from "react"
import StyledBox from "../../common/StyledBox"

const Item = ({ title, data, large = false, dataStyling = {} }) =>
  data ? (
    <div>
      <StyledBox
        whiteSpace
        padding="0 10px"
        title={title}
        data={data}
        large={large}
        dataStyling={dataStyling}
      />
    </div>
  ) : (
    <></>
  )

export default Item
