import axios from "axios"

const createAgreementDraft = async (productId) => {
  const { data } = await axios.get(
    `/si_server_proxy/si_server_proxy/api/financialprofile/agreementDraft/${productId}`
  )
  return data
}

const getInsightProductType = (productType) => {
  if (productType === "Loan") return "LOAN"
  return "GRANT"
}

const getAgreementProductSchema = async (productId, productType) => {
  const insightProductType = getInsightProductType(productType)
  const { data } = await axios.get(
    `/si_server_proxy/si_server_proxy/api/financialprofile/customschema/${insightProductType}?productInternalId=${productId}`
  )

  return data
}

const getInsightNotesContent = async (flowId, noteType) => {
  const data = await axios
    .get(
      `/si_server_proxy/rest/notes/v2/in/admin/externaluuid/${flowId}?noteType=${noteType}`
    )
    .then((response) => response.status)
  return data
}

export {
  createAgreementDraft,
  getAgreementProductSchema,
  getInsightNotesContent,
}
