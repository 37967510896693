import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

/**
 *
 * @param {object} input
 * @param {string} input.error
 * @param {JSX.Element|JSX.Element[]=} input.children
 * @returns {JSX.Element}
 */
const ErrorText = ({ error, children }) => {
  const { t } = useTranslation()
  if (!error || error.length === 0) return <>{children}</>
  return (
    <ErrorStyle>
      {children}
      <div className="error-message">{t(error)}</div>
    </ErrorStyle>
  )
}

const ErrorStyle = styled.div`
  color: var(--flow-color-ferrari);
  .error-message {
    margin-top: 4px;
  }
  input,
  select {
    border-color: var(--flow-color-ferrari) !important;
  }
`

export default ErrorText
