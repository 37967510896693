import React from "react"
import styled from "styled-components"
import { ProjectColors } from "../../common/style/ProjectColors"

const SidebarMenu = ({ children }) => {
  return <StyledList>{children}</StyledList>
}
export default SidebarMenu

const StyledList = styled.ul`
  height: 100%;
  list-style: none;
  padding-left: 0;
  border-right: 1px solid;
  border-color: ${ProjectColors.SlateBoxShadow};
  margin-top: 0;
`
