import styled from "styled-components"
import React, { useEffect, useState } from "react"
import DeliveryComponent from "./DeliveryComponent"
import axios from "axios"
import { Spinner, Icons } from "@stacc/flow-ui-components"
import isTesting from "../../util/isTesting"
import WarningInfoBox from "../common/WarningInfoBox"

/**
 * The special term tab
 * @param {object} props The properties of the SpecialTerm tab
 * @param {InFlow.DeliveryItems} props.deliveries The deliveries in the application
 * @param {InFlow.OnChangeDelivery} props.onChange - Function that adds the changes to the deliveries.
 * @param {function} props.t Translation
 * @param {boolean} [props.readOnly=false] If the tab is read
 * @param {boolean} [props.isPayout] only used in payout subprocess
 * @param {string} [props.currentDeliveryId] only used in payout subprocess
 * @param {Array} [props.previousApplications]
 * @param {string} [props.category]
 * @param {string} [props.dateOfApproval]
 * @returns {JSX.Element}
 */
const SpecialTermsTab = ({
  deliveries,
  previousApplications,
  isPayout,
  dateOfApproval,
  onChange,
  t,
  readOnly = false,
  category,
  currentDeliveryId,
}) => {
  const SPECIAL_TERMS_LINK =
    "https://innovationnorway.sharepoint.com/sites/Finansieringshandboken#4.17-s%C3%A6rvilk%C3%A5r"

  useEffect(() => {
    if (isTesting()) return undefined
    const caller = async () => {
      const specialTermTagsRequest = async () => {
        try {
          setGettingSpecialTerms(true)
          return await axios.get("/api/special-term-tags")
        } catch (error) {
          console.error("Failed to get special term tags")
          setCheckSpecialTermsError(true)
        }
      }
      // Initializing
      const getSpecialTermTags = await specialTermTagsRequest()
      setGettingSpecialTerms(false)

      setSpecialTermTags(
        getSpecialTermTags.data.map((tag) => {
          return {
            value: tag,
            label: tag,
          }
        })
      )
    }
    caller()
  }, [])

  const [gettingSpecialTerms, setGettingSpecialTerms] = useState(false)
  const [checkSpecialTermsError, setCheckSpecialTermsError] = useState(false)
  const [specialTermsTags, setSpecialTermTags] = useState(false)
  const [editingId, setEditingId] = useState(null)

  // Get initial special terms for deliveries
  // This is used to set the deliveriesSpecialTerms state on mount
  /**
   * @type {Array<{deliveryId: string, specialTerms: InFlow.SpecialTerms}>} */
  const initialSpecialTermsDeliveries = Object.keys(deliveries).reduce(
    (acc, deliveryId) => {
      acc.push({
        deliveryId,
        specialTerms: deliveries[deliveryId].specialTerms,
      })
      return acc
    },
    []
  )
  // The deliveriesSpecialTerms state is used to sync the special terms state with the deliveries state
  // When the setDeliveriesSpecialTerms function is called, the special terms are updated in the deliveries state
  // The deliveries are updated using the onChange function
  // The deliveries are passed in to this component as a prop, and the onChange will update the deliveries state in the parent component
  const [deliveriesSpecialTerms, setDeliveriesSpecialTerms] = useState(
    initialSpecialTermsDeliveries
  )
  const [isDisabledEditingSpecialTerm, setIsDisabledEditingSpecialTerm] =
    useState(true)

  // When the deliveriesSpecialTerms state is updated, the special terms are updated in the deliveries state
  // The deliveriesSpecialTerms is updated in the DeliveryComponent
  // The reason for this is that the special terms are updated in the DeliveryComponent,
  // and we need to sync the special terms with the deliveries state
  useEffect(() => {
    if (deliveriesSpecialTerms.length > 0) {
      deliveriesSpecialTerms.forEach((delivery) => {
        if (!delivery?.deliveryId) return
        onChange({
          deliveryId: delivery.deliveryId,
          data: {
            ...deliveries[delivery.deliveryId],
            specialTerms: delivery.specialTerms,
          },
        })
      })
    }
  }, [deliveriesSpecialTerms])

  const isMaintenance = Boolean(deliveries?.vedlikehold)

  const findNewerMaintenanceCase = (previousApplications) => {
    const newerMaintenanceCaseExists = previousApplications.some(
      (application) =>
        application.applicationTypeDescription.includes("maintenance") &&
        application.status === "Approved" &&
        new Date(application.submittedOn) > new Date(dateOfApproval) // maintenance case was created after current case was approved
    )
    return newerMaintenanceCaseExists
  }
  let showErrorBox = false
  let relevantDeliveries = deliveries
  if (isPayout) {
    if (!currentDeliveryId || !deliveries[currentDeliveryId]) {
      relevantDeliveries = {}
    } else {
      relevantDeliveries = {
        [currentDeliveryId]: deliveries[currentDeliveryId],
      } // only get the current delivery in payout
      const newerMaintenanceCaseExists =
        findNewerMaintenanceCase(previousApplications)
      showErrorBox = newerMaintenanceCaseExists
    }
  }

  if (checkSpecialTermsError) {
    return (
      <LoadingPage>
        <Icons.ErrorCircle />
        <div>
          {t("could-not-fetch-specialterms")} <br /> {t("close-and-try-again")}
        </div>
      </LoadingPage>
    )
  }

  return (
    <ContentWrapper>
      {gettingSpecialTerms ? (
        <LoadingPage>
          <Spinner size={50} />
          <div>{t("wait-specialterms")}</div>
        </LoadingPage>
      ) : (
        <>
          <StyledLinkWrapper>
            <StyledLink href={SPECIAL_TERMS_LINK}>
              {t("financialGuideLink")}
            </StyledLink>
          </StyledLinkWrapper>
          <Title>{t("PRODUCT")}</Title>
          {Object.keys(relevantDeliveries || {})?.map((deliveryId, i) => (
            <React.Fragment key={i}>
              <DeliveryComponent
                delivery={relevantDeliveries[deliveryId]}
                deliveryId={deliveryId}
                t={t}
                onChange={onChange}
                specialTermTags={specialTermsTags}
                readOnly={readOnly}
                editingId={editingId}
                setEditingId={setEditingId}
                isMaintenance={isMaintenance}
                category={category}
                deliveries={deliveries}
                setDeliverySpecialTerms={setDeliveriesSpecialTerms}
                isDisabledEditingSpecialTerm={isDisabledEditingSpecialTerm}
                setIsDisabledEditingSpecialTerm={
                  setIsDisabledEditingSpecialTerm
                }
              />
              <Divider />
            </React.Fragment>
          ))}
        </>
      )}

      {showErrorBox && (
        <WarningInfoBox>{t("newer-maintenance-case-exists")}</WarningInfoBox>
      )}
    </ContentWrapper>
  )
}

export default SpecialTermsTab

const ContentWrapper = styled.div`
  margin: 15px;
`
const Title = styled.div`
  color: var(--flow-color-grey1);
  margin: 0 0 10px 5px;
`
const Divider = styled.div`
  margin: 15px;
`
const LoadingPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    margin-left: 10px;
  }
`
const StyledLinkWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 10px;
  margin-bottom: 10px;
`
const StyledLink = styled.a`
  text-decoration: underline;
  color: var(--flow-color-sea);
  cursor: pointer;
`
