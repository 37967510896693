import * as yup from "yup"
import { addThousandSeparator } from "../../../util/addThousandSeparator"

const baseValidator = yup
  .string()
  .required("required-error")
  .typeError("type-error")
  .nullable()
const bankInformation = (productConfiguration, t) => {
  const maxAmount =
    (productConfiguration.measurement.amountUpperLimitGuaranteePerOrgNumber /
      productConfiguration.guaranteePercentage) *
    100
  return yup.object().shape({
    loanSize: yup
      .number()
      .required("required-error")
      .typeError("type-error")
      .max(
        maxAmount,
        t("max-error").replace("{amount}", addThousandSeparator(maxAmount))
      ),
    loanNumber: baseValidator,
  })
}

export const evaluateBasisVekstgaranti = (productConfiguration, t) =>
  yup.object().shape({
    supportRegime: baseValidator.not(["noOption"], "required-error"),
    bankInformation: bankInformation(productConfiguration, t),
    caseComment: yup.string().required(""),
  })
