import React from "react"
import styled from "styled-components"
import { TextArea } from "@stacc/flow-ui-components"
import { TransparentButton } from "@stacc/flow-ui-components"
import PayoutDetailsForm from "./PayoutDetailsForm"
import { v4 as uuidv4 } from "uuid"

/**
 * @typedef {object} Props
 * @property {string} flowId - The id of the flow
 * @property {InFlow.KarCheckMessage} karCheckMessage - The message from the kar check
 * @property {InFlow.PayoutRequestReceived} payoutRequestReceived - The payout request received
 * @property {InFlow.Commitment} [commitment]
 * @property {number} [remainingAmount]
 * @property {string} [errorMsg]
 * @property {InFlow.Delivery} currentDelivery - The current delivery
 * @property {object} flow - The flow
 * @property {function} t - The translation function
 * @property {InFlow.StateData} stateData - The state data
 * @property {Function} setStateData - The state data setter
 * @property {boolean} readonly - Is the form readonly
 * @property {object} validationError - The error messages from yup
 * @property {string} deliveryId
 * @property {boolean} isPerformFinancialCheck - Is the form in perform financial check
 */

/**
 * @param {Props} props
 * @returns {JSX.Element}
 */
const PayoutDetails = ({
  flowId,
  karCheckMessage,
  payoutRequestReceived,
  currentDelivery,
  flow,
  t,
  stateData,
  setStateData,
  readonly,
  validationError,
  deliveryId,
  isPerformFinancialCheck,
}) => {
  /** @type {CoreView.CustomerAccount} */
  const customerAccount = flow?.data.raw?.accountMaster?.customerAccount || {}

  const addPayoutDeets = () => {
    const existingPayments = stateData.payments
    existingPayments.push({
      id: uuidv4(),
      payoutAmount: 0,
      payoutName: customerAccount.name,
      payoutOrgNumber: customerAccount.publicId,
      customerAccountNumber: payoutRequestReceived?.customerAccountNumber,
      payoutApplicantOrThirdPerson: "applicant",
    })
    setStateData({ ...stateData, payments: existingPayments })
  }

  /** @param {number} index */
  const onDelete = (index) => {
    const existingPayments = stateData.payments
    existingPayments.splice(index, 1)
    setStateData({ ...stateData, payments: existingPayments })
  }

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e
   * @param {string} key
   * @returns
   */
  const updatePayoutDefinitionText = (e, key) => {
    if (readonly) return
    if (key === "payoutDefinition") {
      const value = e.target.value
      const newStateData = { ...stateData }
      newStateData[key] = value
      setStateData(newStateData)
      return
    }
  }

  /**
   * @param {InFlow.Payment} payment
   * @param {number} index
   * @returns {void}
   */
  const updateCurrentPayment = (payment, index) => {
    const existingPayments = stateData.payments
    existingPayments[index] = payment
    setStateData({ ...stateData, payments: existingPayments })
  }

  return (
    <>
      {stateData?.payments?.map((x, i) => {
        // We need to check the karcheckkmessage based on payment.id and get it from delivery?.currentPayout?.karCheck[payment.id]?.resultText
        /** @type {InFlow.KarCheckMessage} */
        const karCheckMessageForPayment =
          i === 0 ? karCheckMessage : "Ingen kar sjekk utført"

        return (
          <PayoutDetailsForm
            currentDelivery={currentDelivery}
            karCheckMessage={karCheckMessageForPayment}
            flowId={flowId}
            key={x.id}
            payment={x}
            index={i}
            payoutRequestReceived={payoutRequestReceived}
            readonly={readonly}
            stateData={stateData}
            t={t}
            customerAccount={customerAccount}
            setStateData={setStateData}
            validationError={validationError}
            deliveryId={deliveryId}
            onDelete={() => onDelete(i)}
            updateCurrentPayment={(payment) => updateCurrentPayment(payment, i)}
            isPerformFinancialCheck={isPerformFinancialCheck}
          />
        )
      })}
      <Row>
        {!readonly && (
          <StyledTransparentButton
            onClick={() => addPayoutDeets()}
            disabled={readonly}
          >
            {t("addpayments")}
          </StyledTransparentButton>
        )}
      </Row>
      <Row>
        <TextAreaContainer>
          <TextAreaBox
            label={t("details-payout-explanation")}
            help={t("payout-details-help")}
            value={stateData?.payoutDefinition}
            disabled={readonly}
            onChange={(e) => updatePayoutDefinitionText(e, "payoutDefinition")}
            name=""
            placeholder={readonly ? "" : t("payout-details-placeholder")}
          />
          {validationError?.payoutDefinition && (
            <ErrorField> {validationError?.payoutDefinition}</ErrorField>
          )}
        </TextAreaContainer>
      </Row>
    </>
  )
}
export default PayoutDetails

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 2.5rem;
`

const ErrorField = styled.span`
  color: var(--flow-color-ferrari);
`

const TextAreaBox = styled(TextArea)`
  width: 100%;
  flex-basis: 100%;
  height: 150px;
`

const TextAreaContainer = styled.div`
  flex-basis: 100%;
`
const StyledTransparentButton = styled(TransparentButton)`
  color: blue;
`
/**
 * @typedef {object} Payment
 * @property {string} customerAccountNumber - The customer account number
 * @property {string} payoutName - The name of the payout
 * @property {string} id - The id of the payout
 * @property {string} payoutOrgNumber - The org number of the payout
 * @property {boolean} isPerson - Is the payout a person
 * @property {string} payoutApplicantOrThirdPerson - Is the payout the applicant or a third person
 * @property {number} payoutAmount - The amount of the payout
 */
