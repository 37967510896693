import React from "react"
import { RadioButtons } from "@stacc/flow-ui-components"
import styled from "styled-components"

const IddRadioButtons = ({ isTeam, taskData, setTaskData, t, isValid }) => {
  const decisionOptions = [
    { label: t("completed-by-caseworker"), value: "0" },
    { label: t("send-to-idd"), value: "1" },
  ]

  const checkedValue = taskData.completedByCaseworker
    ? "0"
    : taskData.sendToIddTeam
    ? "1"
    : "-1"

  const onDecisionChange = (e, val) => {
    const clone = { ...taskData }
    clone.sendToIddTeam = val === "1"
    clone.completedByCaseworker = val === "0"
    setTaskData(clone)
  }

  let decisionValidation = ""
  if (!taskData.sendToIddTeam && !taskData.completedByCaseworker) {
    decisionValidation = t("decision-validation")
  }

  return (
    <>
      {!isTeam && (
        <>
          <DecisionText>{t("decision")}</DecisionText>
          <RadioButtons
            checkedValue={checkedValue}
            name="sendToIddTeam"
            onChange={onDecisionChange}
            required
            options={decisionOptions}
          />
          <ValidationError>{!isValid && decisionValidation}</ValidationError>
        </>
      )}
    </>
  )
}

export default IddRadioButtons

const DecisionText = styled.div`
  text-transform: capitalize;
  font-style: normal;
  font-weight: normal;
  font-family: "IBM Plex Sans";
  color: #6c6c6c;
  font-size: 14px;
  line-height: 1.4em;
  font-weight: 500;
  color: #2b2b2b;
  padding-bottom: 0.4em;
  margin-top: 1.2em;
`

const ValidationError = styled.span`
  color: var(--flow-color-ferrari);
`
