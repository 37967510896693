import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components"
import FinancialCheckTab from "../components/performFinancialCheck/FinancialCheckTab"
import { checkForDepotPayoutAuthority } from "../components/utils/checkForAuthority"
import { FLOW_NS_DEV_OR_TEST } from "../components/ONLY_RENDER_IN_DEVELOPMENT"

const DirectPayout = ({ task, flow, t, schema, save, complete, user }) => {
  const { data, defaults } = task
  const taskHasData = data && Object.keys(data).length > 0
  const stateDataFromTask =
    flow?.data?.deliveries[task.variables.delivery.id]?.currentPayout
      ?.performFinancialCheck || {}
  const deliveryId = task.variables.delivery.id
  const [isProcessing, setProcessing] = useState(false)

  const [allowedToDecide, setAllowedToDecide] = useState(undefined)
  const [allowedToDecideReason, setAllowedToDecideReason] = useState("")

  const [formData, setFormData] = useState(taskHasData ? data : defaults)
  const stateData = stateDataFromTask
  const { applicationSummary, insightComponentData } = task.context

  useEffect(() => {
    const fetchData = async () => {
      setProcessing(true)
      const data = await checkForDepotPayoutAuthority(
        user,
        task.taskType,
        flow.flowId
      )
      if (FLOW_NS_DEV_OR_TEST()) {
        setAllowedToDecide(true)
        setAllowedToDecideReason("Dev or test")
      } else {
        setAllowedToDecide(data.allowed)
        setAllowedToDecideReason(data.reason)
      }
      setProcessing(false)
    }

    fetchData()
  }, [user])

  const handleComplete = (values) => {
    setProcessing(true)
    complete(
      values,
      () => setProcessing(false),
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }
  const handleSave = (e) => {
    e.preventDefault()
    setProcessing(true)
    save(
      formData,
      () => setProcessing(false),
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }
  return (
    <Layout forceHeight>
      <MainContent>
        <FinancialCheckTab
          flow={flow}
          t={t}
          stateData={stateData}
          deliveryId={deliveryId}
          readonly
          commonData={insightComponentData?.commonData}
          task={task}
        />
      </MainContent>
      <Context flow={flow} context={applicationSummary}>
        {!allowedToDecide && (
          <p
            style={{ color: "var(--flow-color-ferrari)", paddingBottom: "5px" }}
          >
            {t(allowedToDecideReason)}
          </p>
        )}
        <StyledForm>
          <ReactForm
            schema={schema}
            formData={formData}
            disabled={isProcessing || !allowedToDecide}
            onSubmit={(values) => handleComplete(values)}
            onChange={(values) => setFormData(values)}
          >
            <ButtonContainer>
              <PrimaryButton
                type="submit"
                isLoading={isProcessing}
                disabled={isProcessing || !allowedToDecide}
              >
                {t("complete")}
              </PrimaryButton>
              <SecondaryButton
                type="button"
                disabled={isProcessing}
                onClick={(e) => handleSave(e)}
              >
                {t("save")}
              </SecondaryButton>
            </ButtonContainer>
          </ReactForm>
        </StyledForm>
      </Context>
    </Layout>
  )
}
export default withTranslation()(DirectPayout)

const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  overflow-y: scroll;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`
