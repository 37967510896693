import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import InputField from "../InputField"

const NumberInput = ({ t, name, ...rest }) => {
  const { control, errors } = useFormContext()
  return (
    <Controller
      control={control}
      name={name}
      key={name}
      render={({ field: { name, onBlur, onChange, value } }) => {
        return (
          <InputField
            onBlur={onBlur}
            onChange={onChange}
            name={name}
            errors={errors}
            t={t}
            value={value || ""}
            {...rest}
          />
        )
      }}
    />
  )
}

export default NumberInput
