/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import formatWithLocale from "../../util/dateFormatter"

const DepotControlSummary = ({
  applicant,
  followUpDate,
  caseNumber,
  companyName,
}) => {
  const { t } = useTranslation()
  const formattedFollowUpDate = formatWithLocale(followUpDate, "dd.MM.yyyy")
  return (
    <Content>
      <ItemWrapper>
        <Title>{t("depotControlApplicant")}</Title>
        <ContextContent>{applicant}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("followUpDate")}</Title>
        <ContextContent>{formattedFollowUpDate}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("caseNumber")}</Title>
        <ContextContent>{caseNumber}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("companyName2")}</Title>
        <ContextContent>{companyName}</ContextContent>
      </ItemWrapper>
    </Content>
  )
}

const Content = styled.div.attrs(function (props) {
  return { columns: props.columns ?? 3 }
})`
  display: grid;
  padding: 10px;
  background-color: #f5f9ff;
  border-radius: 10px;
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  gap: 10px;
`

const Title = styled.h3`
  font-weight: 300;

  ::first-letter {
    text-transform: capitalize;
  }

  color: #8b8a8d;
  font-size: 12pt;
  margin-bottom: 8px;
`

const ItemWrapper = styled.div`
  ::first-letter {
    text-transform: capitalize;
  }
`
const ContextContent = styled.div`
  a {
    text-decoration: none;
  }
`

export default DepotControlSummary
