const dummyStatisticalData = {
  comment: "yes yes",
  projectRiskRadio: "3",
  growthPotentialProject: "notEvaluated",
  innovationLevel: "innovationNationalLevel",
  innovationType: "innovationOrganizational",
  industryArea: "S-005",
  otherCharacteristics: [
    {
      label: "Batteri",
      value: "battery",
    },
  ],
  femaleInitiativeRadio: "no",
  numberEmployeesFemale: "123",
  numberEmployeesMale: "123",
  socialEffectRadio: "no",
  environmentalEffectRadio: "no",
  femaleInitiative: [],
  socialEffect: "",
  environmentalEffect: "",
  industry: {
    code: "01.110",
    descriptionNorwegian:
      "Dyrking av korn (unntatt ris), belgvekster og oljeholdige vekster",
    descriptionEnglish:
      "Growing of cereals (except rice), leguminous crops and oil seeds",
  },
  projectProfitability: null,
  socioEconomicProfitability: null,
  levelOfInnovation: null,
  safety: null,
  triggeringEffect: null,
  internationalNationalOrientation: "internationalExport",
}

export default dummyStatisticalData
