import axios from "axios"

export const getFile = async (fileId, responseType = "arraybuffer") => {
  const filePath = `/api/files/${fileId}`

  try {
    const res = await axios({
      method: "get",
      url: filePath,
      responseType,
    })

    const type = res.headers["content-type"]
    if (type !== null) {
      return { file: res.data, type }
    }

    const error = new Error(
      `Error while getting file. Unexpected content-type: ${type}`
    )
    console.error(error)
    return null
  } catch (error) {
    console.error(error)
    return null
  }
}

export const getFileMetadata = async (fileId) => {
  const filePath = `/api/files/${fileId}/meta`

  try {
    return await axios.get(filePath)
  } catch (error) {
    console.error(error)
    return null
  }
}
