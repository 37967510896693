import React from "react"
import styled from "styled-components"
import Error from "../Error"

const TextAreaInput = ({ onChange, name, value, errors, t, showErrors }) => {
  const error = errors[name]?.message
  return (
    <>
      <StyledTextArea onChange={onChange} name={name} value={value} />
      {error && showErrors && <Error error={error} t={t} />}
    </>
  )
}
export default TextAreaInput

const StyledTextArea = styled.textarea`
  display: flex;
  min-height: 5em;
  width: 80%;
  padding: 0.5em;
`
