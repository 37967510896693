import React from "react"
import { addThousandSeparator } from "../../../util/addThousandSeparator"
import GridContent from "./GridContent"

/**
 * The remaining amount component
 * @param {object} props The properties of the RemainingAmount component
 * @param {function} props.t The translation function
 * @param {number} props.remainingAmount The remaining amount
 * @param {string} props.currency The currency
 * @param {object} [props.commitment]
 * @param {object} [props.stateData]
 * @param {object} [props.payoutRequestReceived]
 * @returns {JSX.Element}
 */
const RemainingAmount = ({ t, remainingAmount, currency }) => {
  return (
    <GridContent
      title={t("remainingAmount")}
      data={
        (remainingAmount
          ? addThousandSeparator(remainingAmount.toString())
          : "0") +
        " " +
        currency
      }
    />
  )
}

export default RemainingAmount
