import React, { useState } from "react"
import axios from "axios"
import { Text } from "@stacc/flow-ui-components"
import styled from "styled-components"
import { FontStyles } from "@stacc/flow-ui-components"
import { TransparentButton } from "@stacc/flow-ui-components"
import { Icons } from "@stacc/flow-ui-components"

/**
 *
 * @typedef {object} EditAccountProps
 * @property {string} title
 * @property {string} data
 * @property {function} setMode
 * @property {function} setEditData
 * @property {function} partialSave
 * @property {(payment: InFlow.Payment, index?: number) => void} updateCurrentPayment - The update current payment function
 * @property {string} flowId
 * @property {function} setLoading
 * @property {string} [errorMessage]
 * @property {function} setErrorMessage
 * @property {string} deliveryId
 * @property {function} t
 * @property {InFlow.Payment} payment
 * @property {boolean} isAbroad
 *
 */

/**
 * @param {EditAccountProps} props
 * @returns {JSX.Element}
 */
const EditAccount = ({
  title,
  data,
  setMode,
  setEditData,
  updateCurrentPayment,
  flowId,
  setLoading,
  errorMessage,
  setErrorMessage,
  deliveryId,
  t,
  payment,
  isAbroad,
}) => {
  //Hente ut pending task slik at vi kan hente taskId på message-tasken som skal trigges
  const getTaskId = async (flowId) => {
    const res = await axios.get(
      `/api/flow/tasks?flowId=${flowId}&status=pending&taskType=kar-check`
    )
    const tasks = res?.data
    if (tasks.length > 0) {
      const pendingTask =
        tasks.find(
          (x) => x.taskType === "kar-check" && x.taskCategory === "message-task"
        ) || []
      return pendingTask.taskId
    }
  }

  /**
   * Triggers the KAR check
   * @param {string} flowId - The id of the flow
   * @param {string} newAccountNumber - The new account number
   * @returns {Promise<void>}
   */
  const triggerKARCheck = async (flowId, newAccountNumber) => {
    setErrorMessage(t("wait-karcheck-message"))
    payment.customerAccountNumber = newAccountNumber

    updateCurrentPayment({
      ...payment,
      customerAccountNumber: newAccountNumber,
    })
    try {
      setMode("none")
      setLoading(true)
      const taskId = await getTaskId(flowId)

      const startTime = new Date()
      await axios.post(`/api/flow/tasks/${taskId}/trigger`, {
        customerAccountNumber: newAccountNumber,
        organizationNumber: payment.payoutOrgNumber,
        isPerson: payment.isPerson,
        paymentId: payment.id,
      })

      let counter = 0

      const interval = setInterval(async () => {
        const res = await axios.get(`/api/flow/flows/${flowId}`)
        const flowData = res?.data?.data
        const tasks = res?.data?.tasks

        /** @type {InFlow.Delivery} */
        const delivery = flowData?.deliveries[deliveryId]

        if (tasks.length > 0) {
          const karchecks =
            tasks.filter(
              (x) =>
                x.taskType === "kar-check-on-message-task" &&
                x.taskCategory === "service-task"
            ) || []
          const pendingKarCheck =
            karchecks.filter((x) => new Date(x.createdAt) > startTime).pop() ||
            {}

          if (pendingKarCheck.status === "completed") {
            setLoading(false)
            const errorText =
              delivery?.currentPayout?.karCheck[payment.id]?.resultText

            setErrorMessage(errorText || t("karcheck-failed-message"))

            payment.customerAccountNumber = newAccountNumber

            payment.karErrorMessage = errorText || t("karcheck-failed-message")
            payment.karCheckMessage = errorText || t("karcheck-failed-message")
            updateCurrentPayment(payment)

            clearInterval(interval)
          }
        }

        if (counter >= 10) {
          clearInterval(interval)
          setLoading(false)
          setErrorMessage(t("karcheck-failed-message"))
        }
        counter++
      }, 2000)
    } catch (error) {
      console.error("Failed to trigger message-task Kar-check", error)
    }
  }
  const [input, setInput] = useState(data)

  const handleCancel = () => {
    setEditData(data)
    setMode("none")
  }

  const handleSubmit = () => {
    // Strip input of non-numeric characters
    const strippedInput = input.replace(/\D/g, "")

    setEditData(strippedInput)
    return triggerKARCheck(flowId, strippedInput)
  }
  const handleSubmitNoKarCheck = () => {
    const strippedInput = input.replace(/\s/g, "")

    setEditData(strippedInput)
    updateCurrentPayment({
      ...payment,
      customerAccountNumber: strippedInput,
    })
    setMode("none")
  }
  return (
    <div>
      <Line>
        <p>{title}</p>
      </Line>
      <CustomStyledBox>
        <Line>
          <Text
            defaultValue={data}
            onChange={(e) => setInput(e.target.value)}
            name="account"
          />
          {errorMessage}
          <ButtonContainer>
            <Button
              type="button"
              onClick={isAbroad ? handleSubmitNoKarCheck : handleSubmit}
            >
              <Icons.Check color="var(--flow-color-grass-dark)" />
            </Button>
            <Button type="button" onClick={handleCancel}>
              <Icons.Remove color="var(--flow-color-ferrari)" />
            </Button>
          </ButtonContainer>
        </Line>
      </CustomStyledBox>
    </div>
  )
}

export default EditAccount

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Button = styled(TransparentButton)`
  padding: 0;
  margin: 0;
  height: 18px;
`

const CustomStyledBox = styled.div`
  display: flex;
  background-color: inherit;
  border-radius: 5px;
  flex-direction: column;
  input {
    border: solid 1px var(--flow-color-grey2);
  }
  :first-child {
    margin: 0;
  }
`

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  p {
    ${FontStyles.Small}
    padding-right: 5px;
  }

  &:nth-child(2) {
    p {
      ${FontStyles.Normal}
      color: black;
    }
  }
`
