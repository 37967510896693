const Slate90 = "#F0F0EF"
const SlateBoxShadow = "#E2E2E1"
const Slate95 = "#F8F8F8"
const InputBorder = "#DAD9D8"
const BorderColor = "#E0E0E0"
const Sea97 = "#F5F9FF"
const MenuColor = "#030303"
const CommentColor = "#f9fbff"
const Slate40 = "#FEFAF9"

const ProjectColors = {
  Slate90,
  Slate40,
  SlateBoxShadow,
  Slate95,
  InputBorder,
  BorderColor,
  Sea97,
  MenuColor,
  CommentColor,
}
export { ProjectColors }
