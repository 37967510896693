const validateSchema = async (schema, item) => {
  try {
    const result = await schema.validate(item, {
      abortEarly: false,
    })
    return {
      hasErrors: false,
      result,
    }
  } catch (e) {
    const errors = e.inner.reduce((acc, x) => {
      acc[x.path] = x.message
      return acc
    }, {})
    return {
      hasErrors: true,
      errors,
    }
  }
}

export { validateSchema }
