import { Controller, useFormContext } from "react-hook-form"
import React from "react"
import styled from "styled-components"
import { ProjectColors } from "../../../../common/style/ProjectColors"
import { calculatePercentageOf } from "../../../../../util/calculatePercentageOf"
import Error from "../../../../common/Error"
import BentoInput from "../../../../common/inputs/bento/BentoInput"

const Amount = ({ totalAppliedFor, name, t }) => {
  const { setValue, control, errors, showErrors } = useFormContext()
  const error = errors[`${name}.amount`]?.message
  const handleChange = (onChange, element) => {
    setValue(
      "products.fundingIntensity",
      calculatePercentageOf(element, totalAppliedFor)
    )
    onChange(element)
  }
  return (
    <Content>
      <Controller
        name={`${name}.amount`}
        control={control}
        key={`${name}.amount`}
        render={({ field: { name, onChange, onBlur, ...rest } }) => (
          <StyledInput>
            <BentoInput
              name={name}
              id={name}
              mode="currency"
              inputMode="numeric"
              pattern={/[0-9]*/}
              type="text"
              onChange={(e) => handleChange(onChange, e.rawValue)}
              onBlur={(e) => onBlur(e.rawValue)}
              {...rest}
            />
          </StyledInput>
        )}
      />
      {false && error && <Error error={error} t={t} />}
    </Content>
  )
}
const StyledInput = styled.div`
  display: flex;

  input {
    align-self: flex-start;
    padding: 3px 0px 3px 5px;
    border-radius: 5px;
    border: solid 1px ${ProjectColors.InputBorder};
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export default Amount
