import React from "react"
import styled from "styled-components"
import { useFormContext, Controller } from "react-hook-form"
import StyledBox from "../../common/StyledBox"
import renderField from "../vekstgaranti/renderField"
import { ProjectColors } from "../../common/style/ProjectColors"

const SubFieldsLine = ({ fields, name, fieldName, index, t }) => {
  const { control } = useFormContext()
  return (
    <>
      {fields.map((field, i) => {
        return (
          <StyledBox
            width="100%"
            key={i}
            padding={"0px"}
            title={`${field.title || field.displayNameShort}`}
          >
            <StyledInput>
              <Controller
                name={`products.${index}.${name}.${i}.${fieldName}`}
                control={control}
                key={`products.${index}.${name}.${i}.${fieldName}`}
                render={({
                  field: { name, onChange, onBlur, value, ...rest },
                }) =>
                  renderField(field.type, {
                    ...field,
                    onChange,
                    value: value,
                    field: { ...rest, name },
                  })
                }
              />
            </StyledInput>
          </StyledBox>
        )
      })}
    </>
  )
}

const StyledInput = styled.div`
  display: flex;

  input {
    align-self: flex-start;
    padding: 3px 0px 3px 5px;
    border-radius: 5px;
    border: solid 1px ${ProjectColors.InputBorder};
  }
`

export default SubFieldsLine
