import React from "react"
import styled from "styled-components"
import { ProjectColors } from "../../common/style/ProjectColors"

const SidebarItem = ({ onClick, active, index, total, currency, t, name }) => {
  return (
    <StyledLi>
      <StyledButton active={active} onClick={() => onClick(index)}>
        <StyledColumn>
          <p>{index !== "overview" ? name : t(index)}</p>
          <p>
            {total} {currency}
          </p>
        </StyledColumn>
      </StyledButton>
    </StyledLi>
  )
}
export default SidebarItem

const StyledLi = styled.li`
  display: flex;
  flex-direction: row;
`
const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StyledButton = styled.button`
  background-color: ${(props) =>
    props.active ? `var(--flow-color-snow)` : `${ProjectColors.Slate90}`};
  border: ${(props) =>
    props.active ? `solid 1px var(--flow-color-sea)` : `none`};
  border-radius: 5px;
  margin: 5px 5px 0px 5px;
  padding: 10px 0px 10px 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  width: 100%;
  cursor: pointer;

  p {
    color: ${(props) => (props.active ? `var(--flow-color-sea)` : `inherit`)};
    font-weight: bold;
    font-size: smaller;
    text-align: left;

    :last-child {
      color: ${(props) =>
        props.active ? `var(--flow-color-coal)` : `var(--flow-color-grey1)`};
      font-weight: normal;
      font-size: x-small;
      padding-top: 4px;
    }
  }
`
