import styled from "styled-components"

const Notice = styled.div`
  background-color: ${(props) =>
    props.backgroundColor || "var(--flow-color-sea-lightest)"};
  padding: 10px;
  border-radius: 15px;
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  border: ${(props) =>
    `${props.border?.size} ${props.border?.style} ${props.border?.color}` ||
    props.backgroundColor ||
    "var(--flow-color-sea-lightest)"};
`

export default Notice
