import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import Select from "../MultiSelect"
import { DropDownValues } from "../../../common/tempData/DropDownValues"

const FemaleInitiativeContainer = ({ t }) => {
  const { control, errors } = useFormContext()
  const selectOptions = DropDownValues.FemaleInitiative.reduce(
    (prevOption, option) => {
      const { name: label, value } = option
      prevOption.push({
        label,
        value,
      })
      return prevOption
    },
    []
  )

  return (
    <Controller
      control={control}
      name={"femaleInitiative"}
      key={"femaleInitiative"}
      render={({ field: { name, onBlur, onChange, value } }) => (
        <Select
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          name={name}
          errors={errors}
          t={t}
          selectOptions={selectOptions}
        />
      )}
    />
  )
}

export default FemaleInitiativeContainer
