/**
 * Get rest sum of a loan engagement / existing loan.
 * @param {CoreView.Engagement | CoreView.EngagementNumbers} loan - An object containing bookBalance, availableAmount and blockedAmount.
 * @returns {number} Rounded sum
 */
export const getEngagementAmount = (loan) => {
  const { bookBalance, availableAmount, blockedAmount } = loan

  // Only bookBalance might be negative. We cannot throw exceptions without issues
  // here, so we just default to 0. This isn't too terrible because the parts running
  // this code aren't as sensitive as the /setup/version.
  const loanSum =
    Math.abs(bookBalance ?? 0) + (availableAmount ?? 0) + (blockedAmount ?? 0)

  // IN-2895 (86428): numbers should not contain decimals.
  return Math.round(loanSum)
}
