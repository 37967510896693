const { isValid } = require("date-fns")
const { formatInTimeZone } = require("date-fns-tz")

export const formatDate = (date, pattern = "dd.MM.yyyy") => {
  if (!date) return ""
  const parsedDate = new Date(date)
  if (!parsedDate || !isValid(parsedDate)) return ""

  const formattedDate = formatInTimeZone(parsedDate, "Europe/Oslo", pattern)
  return formattedDate
}
