import styled from "styled-components"

export const DecisionLabelYellow = ({ text }) => {
  return <YellowLabel>{text}</YellowLabel>
}

export const DecisionLabelBlue = ({ text }) => {
  return <BlueLabel>{text}</BlueLabel>
}

const Label = styled.div`
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  width: 61px;
  height: 21px;
  font-weight: 600;
  font-size: 12px;
`

const YellowLabel = styled(Label)`
  border: 1px solid #9e3f0033;
  color: #9e3f00;
  background-color: #fff0cb;
`

const BlueLabel = styled(Label)`
  border: 1px solid #1b4df533;
  color: #192d8f;
  background-color: #bcd6ff;
`
