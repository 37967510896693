import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { FontStyles } from "@stacc/flow-ui-components"

const ContentParagraph = styled.p`
  ${FontStyles.Normal};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--flow-color-grey3);

  &.bold {
    ${FontStyles.NormalStrong};
  }

  &:last-child,
  &:nth-last-child(2) {
    border-bottom-width: 0px;
  }
`

const DetailsContainer = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 4fr 5fr;
  max-height: calc(25vw - 100px);
  overflow-y: auto;
`

const ErrorViewDetailed = ({ error }) => (
  <DetailsContainer>
    {Object.keys(error).map((key) => (
      <React.Fragment key={key}>
        <ContentParagraph className="bold">{key}</ContentParagraph>
        <ContentParagraph>{error[key]}</ContentParagraph>
      </React.Fragment>
    ))}
  </DetailsContainer>
)

ErrorViewDetailed.propTypes = {
  error: PropTypes.shape({
    activityId: PropTypes.string,
    causeIncidentId: PropTypes.string,
    configuration: PropTypes.string,
    executionId: PropTypes.string,
    id: PropTypes.string,
    incidentMessage: PropTypes.string,
    incidentTimestamp: PropTypes.string,
    incidentType: PropTypes.string,
    jobDefinitionId: PropTypes.string,
    processDefinitionId: PropTypes.string,
    processInstanceId: PropTypes.string,
    referenceId: PropTypes.string,
    rootCauseIncidentId: PropTypes.string,
    tenantId: PropTypes.string,
  }).isRequired,
}

export default ErrorViewDetailed
