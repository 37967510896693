import * as yup from "yup"

// check validity
const validate = async (data, t) => {
  let schema = yup.object().shape({
    payoutDecision: yup
      .string()
      .required("Velg beslutning")
      .oneOf(["OK", "REJECT", "MOREINFO"]),
    payoutType: yup
      .mixed()
      .required(t("payoutType-required"))
      .oneOf(
        ["partialPayout", "advancePayout", "finalPayout"],
        t("payoutType-required")
      ),
    payoutDefinition: yup.string(),
    specialConditions: yup
      .string()
      .oneOf(["controlled", "not-relevant"])
      .required(),
    projectAccounting: yup
      .string()
      .oneOf(["controlled", "not-relevant"])
      .required(),
    navRegistered: yup
      .string()
      .oneOf(["controlled", "not-relevant"])
      .required(),
    ongoingLoanManagement: yup
      .string()
      .oneOf(["controlled", "not-relevant"])
      .required(),
    anticipatedDefaultAssessment: yup
      .string()
      .oneOf(["controlled", "not-relevant"])
      .required(),
    payoutPowerOfAttorney: yup
      .string()
      .oneOf(["controlled", "not-relevant"])
      .required(),
    innovationContractCollaborationAgreement: yup
      .string()
      .oneOf(["controlled", "not-relevant"])
      .required(),
    progressReport: yup.string().when("payoutType", {
      is: "partialPayout",
      then: (s) => s.oneOf(["controlled", "not-relevant"]).required(),
      otherwise: (s) => s.nullable(),
    }),
    certificateOfCompletion: yup.string().when("payoutType", {
      is: "finalPayout",
      then: (s) => s.oneOf(["controlled", "not-relevant"]).required(),
      otherwise: (s) => s.nullable(),
    }),
    finalReport: yup.string().when("payoutType", {
      is: "finalPayout",
      then: (s) => s.oneOf(["controlled", "not-relevant"]).required(),
      otherwise: (s) => s.nullable(),
    }),
    checkSigners: yup.string().when("mustCheckSigners", {
      is: true,
      then: (s) => s.oneOf(["controlled", "not-relevant"]).required(),
      otherwise: (s) => s.nullable(),
    }),
    comment: yup.string(),
  })

  let validationResults = {}
  let isValidSchema = false
  let errors = {}

  try {
    const results = await schema.validate(data, { abortEarly: false })
    const valid = await schema.isValid(data, { abortEarly: false })
    validationResults = results
    isValidSchema = valid
  } catch (e) {
    e.inner.forEach((error) => {
      if (error.path) {
        errors[error.path] = error.message
      }
    })
  }
  return { validationResults, isValidSchema, errors }
}
export default validate
