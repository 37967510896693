import React, { useState } from "react"
import styled from "styled-components"
import GrowthPotentialProject from "./Selects/GrowthPotentialProject"
import InnovationLevel from "./Selects/InnovationLevel"
import InnovationType from "./Selects/InnovationType"
import IndustryArea from "./Selects/IndustryArea"
import OtherCharacteristics from "./Selects/OtherCharacteristics"
import FemaleInitiative from "./Selects/FemaleInitiative"
import FemaleInitiativeRadio from "./RadioButtons/FemaleInitiativeRadio"
import InternationalNationalOrientation from "./Selects/Orientation"
import { ProjectColors } from "../common/style/ProjectColors"
import ProjectRiskRadio from "./RadioButtons/ProjectRiskRadio"
import NumberInput from "./inputs/NumberInput"
import ProductionData from "./inputs/ProductionData"
import Heading from "../common/Heading"
import Industry from "./SearchableSelect/Industry"
import { Icons } from "@stacc/flow-ui-components"
import ErrorContext from "../common/Error"
import ProjectProfitability from "./RadioButtons/ProjectProfitability"
import SocioEconomicProfitability from "./RadioButtons/SocioEconomicProfitability"
import LevelOfInnovation from "./RadioButtons/LevelOfInnovation"
import Safety from "./RadioButtons/Safety"
import TriggeringEffect from "./RadioButtons/TriggeringEffect"

const StatisticalData = ({
  t,
  hasFemaleInitiative,
  industry,
  errors,
  isAgriculture,
  applicationContent,
}) => {
  const [isEditing, setIsEditing] = useState(!industry?.code)
  const isDisabled = true
  return (
    <Content>
      <Section columns={3}>
        <StyledProjectRisk>
          <Heading>{t("projectRiskRadio")}</Heading>
          <ProjectRiskRadio t={t} />
        </StyledProjectRisk>
        <InternationalNationalOrientation t={t} />
      </Section>
      <Section>
        <GrowthPotentialProject t={t} />
        <InnovationLevel t={t} />
        <InnovationType t={t} />
      </Section>
      <Section columns={3}>
        <StyledIndustry>
          <SubIndustry>
            <Heading>{t("industry")}</Heading>
            <StyledButton
              type={"button"}
              onClick={() => setIsEditing(!isEditing)}
            >
              <Icons.Edit />
            </StyledButton>
          </SubIndustry>
          {isEditing ? (
            <Industry t={t} setEditing={setIsEditing} />
          ) : (
            <>
              {industry ? (
                <div>
                  {industry?.code} - {industry?.descriptionNorwegian}
                </div>
              ) : (
                <div>{t("no-industry-chosen")}</div>
              )}
            </>
          )}
          {errors?.industry?.message && (
            <ErrorContext t={t} error={errors?.industry?.message} />
          )}
        </StyledIndustry>
        <IndustryArea t={t} />
        <OtherCharacteristics t={t} />
      </Section>
      <Section columns={3}>
        <SubSection>
          <FemaleInitiativeRadio t={t} />
          {hasFemaleInitiative === "yes" && <FemaleInitiative t={t} />}
        </SubSection>
        <SubSection>
          <Heading>{t("numberOfEmployees")}</Heading>
          <SubSubSection>
            <NumberInput t={t} name="numberEmployeesFemale" />
            <NumberInput t={t} name="numberEmployeesMale" />
          </SubSubSection>
        </SubSection>
      </Section>
      {isAgriculture && !isDisabled && (
        <>
          <Section>
            <SubSection>
              <Heading>{t("projectProfitability")}</Heading>
              <ProjectProfitability t={t} />
            </SubSection>
            <SubSection>
              <Heading>{t("socioEconomicProfitability")}</Heading>
              <SocioEconomicProfitability t={t} />
            </SubSection>
          </Section>
          <Section>
            <SubSection>
              <Heading>{t("levelOfInnovation")}</Heading>
              <LevelOfInnovation t={t} />
            </SubSection>
            <SubSection>
              <Heading>{t("safety")}</Heading>
              <Safety t={t} />
            </SubSection>
          </Section>
          <Section>
            <SubSection>
              <Heading>{t("triggeringEffect")}</Heading>
              <TriggeringEffect t={t} />
            </SubSection>
          </Section>{" "}
        </>
      )}
      {isAgriculture && (
        <Section>
          <SubSection>
            <Heading>{t("productionData")}</Heading>
            <ProductionData t={t} applicationContent={applicationContent} />
          </SubSection>
        </Section>
      )}
    </Content>
  )
}
export default StatisticalData

const StyledProjectRisk = styled.div`
  background-color: ${ProjectColors.Slate95};
  grid-column-end: span 2;
`

const Content = styled.div`
  margin: 10px 40px;
`

const Section = styled.div`
  margin: 60px 0 60px 0;
  border-radius: 5px;
  padding: 1em 1em 2em 1em;
  display: grid;
  background-color: ${ProjectColors.Slate95};
  grid-template-columns: ${(props) => `repeat(${props.columns || 3}, 1fr)`};
  grid-column-gap: 2%;
  width: calc(100% - 2em);
`
const StyledButton = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  background-color: inherit;
  cursor: pointer;
`

const StyledIndustry = styled.div`
  display: flex;
  flex-direction: column;
`

const SubIndustry = styled.div`
  display: flex;
  flex-direction: row;
`

const SubSection = styled.div`
  justify-content: ${({ isExpanded }) => (isExpanded ? "space-between" : "")};
  display: flex;
  flex-direction: column;
`
const SubSubSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1em;

  span {
    margin-right: 1em;
  }
`
