import { trimOperationalRisk } from "./trimOperationalRisk"
import * as yup from "yup"
/**
 * This function calculates the subsidy effect based on the passed in loan.
 * @param {object} loan - The loan.
 * @param {InFlow.SubsidyEffectParams} loan.subsidyEffectParams - The subsidy effect params.
 * @param {number} loan.loanAmount - The loan amount.
 *
 *
 * @returns {{subsidyEffect: number, netPresentValue: number, deliveryId: string}|null} - The calculated `subsidyEffect`.
 *
 * @example
 * const loan = {
 *  loanAmount: 1000000, // Innvilget belop
 *  subsidyEffectParams: {
 *    terminerPerAar: 4, // Terminer
 *    terminGebyr: 100, // Termingebyr
 *    antallAar: 3, // Avdragstid
 *    antallAvdragsFrieAar: 3, // Avdragsfri periode
 *    antallRenteFrieAar: 2, // Rente fri periode
 *    nominellRente: 0.08199999999999999,
 *    referanseRente: 0.0473,
 *    sikkerhetsRisiko: 7,
 *    driftsRisiko: "D", // Driftsrisiko A | B | C | D
 *    utbetalingsKurs: 0.995, // Utbetalingskurs
 *    laaneType: "SERIELAAN",
 *    frekvens: "KVARTALVIS",
 *    risikopaaslag: 0,
 *    deliveryId: "C0A5F1B0-F508-4972-B77B-F51C63CFCD6A",
 *    hasUpsideFee: false,
 *  }
 * }
 *
 * const result = calculateSubsidyEffect(loan)
 */

function calculateSubsidyEffect(loan) {
  const validationErrors = validateSubsidyEffectParams(
    loan.subsidyEffectParams,
    loan.loanAmount
  )

  if (validationErrors.length > 0) {
    console.error(validationErrors)
  }

  try {
    const { loanAmount, subsidyEffectParams } = loan
    const {
      terminerPerAar,
      terminGebyr,
      antallAar,
      utbetalingsKurs,
      referanseRente,
      antallAvdragsFrieAar,
      antallRenteFrieAar = 0,
      nominellRente,
      sikkerhetsRisiko,
      driftsRisiko,
      laaneType,
      frekvens,
      deliveryId,
    } = subsidyEffectParams

    const { tableDiff } = createSubsidyEffectTable(loanAmount, {
      terminerPerAar,
      terminGebyr,
      antallAar,
      antallAvdragsFrieAar,
      antallRenteFrieAar,
      nominellRente,
      sikkerhetsRisiko,
      driftsRisiko,
      referanseRente,
      risikopaaslag: 0, // 0.01 - 0.1
      utbetalingsKurs,
      laaneType,
      frekvens,
      deliveryId,
    })
    /**
     * @type {number[]} - The cashflows.
     */
    const cashFlows =
      tableDiff?.map((loanTerm) => loanTerm.differanseKontantStrøm) ?? []

    const referanseRentePlusOnePercent = (referanseRente * 100 + 1) / 100
    const terminRenteSubsidieEffekt =
      Math.pow(1 + referanseRentePlusOnePercent, 1 / terminerPerAar) - 1

    const netPresentValue = calculateNPV(terminRenteSubsidieEffekt, cashFlows)

    const differanseKontantStrømUtbetaling =
      -loanAmount + loanAmount * utbetalingsKurs

    const subsidyEffectAmount =
      netPresentValue + differanseKontantStrømUtbetaling

    return {
      netPresentValue: netPresentValue,
      subsidyEffect: subsidyEffectAmount,
      deliveryId,
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

/**
 * This function calculates the subsidy effect for multiple loans.
 * @param {object[]} loans - The loans.
 * @param {InFlow.SubsidyEffectParams} loans.subsidyEffectParams - The subsidy effect params.
 * @param {number} loans.loanAmount - The loan amount.
 * @returns {({subsidyEffect: number, netPresentValue: number, deliveryId: string} | null)[]} - The calculated subsidy effects.
 */
export default function calculateMultipleSubsidyEffects(loans) {
  const result = loans?.map((loan) => calculateSubsidyEffect(loan)) || []
  return result
}

/**
 * This function validates the subsidy effect params.
 * @param {InFlow.SubsidyEffectParams} subsidyEffectParams - The subsidy effect params.
 * @param {number} loanAmount - The loan amount.
 * @returns {string[]} - The validation errors.
 */
const validateSubsidyEffectParams = (subsidyEffectParams, loanAmount) => {
  const schema = yup.object().shape({
    loanAmount: yup.number().required(),
    terminerPerAar: yup.number().required(),
    terminGebyr: yup.number().required(),
    antallAar: yup.number().required(),
    utbetalingsKurs: yup.number().required(),
    antallAvdragsFrieAar: yup.number().required(),
    antallRenteFrieAar: yup.number().required(),
    nominellRente: yup.number().required(),
    referanseRente: yup.number().required(),
    sikkerhetsRisiko: yup.number().required(),
    driftsRisiko: yup.string().required(),
    laaneType: yup.string().required(),
    frekvens: yup.string().required(),
    risikopaaslag: yup.number().required(),
    deliveryId: yup.string().required(),
    hasUpsideFee: yup.boolean().notRequired(),
  })

  const validationErrors = []
  try {
    schema.validateSync({
      ...subsidyEffectParams,
      loanAmount,
    })
  } catch (error) {
    validationErrors.push(error.message)
  }

  return validationErrors
}

/**
 * This function creates a table with the subsidy effect for each term.
 * @param {number} loanAmount - The loan amount.
 * @param {InFlow.SubsidyEffectParams} subsidieEffectParams - The subsidy effect params.
 *
 * @returns {{tableLoan: InFlow.TableCashFlow[], tableReferenceLoan: InFlow.TableCashFlow[], tableDiff: InFlow.TableCashFlow[], terminRenteReferanseLaan: number}} - The calculated table.
 */
function createSubsidyEffectTable(loanAmount, subsidieEffectParams) {
  const {
    terminerPerAar,
    terminGebyr,
    antallAar,
    antallAvdragsFrieAar,
    antallRenteFrieAar,
    nominellRente,
    referanseRente,
    risikopaaslag,
    utbetalingsKurs,
    laaneType,
    frekvens,
    sikkerhetsRisiko,
    driftsRisiko,
  } = subsidieEffectParams

  const frekvensInMonths = {
    AARLIG: 12,
    HALVAARLIG: 6,
    KVARTALVIS: 3,
    MAANEDLIG: 1,
  }
  const antallTerminer = terminerPerAar * (antallAar + antallAvdragsFrieAar)
  const frekvensInNumber = frekvensInMonths[frekvens]
  const risikoPaaslagCalculated = calculateRisikoPaaslag(
    sikkerhetsRisiko,
    driftsRisiko,
    risikopaaslag
  )

  const referanseRenteMedRisikoTillegg =
    referanseRente + risikoPaaslagCalculated

  // Limit to 15 decimals, to avoid floating point errors compared to Excel sheet
  const terminRenteReferanseLaan = (
    Math.pow(1 + referanseRenteMedRisikoTillegg, 1 / terminerPerAar) - 1
  ).toFixed(16)

  let avdragBeregnet = 0
  if (laaneType === "SERIELAAN") {
    avdragBeregnet = calculateAvdragSerielaan(
      loanAmount,
      antallTerminer,
      antallAvdragsFrieAar,
      terminerPerAar
    )
  } else {
    avdragBeregnet = calculateAnnuity(
      loanAmount,
      nominellRente,
      terminerPerAar,
      antallAar
    )
  }

  const tableLoan = []
  let currentSaldo = loanAmount

  const firstUtbetaltSum = loanAmount * utbetalingsKurs
  const saldoUtbetaling = {
    termin: 0,
    saldo: currentSaldo,
    utbetalt: -firstUtbetaltSum,
    rente: 0,
    avdrag: 0,
    kontantstrøm: -firstUtbetaltSum,
    terminBeløp: 0,
    gebyr: 0,
    terminRente: 0,
  }
  tableLoan.push(saldoUtbetaling)

  for (let i = 0; i < antallTerminer; i++) {
    const isAvdragsfriPeriode = i < terminerPerAar * antallAvdragsFrieAar
    const isRenteFriPeriode = i < terminerPerAar * antallRenteFrieAar
    const avdragTermin = isAvdragsfriPeriode ? 0 : avdragBeregnet
    let terminGebyrAmount = terminGebyr
    let renteSumLoan = (currentSaldo * nominellRente) / terminerPerAar
    if (!isAvdragsfriPeriode) {
      currentSaldo = currentSaldo - avdragBeregnet
    }
    if (isRenteFriPeriode) {
      renteSumLoan = 0
      terminGebyrAmount = 0
    }
    tableLoan.push({
      termin: i + 1,
      rente: renteSumLoan,
      avdrag: avdragTermin,
      saldo: currentSaldo,
      kontantstrøm: avdragTermin + renteSumLoan + terminGebyrAmount,
      terminBeløp: avdragTermin + renteSumLoan + terminGebyrAmount,
      gebyr: terminGebyrAmount,
      utbetalt: 0,
      terminRente: Number(terminRenteReferanseLaan),
    })
  }

  let currentSaldoReferanseLaan = loanAmount
  const tableReferenceLoan = []
  saldoUtbetaling.utbetalt = -loanAmount
  saldoUtbetaling.kontantstrøm = -loanAmount
  tableReferenceLoan.push(saldoUtbetaling)

  for (let i = 0; i < antallTerminer; i++) {
    const isAvdragsfriPeriode = i < terminerPerAar * antallAvdragsFrieAar
    const avdragTermin = isAvdragsfriPeriode ? 0 : avdragBeregnet
    const renterReferanseLoan =
      Number(terminRenteReferanseLaan) * currentSaldoReferanseLaan

    if (isAvdragsfriPeriode) {
      // Hvis det er avdragsfri periode, så skal det ikke betales avdrag
    } else {
      currentSaldoReferanseLaan = currentSaldoReferanseLaan - avdragBeregnet
    }

    tableReferenceLoan.push({
      saldo: currentSaldoReferanseLaan,
      termin: i + 1,
      rente: renterReferanseLoan,
      avdrag: avdragTermin,
      kontantstrøm: avdragTermin + renterReferanseLoan,
      terminBeløp: 0,
      gebyr: 0,
      utbetalt: 0,
      terminRente: +terminRenteReferanseLaan,
    })
  }

  const tableDiff = []
  for (let i = 1; i <= antallTerminer; i++) {
    const differanseKontantStrøm =
      tableReferenceLoan[i].kontantstrøm - tableLoan[i].kontantstrøm
    tableDiff.push({
      saldo: tableLoan[i].saldo,
      termin: i,
      rente: tableLoan[i].rente,
      avdrag: tableLoan[i].avdrag,
      kontantstrøm:
        tableReferenceLoan[i].kontantstrøm - tableLoan[i].kontantstrøm,
      terminBeløp: tableLoan[i].terminBeløp,
      gebyr: 0,
      utbetalt: 0,
      terminRente: +terminRenteReferanseLaan,
      differanseKontantStrøm: differanseKontantStrøm,
      referanseKontantStrøm: tableReferenceLoan[i].kontantstrøm,
      låneKontantStrøm: tableLoan[i].kontantstrøm,
      låneRenter: tableLoan[i].rente,
      referanseRenter: tableReferenceLoan[i].rente,
    })
  }
  return {
    tableLoan: tableLoan,
    tableReferenceLoan: tableReferenceLoan,
    tableDiff,
    terminRenteReferanseLaan: +terminRenteReferanseLaan,
  }
}

/**
 * This function calculates the net present value of the cashflows.
 * then returns the calculated result.
 * @param {number} rate - The rate.
 * @param {number[]} cashFlows - The cashflows.
 * @returns {number} - The calculated NPV.
 *
 * @example
 * const result = calculateNPV(0.014027, [100, 200, 300])
 */

function calculateNPV(rate = -1, cashFlows) {
  let npv = 0
  // Start on term 1
  for (let i = 0; i < cashFlows.length; i++) {
    const cashFlow = cashFlows[i]
    npv += cashFlow / Math.pow(1 + rate, i + 1)
  }
  return npv
}

// Private function
/**
 * @param {number} loanAmount - The loan amount.
 * @param {number} rate - The rate.
 * @param {number} numberOfPaymentsPerYear - The number of payments per year.
 * @param {number} loanTermYears - The loan term in years.
 *
 * @returns {number} - The calculated annuity.
 */
function calculateAnnuity(
  loanAmount,
  rate,
  numberOfPaymentsPerYear,
  loanTermYears
) {
  const numberOfPayments = numberOfPaymentsPerYear * loanTermYears
  const monthlyRate = rate / (12 / numberOfPaymentsPerYear)
  const annuity =
    (loanAmount * (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments))) /
    (Math.pow(1 + monthlyRate, numberOfPayments) - 1)
  return annuity
}

// Private function
/**
 * @param {number} loanAmount - The loan amount.
 * @param {number} antallTerminer - The number of terms.
 * @param {number} antallAvdragsFrieAar - The number of years with no repayments.
 * @param {number} terminerPerAar - The number of terms per year.
 * @returns {number} - The calculated repayment.
 *
 * @example
 * const result = calculateAvdragSerielaan(1000000, 24, 3, 4)
 */
function calculateAvdragSerielaan(
  loanAmount,
  antallTerminer,
  antallAvdragsFrieAar,
  terminerPerAar
) {
  return loanAmount / (antallTerminer - terminerPerAar * antallAvdragsFrieAar)
}

/**
 * This function calculates the risk surcharge.
 * @param {number} sikkerhetsRisiko - The security risk.
 * @param {InFlow.OperationalRisk} driftsRisiko - The operational risk.
 * @param {number} risikopaaslag - The risk surcharge.
 * @returns {number} - The calculated risk surcharge.
 *
 * @example
 * const result = calculateRisikoPaaslag(7, "D", 0)
 */
function calculateRisikoPaaslag(
  sikkerhetsRisiko = 1,
  driftsRisiko = "A", // "A" | "B" | "C" | "D"
  risikopaaslag = 0
) {
  const driftsRisikoArray = ["A", "B", "C", "D"]
  const tapsProsent = [
    [0.75, 1.0, 2.2, 4.0],
    [0.75, 1.0, 2.2, 4.0],
    [0.75, 1.0, 2.2, 4.0],
    [1.0, 2.2, 4.0, 6.5],
    [2.2, 4.0, 6.5, 10.0],
    [2.2, 4.0, 6.5, 10.0],
    [2.2, 4.0, 6.5, 10.0],
  ]
  // Get index of driftsRisiko
  if (risikopaaslag > 0) {
    return risikopaaslag
  }
  const indexOfDriftsRisiko = driftsRisikoArray.indexOf(driftsRisiko)
  return tapsProsent[sikkerhetsRisiko - 1][indexOfDriftsRisiko] / 100
}

/**
 * @param {InFlow.PaymentFrequencyNorwegian} frequency
 * @returns {number} - The number of terms per year.
 *
 * @example
 * const result = convertFrequencyToNumber("AARLIG")
 */
export const convertFrequencyToNumber = (frequency) => {
  switch (frequency) {
    case "AARLIG":
      return 1
    case "HALVAARLIG":
      return 2
    case "KVARTALVIS":
      return 4
    case "TRE_GANGER_I_AARET":
      return 3
    case "BIMAANEDLIG":
      return 6
    case "MAANEDLIG":
      return 12
    default:
      return 1
  }
}

/**
 * @param {InFlow.PaymentFrequency} frequency
 * @returns {InFlow.PaymentFrequencyNorwegian} - The frequency in Norwegian.
 *
 * @example
 * const result = convertFrequencyFromAgreementNameToNorwegian("YEARLY")
 */
export const convertFrequencyFromAgreementNameToNorwegian = (frequency) => {
  switch (frequency) {
    case "YEARLY":
      return "AARLIG"
    case "BIANNUALLY":
      return "HALVAARLIG"
    case "QUARTERLY":
      return "KVARTALVIS"
    case "TRIANNUALLY":
      return "TRE_GANGER_I_AARET"
    case "BIMONTHLY":
      return "BIMAANEDLIG"
    case "MONTHLY":
      return "MAANEDLIG"
    default:
      return "KVARTALVIS"
  }
}

// TODO: Remove this it seems not to be used
// This function parses the subsidy effect data from api-gateway
// to the format needed for mappedStateAid in caseMemo
/**
 * Calculates the sum of two numbers.
 *
 * @param {Insight.CaseMemo} caseMemo - The caseMemo object which contains mappedStateAid.
 * @param {[
 *  {
 *   deliveryId: string,
 *   subsidyEffect: object,
 *   netPresentValue: number
 *  }
 * ]} deliverySubsidyEffects - The calculated subsidy effects containing deliveryId, subsidyEffect and netPresentValue.
 * @returns {object} - The new `mappedStateAid` with newly calculated `subsidyEffects`.
 * @property {Insight.MappedStateAidActivity[]} activities - The updated activities with new calculated subsidy effect.
 * @property {Insight.MappedStateAidProduct[]} products - The updated products with new calculated subsidy effect.
 * @property {object} ...rest - The old values that are not overwritten.
 */
export const parseSubsidyEffectForDelivery = (
  caseMemo,
  deliverySubsidyEffects // updated mappedStateAid
) => {
  const products = caseMemo?.mappedStateAid?.products

  // Map subsidy effect to correct product in activities
  const updatedActivitiesProducts = caseMemo?.mappedStateAid?.activities?.map(
    (activity) => {
      // Check if current activity has products, and that product matches delivery
      const productsInActivity = activity?.products?.map((product) => {
        const newCalculation = deliverySubsidyEffects?.find(
          (delivery) => delivery?.deliveryId === product?.product
        )
        return {
          ...product,
          subsidyEffect:
            newCalculation?.subsidyEffect && newCalculation.subsidyEffect > 0
              ? newCalculation?.subsidyEffect
              : 0,
        }
      })
      return {
        ...activity,
        products: productsInActivity,
      }
    }
  )
  const newProducts = products?.map((product) => {
    const newCalculation = deliverySubsidyEffects?.find(
      (delivery) => delivery?.deliveryId === product?.productId
    )
    if (!newCalculation) return product

    const { calculatedFields } = product
    const { subsidyEffect, fundingIntensity, supportBasis } = calculatedFields

    let newCalculatedSubsidyEffect = 0

    if (
      typeof newCalculation?.subsidyEffect === "number" &&
      newCalculation?.subsidyEffect > 0
    ) {
      newCalculatedSubsidyEffect = newCalculation?.subsidyEffect
    } else if (newCalculation?.subsidyEffect <= 0) {
      newCalculatedSubsidyEffect = 0
    } else if (subsidyEffect) {
      newCalculatedSubsidyEffect = subsidyEffect
    }

    const newCalculatedFields = {
      fundingIntensity:
        newCalculation?.subsidyEffect?.fundingIntensity ?? fundingIntensity,
      subsidyEffect: newCalculatedSubsidyEffect,
      supportBasis: newCalculation?.subsidyEffect?.supportBasis ?? supportBasis,
    }

    const productRows = product?.rows?.map((row) => {
      return {
        ...row,
        subsidyEffect: newCalculatedSubsidyEffect,
      }
    })

    return {
      ...product,
      rows: productRows,
      calculatedFields: {
        ...newCalculatedFields,
      },
    }
  })
  const newProductsSubsidyCalculated = newProducts?.map((stateAidProduct) => ({
    ...stateAidProduct,
    calculatedFields: {
      fundingIntensity: stateAidProduct.rows.reduce(
        (acc, { fundingIntensity }) => acc + +fundingIntensity,
        0
      ),
      subsidyEffect: stateAidProduct.rows.reduce(
        (acc, { subsidyEffect }) => acc + +subsidyEffect,
        0
      ),
      supportBasis: stateAidProduct.rows.reduce(
        (acc, { supportBasis }) => acc + +supportBasis,
        0
      ),
    },
  }))
  return {
    ...caseMemo?.mappedStateAid,
    activities: updatedActivitiesProducts,
    products: newProductsSubsidyCalculated,
  }
}

/**
 * This function calculates the subsidy effect for the delivery.
 * @param {InFlow.Delivery} delivery - The delivery object.
 * @param {Insight.CaseOverview} caseOverview - The case overview object.
 * @param {InFlow.SubsidyEffectData} subsidyEffectData - The subsidy effect data.
 * @param {InFlow.SecurityRiskData[]} securityRiskData - The security risk data.
 * @param {InFlow.OperationalRisk} selectedOperationalRisk - The selected operational risk.
 *
 * @returns {InFlow.SubsidyEffectParams} - The subsidy effect params.
 */
export const getSubsidyEffectParamsForDelivery = (
  delivery,
  caseOverview,
  subsidyEffectData,
  securityRiskData,
  selectedOperationalRisk
) => {
  const { terminGebyr, establishmentFeePercentage } = subsidyEffectData || {}
  const referanseRente = 4.73

  const {
    gracePeriod,
    interestFreePeriod,
    maturity,
    loanType,
    paymentInformation,
    interestProfile,
  } = delivery?.agreement?.data?.specificAccountTypeInfo || {}
  const lopetid = maturity?.numberOfMonths / 12
  const avdragsFriPeriode = gracePeriod?.numberOfMonths / 12
  const antallRenteFrieAar = interestFreePeriod?.numberOfMonths / 12
  const avdragsPeriode = lopetid - avdragsFriPeriode
  const frekvensFromAgreement = convertFrequencyFromAgreementNameToNorwegian(
    paymentInformation?.paymentFrequency
  )
  const { baseRate, marginRate = {} } =
    interestProfile?.interestLine?.interestRates
  const nominellRenteFromAgreement =
    (baseRate?.value + (marginRate?.value ?? 0)) / 100

  const terminerPerAar = convertFrequencyToNumber(frekvensFromAgreement)
  const driftsRisiko = trimOperationalRisk(
    selectedOperationalRisk || caseOverview?.selectedOperationalRisk?.title
  )

  // Find the risk item that matches the delivery ID
  const matchingRiskItem = securityRiskData?.find(
    (riskItem) => riskItem?.deliveryId === delivery?.deliveryId
  )

  const sikkerhetsRisiko = matchingRiskItem
    ? Number(matchingRiskItem.safetyRisk)
    : undefined

  /**
   * @type {InFlow.LoanType} - The loan type.
   */
  const laaneType = loanType === "SERIAL" ? "SERIELAAN" : "ANNUITETSLAAN"

  /** @type {InFlow.SubsidyEffectParams} */
  const subsidyEffectParams = {
    terminerPerAar: terminerPerAar,
    terminGebyr,
    antallAar: avdragsPeriode,
    antallAvdragsFrieAar: avdragsFriPeriode,
    antallRenteFrieAar,
    nominellRente: nominellRenteFromAgreement,
    referanseRente: referanseRente / 100,
    sikkerhetsRisiko: sikkerhetsRisiko || -1,
    driftsRisiko,
    utbetalingsKurs: (100 - establishmentFeePercentage) / 100,
    laaneType,
    frekvens: frekvensFromAgreement,
    risikopaaslag: 0,
    deliveryId: delivery?.deliveryId,
  }
  return subsidyEffectParams
}
