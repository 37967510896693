import React from "react"
import TextButton from "../../common/TextButton"
import { useCallback } from "react"
import { CustomActivityDefaultValues } from "../activity/Activity.validationSchema"

const AddSidebarItem = ({ addActivity, t }) => {
  const _addActivity = useCallback(() => {
    const customActivity = JSON.parse(
      JSON.stringify(CustomActivityDefaultValues())
    )
    addActivity(customActivity)
  }, [addActivity])
  return (
    <div>
      <TextButton onClick={_addActivity} button="button" t={t}>
        + {t("add-activity")}
      </TextButton>
    </div>
  )
}

export default AddSidebarItem
