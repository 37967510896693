import styled from "styled-components"
import OpenApplicationButton from "./OpenApplicationButton"
import TaskContext from "./TaskContext"
import React from "react"
import MaintenanceTaskContext from "../maintenance/MaintenanceTaskContext"

const Layout = ({ children, forceHeight }) => {
  return <Container forceHeight={forceHeight}>{children}</Container>
}

/**
 *
 * @param {object} props
 * @param {object} props.flow
 * @param {object} props.context
 * @param {JSX.Element | JSX.Element[]=} props.children
 * @param {JSX.Element=} props.GenerateReportButton
 * @returns {JSX.Element}
 */
export const Context = ({ flow, context, children, GenerateReportButton }) => {
  if (flow.flowDefinitionId === "payout-control") {
    return (
      <ContextStyle>
        <TaskContext data={context} />
        <ContextComponent>{children}</ContextComponent>
      </ContextStyle>
    )
  } else if (flow.flowDefinitionId === "annulment-control") {
    return (
      <ContextStyle>
        <TaskContext data={context} />
        <ContextComponent>{children}</ContextComponent>
      </ContextStyle>
    )
  } else if (flow.flowDefinitionId === "maintenance") {
    return (
      <ContextStyle>
        <OpenApplicationButton state={flow?.data} />
        {GenerateReportButton ? GenerateReportButton : null}
        <MaintenanceTaskContext context={context} children={children} />
      </ContextStyle>
    )
  } else if (flow.flowDefinitionId === "depot-end-control") {
    return (
      <ContextStyle>
        <TaskContext data={context} />
        <ContextComponent>{children}</ContextComponent>
      </ContextStyle>
    )
  } else if (
    flow.flowDefinitionId === "tapsvurdering" ||
    flow.flowDefinitionId === "tapsavsetning"
  ) {
    return (
      <ContextStyle>
        {GenerateReportButton ? GenerateReportButton : null}
        <TaskContext data={context} />
        <ContextComponent>{children}</ContextComponent>
      </ContextStyle>
    )
  } else {
    return (
      <ContextStyle>
        <OpenApplicationButton state={flow?.data} />
        {GenerateReportButton ? GenerateReportButton : null}
        <TaskContext data={context} />
        <ContextComponent>{children}</ContextComponent>
      </ContextStyle>
    )
  }
}

const Container = styled.div.attrs(function (props) {
  return props
})`
  display: flex;
  height: ${({ forceHeight }) => (forceHeight ? "100%" : "unset")};
  overflow-y: ${({ forceHeight }) => (forceHeight ? "hidden" : "auto")};
`

const ContextStyle = styled.div`
  min-width: 335px;
  width: 335px;
  margin-left: auto;
  justify-self: flex-end;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid var(--flow-color-grey3);
`

const ContextComponent = styled.div`
  width: 100%;
`

export default Layout
