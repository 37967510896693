import React from "react"
import { useFormContext, Controller } from "react-hook-form"
import RadioButtonYesNo from "../../../common/inputs/RadioButtonYesNo"

const ReceivablesFebruaryThroughMay = ({ t }) => {
  const { showErrors, control, errors } = useFormContext()

  return (
    <Controller
      control={control}
      name={"receivablesFebruaryThroughMay"}
      key={"receivablesFebruaryThroughMay"}
      render={({ field: { name, onBlur, onChange, value } }) => (
        <RadioButtonYesNo
          onChange={onChange}
          value={value}
          name={name}
          errors={errors}
          t={t}
          label={t(name)}
          showErrors={showErrors}
        />
      )}
    />
  )
}
export default ReceivablesFebruaryThroughMay
