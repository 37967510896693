/**
 * Creates a checksum of a string
 * @param {string} str - The value to create a checksum of
 * @returns {number} - The CRC32 checksum of the string
 */
export default function crc32(str) {
  let crc = 0xffffffff
  let mask = 0

  for (let i = 0; i < str.length; i++) {
    crc ^= str.charCodeAt(i)

    for (let j = 0; j < 8; j++) {
      mask = -(crc & 1)
      crc = (crc >>> 1) ^ (0xedb88320 & mask)
    }
  }

  return (crc ^ 0xffffffff) >>> 0
}
