import { formatInTimeZone } from "date-fns-tz"

const formatWithLocale = (date, pattern = "dd.MM.yyyy HH:mm") => {
  if (!date) return ""
  const parsedDate = new Date(date)
  if (!parsedDate) return ""
  return formatInTimeZone(parsedDate, "Europe/Oslo", pattern)
}

export default formatWithLocale
