import React from "react"
import { TransparentButton } from "@stacc/flow-ui-components"
import { Icons } from "@stacc/flow-ui-components"

/**
 * @typedef {object} IconButtonProps
 * @property {string} icon
 * @property {boolean} disabled
 * @property {function} onClick
 * @property {string} [color]
 * @property {string} [className]
 *
 * @param {IconButtonProps} props
 * @returns {JSX.Element}
 */
const IconButton = ({ icon, disabled, onClick, color, className }) => {
  const Icon = Icons[icon]
  return (
    <TransparentButton
      className={className}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <Icon
        color={
          color ||
          (disabled ? "var(--flow-color-grey)" : "var(--flow-color-sea)")
        }
      />
    </TransparentButton>
  )
}

export default IconButton
