import styled from "styled-components"
import {
  TransparentButton,
  SecondaryButton,
  FontStyles,
} from "@stacc/flow-ui-components"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { editSecurityTextValidationSchema } from "./EditSecurityText.validationSchema"
import { TextBoxInput, TextAreaInput } from "./InputComponents"

const EditSecurityText = ({ t, onSubmit, onCancelEdit, securityText }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
    register,
    ...rest
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(editSecurityTextValidationSchema),
    defaultValues: {
      ...securityText,
    },
  })

  const canSubmitForm = () => {
    const title = watch("title")
    if (!title) return false

    const subtitle = watch("subtitle")
    if (!subtitle) return false

    const text = watch("text")
    if (!text) return false

    return true
  }

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      control={control}
      errors={errors}
      watch={watch}
      setValue={setValue}
      getValues={getValues}
      {...rest}
    >
      <form id="editSecurityTextForm" onSubmit={handleSubmit(onSubmit)}>
        <Heading>{t("editing-security-text")}</Heading>
        <TextBoxInput fieldName="title" t={t} disabled={false} />
        <TextBoxInput fieldName="subtitle" t={t} disabled={false} />
        <TextAreaInput fieldName="text" t={t} disabled={false} />
        <Divider />
        <>
          <FlexRow>
            <SecondaryButton
              form="editSecurityTextForm"
              type="submit"
              disabled={!canSubmitForm()}
            >
              {t("save-security-text-edit")}
            </SecondaryButton>
            <TransparentButton onClick={() => onCancelEdit()}>
              {t("cancel-special-terms-button-text")}
            </TransparentButton>
          </FlexRow>
        </>
      </form>
    </FormProvider>
  )
}

export default EditSecurityText

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
`

const Divider = styled.div`
  margin: 15px;
`

const Heading = styled.h3`
  ${FontStyles.Large}
  padding: 5px 0;
`
