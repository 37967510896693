import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components"
import Notice from "../components/common/Notice"
import { formatDate } from "../components/utils/formatDate"

const RefetchEngagement = ({
  task,
  t,
  flow,
  save,
  trigger,
  schema,
  updateCase,
}) => {
  const { data, defaults } = task

  const taskHasData = data && Object.keys(data).length > 0
  const [formData, setFormData] = useState(taskHasData ? data : defaults)
  const [isProcessing, setProcessing] = useState(false)
  const [time, setTime] = useState(Date.now())
  const [error, setError] = useState(null)

  const isRefetchingEngagements = flow.data?.tmp?.creditGroup?.isRefetching

  useEffect(() => {
    if (isRefetchingEngagements) {
      const interval = setInterval(() => {
        updateCase()
        setTime(Date.now())
      }, 5000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [isRefetchingEngagements])

  const onFormChange = (values) => {
    setFormData(values)
  }

  const handleSave = () => {
    setProcessing(true)
    save(
      formData,
      () => {
        setError(null)
        setProcessing(false)
      },
      (err) => {
        console.error("Could not save task")
        setError(err)
        setProcessing(false)
      }
    )
  }

  const handleComplete = (values) => {
    setProcessing(true)
    trigger(
      formData,
      () => {
        setError(null)
        setProcessing(false)
      },
      (err) => {
        console.error("Could not complete task", err)
        setError(err)
        setProcessing(false)
      }
    )
  }

  return (
    <Layout forceHeight>
      <MainContainer>
        <ReactForm
          schema={schema}
          formData={formData}
          disabled={isProcessing}
          onChange={(values) => onFormChange(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <LoadingNotice hidden={!isRefetchingEngagements}>
            {`${t(
              "update-engagements-and-creditgroup-in-progress"
            )}  ${formatDate(time, "HH:mm:ss")}`}
          </LoadingNotice>
          <ErrorNotice hidden={!error}>{`${t(error?.detail)}`}</ErrorNotice>
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              isLoading={isProcessing}
              disabled={isProcessing || isRefetchingEngagements}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButtonModified
              type="button"
              disabled={isProcessing || isRefetchingEngagements}
              onClick={() => handleSave()}
            >
              {t("save")}
            </SecondaryButtonModified>
          </ButtonContainer>
        </ReactForm>
      </MainContainer>
      <Context context={task.context} flow={flow}></Context>
    </Layout>
  )
}

const LoadingNotice = styled(Notice)`
  margin-top: 10px;
  display: ${(props) => props.display};
  color: var(--flow-color-coal);
  background-color: var(--flow-color-orange-lighter);
  border-radius: 5px;
`

const ErrorNotice = styled(LoadingNotice)`
  background-color: var(--flow-color-ferrari-lighter);
  color: var(--flow-color-ferrari);
`

const MainContainer = styled.div`
  padding: 15px;
  width: 100%;
  overflow-y: auto;
`

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
`

export default withTranslation()(RefetchEngagement)
