export const handleSave = (save, data, setSaving) => {
  setSaving(true)
  save(
    data,
    () => setSaving(false),
    () => {
      console.error("Could not save task")
      setSaving(false)
    }
  )
}

export const handleComplete = (complete, data, setSubmitting) => {
  setSubmitting(true)
  complete(
    data,
    () => setSubmitting(false),
    () => {
      console.error("Could not complete task")
      setSubmitting(false)
    }
  )
}
