import { categoriesConstants } from "./getInsightContextForCategory"

const DEFAULT_CURRENCY = "NOK"

const categoryCurrencyMap = {
  [categoriesConstants.EEA]: "EUR",
}

/**
 * Get the currency for a given category.
 * @param {string} category - The category.
 * @returns {string} The currency ("NOK" | "EUR").
 */
export const getCurrency = (category) => {
  return categoryCurrencyMap[category] || DEFAULT_CURRENCY
}

/**
 *
 * @param {Insight.Engagement} engagement
 * @param {string} category
 * @returns {Insight.Engagement} engagement with currency added to grants, loans and guarantees
 */
export const addCurrencyToEngagement = (engagement, category) => {
  const { grants, loans, guarantees } = engagement
  const currency = getCurrency(category)
  const addCurrency = (array) => {
    return array.map((item) => {
      return {
        ...item,
        currency,
      }
    })
  }
  return {
    ...engagement,
    grants: addCurrency(grants),
    loans: addCurrency(loans),
    guarantees: addCurrency(guarantees),
  }
}
