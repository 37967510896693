/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import {
  FLOW_NS_DEV_OR_TEST,
  FLOW_NS_STAGING,
} from "../ONLY_RENDER_IN_DEVELOPMENT"

const getCuboDomain = () => {
  if (FLOW_NS_DEV_OR_TEST()) return "https://cubo-dev.innovasjonnorge.no"
  if (FLOW_NS_STAGING()) return "https://cubo-test.innovasjonnorge.no"
  return "https://cubo.innovasjonnorge.no"
}

const LossAssessmentSummary = ({
  customerAccount,
  year,
  caseId,
  parentFlowId,
}) => {
  const { t } = useTranslation()
  const cuboDomain = getCuboDomain()
  return (
    <Content>
      <ItemWrapper>
        <Title>
          {customerAccount.accountType === "Company"
            ? t("company")
            : t("person")}
        </Title>
        <ContextContent>{customerAccount?.publicId}</ContextContent>
        <ContextContent>{customerAccount?.name}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("period")}</Title>
        <ContextContent>{year}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("archive-reference")}</Title>
        <ContextContent>{caseId}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("customer-contact-in")}</Title>
        <ContextContent>{customerAccount?.manager}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("urls")}</Title>
        <ContextContent>
          <a
            href={`/inbox/tapsvurdering-active-cases-tapsavsetning/tapsavsetning/${parentFlowId}`}
            target=""
            rel="noopener noreferrer"
          >
            {t("queue-tapsavsetning")}
          </a>
          <br />
          <a
            href={`${cuboDomain}/customer/${customerAccount?.publicId}/overview`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {"Cubo"}
          </a>
        </ContextContent>
      </ItemWrapper>
    </Content>
  )
}

const Content = styled.div.attrs(function (props) {
  return { columns: props.columns ?? 3 }
})`
  display: grid;
  padding: 10px;
  background-color: #f5f9ff;
  border-radius: 10px;
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  gap: 10px;
`

const Title = styled.h3`
  font-weight: 300;

  ::first-letter {
    text-transform: capitalize;
  }

  color: #8b8a8d;
  font-size: 12pt;
  margin-bottom: 8px;
`

const ItemWrapper = styled.div`
  ::first-letter {
    text-transform: capitalize;
  }
`
const ContextContent = styled.div`
  a {
    text-decoration: none;
  }
`

export default LossAssessmentSummary
