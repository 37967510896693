const checkListSchemas = {
  specialConditions: [
    { label: "controlled", value: "controlled" },
    { label: "not-relevant", value: "not-relevant" },
  ],
  projectAccounting: [
    { label: "controlled", value: "controlled" },
    { label: "not-relevant", value: "not-relevant" },
  ],
  navRegistered: [
    { label: "controlled", value: "controlled" },
    { label: "not-relevant", value: "not-relevant" },
  ],
  progressReport: [
    { label: "controlled", value: "controlled" },
    { label: "not-relevant", value: "not-relevant" },
  ],
  certificateOfCompletion: [
    { label: "controlled", value: "controlled" },
    { label: "not-relevant", value: "not-relevant" },
  ],
  finalReport: [
    { label: "controlled", value: "controlled" },
    { label: "not-relevant", value: "not-relevant" },
  ],
  payoutPowerOfAttorney: [
    { label: "controlled", value: "controlled" },
    { label: "not-relevant", value: "not-relevant" },
  ],
  checkSigners: [
    { label: "controlled", value: "controlled" },
    { label: "not-relevant", value: "not-relevant" },
  ],
  ongoingLoanManagement: [
    { label: "controlled", value: "controlled" },
    { label: "not-relevant", value: "not-relevant" },
  ],
  anticipatedDefaultAssessment: [
    { label: "controlled", value: "controlled" },
    { label: "not-relevant", value: "not-relevant" },
  ],
  innovationContractCollaborationAgreement: [
    { label: "controlled", value: "controlled" },
    { label: "not-relevant", value: "not-relevant" },
  ],
}
export default checkListSchemas
