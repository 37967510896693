import React from "react"
import styled from "styled-components"
import { FontStyles } from "@stacc/flow-ui-components"

/**
 * @typedef {object} GridContentProps
 * @property {string} title
 * @property {function | string} data
 * @property {object} [props.titleProps={}]
 * @property {object} [props.contentProps={}]
 * @param {GridContentProps} props
 * @returns {JSX.Element}
 */
const GridContent = ({ title, data, titleProps = {}, contentProps = {} }) => {
  let DataComponent = data
  if (typeof data !== "function") {
    DataComponent = () => <>{data}</>
  }
  return (
    <BoxStyled>
      <Title {...titleProps}>{title}</Title>
      <Content {...contentProps}>
        <DataComponent />
      </Content>
    </BoxStyled>
  )
}
export default GridContent

const Title = styled.h3`
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  font-size: ${({ fontSize }) => fontSize || FontStyles.Small};
  color: ${({ color }) => color || "inherit"};
`

const Content = styled.div`
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  font-size: ${({ fontSize }) => fontSize || FontStyles.Normal};
  color: ${({ color }) => color || "var(--flow-color-coal)"};
`

const BoxStyled = styled.div``
