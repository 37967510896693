import * as yup from "yup"
import { v4 as uuidv4 } from "uuid"
import formatWithLocale from "../../../util/dateFormatter"

const dateValidator = yup
  .string()
  .required("required-error")
  .typeError("type-error")
  .nullable()

const baseValidatorString = yup
  .string()
  .required("required-error")
  .typeError("type-error")
  .nullable()

const baseValidatorNumber = yup
  .number()
  .required("required-error")
  .typeError("type-error")
  .nullable()

const defaultSelect = baseValidatorString.test(
  "chooseValidOption",
  "required-error",
  (item) => !!item && item !== "not-chosen" && item !== "noOption"
)

const productValidator = yup.array().of(
  yup.object().shape({
    product: baseValidatorString
      .test("chooseValidOption", "chooseValidOption", (item, context) => {
        const isValid = !!item && item !== "not-chosen" && item !== "noOption"
        return isValid
      })
      .test("noDuplicates", "noDuplicates", (item, context) => {
        const allProducts = context.from[1].value.products?.map(
          (e) => e?.product
        )

        const noDuplicates = new Set(allProducts).size < allProducts.length
        return noDuplicates
      }),
    amount: baseValidatorString,
    fundingIntensity: yup
      .number()
      .test("test_percentage", "percentage-test", (value, context) => {
        const amount = context.parent.amount
        const inputTotal = context.from[1].value.inputTotal
        return (amount > 0 && inputTotal > 0) || amount / inputTotal === value
      }),
    productType: baseValidatorString,
    subsidyEffect: baseValidatorNumber,
  })
)

export const ActivityValidationSchema = yup.object().shape(
  {
    activityType: defaultSelect,
    supportRegime: defaultSelect,
    products: productValidator,
    activityStart: dateValidator,
    activityEnd: dateValidator,
    inputTotal: baseValidatorNumber,
  },
  ["amount", "inputTotal", "fundingIntensity"]
)

export const EvaluateBasisValidationSchema = yup.object().shape({
  activities: yup.array().of(ActivityValidationSchema),
})

export const ActivityDefaultValues = {
  activityType: "noOption",
  supportRegime: "noOption",
  products: [],
  activityStart: "dd.mm.yyyy",
  activityEnd: "dd.mm.yyyy",
  costs: [],
}

export const CustomActivityDefaultValues = () => ({
  ...ActivityDefaultValues,
  isCustomActivity: true,
  activityStart: formatWithLocale(new Date(), "yyyy-MM-dd"),
  activityEnd: formatWithLocale(new Date(), "yyyy-MM-dd"),
  id: uuidv4(),
})
