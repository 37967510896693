export const pollForInitializedApp = (
  interval,
  maxAttempts,
  app,
  setError,
  previousErrors = []
) => {
  let attempts = 0
  return new Promise((resolve, reject) => {
    const executePoll = (resolve, reject) => {
      let result = {}
      try {
        result = app.getCurrentState()
      } catch (e) {
        result.isInitialized = false
      }
      attempts++
      if (result?.isInitialized) {
        //remove not initialized from previous errors
        previousErrors = previousErrors?.filter((e) => e === "not-initialized")
        setError(previousErrors)
        resolve(true)
      } else if (attempts >= maxAttempts) {
        previousErrors = previousErrors?.filter(
          (e) => e === "not-initialized" || e === "could-not-load"
        )
        setError([...previousErrors, "could-not-load"])
        resolve(false)
      } else {
        //remove not initialized from previous errors
        previousErrors = previousErrors?.filter((e) => e === "not-initialized")
        setError([...previousErrors, "not-initialized"])
        setTimeout(() => executePoll(resolve, reject), interval)
      }
    }
    executePoll(resolve, reject)
  })
}
