export const formatStakeholderFromState = (
  stateStakeholder = {},
  id,
  cancelDisabled = false,
  editing = false,
  creditChecking = false
) => {
  return {
    id,
    ...stateStakeholder?.info,
    //only used in dev
    ...(stateStakeholder?.info?.hashedSsn
      ? { hashedSsn: stateStakeholder?.info?.hashedSsn }
      : {}),
    editing,
    cancelDisabled,
    creditChecking,
    creditChecks:
      stateStakeholder?.creditChecks?.map((x) => ({
        date: x.date,
        file: x.file,
        provider: x.provider,
      })) || [],
    ownedCadastres: stateStakeholder.ownedCadastres || [],
    nationalPopulationRegister: stateStakeholder.nationalPopulationRegister,
  }
}
