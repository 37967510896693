import getInsightContextForCategory, {
  categoriesConstants,
} from "../../util/getInsightContextForCategory"
import {
  getNewAndExistingEngagements,
  mapLoansWithAgreementData,
} from "./mapAgreements"

/**
 * @param {object} state
 *
 * @returns {object}
 */
export const mapCaseReportData = (state) => {
  const context = getInsightContextForCategory(
    state.application.category,
    true,
    state?.analysis?.setDecision?.needAnalysis,
    true
  )
  const isMaintenance =
    state.application.category === categoriesConstants.MAINTENANCE

  const decisionTexts = isMaintenance
    ? state?.deliveries?.vedlikehold?.changes?.map((change) => {
        return {
          userAdded: change?.decisionTexts?.userAdded || [],
          generated: change?.decisionTexts?.generated || [],
        }
      })
    : []

  const hasLoan = isMaintenance
    ? true
    : state?.analysis?.setDecision?.hasLoan || false
  state.mapped.commonData.hasLoan = hasLoan

  const isTaxonomyRelevant =
    state.analysis?.setDecision?.isTaxonomyRelevant ?? false
  const showTaxonomy = isTaxonomyRelevant
  const shouldDisplayPrognose = true

  const newAndExistingCollateralEngagements = getNewAndExistingEngagements(
    state.deliveries,
    state.mapped?.collateralEngagements || []
  )
  const securityRiskData =
    state.analysis?.termsAndSecurity?.input?.securityRiskData ?? []

  const agreementRegisterData =
    mapLoansWithAgreementData(
      state.mapped.agreementData || [],
      newAndExistingCollateralEngagements,
      state.mapped.engagement.loans || [],
      securityRiskData
    ) || []
  const isForeignAccount =
    state?.application?.applicationContent.isForeignAccount
  const policy = state?.decisionTables?.policyCheckApplication
    ? {
        timestamp: state?.decisionTables?.policyCheckApplication?.timestamp,
        policies: state?.decisionTables?.policyCheckApplication?.output,
      }
    : null
  const showInnovationAndDigitalization =
    state?.analysis?.setDecision?.isInnovationAndDigitalizationRelevant ?? false
  const showSustainability =
    state?.analysis?.setDecision?.isSustainabilityRelevant ?? false
  const payload = {
    ...state?.mapped,
    context: context,
    decisionTexts,
    shouldDisplayPrognose,
    showTaxonomy,
    projectParticipants: state?.mapped?.projectParticipantsUpdated || [],
    agreementData: agreementRegisterData,
    isForeignAccount: isForeignAccount,
    policy: policy,
    showInnovationAndDigitalization: showInnovationAndDigitalization,
    showSustainability: showSustainability,
  }
  return payload
}
