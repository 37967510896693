import React from "react"
import TrafficLightCircle from "../common/TrafficLightCircle"

const getColorFromOrder = (order) => {
  if (order === "lav") return "green"
  else {
    return "red"
  }
}

const IddReason = ({ order, text }) => {
  if (!order) return <></>

  const color = getColorFromOrder(order)
  return (
    <p>
      <TrafficLightCircle color={color} /> {text}
    </p>
  )
}

const IddBasis = ({
  title,
  selfRisk,
  industryRisk,
  companyRisk,
  manualAssessment,
  t,
}) => {
  return (
    <div>
      <b>{t(title)}</b>
      <IddReason order={selfRisk} text={t("selfRisk")} />
      <IddReason order={industryRisk} text={t("industryRisk")} />
      <IddReason order={companyRisk} text={t("companyRisk")} />
      {manualAssessment && (
        <IddReason order={"høy"} text={t("startedManually")} />
      )}
    </div>
  )
}
export default IddBasis
