import styled from "styled-components"
import React, { useState } from "react"
import { TextArea } from "@stacc/flow-ui-components"
import { SecondaryButton, TransparentButton } from "@stacc/flow-ui-components"

/**
 * Component containing a special term in a delivery. The special term consists
 * of a category, a term, user instructions if any and the text of the term.
 * @param {object} props The properties of the SpecialTerm component
 * @param {InFlow.SpecialTerm} props.specialTerm Contains information about the term and variables for
 * determining if it is in edit (disabled) or can be deleted.
 * @param {function} props.t Translation
 * @param {(newTermText: string, selectedTerm: string, isUserAdded: boolean) => void} props.editTermText The function to set the special terms
 * @param {() => void} props.onCancel The function to cancel the edit of the special terms
 * @returns {JSX.Element}
 */
const EditSpecialTermText = ({ specialTerm, editTermText, t, onCancel }) => {
  const [newTermText, setNewTermText] = useState(specialTerm?.termText)

  return (
    <>
      <StyledTextArea
        key={specialTerm.id}
        name={"termTextArea"}
        onChange={(e) => {
          setNewTermText(e.target.value)
        }}
        value={newTermText}
        placeholder={t("term-text-placeholder")}
      />
      <StyledButtonWrapper>
        <SecondaryButton
          onClick={() => {
            editTermText(newTermText, specialTerm.term, specialTerm.isUserAdded)
          }}
        >
          {t("save-security-text-edit")}
        </SecondaryButton>
        <TransparentButton onClick={() => onCancel()}>
          {t("cancel-special-terms-button-text")}
        </TransparentButton>
      </StyledButtonWrapper>
    </>
  )
}

export default EditSpecialTermText

const StyledTextArea = styled(TextArea)`
  width: 100%;
  min-height: 140px;
  border-radius: 5px;
  background: white;
`
const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6em;
  margin-top: 1em;
`
