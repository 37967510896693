import React, { useState, useMemo } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components"
import { FormProvider, useForm } from "react-hook-form"
import SpecialTermsMaintenance from "../components/maintenance/SpecialTermsMaintenance"
import ErrorText from "../components/common/ErrorText"
import Tabs from "../components/common/Tabs"
import { returnFirstArgWithValue } from "../util/returnValue"
import AssessmentComments from "../components/common/AssessmentComments"
import { parseCommentsForAssessment } from "../components/utils/mapMaintenance"
import DecisionTextsList, {
  DownloadDecisionLetter,
} from "../components/maintenance/DecisionTextsList"
import { mapSpecialTerms } from "../components/utils/mapSpecialTerms"

const ManuallyUpdateCore = ({
  task,
  flow,
  t,
  schema,
  save,
  complete,
  user,
}) => {
  const { data, context } = task

  const [formData, setFormData] = useState(
    returnFirstArgWithValue(data, context.formData)
  )
  const [isProcessing, setProcessing] = useState(false)
  const [error, setError] = useState()
  const [activeTab, setActiveTab] = useState("decisiontexts")

  const decisionPdf = flow.data.files.find(
    (x) => x.documentType === "Vedtaksbrev" && x.documentGroup === "Archive"
  )

  const decisionTexts = useMemo(
    () => flow?.data?.deliveries?.vedlikehold?.changes,
    [flow?.data?.deliveries?.vedlikehold?.changes]
  )

  const specialTerms = useMemo(() => {
    return mapSpecialTerms(context.formData?.specialTerms)
  }, [context.formData])

  const currentUser = user.profile?.name

  const parsedComments = parseCommentsForAssessment(context, t)
  const comments = [
    ...(flow?.data?.assessmentComments ?? []),
    ...parsedComments,
  ]

  const methods = useForm({ defaultValues: task?.data })

  const handleComplete = () => {
    const values = methods.getValues()
    const data = {
      ...values,
      ...formData,
    }

    setError()
    setProcessing(true)
    complete(
      data,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const handleSave = () => {
    const data = {
      ...formData,
    }
    setProcessing(true)
    save(
      data,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const options = [
    {
      id: "decisiontexts",
      title: t("decisionTexts"),
      component: (
        <>
          <DecisionTextsList t={t} changes={decisionTexts} />
          {decisionPdf ? (
            <DownloadDecisionLetter t={t} decisionText={decisionPdf} />
          ) : (
            <p>Ingen vedtaksbrev tilgjengelig</p>
          )}
        </>
      ),
    },
    {
      id: "comments",
      title: t("assessment-history"),
      component: (
        <AssessmentComments
          comments={comments}
          t={t}
          assignee={flow?.assignee?.name}
        />
      ),
    },
    {
      id: "specialterms",
      title: t("specialTerms"),
      component: (
        <div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleComplete)}>
              <SpecialTermsWrapper>
                <SpecialTermsMaintenance
                  data={specialTerms}
                  t={t}
                  currentUser={currentUser}
                  readOnly={true}
                />
              </SpecialTermsWrapper>
            </form>
          </FormProvider>
        </div>
      ),
    },
  ]

  return (
    <Layout forceHeight>
      <MainContent>
        <Tabs
          defaultTab="decisiontexts"
          options={options}
          onChange={(tab) => setActiveTab(tab)}
        />
      </MainContent>
      <Context flow={flow} context={context.applicationSummary}>
        <StyledForm>
          <ReactForm
            schema={schema}
            formData={formData}
            disabled={isProcessing}
            onChange={(data) => setFormData(data)}
            onSubmit={handleComplete}
          >
            {error && <ErrorText error={error} t={t} />}
            <ButtonContainer>
              <PrimaryButton
                type="submit"
                isLoading={isProcessing}
                disabled={isProcessing}
              >
                {t("complete")}
              </PrimaryButton>
              <SecondaryButton
                type="button"
                disabled={isProcessing}
                onClick={() => handleSave()}
              >
                {t("save")}
              </SecondaryButton>
            </ButtonContainer>
          </ReactForm>
        </StyledForm>
      </Context>
    </Layout>
  )
}

const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  padding: 15px;
  overflow: scroll;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`

const SpecialTermsWrapper = styled.div`
  margin-top: 1.5em;
  max-width: 100%;
`

export default withTranslation("maintenance")(ManuallyUpdateCore)
