export const mapSpecialTerms = (specialTerms) => {
  const mandatory = specialTerms?.mandatory || []
  const userAdded = specialTerms?.userAdded || []

  const allSpecialTerms = [...mandatory, ...userAdded]
  const mappedSpecialTerms = allSpecialTerms?.reduce((acc, curr) => {
    acc.push({
      category: curr?.category,
      term: curr?.term,
      termText: curr?.termText,
    })
    return acc
  }, [])
  return mappedSpecialTerms
}
