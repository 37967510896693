import React from "react"
import styled from "styled-components"
import { ProjectColors } from "../../common/style/ProjectColors"
import BentoInput from "../../common/inputs/bento/BentoInput"

const FieldContainer = styled.div`
  display: flex;

  width: 100px;
  border: solid 1px ${ProjectColors.InputBorder};
  background-color: white;
  border-radius: 5px;
  padding: 1px 0px 1px 5px;
  height: 25px;

  span:nth-of-type(1) {
    text-align: right;
    color: black;
    margin-top: 3px;
  }

  span:nth-of-type(2) {
    padding-left: 1px;
    padding-top: 1px;
    margin-top: 1px;
  }

  input {
    border: none;
    border-radius: 0;
    background-color: transparent;
  }

  div:nth-of-type(1) input {
    text-align: right;
    width: 44px;
  }

  div:nth-of-type(2) input {
    text-align: left;
    width: 16px;
  }
`

const CustomPercentField = ({ name, index, actualValue, setActualValue }) => {
  // Because a single field for percents is hard to get right, we use two fields.
  // One for the whole part of the percent, and one for the decimal part.
  // The decimal part is treated as an integer, and onChange we convert it to an actual decimal.

  const wholePart = Math.floor(Number(actualValue)).toString()
  const decimalPart = (actualValue.split(".")[1] || "00").padEnd(2, "0")

  const onChangeWhole = (e) => {
    if (e.rawValue === wholePart) return

    const newValue = e.rawValue + "." + decimalPart

    setActualValue(newValue, index)
  }

  const onChangeDecimal = (e) => {
    const newValue = e.rawValue
    if (newValue === decimalPart) return

    const newNumber = +(wholePart + "." + newValue)
    const newActualValue = newNumber.toFixed(2).toString()

    setActualValue(newActualValue, index)
  }

  React.useEffect(() => {
    const wholeInputElement = document.getElementById(`whole-${name}`)
    const decimalInputElement = document.getElementById(`decimal-${name}`)

    if (wholeInputElement && decimalInputElement) {
      const handleKeyDown = (e) => {
        const targetElement = e.target

        if (e.key === "ArrowRight" && targetElement === wholeInputElement) {
          if (targetElement.selectionStart === targetElement.value.length) {
            setTimeout(() => {
              decimalInputElement.focus()
              decimalInputElement.setSelectionRange(0, 0)
            }, 0)
          }
        } else if (
          e.key === "ArrowLeft" &&
          targetElement === decimalInputElement
        ) {
          if (targetElement.selectionStart === 0) {
            setTimeout(() => {
              wholeInputElement.focus()
              wholeInputElement.setSelectionRange(
                wholeInputElement.value.length,
                wholeInputElement.value.length
              )
            }, 0)
          }
        }
      }

      wholeInputElement.addEventListener("keydown", handleKeyDown)
      decimalInputElement.addEventListener("keydown", handleKeyDown)

      return () => {
        wholeInputElement.removeEventListener("keydown", handleKeyDown)
        decimalInputElement.removeEventListener("keydown", handleKeyDown)
      }
    }
  }, [name])

  return (
    <FieldContainer>
      <BentoInput
        name={name}
        id={`whole-${name}`}
        mode="creditcard"
        pattern={/[0-9]*/}
        type="text"
        value={wholePart}
        onClick={(e) => e.stopPropagation()}
        onChange={onChangeWhole}
      />
      <span>,</span>
      <BentoInput
        name={name}
        id={`decimal-${name}`}
        mode="creditcard"
        pattern={/[0-9]*/}
        type="text"
        value={decimalPart}
        onClick={(e) => e.stopPropagation()}
        onChange={onChangeDecimal}
      />
      <span>%</span>
    </FieldContainer>
  )
}

export default CustomPercentField
