import CurrencyField from "../../common/inputs/CurrencyField"
import TextField from "../../common/inputs/TextField"
import DateField from "../../common/inputs/DateField"
import SelectField from "../../common/inputs/SelectField"
import DurationField from "../../common/inputs/DurationField"
import PercentField from "../../common/inputs/PercentField"

const renderField = (type, props) => {
  const fields = props.fields || []
  switch (type) {
    case "currency":
      return <CurrencyField {...props} />
    case "date":
      return <DateField {...props} />
    case "select":
      return <SelectField {...props} />
    case "duration":
      return <DurationField {...props} />
    case "percent":
      return <PercentField {...props} />
    case "baseRent":
      const rent =
        fields.find((x) => x.variable === "baseRent")?.formattedValue || "0"
      return <PercentField {...props} readonly={true} value={rent} />
    case "nominalInterest":
      const baseRent = Number.parseFloat(
        fields.find((x) => x.variable === "baseRent")?.value || "0"
      )
      const rentMargin = Number.parseFloat(
        fields.find((x) => x.variable === "rentMargin")?.value || "0"
      )

      const nominellRente = (baseRent + rentMargin)
        .toFixed(2)
        .toString()
        .replace(".", ",")

      return <PercentField {...props} readonly={true} value={nominellRente} />
    case "text":
    default:
      return <TextField {...props} />
  }
}

export default renderField
