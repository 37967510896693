import { useState } from "react"
import DisplaySigner from "./DisplaySigner"
import EditSigner from "./EditSigner"

const Signer = ({ t, signer, handleEdit, handleDelete }) => {
  const [editing, setEditing] = useState(false)

  function toggleEditing() {
    setEditing(!editing)
  }

  return (
    <>
      {editing ? (
        <EditSigner
          handleEdit={handleEdit}
          toggleEditing={toggleEditing}
          signer={signer}
          t={t}
        />
      ) : (
        <DisplaySigner
          handleDelete={handleDelete}
          onEditing={toggleEditing}
          signer={signer}
          t={t}
        />
      )}
    </>
  )
}

export default Signer
