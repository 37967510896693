import React from "react"
import ErrorContext from "../../common/Error"
import styled from "styled-components"
import { ProjectColors } from "../../common/style/ProjectColors"
import BentoInput from "../../common/inputs/bento/BentoInput"

const InputField = ({
  onChange,
  onBlur,
  name,
  value,
  errors,
  t,
  minWidth = "20px",
  maxWidth = "30px",
  hideLabel = false,
}) => {
  const error = errors[name]?.message

  return (
    <span>
      <InputStyled minWidth={minWidth} maxWidth={maxWidth}>
        {!hideLabel && <label>{t(name)}</label>}
        <BentoInput
          name={name}
          key={name}
          id={name}
          onChange={(e) => onChange(e.rawValue)}
          onBlur={(e) => onBlur(e.rawValue)}
          mode="currency"
          inputMode="numeric"
          pattern={/[0-9]*/}
          type="text"
          value={value}
        />
      </InputStyled>
      <ErrorContext t={t} error={error} />
    </span>
  )
}

export default InputField

const InputStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  label {
    padding-right: 5px;
  }

  input {
    border-radius: 5px;
    border: solid 1px ${ProjectColors.InputBorder};
    min-width: ${({ minWidth }) => minWidth};
    max-width: ${({ maxWidth }) => maxWidth};
    min-height: 20px;
    max-height: 30px;
    padding: 0.1em 0.4em 0.1em 0.4em;
    font-size: smaller;
  }
`
