import React, { useMemo, useState, useEffect } from "react"
import styled from "styled-components"
import Sidebar from "../components/common/Sidebar"
import { returnFirstArgWithValue } from "../util/returnValue"
import ErrorContext from "../components/common/Error"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Layout, { Context } from "../components/common/Layout"
import VekstgarantiProduct from "../components/evaluateBasisForSupport/vekstgaranti/VekstgarantiProduct"
import { evaluateBasisVekstgaranti } from "../components/evaluateBasisForSupport/vekstgaranti/EvaluateBasisVekstgaranti.validationSchema"
import _ from "lodash"
import { calculateSubsidyEffectCounterGuarantee } from "../util/calculateSubsidyEffect"
import { yupResolver } from "@hookform/resolvers/yup"
import { CenteredSpinner } from "@stacc/flow-ui-components"
import axios from "axios"
import {
  AgreementProvider,
  useCreateAgreementStore,
} from "../components/evaluateBasisForSupport/lineItems/useProductDraft"

/**
 * Due to getContext not being async do we need to fetch the products from
 * the product registry when loading the component. Also since hooks cannot be rendered
 * conditionally do we need to wrap the component in a loader componetn
 */
const ProductLoader = (data) => {
  const [vekstgarantiProduct, setVekstgarantiProduct] = useState()

  useEffect(() => {
    const asyncCallback = async () => {
      const { data } = await axios.get("/api/products?productId=vekstgaranti")
      setVekstgarantiProduct(data)
    }

    asyncCallback()
  }, [])

  if (!vekstgarantiProduct) {
    return <CenteredSpinner />
  }

  data.task.context.productConfiguration = vekstgarantiProduct

  return <EvaluateBasisForSupportVekstgaranti {...data} />
}

function EvaluateBasisForSupportVekstgaranti({ flow, task, save, complete }) {
  const {
    applicationSummary,
    amountAppliedFor,
    productConfiguration,
    stateData,
    currency,
    bankInformation,
    otherEngagements,
  } = task?.context

  const category = flow?.data?.application?.category
  const { t } = useTranslation("EvaluateBasisForSupport")
  const _bankInformation = Object.keys(bankInformation).reduce((acc, key) => {
    const taskData = (task.data?.bankInformation || {})[key]
    const stateDataItem = (stateData?.bankInformation || {})[key]
    acc[key] = returnFirstArgWithValue(
      taskData,
      stateDataItem,
      bankInformation[key].value
    )
    return acc
  }, {})

  const initialVekstgarantiProduct = useMemo(() => {
    const loanAmount = _bankInformation.loanSize
    const loanDuration = _bankInformation.loanDuration
    const guaranteeAmount =
      (loanAmount * productConfiguration.guaranteePercentage) / 100.0
    const subsidyEffect = Math.round(
      calculateSubsidyEffectCounterGuarantee(
        guaranteeAmount,
        Math.ceil(loanDuration / 12)
      )
    )
    const commission = Math.round(
      (guaranteeAmount * productConfiguration.commissionPercentage) / 100.0
    ).toString()
    const establishmentFee = Math.round(
      (guaranteeAmount * productConfiguration.establishmentFeePercentage) /
        100.0
    ).toString()

    _bankInformation.commission = commission
    _bankInformation.establishmentFee = establishmentFee
    return {
      product: t("growth-guarantee"),
      productType: t("guarantee"),
      fundingIntensity: `${productConfiguration.guaranteePercentage}%`,
      amount: guaranteeAmount,
      subsidyEffect,

      ...(stateData?.product || {}),
      ...(task?.data?.product || {}),
    }
  }, [_bankInformation, productConfiguration, stateData, task, t])

  const {
    handleSubmit,
    control,
    watch,
    onChange,
    setValue,
    getValues,
    ...rest
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(evaluateBasisVekstgaranti),
    defaultValues: {
      ...(stateData || {}),
      ...task.data,
      bankInformation: _bankInformation,
      product: initialVekstgarantiProduct,
    },
  })

  const [editing, setEditing] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [showValidation, setShowValidation] = useState(false)
  const onSave = (e) => {
    e.preventDefault()
    setLoading(true)
    save(
      getValues(),
      () => setLoading(false),
      () => setLoading(false)
    )
  }

  const validateForm = async (values) => {
    let validatedValues = {}
    let errors = {}
    try {
      validatedValues = await evaluateBasisVekstgaranti(
        productConfiguration,
        t
      ).validate(values, {
        abortEarly: false,
      })
    } catch (e) {
      console.error(e)
      errors = e.inner.reduce((acc, curr) => {
        acc[curr.path] = { message: curr.message, sidebarMessage: curr.message }

        if (curr.path === "supportRegime") {
          acc[curr.path].sidebarMessage = t("support-regime-required-error")
        }
        return acc
      }, {})
    }

    // Validate that we are not editing the form
    if (editing !== 0) {
      errors.editing = { sidebarMessage: t("no-editing-error") }
    }

    //if validation complete (values returned to validatedValues), set errors to {}
    setErrors(errors)
    if (!_.isEmpty(errors)) {
      setShowValidation(true)
    } else {
      setShowValidation(false)
    }
    return { values: validatedValues, errors }
  }

  const onComplete = async (e) => {
    const { values, errors } = await validateForm(getValues())
    if (!_.isEmpty(errors)) {
      return
    }

    setLoading(true)
    complete(
      values,
      () => setLoading(false),
      () => setLoading(false)
    )
  }

  const clearError = (field) => {
    const newErrors = { ...errors }
    delete newErrors[field]
    setErrors(newErrors)
  }

  const caseComment = watch("caseComment")
  const agreementStore = useCreateAgreementStore({})

  return (
    <Layout forceHeight>
      <Content>
        <FormProvider
          handleSubmit={handleSubmit}
          clearError={clearError}
          control={control}
          errors={errors}
          watch={watch}
          onChange={onChange}
          setValue={setValue}
          showErrors={showValidation}
          editing={editing}
          setEditing={setEditing}
          productConfiguration={productConfiguration}
          getValues={getValues}
          {...rest}
        >
          <AgreementProvider store={agreementStore}>
            <form>
              <VekstgarantiProduct
                productConfiguration={productConfiguration}
                options={{
                  productConfiguration: { vekstgaranti: productConfiguration },
                }}
                bankInformation={bankInformation}
                otherEngagements={otherEngagements}
                amountAppliedFor={amountAppliedFor}
                currency={currency}
                category={category}
              />
            </form>
          </AgreementProvider>
        </FormProvider>
      </Content>
      <Context context={applicationSummary} flow={flow}>
        <Sidebar
          commentPlaceholder={t("write-here", "Skriv her...")}
          commentValue={caseComment}
          commentHeading={t("comments-and-reasons")}
          commentOnChange={(comment) => setValue("caseComment", comment)}
          primaryButtonName={t("complete")}
          primaryButtonProps={{
            disabled: isLoading,
            onClick: onComplete,
          }}
          secondaryButtonName={t("save-and-close")}
          secondaryButtonProps={{
            disabled: isLoading,
            onClick: onSave,
          }}
        >
          {!caseComment && showValidation && (
            <ErrorContext t={t} error={"comment-missing"} />
          )}
          {showValidation &&
            Object.keys(errors).map((index) => {
              return <ErrorContext t={t} error={errors[index].sidebarMessage} />
            })}
        </Sidebar>
      </Context>
    </Layout>
  )
}

export default ProductLoader

const Content = styled.div`
  padding: 15px;
  width: 100%;
  overflow-y: auto;
  padding: 1em 1em 1em 1em;
`
