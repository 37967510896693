import { DropDownValues } from "../tempData/DropDownValues"
import { useTranslation } from "react-i18next"

const { default: Dropdown } = require("./Dropdown")

const SelectField = ({
  label,
  onChange,
  value,
  readonly,
  hasNoOption,
  options,
  field: { id, key, name, onBlur },
}) => {
  const { t } = useTranslation()
  if (readonly) {
    return <span>{t(value)}</span>
  }
  return (
    <Dropdown width="100%">
      <select
        name={name}
        id={key}
        key={key}
        onClick={(e) => e.stopPropagation()}
        onChange={(event) => onChange(event.target.value)}
        onBlur={onBlur}
        value={value}
      >
        {hasNoOption && (
          <option value={DropDownValues.NoOption.value}>
            {DropDownValues.NoOption.name}
          </option>
        )}
        {options.map((optionItem) => {
          return (
            <option
              key={`${name}-${optionItem?.value}`}
              value={optionItem?.value}
            >
              {t(optionItem?.name)}
            </option>
          )
        })}
      </select>
    </Dropdown>
  )
}

export default SelectField
