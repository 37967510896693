import { FontStyles } from "@stacc/flow-ui-components"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { ProjectColors } from "../../common/style/ProjectColors"

const OtherEngagements = ({ otherEngagements }) => {
  const { t } = useTranslation("EvaluateBasisForSupport")
  const { watch } = useFormContext()
  const loanNumber = watch("bankInformation.loanNumber")
  return (
    <div>
      <StyledHeading>{t("other-engagements-heading")}</StyledHeading>
      <EngagementsContainer>
        <>
          <EngagementItem white className="loanType" isTitle>
            {t("engagement-loan-type")}
          </EngagementItem>
          <EngagementItem white className="loanNumber" isTitle>
            {t("engagement-loan-number")}
          </EngagementItem>
          <EngagementItem white className="amount" isTitle>
            {t("engagement-loan-amount")}
          </EngagementItem>
          <EngagementItem white className="comment" isTitle></EngagementItem>
        </>
        {otherEngagements.map((engagement, i) => {
          const matching =
            loanNumber.replaceAll(".", "") ===
            engagement.loanNumber.replaceAll(".", "")
          const isWhite = i % 2 === 1
          return (
            <>
              <EngagementItem
                key={`loanType_${i}`}
                white={isWhite}
                className={`loanType`}
              >
                {engagement.loanType}
              </EngagementItem>
              <EngagementItem
                key={`loanNumber_${i}`}
                white={isWhite}
                marked={matching}
                className={`loanNumber`}
              >
                {engagement.loanNumber}
              </EngagementItem>
              <EngagementItem
                key={`amount_${i}`}
                white={isWhite}
                className={`amount`}
              >
                {engagement.amount}
              </EngagementItem>
              <EngagementItem
                key={`comment_${i}`}
                white={isWhite}
                italic
                className={`comment`}
              >
                {matching && t("loan-number-match-application")}
              </EngagementItem>
            </>
          )
        })}
      </EngagementsContainer>
    </div>
  )
}

export default OtherEngagements

const EngagementItem = styled.div`
  background-color: ${(props) => (props.white ? "white" : "inherit")};
  font-style: ${(props) => (props.italic ? "italic" : "inherit")};
  color: ${(props) => (props.marked ? "var(--flow-color-ferrari)" : "inherit")};
  font-weight: ${(props) => (props.isTitle ? "500" : "inherit")};
`

const EngagementsContainer = styled.div`
  background-color: ${ProjectColors.Slate95};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;

  div {
    padding: 10px;
  }

  .white {
    background-color: white;
  }
`

const StyledHeading = styled.h1`
  ${FontStyles.Normal};
  margin: 4px;
  padding: 10px;
  padding-bottom: 0;
  margin: 0;
  margin-top: 5px;
  font-size: 14px;

  line-height: 15px;
  text-transform: uppercase;
`
