import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { formatDate } from "../components/utils/formatDate"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components"
import { Tabs } from "@stacc/flow-ui-components"
import InformationLine from "../components/performFinancialCheck/detailsComponents/InformationLine"
import { addThousandSeparator } from "../util/addThousandSeparator"
import DownloadFileButton from "../components/common/DownloadFileButton"
import DisplaySecurityText from "../components/TermsAndSecurity/DisplaySecurityText"
import SpecialTermsTab from "../components/AnalyzeApplication/SpecialTermsTab"
import RegisterCollateralManualComponent from "../components/RegisterCollateralManual/RegisterCollateralManual"
import { hasData } from "../util/returnValue"
import capitalizeFirstLetter from "../util/capitalizeFirstLetter"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { realtyLookup } from "../components/TermsAndSecurity/cadastreLookup"
import _ from "lodash"
import {
  CheckListTable,
  MiniTable,
} from "../components/controlPriority/TableForCheckList"
import ErrorText from "../components/common/ErrorText"
import { ProjectColors } from "../components/common/style/ProjectColors"
import { TextArea } from "@stacc/flow-ui-components"
import { ONLY_RENDER_IN_DEVELOPMENT } from "../components/ONLY_RENDER_IN_DEVELOPMENT"
import DecisionTextsList from "../components/maintenance/DecisionTextsList"

const LOAN_AMOUNT_THRESHOLD = 10_000_000 // Lån over 10 millioner skal kontrolleres av to ulike depotmedarbeidere

const formatDiaryNumber = (
  dokumentAar,
  dokumentNr,
  rettsstiftelsesnummer,
  embete
) => `${dokumentAar}/${dokumentNr}-${rettsstiftelsesnummer}/${embete}`

const createCommonFields = (
  t,
  diaryNumber,
  registrationStatus = "",
  tinglysingsDate = "",
  collateralType,
  amount,
  relevantDocuments = []
) => {
  const dateWithTime = !!tinglysingsDate ? tinglysingsDate.includes("T") : false
  const tinglysingsDateFormatted = dateWithTime
    ? formatDate(tinglysingsDate, "dd.MM.yyyy HH.mm.ss")
    : tinglysingsDate

  return [
    {
      title: t("diary-number"),
      value: diaryNumber,
    },
    {
      title: t("registration-status"),
      value: capitalizeFirstLetter(registrationStatus.toLowerCase()),
    },
    {
      title: t("registration-date"),
      value: tinglysingsDateFormatted,
    },
    {
      title: t("type"),
      value: t(collateralType),
    },
    {
      title: t("amount"),
      value: addThousandSeparator(amount),
    },
    {
      title: t("relevant-docs"),
      value: (
        <>
          {(relevantDocuments || [])
            ?.filter((x) => !!x)
            ?.map((x) => {
              return (
                <StyledDownload>
                  <DownloadFileButton
                    title={t(x?.filename)}
                    fileId={x?.storeID}
                    key={x?.storeID}
                  />
                </StyledDownload>
              )
            })}
        </>
      ),
    },
  ]
}

const ControlLoans = ({
  currentUser,
  currentUserEmail,
  t,
  amount,
  userWhoLastControlledLoan,
  lastControlDate,
}) => {
  const { register, watch, setValue } = useFormContext()
  const loanAmountControlled = watch("controlLoanAmount")
  const controlledBy = watch("controlLoanAmountUser")
  const dateControlled = watch("controlLoanAmountDate")
  const [checkedCount, setCheckedCount] = useState(0)

  useEffect(() => {
    setCheckedCount((checkedCount) => checkedCount + 1)
  }, [loanAmountControlled])

  useEffect(() => {
    // If checkedCount > 1, then we know that the user has checked the checkbox
    // We need to set the user who last controlled the loan
    if (checkedCount > 1) {
      if (loanAmountControlled) {
        setValue(`controlLoanAmountUser`, currentUser)
        setValue(`controlLoanAmountUserEmail`, currentUserEmail)
        setValue(`controlLoanAmountDate`, new Date().toISOString())
      } else {
        setValue(`controlLoanAmountUser`, "")
        setValue(`controlLoanAmountUserEmail`, "")
        setValue(`controlLoanAmountDate`, "")
      }
    }
  }, [checkedCount])

  return (
    <>
      <Heading>Kontroller lån</Heading>
      <div>
        <p>
          Lånebeløp over {addThousandSeparator(LOAN_AMOUNT_THRESHOLD)} må
          depotgodkjennes av to ulike depotmedarbeidere. Gjeldende lånebeløp er:
          {"  "}
          <strong>{addThousandSeparator(amount)}</strong>
        </p>
        <br />
        <StyledTableWithSpacing>
          <tbody>
            <StyledTR>
              <StyledTD>Kommentar</StyledTD>
              <StyledTD>Kontrollert</StyledTD>
              <StyledTD>Kontrollert av</StyledTD>
              <StyledTD>Dato</StyledTD>
            </StyledTR>
            <StyledTR>
              <StyledTD>
                <StyledTextArea value="Depotkontroll" disabled />
              </StyledTD>
              <StyledTD>
                <CheckboxContainer>
                  <StyledCheckbox
                    {...register(`controlLoanAmount`)}
                    type="checkbox"
                  />
                </CheckboxContainer>
                <input type="hidden" {...register(`controlLoanAmountUser`)} />
                <input type="hidden" {...register(`controlLoanAmountDate`)} />
              </StyledTD>
              <StyledTD>{controlledBy}</StyledTD>
              <StyledTD>
                {dateControlled
                  ? new Date(dateControlled).toLocaleDateString() +
                    " kl " +
                    new Date(dateControlled).toLocaleTimeString()
                  : ""}
              </StyledTD>
            </StyledTR>
            <ONLY_RENDER_IN_DEVELOPMENT>
              {lastControlDate && userWhoLastControlledLoan?.name ? (
                <StyledTR>
                  <StyledTD>
                    Gjeldende bruker: {currentUser} ({currentUserEmail})
                  </StyledTD>
                  <StyledTD>Sist lagrede kontroll av:</StyledTD>
                  <StyledTD> {userWhoLastControlledLoan?.name}</StyledTD>
                  <StyledTD>
                    {new Date(lastControlDate).toLocaleDateString()} kl{" "}
                    {new Date(lastControlDate).toLocaleTimeString()}
                  </StyledTD>
                </StyledTR>
              ) : null}
            </ONLY_RENDER_IN_DEVELOPMENT>
          </tbody>
        </StyledTableWithSpacing>
      </div>
    </>
  )
}

const InformationLineEtinglysing = ({
  t,
  tinglysingElement,
  relevantDocuments = [],
  currentUser,
  methods,
}) => {
  const regStatus = tinglysingElement?.registrationStatus
  const regCollateral = tinglysingElement?.registerCollateral
  const commonFields = createCommonFields(
    t,
    regStatus?.dagbokNummer,
    regStatus?.status,
    regStatus?.tinglysingsdato,
    regCollateral?.input?.collateralType,
    regCollateral?.input?.meldingInput?.belop,
    relevantDocuments
  )

  const { getValues } = methods
  const isChecked = getValues(
    tinglysingElement.collateralElement
  )?.checklistControlled

  const checkedColor = isChecked ? "var(--flow-color-grass-light)" : ""
  const agreementData = tinglysingElement?.agreementData

  return (
    <StyledMovables backgroundColor={checkedColor}>
      <InformationLine
        margin={"0 0 1em 0"}
        padding={"1em"}
        data={[
          ...commonFields,
          {
            title: t("SecuredFor"),
            value: (
              <>
                {agreementData?.collateralObjectSecurity?.map((security, i) => (
                  <ContentTr key={i}>
                    <td>{security?.productName}</td>
                    <td>
                      {t("Financing")}{" "}
                      {addThousandSeparator(security?.productAmount)}
                    </td>
                    <td>
                      {t("safetyRisk")} {security?.safetyRisk}
                    </td>
                  </ContentTr>
                ))}
              </>
            ),
          },
        ]}
        backgroundColor={checkedColor}
      />
      <StyledTable>
        <MiniTable
          t={t}
          currentUser={currentUser}
          agreementId={tinglysingElement.collateralElement}
          checkedColor={checkedColor}
        />
      </StyledTable>
    </StyledMovables>
  )
}

const InformationLineAmbitaTinglysing = ({
  t,
  tinglysingElement,
  oldCadastres,
  relevantDocuments = [],
  methods,
  currentUser,
  flow,
  task,
}) => {
  const regStatus = tinglysingElement?.registrationStatus
  const linkForCadastrepdf = regStatus?.urlGrunnbok
  const { dokumentAar, dokumentNr, embete, system, rettsstiftelser } =
    regStatus?.resultatTinglysing?.status?.documentStatusTinglysing || {}
  const rettsstiftelsesnummer = rettsstiftelser?.rettstiftelse
    ?.map((x) => x?.rettsstiftelsesnummer)
    ?.join(" + ")
  const { agreementType } = tinglysingElement?.agreementData
  const commonFields = createCommonFields(
    t,
    formatDiaryNumber(dokumentAar, dokumentNr, rettsstiftelsesnummer, embete),
    regStatus?.status,
    regStatus?.tinglysingsdato,
    system,
    tinglysingElement?.amount,
    relevantDocuments
  )

  const agreementData = tinglysingElement?.agreementData
  const { getValues } = methods
  const isChecked = getValues(
    tinglysingElement.collateralElement
  )?.checklistControlled

  const [updatedNewCadastres, setUpdatedNewCadastres] = useState(
    tinglysingElement?.newCadastres
  )

  const [isLooking, setIsLooking] = useState([])
  const pathCurrentCollateralElement = `deliveries.${task.variables.delivery.id}.collateral.servicesAmbitaEtinglysing.${tinglysingElement.agreementData.agreementId}`

  const checkedColor = isChecked ? "var(--flow-color-grass-light)" : ""
  const getLookupCadastrePdf = async (cadastreKey) => {
    const lookedUp = await realtyLookup(
      "cadastrepdf",
      cadastreKey,
      flow.flowId,
      [],
      pathCurrentCollateralElement + ".realty",
      pathCurrentCollateralElement,
      "start-lookup-cadastrepdf",
      agreementData.agreementId,
      "servicesAmbitaEtinglysing",
      "control-priority"
    )

    const file = {
      storedFile: lookedUp.data,
      timestamp: lookedUp.timestamp,
    }
    const copy = _.cloneDeep(updatedNewCadastres)
    if (lookedUp.cadastre in copy) {
      copy[lookedUp.cadastre].cadastrepdf.push(file)
    } else {
      copy[lookedUp.cadastre] = {
        cadastrepdf: [file],
        cadastre: [],
      }
    }
    setUpdatedNewCadastres(copy)
  }
  const schemaData = [
    ...commonFields,
    {
      title: t("SecuredFor"),
      value: (
        <>
          {agreementData?.collateralObjectSecurity?.map((security, i) => (
            <ContentTr key={i}>
              <td>{security?.productName}</td>
              <td>
                {t("Financing")} {addThousandSeparator(security?.productAmount)}
              </td>
              <td>
                {t("safetyRisk")} {security?.safetyRisk}
              </td>
            </ContentTr>
          ))}
        </>
      ),
    },
    {
      title: t("linkForCadastrepdf"),
      value: (
        <>
          {(linkForCadastrepdf || [])
            ?.filter((x) => !!x)
            ?.map((x) => {
              return (
                <StyledDownload>
                  <a target={"_blank"} href={x} rel="noreferrer">
                    {t("get-printout")}
                  </a>
                </StyledDownload>
              )
            })}
        </>
      ),
    },
  ]
  return (
    <>
      <RegisterCollateralManualComponent
        schemaData={schemaData}
        collateralObjects={tinglysingElement?.agreementData?.collateralObject}
        t={t}
        oldCadastres={oldCadastres}
        currentUser={currentUser}
        agreementId={agreementData.agreementId}
        checkedColor={checkedColor}
        updatedNewCadastres={updatedNewCadastres}
        getLookupCadastrePdf={getLookupCadastrePdf}
        isLooking={isLooking}
        setIsLooking={setIsLooking}
        readOnly={false}
        headerStyle={"small"}
        agreementType={agreementType}
      />
    </>
  )
}
const InformationLineManualTinglysing = ({
  t,
  tinglysingElement,
  oldCadastres,
  relevantDocuments = [],
  flow,
  task,
  currentUser,
}) => {
  const agreementData = tinglysingElement?.agreementData
  const { agreementType, collateralAmount } = agreementData
  const {
    dokumentAar,
    dokumentNr,
    rettsstiftelsesnummer,
    embete,
    diaryNumber,
  } = tinglysingElement?.registerCollateralManual || {}
  const commonFields = createCommonFields(
    t,
    agreementType === "REAL_ESTATE"
      ? formatDiaryNumber(
          dokumentAar,
          dokumentNr,
          rettsstiftelsesnummer,
          embete
        )
      : diaryNumber,
    t("manually-registered-collateral"),
    tinglysingElement?.registerCollateralManual?.dateTinglyst,
    agreementType,
    collateralAmount,
    relevantDocuments
  )

  const [updatedNewCadastres, setUpdatedNewCadastres] = useState(
    tinglysingElement?.newCadastres
  )

  const [isLooking, setIsLooking] = useState([])
  const pathCurrentCollateralElement = `deliveries.${task.variables.delivery.id}.collateral.manualTinglysing.${tinglysingElement.agreementData.agreementId}`

  const { getValues } = useFormContext()
  const getValueOnChecklistControlled = getValues(
    `${tinglysingElement.agreementData.agreementId}.checklistControlled`
  )
  const checkedColor = getValueOnChecklistControlled
    ? "var(--flow-color-grass-light)"
    : ""

  const getLookupCadastrePdf = async (cadastreKey) => {
    const lookedUp = await realtyLookup(
      "cadastrepdf",
      cadastreKey,
      flow.flowId,
      [],
      pathCurrentCollateralElement + ".realty",
      pathCurrentCollateralElement,
      "start-lookup-cadastrepdf",
      agreementData.agreementId,
      "manualTinglysing",
      "control-priority"
    )

    const file = {
      storedFile: lookedUp.data,
      timestamp: lookedUp.timestamp,
    }
    const copy = _.cloneDeep(updatedNewCadastres)
    if (lookedUp.cadastre in copy) {
      copy[lookedUp.cadastre].cadastrepdf.push(file)
    } else {
      copy[lookedUp.cadastre] = {
        cadastrepdf: [file],
        cadastre: [],
      }
    }
    setUpdatedNewCadastres(copy)
  }

  const schemaData = [
    {
      title: t("SecuredFor"),
      value: (
        <>
          {agreementData?.collateralObjectSecurity?.map((security, i) => (
            <div key={i}>{security?.productName}</div>
          ))}
        </>
      ),
    },
    {
      title: t("Financing"),
      value: (
        <>
          {agreementData?.collateralObjectSecurity?.map((security, i) => (
            <div key={i}>{addThousandSeparator(security?.productAmount)}</div>
          ))}
        </>
      ),
    },
    {
      title: t("safetyRisk"),
      value: (
        <>
          {agreementData?.collateralObjectSecurity?.map((security, i) => (
            <div key={i}>{security?.safetyRisk}</div>
          ))}
        </>
      ),
    },
  ]

  return (
    <RegisterCollateralManualComponent
      schemaData={[...commonFields, ...schemaData]}
      collateralObjects={tinglysingElement?.agreementData?.collateralObject}
      t={t}
      oldCadastres={oldCadastres}
      updatedNewCadastres={updatedNewCadastres}
      getLookupCadastrePdf={getLookupCadastrePdf}
      isLooking={isLooking}
      setIsLooking={setIsLooking}
      readOnly={false}
      headerStyle={"small"}
      agreementType={agreementType}
      manualCollateralSecurityTexts={tinglysingElement}
      securityTextsTwo={
        flow?.data?.deliveries[task.variables?.delivery?.id]?.securityTexts
      }
      currentUser={currentUser}
      agreementId={agreementData.agreementId}
      checkedColor={checkedColor}
    />
  )
}

const ControlPriority = ({ task, flow, t, schema, save, complete, user }) => {
  const { data, defaults, context, variables } = task
  const currentUser = user?.profile?.name
  const currentUserEmail = user?.profile?.email
  const currentDeliveryTotalAmount =
    flow?.data?.deliveries[variables?.delivery?.id].amount
  const {
    applicationSummary,
    tinglysingAmbita,
    tinglysingBrreg,
    tinglysingManual,
    oldCadastres,
    isMaintenance,
  } = context

  const mergeTinglysing = [
    ...(tinglysingAmbita?.objects || []),
    ...(tinglysingBrreg?.objects || []),
    ...(tinglysingManual?.objects || []),
  ]

  const existingData = mergeTinglysing.reduce((acc, currAgreement) => {
    const agreementId = currAgreement.collateralElement
    if (!acc[agreementId]) {
      acc[agreementId] =
        currAgreement?.manual || currAgreement?.registerCollateralManual
    }
    return acc
  }, {})

  const defaultValue = { ...existingData, ...defaults }

  const [activeTab, setActiveTab] = useState("controlPriority")
  const [stateData, setStateData] = useState(data?.stateData || defaultValue)
  const [formData, setFormData] = useState(data?.formData)
  const [isProcessing, setProcessing] = useState(false)
  const [isError, setError] = useState(false)

  const userWhoLastControlledLoan = {
    email: data?.stateData?.controlLoanAmountUserEmail,
    name: data?.stateData?.controlLoanAmountUser,
  } // If this is not set then it is undefined and the current session can not complete task

  const methods = useForm({
    defaultValues: stateData,
  })

  // If loan amount is greater than ten million schrute-bucks, then we need to have a new caseworker to approve the payout
  const isLoanAboveThreshold =
    currentDeliveryTotalAmount > LOAN_AMOUNT_THRESHOLD

  const { watch } = methods
  const controlLoanAmountChecked = watch("controlLoanAmount")

  // Check if checkbox is checked, and that the person completing the task is different from the person who controlled the loan
  const isLoanControlled = controlLoanAmountChecked
  const isBothCheckedByDifferentUsers =
    userWhoLastControlledLoan.email !== currentUserEmail
  const isLoanControlValid = isLoanControlled && isBothCheckedByDifferentUsers

  let controlLoanErrorMessages = []
  if (isLoanAboveThreshold) {
    if (!isLoanControlled) {
      controlLoanErrorMessages.push(
        "Lån over 10 millioner må kontrolleres av en depotmedarbeider før du kan fullføre"
      )
    }

    if (!isBothCheckedByDifferentUsers) {
      controlLoanErrorMessages.push(
        "Du kan ikke fullføre en sak du selv har depotkontrollert"
      )
    }
    if (!userWhoLastControlledLoan?.email) {
      controlLoanErrorMessages.push(
        "Saken må depotkontrolleres av en person før en annen depotmedarbeider kan fullføre saken" // Hør med Ida om vi kan skrive en bedre feilmelding
      )
    }
  }

  const handleComplete = () => {
    const values = methods.getValues()
    const newData = {
      ...stateData,
      ...values,
    }

    // Check if loan is above treshold and that the user completing task is the same as the one who controlled the loan
    if (
      controlLoanErrorMessages.length > 0 ||
      (!isLoanControlValid && isLoanAboveThreshold)
    ) {
      setError(true)
      return
    }
    //For validation only
    const etinglystAgreements = Object.keys(newData).reduce((acc, elem) => {
      const keysToSkip = [
        "dataChecklist",
        "controlLoanAmount",
        "controlLoanAmountDate",
        "controlLoanAmountUser",
        "controlLoanAmountUserEmail",
      ]
      if (!keysToSkip.includes(elem)) {
        const agreement = newData[elem]
        if (agreement?.agreementType !== "SECURITYTEXT") {
          acc.push(agreement)
        }
      }
      return acc
    }, [])
    const hasError = etinglystAgreements.some((x) => !x.checklistControlled)

    if (!hasError) {
      setError(false)
      setProcessing(true)
      complete(
        newData,
        () => {
          setProcessing(false)
        },
        () => {
          console.error("Could not complete task")
          setProcessing(false)
        }
      )
    } else {
      setError(true)
    }
  }

  const handleSave = () => {
    const values = methods.getValues()
    const newData = {
      stateData: {
        ...stateData,
        ...values,
      },
      formData,
    }
    setProcessing(true)
    save(
      newData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }
  const options = [
    {
      id: "controlPriority",
      title: t("controlPriority"),
      component: (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleComplete)}>
            {isLoanAboveThreshold && (
              <ControlLoans
                t={t}
                currentUser={currentUser}
                currentUserEmail={currentUserEmail}
                userWhoLastControlledLoan={userWhoLastControlledLoan}
                amount={currentDeliveryTotalAmount}
                lastControlDate={data?.stateData?.controlLoanAmountDate}
              />
            )}

            <StyledTinglysings>
              <div className={"elements"}>
                <Heading>{t("tinglysing-moveables")}</Heading>
                {tinglysingBrreg?.objects?.length > 0 ? (
                  tinglysingBrreg?.objects?.map((elem) => (
                    <InformationLineEtinglysing
                      t={t}
                      tinglysingElement={elem}
                      relevantDocuments={tinglysingBrreg?.relevantDocuments}
                      currentUser={currentUser}
                      methods={methods}
                    />
                  ))
                ) : (
                  <Subtitle>{t("no-tinglysinger-available")}</Subtitle>
                )}
              </div>
              <div className={"elements"}>
                <Heading>{t("tinglysing-property")}</Heading>
                {tinglysingAmbita?.objects?.length > 0 ? (
                  tinglysingAmbita?.objects?.map((elem) => (
                    <InformationLineAmbitaTinglysing
                      t={t}
                      tinglysingElement={elem}
                      oldCadastres={oldCadastres}
                      relevantDocuments={tinglysingAmbita?.relevantDocuments}
                      currentUser={currentUser}
                      methods={methods}
                      flow={flow}
                      task={task}
                    />
                  ))
                ) : (
                  <Subtitle>{t("no-tinglysinger-available")}</Subtitle>
                )}
              </div>
              <div className={"elements"}>
                <Heading>{t("tinglysing-manual")}</Heading>
                {hasData(tinglysingManual?.objects) ? (
                  tinglysingManual?.objects?.map((elem) => (
                    <InformationLineManualTinglysing
                      t={t}
                      tinglysingElement={elem}
                      oldCadastres={oldCadastres}
                      relevantDocuments={tinglysingManual?.relevantDocuments}
                      flow={flow}
                      task={task}
                      currentUser={currentUser}
                    />
                  ))
                ) : (
                  <Subtitle>{t("no-tinglysinger-available")}</Subtitle>
                )}
              </div>
              <div>
                <CheckListTable
                  dataPath={`dataChecklist`}
                  t={t}
                  currentUser={currentUser}
                  methods={methods}
                />
              </div>
            </StyledTinglysings>
          </form>
        </FormProvider>
      ),
    },
    {
      id: "securityTexts",
      title: "Sikkerhetstekster",
      component: (
        <>
          {flow?.data?.deliveries[variables?.delivery?.id]?.securityTexts
            ?.length > 0 ? (
            flow?.data?.deliveries[variables?.delivery?.id]?.securityTexts?.map(
              (securityText, securityTextIndex) => {
                return (
                  <DisplaySecurityText
                    t={t}
                    key={securityTextIndex}
                    isEmpty={!securityText?.subtitle?.length}
                    isFirst={securityTextIndex === 0}
                    securityText={securityText}
                    userGenerated={securityText?.userGenerated}
                    isReadOnly={true}
                  />
                )
              }
            )
          ) : (
            <p>{t("no-security-text")}</p>
          )}
        </>
      ),
    },
    {
      id: "specialconditions",
      title: "Særvilkår",
      component: (
        <SpecialTermsTab
          deliveries={flow?.data?.deliveries}
          onChange={() => {}}
          t={t}
          readOnly={true}
        />
      ),
    },
  ]

  //Legge til tabber dersom det er vedlikehold
  if (isMaintenance) {
    const decisionTexts = flow?.data?.deliveries?.vedlikehold?.changes
    options.push({
      id: "decisiontexts",
      title: t("decisionTexts"),
      component: <DecisionTextsList t={t} changes={decisionTexts} />,
    })
  }

  return (
    <Layout forceHeight>
      <MainContent>
        <Tabs options={options} onChange={(e) => setActiveTab(e)} />
        <TabContainer>
          {options?.map((x, i) => (
            <div
              key={i}
              style={{ display: x?.id === activeTab ? "block" : "none" }}
            >
              {x?.component}
            </div>
          ))}
        </TabContainer>
      </MainContent>
      <Context flow={flow} context={applicationSummary}>
        <StyledForm>
          <ReactForm
            schema={schema}
            formData={formData}
            disabled={isProcessing}
            onChange={(formData) => setFormData(formData)}
            onSubmit={handleComplete}
          >
            {controlLoanErrorMessages?.length > 0 &&
              controlLoanErrorMessages.map((message) => (
                <ErrorField>{message}</ErrorField>
              ))}
            {isError && (
              <ErrorText error={t("control-priority-controlledCheck")} />
            )}
            <br />
            <Heading>Utfør depotkontroll</Heading>
            <ButtonContainer>
              <PrimaryButton
                type="submit"
                isLoading={isProcessing}
                disabled={
                  isProcessing ||
                  controlLoanErrorMessages.length > 0 ||
                  (!isLoanControlValid && isLoanAboveThreshold)
                }
              >
                {t("complete")}
              </PrimaryButton>
              <SecondaryButton
                type="button"
                disabled={isProcessing}
                onClick={() => handleSave()}
              >
                {t("save")}
              </SecondaryButton>
            </ButtonContainer>
          </ReactForm>
        </StyledForm>
      </Context>
    </Layout>
  )
}
export default withTranslation()(ControlPriority)
const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  padding: 15px;
  overflow: scroll;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`

const TabContainer = styled.div`
  margin: 20px 20px 20px 0;
  flex: 1;
`

const Subtitle = styled.p`
  padding: 1em 1.5em 1em 1.5em;
  border-radius: 15px;
  font-size: small;
  background-color: var(--flow-color-sea-lighter);
`
const Heading = styled.p`
  font-size: 1em;
  color: black;
  padding-bottom: 0.5em;
`

const StyledTinglysings = styled.div`
  display: flex;
  flex-direction: column;

  .elements {
    padding-bottom: 2em;
  }
`

const StyledDownload = styled.div`
  color: var(--flow-color-grey1);
  padding: 0.1em 0.1em 0.1em 0;
  font-size: small;
  line-height: 0.8em;
  width: 100%;

  button {
    white-space: pre-wrap;
    width: 100%;
  }
  svg {
    display: none;
  }
`
const ContentTr = styled.tr`
  display: flex;
  padding: 5px 10px 5px 0px;
  flex-direction: column;
`

const ErrorField = styled.span`
  color: var(--flow-color-ferrari);
`

const StyledMovables = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.backgroundColor || "var(--flow-color-sea-lighter)"};
  border-radius: 15px;
  padding-top: 1em;
  margin-bottom: 1em;
`
const StyledTable = styled.table`
  display: flex;
  width: 100%;
`
const StyledTD = styled.td`
  width: 25%;
  padding: 1.5em;
`
const StyledTR = styled.tr`
  border-bottom: 1em solid white;
  &:nth-child(even) {
    background-color: ${ProjectColors.Slate90};
  }
`
const StyledTableWithSpacing = styled.table`
  width: 100%;
  margin-bottom: 2em;
  font-size: small;
  border-collapse: collapse;
  td,
  th {
    padding-bottom: 1em;
  }
`

const StyledTextArea = styled(TextArea)`
  display: flex;
  min-height: 5em;
  width: 80%;
  padding: 0.5em;
  background: white;
  font-family: "IBM Plex Sans";
`
const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  padding-top: 0.5em;
  margin-left: 0.5em;
  height: 1.1em;
  width: 1.1em;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
