/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import formatWithLocale from "../../util/dateFormatter"

/**
 * @typedef {object} AnnulmentControlSummaryProps
 * @property {Date} searchFromDate - The start date of the search range
 * @property {Date} searchToDate - The end date of the search range
 * @property {number | null} annulmentsFound - The number of annulments found
 * @property {function} t - The translation function
 */

/**
 * AnnulmentControlSummary component
 * @param {AnnulmentControlSummaryProps} props
 */
const AnnulmentControlSummary = ({
  searchFromDate,
  searchToDate,
  annulmentsFound,
  t,
}) => {
  const formattedFromDate = formatWithLocale(searchFromDate, "dd.MM.yyyy")
  const formattedToDate = formatWithLocale(searchToDate, "dd.MM.yyyy")

  return (
    <Content>
      <ItemWrapper>
        <Title>{t("searchFromDate")}</Title>
        <ContextContent>{formattedFromDate}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("searchToDate")}</Title>
        <ContextContent>{formattedToDate}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("annulmentsFound")}</Title>
        <ContextContent>{annulmentsFound ?? "-"}</ContextContent>
      </ItemWrapper>
    </Content>
  )
}

const Content = styled.div`
  display: grid;
  padding: 10px;
  background-color: #f5f9ff;
  border-radius: 10px;
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr)` : "repeat(3, 1fr)"};
  gap: 10px;
`

const Title = styled.h3`
  font-weight: 300;

  ::first-letter {
    text-transform: capitalize;
  }

  color: #8b8a8d;
  font-size: 12pt;
  margin-bottom: 8px;
`

const ItemWrapper = styled.div`
  ::first-letter {
    text-transform: capitalize;
  }
`
const ContextContent = styled.div`
  a {
    text-decoration: none;
  }
`

export default withTranslation()(AnnulmentControlSummary)
