import axios from "axios"
import lodash from "lodash"
import { getTaskIdforMessageTask } from "../../util/getTaskIdforMessageTask"

const checkState = async (path = "analysis.realty", flowId) => {
  const res = await axios.get(`/api/flow/flows/${flowId}`)
  const flowData = res?.data?.data

  return lodash.get(flowData, path, {})
}

const updateRealtyValues = async (
  data,
  flowId,
  updateDataStatePath = "analysis"
) => {
  const stateData = await checkState(updateDataStatePath, flowId)
  const realty = stateData.realty
  let stateCadastre = {}
  let stateCadastrePdf = {}
  let currentCadastreLookup = {}

  if (data.cadastreKey in realty) {
    const currentCadastreData = realty[data.cadastreKey]
    stateCadastrePdf = currentCadastreData?.cadastrepdf.at(-1)
    stateCadastre = currentCadastreData?.cadastre.at(-1)
    currentCadastreLookup = realty?.lookupInProgress[data.cadastreKey] || {}
    // If trying to credit check and there is no pending check, stop checking
    const errorReturn = (error) => ({
      status: true,
      data: { status: 500, message: error, data: {} },
    })

    if (!(data.type in currentCadastreLookup)) {
      if (data.type === "cadastre") {
        if (stateCadastre.error) return errorReturn(stateCadastre?.error)
        return {
          status: true,
          data: {
            data: stateCadastre.mappedCadastreInfo,
            message: "ok",
            status: 200,
            timestamp: stateCadastre?.timestamp,
            stakeholders: stateData.initialGuarantors,
          },
        }
      }
      if (data.type === "cadastrepdf") {
        if (stateCadastrePdf.error) return errorReturn(stateCadastrePdf)
        return {
          status: true,
          data: {
            data: stateCadastrePdf.storedFile,
            message: "ok",
            status: 200,
            timestamp: stateCadastrePdf?.timestamp,
            cadastre: data.cadastreKey,
          },
        }
      }
      return errorReturn(
        `Finished looking up cadastre, but cannot find any results for type ${data?.type}`
      )
    }
  }
  return { status: false, data: {} }
}

const ambitaLookup = async (
  data,
  flowId,
  stakeholders,
  statePath,
  updateDataStatePath,
  triggerTaskName
) => {
  const taskId = await getTaskIdforMessageTask(
    flowId,
    triggerTaskName,
    data.agreementId,
    updateDataStatePath,
    data.currentFrontendComponent
  )

  if (!taskId) {
    return {
      status: 404,
      message: "taskId not found",
    }
  }

  try {
    await axios.post(`/api/flow/tasks/${taskId}/trigger`, {
      ...data,
      stakeholders,
    })
  } catch (e) {
    return {
      status: 500,
      message: "something went wrong triggering lookup",
    }
  }
  let counter = 0
  return new Promise((resolve, reject) => {
    const lookupFinished = async () => {
      if (counter > 20)
        reject({
          status: 500,
          message: `Request took too long, try again later`,
        })
      const updatedValues = await updateRealtyValues(
        data,
        flowId,
        updateDataStatePath
      )
      if (!updatedValues.status) {
        counter++
        setTimeout(lookupFinished, 1500)
      } else {
        resolve(updatedValues.data)
      }
    }
    setTimeout(lookupFinished, 1500)
  })
}

const realtyLookup = async (
  type,
  cadastreKey,
  flowId,
  stakeholders = [],
  statePath = "analysis.realty",
  updateDataStatePath = "analysis",
  triggerTaskName = "get-lookup-cadastre-ambita",
  agreementId = null,
  tingslysingProsessPath = null,
  currentFrontendComponent = null
) => {
  if (!cadastreKey) return { status: 400, message: "Missing cadastre key" }
  if (
    !cadastreKey.match(/^\d{4}[- ]?\d{1,5}[- ]\d{1,4}[- ]\d{1,4}[- ]\d{1,4}$/g)
  ) {
    return {
      status: 400,
      message:
        "Wrong format cadastre key, should match (4 digits)-(1 to 5 digits)-(1 to 4 digits)-(1 to 4 digits)-(1 to 4 digits) eks: 0000-00-00-0-0",
    }
  }
  const stateRealty = await checkState(statePath, flowId)
  const lookupInProgress = stateRealty?.lookupInProgress || {}
  let canPerformLookup = false
  if (!(cadastreKey in lookupInProgress)) canPerformLookup = true
  if (cadastreKey in lookupInProgress) {
    if (!(type in lookupInProgress[cadastreKey])) canPerformLookup = true
  }
  if (canPerformLookup) {
    try {
      return await ambitaLookup(
        {
          cadastreKey,
          type,
          agreementId,
          tingslysingProsessPath,
          currentFrontendComponent,
        },
        flowId,
        stakeholders,
        statePath,
        updateDataStatePath,
        triggerTaskName
      )
    } catch (e) {
      return e
    }
  }
  return {
    status: 503,
    message: `Lookup for ${type} in progress for cadastreKey ${cadastreKey}, try again later`,
  }
}

const getHistoricRealtyData = async (cadastreKey, type, flowId) => {
  const historicData = await checkState("analysis.realty", flowId)
  const lastHistoricData = lodash.get(
    historicData,
    `[${cadastreKey}].${type}`,
    []
  )
  let data = lodash.get(
    lastHistoricData,
    `[${lastHistoricData.length - 1}]`,
    null
  )
  const timestamp = lodash.get(data, "timestamp", "")
  if (type === "cadastre") data = lodash.get(data, "mappedCadastreInfo", data)
  if (type === "cadastrepdf") data = lodash.get(data, "storedFile", data)
  return data
    ? {
        status: 200,
        message: "ok",
        data,
        timestamp,
      }
    : {
        status: 404,
        message: `Lookup for ${type} with key ${cadastreKey}, does not exist in historic data`,
      }
}
export { getHistoricRealtyData, realtyLookup }
