import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { TextArea } from "@stacc/flow-ui-components"
import { calculatePercentageOf } from "../../../util/calculatePercentageOf"
import BentoInput from "../../common/inputs/bento/BentoInput"

/**
 * @typedef {object} GuaranteeDetailsProps
 * @property {function} t - The translation function
 * @property {InFlow.Delivery} currentDelivery - The current delivery
 * @property {InFlow.StateData} stateData - The state data
 * @property {Function} setStateData - The state data setter
 * @property {boolean} readonly - Is the form readonly
 */

/**
 * @param {GuaranteeDetailsProps} props
 * @returns {JSX.Element}
 */
export default function GuaranteeDetails({
  t,
  currentDelivery,
  stateData,
  setStateData,
  readonly = false,
}) {
  const { payoutRequestReceived, commitment } =
    currentDelivery.currentPayout || {}
  const [nameOfBank, setNameOfBank] = useState(
    stateData.guarantee?.nameOfBank ??
      currentDelivery.guarantee?.nameOfBank ??
      ""
  )
  const [creditAmount, setCreditAmount] = useState(
    stateData.guarantee?.creditAmount ??
      currentDelivery.guarantee?.creditAmount ??
      0
  )
  const [guaranteeAmount, setGuaranteeAmount] = useState(
    payoutRequestReceived?.payoutAmount ??
      stateData.guarantee?.guaranteeAmount ??
      currentDelivery.amount ??
      0
  )
  const [bankAddress, setBankAddress] = useState(
    stateData.guarantee?.bankAddress ?? ""
  )
  const [loanAgreementNumber, setLoanAgreementNumber] = useState(
    stateData.guarantee?.loanAgreementNumber ?? ""
  )

  const guaranteePercent = calculatePercentageOf(
    guaranteeAmount ?? 0,
    creditAmount ?? 0
  ).toFixed(2)

  // This could probably be solved in a better way.
  useEffect(() => {
    if (readonly) return
    setStateData((prevState) => ({
      ...prevState,
      guarantee: {
        nameOfBank,
        creditAmount,
        guaranteeAmount,
        guaranteePercent,
        bankAddress,
        loanAgreementNumber,
      },
    }))
  }, [
    nameOfBank,
    creditAmount,
    guaranteeAmount,
    guaranteePercent,
    bankAddress,
    loanAgreementNumber,
  ])

  return (
    <Content>
      <StyledInputs>
        <BentoInput
          label={t("name-of-bank")}
          value={nameOfBank}
          type="text"
          onChange={(e) => setNameOfBank(e.target.value)}
          disabled={readonly}
        />
        <BentoInput
          label={t("credit-amount")}
          value={creditAmount}
          mode="currency"
          inputMode="numeric"
          pattern={/[0-9]*/}
          type="text"
          onChange={(e) => setCreditAmount(e.rawValue)}
          disabled={readonly}
        />
        <BentoInput
          label={t("guarantee-amount-value")}
          value={guaranteeAmount}
          mode="currency"
          inputMode="numeric"
          pattern={/[0-9]*/}
          type="text"
          onChange={(e) => setGuaranteeAmount(e.rawValue)}
          disabled={readonly}
        />
        <div>
          <label>{t("guarantee-percent")}</label>
          <StyledPercent>
            <b>{guaranteePercent} %</b>
          </StyledPercent>
        </div>
      </StyledInputs>
      <StyledInputs>
        <TextAreaBox
          label={t("bank-address")}
          value={bankAddress}
          disabled={readonly}
          onChange={(e) => setBankAddress(e.target.value)}
          name=""
        />
        <BentoInput
          label={t("loan-agreement-number")}
          value={loanAgreementNumber}
          onChange={(e) => setLoanAgreementNumber(e.target.value)}
          disabled={readonly}
        />
      </StyledInputs>
    </Content>
  )
}

const Content = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`

const StyledPercent = styled.p`
  margin-top: 0.5rem;
  text-align: right;
`

const StyledInputs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2.5rem;

  input {
    margin: 0;
    border: solid 1px var(--flow-color-grey2);
    width: 200px;
    border-radius: 0.4em;
    padding: 0.5em;
  }

  label {
    font-size: 12px;
    line-height: 1.4em;
    letter-spacing: 0.01em;
    padding: 0.2em;
  }
`

const TextAreaBox = styled(TextArea)`
  width: 100%;
  min-width: 200px;
  flex-basis: 100%;
  height: 150px;
`
