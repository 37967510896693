import React from "react"

import DisplayMessageTaskInfo from "../components/Annulment/DisplayMessageTaskInfo"

const CompanyBankruptcy = ({ task, flow, t, schema, save, trigger, user }) => {
  return (
    <DisplayMessageTaskInfo
      task={task}
      flow={flow}
      t={t}
      schema={schema}
      save={save}
      trigger={trigger}
      user={user}
      warningText={t("data-for-bankruptcy")}
    />
  )
}
export default CompanyBankruptcy
