import styled from "styled-components"
import { FontStyles } from "@stacc/flow-ui-components"

const TaskContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  ${FontStyles.LargeStrong};
`

const TaskDescription = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;

  p {
    margin: 0;
    padding-left: 20px;
  }
`

export { TaskContainer, TaskDescription }
