import React from "react"
import DisplayMessageTaskInfo from "../components/Annulment/DisplayMessageTaskInfo"

const ApplicationAnnuled = ({ task, flow, t, schema, save, trigger, user }) => {
  return (
    <DisplayMessageTaskInfo
      task={task}
      flow={flow}
      t={t}
      schema={schema}
      save={save}
      trigger={trigger}
      user={user}
      warningText={t("vekstgaranti-annulment-info")}
      placeholderText={t("vekstgaranti-annulment-info-placeholder")}
      label={t("reason-for-annulment")}
    />
  )
}

export default ApplicationAnnuled
