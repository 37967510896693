import { useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { Icons } from "../../common/style/Icons"
import ControlledField from "./ControlledField"
import FieldValue from "./FieldValue"

const EditableField = (params) => {
  const { field, value, type, productConfiguration } = params
  const [isEditing, setIsEditing] = useState(false)
  const { control, editing, setEditing } = useFormContext()
  const _setEditing = (isEditing) => {
    setIsEditing(isEditing)
    if (isEditing) {
      setEditing(editing + 1)
    } else {
      setEditing(editing - 1)
    }
  }

  return (
    <>
      {isEditing && (
        <div>
          <Controller
            control={control}
            name={`bankInformation.${field}`}
            id={`bankInformation.${field}`}
            render={({ field }) => (
              <ControlledField
                field={field}
                setEditing={_setEditing}
                type={type}
              />
            )}
          />
        </div>
      )}
      {!isEditing && (
        <>
          <span className={`field-${field}`}>
            <FieldValue
              {...params}
              type={type}
              field={field}
              value={value}
              productConfiguration={productConfiguration}
            />
          </span>
          <span onClick={() => _setEditing(true)}>
            <Icons.Edit size={18} />
          </span>
        </>
      )}
    </>
  )
}

export default EditableField
