// @ts-check

import { addThousandSeparator } from "./addThousandSeparator"
import { deliveryByAgreementId } from "../components/utils/mapAgreements"
import {
  collateralObjectTypes,
  collateralAgreementTypes,
} from "./collateralTypes"
import { formatCadastre } from "./formatCadastre"
import { getEngagementAmount } from "./engagementAmount"

const IN_ORGANIZATION_NUMBER = "986399445"

// Etterstående prioritet = det finnes en aktør med høyere prioritet enn IN.
// Sidestilt prioritet = det finnes en aktør med lik prioritet som IN.
// Sidestilt etterstående prioritet = det finnes en aktør med høyere prioritet enn IN OG en aktør med lik prioritet som IN.

/**
 * Creates text like "lån nr. 1234.1234 kr 123 456 og lån nr. 4321.4321 kr 456 123"
 * @param {string[]} accountNumbers Array of account numbers
 * @param {object[]} loans All loans coming from coreview (engagements)
 * @returns {string} A string representing the already existing loans
 */
const createExistingLoansText = (accountNumbers, loans) =>
  accountNumbers
    .map((accountNumber) => {
      // Find the engagement representing this loan
      const loan = loans.find(
        ({ accountName }) => accountNumber === accountName
      )

      if (!loan) {
        throw new Error(`Kunne ikke finne lån ${accountNumber}`)
      }

      const loanSum = getEngagementAmount(loan)

      // We've removed "løpende" from this text because we don't know the status of loan -
      // it isn't necessarily "løpende", it could be "aktiv" or similar. IN liked this change.
      return `lån nr. ${accountNumber} kr ${addThousandSeparator(loanSum)}`
    })
    .join(" og ")

const formatEntity = (entity) => {
  const id =
    entity?.type === "organization" ? entity.organizationNumber : entity.ssn
  if (!id) {
    return `${entity.name}`
  }
  if (entity?.type === "organization") {
    return `${entity.name}, org.nr. ${id}`
  }
  if (entity?.type === "person") {
    return `${entity.name}, fnr. ${id}`
  }
  throw new Error("Unknown entity/stakeholder type")
}

/**
 * Creates a title for a security text.
 * @param {object} deliveries The deliveries
 * @param {string} deliveryId Current deliveryId
 * @param {object} collateralAgreement The collateral agreement to generate a security text for
 * @param {*} agreementData All agreement data
 * @param {object[]} rawEngagements All loans coming from coreview (engagements)
 * @param {boolean} isMaintenance Whether or not this is a maintenance case
 * @returns {string}
 */
const getTitle = (
  deliveries,
  deliveryId,
  collateralAgreement,
  agreementData,
  rawEngagements,
  isMaintenance = false
) => {
  //
  // Handle titles differently for maintenance cases
  //

  if (isMaintenance) {
    // I tillegg til tidligere etablerte sikkerheter, skal følgende nye sikkerheter etableres til sikkerhet for løpende lån nr. [] til rest kr [] og løpende lån nr. [] til rest kr []:
    const coveredLoans =
      collateralAgreement?.basicCollateralAgreementInformation?.collateralCoverages?.map(
        ({ accountNumber }) => accountNumber
      ) ?? []

    // These constants are from insight's structure, not to be confused with IN's loan status.
    const validVersions = ["ACTIVE", "CHANGED"]
    const accountNumbers = agreementData
      ?.filter(
        (agreement) =>
          agreement.data?.type === "LOAN" &&
          validVersions.includes(
            agreement.data?.basicAccountInformation?.version
          ) &&
          coveredLoans.includes(
            agreement.data?.basicAccountInformation?.accountNumber
          )
      )
      ?.map(
        (agreement) => agreement.data?.basicAccountInformation?.accountNumber
      )

    if (!accountNumbers) {
      throw new Error("No accountNumbers found on agreementData")
    }

    const loansText = createExistingLoansText(accountNumbers, rawEngagements)

    return `I tillegg til tidligere etablerte sikkerheter, skal følgende nye sikkerheter etableres til sikkerhet for ${loansText}`
  }

  //
  // Non-maintenance
  //

  const collateralCoverages =
    collateralAgreement?.basicCollateralAgreementInformation
      ?.collateralCoverages ?? []

  if (collateralCoverages?.length === 1) {
    return "Lånet skal sikres som følger:"
  }

  // Hvis kategori er maintenance, så er det alltid snakk om løpende lån.
  // Hvis maintenance og nye avtaler, da skal disse på 04. Vedlikehold - utvidelse av sikkerhet
  // Bare tittelen bryr vi oss om her.
  // I fremtiden må vi også håndtere 03. Vedlikehold - debitorskifte

  const mappedCoverages = collateralCoverages.map((coverage) => ({
    coverage,
    version: agreementData.find((x) => x.internalId === coverage.internalId)
      ?.data?.basicAccountInformation?.version,
  }))

  const newLoans = mappedCoverages.filter((x) => x.version === "NEW")
  const activeLoans = mappedCoverages.filter((x) => x.version === "ACTIVE")

  const mapAgreementIdToDeliveryId = deliveryByAgreementId(deliveries)
  const hasOneNewAndExistingLoan =
    activeLoans?.length > 0 && newLoans?.length === 1

  const hasMultipleNewAndExistingLoans =
    activeLoans?.length > 0 && newLoans?.length > 1

  if (hasOneNewAndExistingLoan || hasMultipleNewAndExistingLoans) {
    const activeLoansText = createExistingLoansText(
      activeLoans.map(({ coverage }) => coverage.accountNumber),
      rawEngagements
    )
    if (hasOneNewAndExistingLoan) {
      return `Nytt lån kr ${addThousandSeparator(
        newLoans?.at(0)?.coverage?.amount
      )} og ${activeLoansText} skal sikres som følger:`
    }

    const otherLoansText = newLoans
      .filter(
        (x) => mapAgreementIdToDeliveryId[x.coverage.internalId] !== deliveryId
      )
      .map(({ coverage }) => {
        const internalId = coverage.internalId
        const deliveryId = mapAgreementIdToDeliveryId[internalId]
        const delivery = deliveries[deliveryId]
        return `${delivery?.productFullName} kr ${addThousandSeparator(
          delivery?.amount
        )}`
      })
      .join(", ")

    return `Lånet skal sammen med ${otherLoansText} og ${activeLoansText} sikres som følger:`
  }

  const otherLoansText = collateralCoverages
    .filter((x) => mapAgreementIdToDeliveryId[x.internalId] !== deliveryId)
    .map(({ internalId }) => {
      const deliveryId = mapAgreementIdToDeliveryId[internalId]
      const delivery = deliveries[deliveryId]
      return `${delivery?.productFullName} kr ${addThousandSeparator(
        delivery?.amount
      )}`
    })
    .join(", ")

  return `Lånet skal sammen med ${otherLoansText} sikres som følger:`
}

/**
 * Checks if the applicant owns any of the assets for a specified collateral agreement.
 * @param {Insight.CollateralAgreement} collateralAgreement
 * @param {object[]} stakeholders
 * @returns {boolean}
 */
function applicantOwnsAssets(collateralAgreement, stakeholders) {
  // Find the applicant / borrower
  const borrower = stakeholders.find((stakeholder) =>
    Boolean(stakeholder.isApplicant)
  )

  // This case shouldn’t happen, but added code to handle it just in case
  if (!borrower) {
    return false
  }

  // Get all mortgagors
  const { basicCollateralAgreementInformation } = collateralAgreement
  const { mortgagors } = basicCollateralAgreementInformation

  // If applicant is one of the mortgagors, the applicant does indeed own at least one of the assets.
  // The text template doesn't specify whether the applicant/borrower is the sole owner or just one of
  // them, but for this it's not relevant.
  return mortgagors.some(({ internalId }) => internalId === borrower.id)
}

/**
 * Gets the subtitle "type" for an agreement
 * @param {Insight.CollateralAgreement} collateralAgreement
 * @param {object[]} stakeholders
 * @returns {2 | 3 | 4}
 */
const getSubtitleType = (collateralAgreement, stakeholders) => {
  // if (Sikkerhet ved kausjon) return 4
  if (
    collateralAgreement?.collateralAgreementType ===
    collateralAgreementTypes.suretyship
  )
    return 4

  // if (Eier aktiva selv) return 2
  if (applicantOwnsAssets(collateralAgreement, stakeholders)) return 2

  return 3
}

/**
 * Get subtitle type in string format
 * @param {2 | 3 | 4} type
 * @returns {string}
 */
const subtitleTypeToString = (type) => {
  const subtitles = {
    2: "Sikkerhet i aktiva tilhørende låntaker:",
    3: "Sikkerhet i aktiva tilhørende andre enn låntaker:",
    4: "Sikkerhet ved kausjon:",
  }

  if (!subtitles[type]) {
    throw new Error("Unknown subtitle type")
  }

  return subtitles[type]
}

/**
 * Get the face value of a collateral agreement
 * @param {Insight.CollateralAgreement} agreement
 * @returns {number}
 */
const getAgreementFaceValue = (agreement) => {
  // TODO: Add support for other types of agreements
  const value =
    agreement?.specificCollateralAgreementInformation?.mortgageDeedDetails
      ?.mortgageDeedFaceValue ||
    agreement?.specificCollateralAgreementInformation?.mortgageDeedDetails
      ?.faceValue

  if (!value) {
    throw new Error(
      `Failed to get agreement face value! (${agreement.internalId})`
    )
  }

  return Number(value)
}

/**
 * Capitalize the first letter of a string
 * @param {string} string
 * @returns {string}
 */
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Joins elements in an array with commas, except for the last element which is joined with "og".
 * @param {any[]} elements An array to join
 * @returns {string}
 */
function joinWithCorrectGrammar(elements) {
  if (elements.length === 1) {
    return elements[0]
  } else if (elements.length === 2) {
    return `${elements[0]} og ${elements[1]}`
  } else {
    const lastElement = elements.pop()
    return [elements.join(", "), `og ${lastElement}`].join(" ")
  }
}

/**
 * Gets the detailed object type, needed to differentiate between sub-types (aka asset types).
 * @param {Insight.CollateralAgreement} collateralAgreement
 * @param {Insight.CollateralObject[]} collateralObjects
 * @param {boolean} isChangedAgreement
 * @returns {string}
 */
function getFinalObjectType(
  collateralAgreement,
  collateralObjects,
  isChangedAgreement
) {
  const { specificCollateralAgreementInformation } = collateralAgreement

  const objectType = isChangedAgreement
    ? specificCollateralAgreementInformation.mortgageDeedType
    : // This only works because all collateral objects have the same type
      collateralObjects.at(0)?.basicCollateralObjectInformation
        ?.collateralObjectType ||
      collateralObjects.at(0)?.basicCollateralObjectInformation?.type

  if (!objectType) {
    throw new Error("Couldn't find objectType")
  }

  /** @type {string} */
  let finalObjectType = objectType

  const objectTypesWithSubTypes = [
    "MOVABLES",
    "VEHICLE",
    // For existing agreements, mortgageDeedType for vehicles is MOTOR_VEHICLE or FLEET_VEHICLES
    "MOTOR_VEHICLE",
    "FLEET_VEHICLES",
    "SHIP",
  ]
  if (objectTypesWithSubTypes.includes(objectType)) {
    if (isChangedAgreement) {
      // We don't get assetType without the object for existing agreements,
      // so we have to derive it from the productId, which we do get.
      // (Are we even guaranteed to get productId? No schemas, no docs, no types <3)
      const { productId } =
        collateralAgreement.basicCollateralAgreementInformation
          ?.basicCollateralInformation?.productInformation

      if (!productId) {
        throw new Error("No productId found for agreement")
      }

      /**
       * Converts some product IDs to asset types. Currently only need to support:
       * - "VEHICLE::FLEET_VEHICLES",
       * - "VEHICLE::MOTOR_VEHICLE",
       * - "MOVABLES::OPERATING_ACCESSORIES",
       * - "MOVABLES::ACCOUNTS_RECEIVABLE",
       * - "MOVABLES::INVENTORIES",
       * - "MOVABLES::AGRICULTURAL_MOVABLES",
       * - "MOVABLES::FISHING_GEAR",
       * @param {string} productId The productId of the agreement
       * @returns {string} Type + Asset Type (joined with ::)
       */
      const productIdToAssetType = (productId) => {
        switch (productId) {
          case "31": // Motorvogner/Anleggsmask.
            return "VEHICLE::MOTOR_VEHICLE"
          case "internalId_produkt_avtale_vehicle_flaate": // Flåte av motorvogner og anleggsmaskiner
            return "VEHICLE::FLEET_VEHICLES"
          case "32": // Landbrukspant
            return "MOVABLES::AGRICULTURAL_MOVABLES"
          case "33": // Factoring
            return "MOVABLES::ACCOUNTS_RECEIVABLE"
          case "34": // Varelager
            return "MOVABLES::INVENTORIES"
          case "37": // Skip/Fiskefartøyer
            return "SHIP::FISHING_VESSELS"
          case "38": // Skip under bygging
            return "SHIP::UNDER_CONSTRUCTION"
          case "39": // Fiskeredskaper
            return "MOVABLES::FISHING_GEAR"
          case "40": // Flytekran/flytedokk
            return "SHIP::FLOATING_DOCK_CRANE"
          case "46": // Driftstilbehør
            return "MOVABLES::OPERATING_ACCESSORIES"
          default:
            throw new Error(
              `Unknown productId '${productId}' - can't convert to assetType`
            )
        }
      }

      finalObjectType = productIdToAssetType(productId)
    } else {
      if (
        objectType === collateralObjectTypes.movables &&
        collateralObjects.at(0)?.collateralObjectDetails?.movablesType
      ) {
        finalObjectType =
          "MOVABLES::" +
          collateralObjects.at(0)?.collateralObjectDetails?.movablesType
      } else if (
        objectType === collateralObjectTypes.vehicle &&
        collateralObjects.at(0)?.collateralObjectDetails?.vehicleType
      ) {
        finalObjectType =
          "VEHICLE::" +
          collateralObjects.at(0)?.collateralObjectDetails?.vehicleType
      }
    }
  }

  return finalObjectType
}

/**
 * Get a collateral object's label, f.ex. "landbruksløsøre" or "driftstilbehør"
 * @param {Insight.CollateralObject | null} collateralObject
 * @param {string} type Collateral object type
 * @returns {string}
 */
function getObjectLabel(collateralObject, type) {
  const constantLabels = {
    "VEHICLE::FLEET_VEHICLES": "samlede motorvogner og anleggsmaskiner",
    "MOVABLES::OPERATING_ACCESSORIES": "driftstilbehør",
    "MOVABLES::ACCOUNTS_RECEIVABLE": "samlede kundefordringer/factoring",
    "MOVABLES::INVENTORIES": "varelager",
    "MOVABLES::AGRICULTURAL_MOVABLES": "landbruksløsøre",
    "MOVABLES::FISHING_GEAR": "fiskeredskaper",
    "SHIP::UNDER_CONSTRUCTION": "skip under bygging",
  }

  let label = constantLabels[type] ?? null
  if (type === collateralObjectTypes.realEstate) {
    const {
      cadastre,
      associatedProperties = [],
      ownershipType,
    } = collateralObject?.collateralObjectDetails || {}
    const formattedMainCadastre = formatCadastre(cadastre)
    const allAssociatedProperties = associatedProperties
      .map((subCadastre) => formatCadastre(subCadastre.cadastre))
      .filter((subCadastre) => subCadastre !== formattedMainCadastre)

    const associatedPropertiesText =
      allAssociatedProperties.length > 0
        ? ", " + allAssociatedProperties.join(", ")
        : ""
    label = `${formattedMainCadastre} (${
      ownershipType === "EXCLUSIVE_OWNERSHIP" ? "eiendomsrett" : "festerett"
    })${associatedPropertiesText}`
  } else if (type === "VEHICLE::MOTOR_VEHICLE") {
    const {
      dateFirstRegistered,
      registrationNumber,
      vehicleBrand,
      vehicleIdentificationNumber,
    } = collateralObject.collateralObjectDetails?.registrationData || {}

    if (registrationNumber) {
      return registrationNumber.toUpperCase()
    }

    if (!dateFirstRegistered || !vehicleBrand || !vehicleIdentificationNumber) {
      throw new Error(
        "Mangler nødvendig informasjon om motorvogn: Førstegangsregistrerings, Merke eller Chassisnummer"
      )
    }

    const yearRegistered = new Date(dateFirstRegistered).getFullYear()
    return `${vehicleBrand}/${vehicleIdentificationNumber}/${yearRegistered}`
  } else if (type === collateralObjectTypes.aquaculture) {
    label =
      "følgende akvakulturtillatelser; " +
      collateralObject.collateralObjectDetails.permissionNumbers
        .map(
          ({ regionNumber, municipalityNumber, serialNumber }) =>
            `${String(regionNumber).padEnd(2, " ")}${String(
              municipalityNumber
            ).padEnd(2, " ")}${String(serialNumber).padStart(4, "0")}`
        )
        .join(", ")
  } else if (type === collateralObjectTypes.shares) {
    // No need to handle shares (collateralObjectTypes.shares) here, as these
    // need very different texts so we create a special case for them instead.

    throw new Error("Special case for 'shares' was not hit")
  } else if (type === collateralObjectTypes.aircraft) {
    const { callSign, name } = collateralObject.collateralObjectDetails
    label = `luftfartøy, ${callSign}, type ${name}`
  } else if (type === "SHIP::FLOATING_DOCK_CRANE") {
    const { callSign, shipName } = collateralObject.collateralObjectDetails
    label = `fartøyet ${shipName} ${callSign} med tilhørende fiskerettigheter`
  } else if (type === "SHIP::FISHING_VESSELS") {
    // Handled same as above, but unsure if this is right.
    const { callSign, shipName } = collateralObject.collateralObjectDetails
    label = `fartøyet ${shipName} ${callSign} med tilhørende fiskerettigheter`
  }

  return label
}

/**
 * Gets text for a specific collateral object. To be concatenated with a prefix text and
 * other collateral objects, forming the security text.
 * @param {Insight.CollateralAgreement} collateralAgreement
 * @param {Insight.CollateralObject} collateralObject
 * @param {string} objectType
 * @returns {string}
 */
const getObjectPriorityText = (
  collateralAgreement,
  collateralObject,
  objectType
) => {
  // Dersom det er 2 objekter på en avtale så skal vi skrive f.eks
  // Pantedokument pålydende kr <agreementFaceValue> i <object_A> med 1. prioritet og <object_A> med prioritet og opptrinnsrett etter pantedokumenter pålydende kr <object_a.lien[0].amount> til <object_a.lien[0].name>
  // Need to get label

  // Get IN priority
  const liensList =
    collateralObject.basicCollateralObjectInformation.collateralObjectLiens

  const agreementFaceValue = getAgreementFaceValue(collateralAgreement)
  const INpriority = liensList.find(
    (lien) =>
      lien.mortgageeId === IN_ORGANIZATION_NUMBER &&
      lien.nominalAmount.amount === agreementFaceValue
  )?.priority

  if (!INpriority) {
    throw new Error("Kunne ikke finne IN's pant på sikkerhetsobjekt")
  }

  if (INpriority === 1) {
    return `1. prioritet`
  }

  // Etterstående pant

  const higherPriorityLiens = liensList.filter(
    (lien) => lien.priority < INpriority
  )

  const higherPriorityLiensText = joinWithCorrectGrammar(
    higherPriorityLiens.map((lien) => {
      const isOrganization = lien.mortgageeId.length === 9
      const isInnovationNorway =
        lien.mortgageeId === IN_ORGANIZATION_NUMBER &&
        lien.nominalAmount.amount === agreementFaceValue
      const mappedLien = {
        name: isInnovationNorway ? "Innovasjon Norge" : lien.mortgageeName,
        type: isOrganization ? "organization" : "person",
        ...(isOrganization
          ? { organizationNumber: lien.mortgageeId }
          : { ssn: lien.mortgageeId }),
      }
      return `kr ${addThousandSeparator(
        lien.nominalAmount.amount
      )} til ${formatEntity(mappedLien)}`
    })
  )

  const isShares = objectType === collateralObjectTypes.shares
  if (isShares) {
    // Comma between "lån" and ${higherPriorityLiensText} is to avoid implying that
    // each of the liens' are loans.
    return `prioritet og opptrinnsrett etter pant til sikkerhet for lån, ${higherPriorityLiensText}`
  } else {
    return `prioritet og opptrinnsrett etter pantedokumenter pålydende ${higherPriorityLiensText}`
  }
}

/**
 * Should be called per collateral agreement, each line can describe several collateral objects
 * @param {2 | 3 | 4} subtitleType
 * @param {Insight.CollateralAgreement} collateralAgreement
 * @param {Insight.CollateralObject[]} collateralObjects
 * @param {object[]} stakeholders
 * @param {*} collateral
 * @returns {string}
 */
const getSecurityLine = (
  subtitleType,
  collateralAgreement,
  collateralObjects,
  stakeholders,
  collateral
) => {
  /**
   * Create string or throw error if it doesn't exist
   * @param {function(): string} createText
   * @param {string} errorMessage
   * @returns {string}
   */
  const textOrException = (
    createText,
    errorMessage = "Mangler data eller klarer ikke å autogenerere tekst for denne avtalen."
  ) => {
    if (!createText) {
      throw new Error(errorMessage)
    }

    return createText()
  }

  // Make sure we actually get some collateral objects
  // subtitleType 4 is a special case, and does not have any collateral objects
  // If collateralagreement is "CHANGED" we also do not have any collateral objects.
  const isChangedAgreement =
    collateralAgreement.basicCollateralAgreementInformation
      ?.basicCollateralInformation?.version === "CHANGED"
  // TODO: Check for "Til sikkerhet for", kanskje ikke her men
  // vi ønsker ikke å generere tekster for andre endringstyper.
  const isSpecialCase = subtitleType === 4 || isChangedAgreement

  if (!collateralObjects.length && !isSpecialCase) {
    throw new Error("Ingen sikkerhetsobjekter lagt til i avtalen.")
  }

  const {
    basicCollateralAgreementInformation,
    specificCollateralAgreementInformation,
  } = collateralAgreement
  const { mortgagors } = basicCollateralAgreementInformation

  // Used to generate text like "Ola Nordmann, org.nr./fnr. 12345678901 og Kari Nordmann, org.nr./fnr. 12345678902"
  const formatEntities = (entities) => {
    return entities?.map(formatEntity)?.join(" og ")
  }

  const buildEntityList = (entityList) => {
    const entityIds = entityList?.map((x) => x.internalId)
    return formatEntities(
      stakeholders?.filter((x) => entityIds?.includes(x.id))
    )
  }

  // 2: Sikkerhet i aktiva tilhørende låntaker
  // 3: Sikkerhet i aktiva tilhørende andre enn låntaker
  // 4: Sikkerhet ved kausjon

  //
  // Sikkerhet ved kausjon (4)
  //
  if (subtitleType === 4) {
    const { suretyLiabilityType, suretyAmount } =
      collateralAgreement.specificCollateralAgreementInformation
    const sureties = mortgagors ?? []
    const suretyList = buildEntityList(sureties)

    const coverageTotalAmount =
      basicCollateralAgreementInformation?.collateralCoverages?.reduce(
        (acc, { amount }) => acc + amount,
        0
      ) ?? 0

    // laveste av maksbeløp eller (kausjonsbeløp)avtalebeløp er større eller lik lånebeløp

    // Temporarily assume every surety has the same coverage, in the future we will
    // extend this to handle different coverage amounts for different sureties
    const coverageFactor = sureties.length ? 1 / sureties.length : 0
    const coveragePercent = `${(coverageFactor * 100).toFixed(2)}%`

    const mappedSureties = sureties.map((surety) => ({
      internalId: surety.internalId,
      name: stakeholders.find((x) => x.id === surety.internalId)?.name,
    }))

    // [] % på [kausjonist A] og med [] % på [kausjonist B]
    const suretyCoverageText = mappedSureties
      .map((x) => `${coveragePercent} på ${x.name}`)
      .join(" og med ")

    const suretiesArePeople =
      stakeholders?.find(
        (stakeholder) => stakeholder.id === sureties.at(0)?.internalId
      )?.type === "person"

    // Hvis kausjonisten er en forbruker, skal ordet "selvskyldnerkausjon" byttes ut med ordet "kausjon". Særvilkår om styre- eller generalforsamlingsbeslutning. JURI bør konsulteres
    const appropriateWord = suretiesArePeople
      ? "kausjon"
      : "selvskyldnerkausjon"

    // Nivå 1: Hvis det er kun en kausjonist for et lån, er du på 01. Hvis du har to eller flere kautionister på et lån, er du på 02 eller 03.
    // Hvis suretyLiabilityType = JOINT_AND_SEVERAL er du på 02, mens PRO_RATA gir 03.
    // Nivå 2: 04 er ikke støttet, 05 under 01 og 02 finnes ved å kombinere produktkoden for oppstartslån og antall kausjonister.

    // check if other agreements that are not suretyship
    const allDeliveryValues =
      Object.values(collateral?.collateralAgreementsForDelivery) || []

    const hasOtherAgreementsThanSuretyship = allDeliveryValues?.some((item) =>
      item?.collateralAgreements
        ?.map(
          (x) =>
            x?.collateralAgreementType !== collateralAgreementTypes.suretyship
        )
        .some((item) => item)
    )

    const isPartialGuarantee =
      basicCollateralAgreementInformation?.collateralCoverages?.at(0)
        ?.collateralCoverage?.suretyshipDetails?.suretyResponsibiltyType ===
        "GUARANTEE_PARTIAL" || false

    let level = null
    if (suretyAmount >= coverageTotalAmount) {
      level = 1
    } else if (suretyAmount < coverageTotalAmount) {
      if (isPartialGuarantee) {
        level = 4
      } else {
        if (!hasOtherAgreementsThanSuretyship) {
          level = 5
        } else {
          level = 2
        }
      }
    }

    const loanWord =
      basicCollateralAgreementInformation?.collateralCoverages?.length > 1
        ? "lånene"
        : "lånet"

    const lineTextTable = {
      oneSurety: {
        1: () =>
          `${capitalizeFirstLetter(
            appropriateWord
          )} fra ${suretyList}. Kausjonen skal også dekke renter og omkostninger ved låntakers eventuelle mislighold.`,
        2: () =>
          `Kr ${addThousandSeparator(
            suretyAmount
          )} av ${loanWord}, med tillegg av renter og omkostninger ved låntakers mislighold, skal sikres med ${appropriateWord} fra ${suretyList}. Kausjonen skal gjelde inntil ${loanWord} er innfridd i sin helhet og skal gjelde for den dårligst pantesikrede delen av ${loanWord}.`,
        4: () => `Kr ${addThousandSeparator(
          suretyAmount
        )} av ${loanWord}, med tillegg av renter og omkostninger ved låntakers mislighold, skal sikres med
          selvskyldnerkausjon fra ${suretyList}. Kausjonen skal gjelde inntil ${loanWord} er innfridd i
          sin helhet og skal gjelde for den dårligst pantesikrede delen av ${loanWord}. Kausjonsbeløpet skal
          imidlertid reduseres med ethvert avdrag (ikke renter) som blir nedbetalt på ${loanWord}. Slik reduksjon
          av kausjonsbeløpet skal dog ikke skje ved nedreguleringer av ${loanWord} som kan relateres til salg av
          pantsatte aktiva, annet enn varelager, med mindre Innovasjon Norge har gitt skriftlig samtykke.`,
        5: () =>
          `Kr ${addThousandSeparator(
            suretyAmount
          )} av ${loanWord}, med tillegg av renter og omkostninger ved låntakers mislighold, skal sikres med ${appropriateWord} fra ${suretyList}. Kausjonen skal gjelde inntil ${loanWord} er innfridd i sin helhet.`,
      },
      jointSureties: {
        // Hver av kausjonistene er fullt ansvarlig for hele beløpet som omfattes av kausjonen (”en for alle –
        // alle for en”). Hver av kausjonistene kan kreves for hele kausjonsbeløpet inntil hele kausjonskravet er
        // dekket fullt ut. Långiver kan velge fritt å forholde seg til én eller flere av kausjonistene.
        1: () =>
          `Solidarisk ${appropriateWord} fra ${suretyList}. Kausjonen skal også omfatte renter og omkostninger ved låntakers eventuelle mislighold.`,
        // Hver av kausjonistene er fullt ansvarlig for hele beløpet som omfattes av kausjonen (”en for alle –
        // alle for en”). Hver av kausjonistene kan kreves for hele kausjonsbeløpet inntil hele kausjonskravet er
        // dekket fullt ut. Långiver kan velge fritt å forholde seg til én eller flere av kausjonistene.
        2: () =>
          `Kr ${addThousandSeparator(
            suretyAmount
          )} av ${loanWord}, med tillegg av renter og omkostningerved låntakers mislighold, skal sikres med solidarisk ${appropriateWord} fra ${suretyList}. Kausjonen skal gjelde inntil ${loanWord} er innfridd i sin helhet og skal gjelde for den dårligst pantesikrede delen av ${loanWord}.`,
        4: () => `Kr ${addThousandSeparator(
          suretyAmount
        )} av ${loanWord}, med tillegg av renter og omkostninger ved låntakers mislighold, skal sikres med
            selvskyldnerkausjon fra ${suretyList}. Kausjonen skal gjelde inntil ${loanWord} er innfridd i
            sin helhet og skal gjelde for den dårligst pantesikrede delen av ${loanWord}. Kausjonsbeløpet skal
            imidlertid reduseres med ethvert avdrag (ikke renter) som blir nedbetalt på ${loanWord}. Slik reduksjon
            av kausjonsbeløpet skal dog ikke skje ved nedreguleringer av ${loanWord} som kan relateres til salg av
            pantsatte aktiva, annet enn varelager, med mindre Innovasjon Norge har gitt skriftlig samtykke.`,
        5: () =>
          `Kr ${addThousandSeparator(
            suretyAmount
          )} av ${loanWord}, med tillegg av renter og omkostninger ved låntakers mislighold, skal sikres med solidarisk ${appropriateWord} fra ${suretyList}. Kausjonen skal gjelde inntil ${loanWord} er innfridd i sin helhet.`,
      },
      prorataSureties: {
        // Hver kausjonist kun er ansvarlig for sin angitte del av kausjonsbeløpet. (Eks: A = 50 %, B = 30 %
        // og C = 20 %). Långiver kan ikke kreve mer av hver kausjonist enn vedkommendes angitte
        // %-andel. Dersom en av kausjonistene ikke er i stand til å betale sin andel, kan långiver ikke
        // overføre dette ansvaret til de andre kausjonistene.
        1: () =>
          `Proratarisk ${appropriateWord} fra ${suretyList}. Kausjonen skal gjelde for hele ${loanWord} med tillegg av renter og omkostninger ved låntakers eventuelle mislighold. Den felles forpliktelsen under kausjonen skal fordeles med ${suretyCoverageText}.`,
        // Hver kausjonist kun er ansvarlig for sin angitte del av kausjonsbeløpet. (Eks: A = 50 %, B = 30 % og C =
        // 20 %). Långiver kan ikke kreve mer av hver kausjonist enn vedkommendes angitte %‐andel. Dersom
        // en av kausjonistene ikke er i stand til å betale sin andel, kan långiver ikke overføre dette ansvaret til
        // de andre kausjonistene (slik som ved solidarisk ansvar).
        2: () =>
          `Kr ${addThousandSeparator(
            suretyAmount
          )} av lånet, med tillegg av renter og omkostninger ved låntakers mislighold, skal sikres med proratarisk ${appropriateWord} fra ${suretyList}. Kausjonen skal gjelde inntil ${loanWord} er innfridd i sin helhet og skal gjelde for den dårligst pantesikrede delen av ${loanWord}. Den felles forpliktelsen under kausjonen skal fordeles med ${suretyCoverageText}.`,
        4: () => `Kr ${addThousandSeparator(
          suretyAmount
        )} av ${loanWord}, med tillegg av renter og omkostninger ved låntakers mislighold, sikres med
          proratarisk ${appropriateWord} fra ${suretyList}.
          Kausjonen skal gjelde inntil ${loanWord} er innfridd i sin helhet og skal gjelde for den dårligst
          pantesikrede delen av ${loanWord}. Kausjonsbeløpet skal imidlertid reduseres med ethvert avdrag (ikke
          renter) som blir nedbetalt på ${loanWord}. Slik reduksjon av kausjonsbeløpet skal dog ikke skje ved
          avdragsbetalinger som kan relateres til salg av pantsatte aktiva, annet enn varelager, med mindre
          Innovasjon Norge har gitt skriftlig samtykke. Den felles forpliktelsen under kausjonen skal fordeles
          med ${suretyCoverageText}.`,
        5: () => `Kr ${addThousandSeparator(
          suretyAmount
        )} av ${loanWord}, med tillegg av renter og omkostninger ved låntakers mislighold, skal sikres med
        proratarisk ${appropriateWord} fra ${suretyList}. Kausjonen
        skal gjelde inntil ${loanWord} er innfridd i sin helhet.`,
      },
    }

    if (sureties.length === 1) {
      return textOrException(lineTextTable.oneSurety[level])
    }

    if (suretyLiabilityType === "JOINT_AND_SEVERAL") {
      return textOrException(lineTextTable.jointSureties[level])
    }

    if (suretyLiabilityType === "PRO_RATA") {
      return textOrException(lineTextTable.prorataSureties[level])
    }

    throw new Error("Kunne ikke finne sikkerhetsansvarstype")
  }

  // Sikkerhet i aktiva tilhøre låntaker / tilhørende andre enn låntaker

  const agreementFaceValue = getAgreementFaceValue(collateralAgreement)
  const mortgagorList = buildEntityList(mortgagors)

  // This makes subtypes easier to work with
  const finalObjectType = getFinalObjectType(
    collateralAgreement,
    collateralObjects,
    isChangedAgreement
  )

  const { registeredDate, registrationNumber } =
    specificCollateralAgreementInformation.mortgageDeedDetails

  // Lag en liste av pantsettere som ikke er låntaker
  const mortgagorsExcludingApplicant = mortgagors.filter((mortgagor) => {
    // For hver pantsetter...
    const { internalId } = mortgagor

    // Finn interessenten for pantsetteren
    const stakeholder = stakeholders.find(
      (stakeholder) => stakeholder.id === internalId
    )

    // Hvis vi ikke fant den, da er pantsetter ikke i interessenter listen.
    // Usikker på om dette kan engang skje, men da er dette sannsynligvis ikke
    // låntaker.
    if (!stakeholder) {
      return false
    }

    // Hvis denne interessenten/pantsetteren ER låntaker, filtrer pantsetteren vekk
    return !stakeholder.isApplicant
  })

  // Dersom det eksisterer en eller flere pantsettere som *ikke* er låntaker,
  // da skal vi vise "tilhørende <mortgagorList>" i teksten.
  const showMortgagorList = mortgagorsExcludingApplicant.length > 0
  const mortgagorsText = showMortgagorList ? ` tilhørende ${mortgagorList}` : ""

  if (finalObjectType === collateralObjectTypes.shares) {
    // For some reason IN will never have several collateral objects of type shares
    // under the same agreement. Those would be under separate collateral agreements.
    // This, combined with this special case (since they want the texts to look very
    // different for shares), means we can simply throw an error if there are multiple
    // collateral objects.
    if (collateralObjects.length > 1) {
      throw new Error(
        "Flere sikkerhetsobjekter under én avtale er ikke støttet når dette er av typen 'akjser'."
      )
    }

    // Converts from f.ex. "2024-08-20" to "20.08.2024"
    const issuedAt = collateralAgreement.meta.created
      .split("-")
      .reverse()
      .join(".")

    /**
     * Gets ownership percentage of an object.
     * This is not nice, but apparently the sum of shareOfTotalShareCapital in
     * each entry is the total ownership percentage.
     * @param {Insight.CollateralObjectDetails} Details
     * @returns {number} Ownership percentage
     */
    const getOwnershipPercentage = ({ listOfShareSeries }) =>
      listOfShareSeries?.reduce(
        (acc, cur) => acc + (cur?.shareOfTotalShareCapital ?? 0),
        0
      ) ?? 0

    // Although IN does not need to support multiple collateral objects of type shares,
    // we might as well support it here incase they change their mind.
    const shareText = collateralObjects
      .map((collateralObject) => {
        const { collateralObjectDetails } = collateralObject
        // If we do have share %, get the total ownership percentage
        const listOfShareSeriesLength =
          collateralObjectDetails?.listOfShareSeries?.length ?? 0
        if (listOfShareSeriesLength > 0) {
          const ownershipPercentage = getOwnershipPercentage(
            collateralObjectDetails
          )
          return `${collateralObjectDetails.organizationName} (aksjeandel ${ownershipPercentage} %)`
        }
        return collateralObjectDetails.organizationName
      })
      .join(", ")

    const ownerText = showMortgagorList ? mortgagorList : "låntaker"

    if (isChangedAgreement) {
      return `Eksisterende pant utstedt ${issuedAt} i samtlige aksjer som ${ownerText} eier.`
    } else {
      const collateralObject = collateralObjects[0]
      const priorityText = getObjectPriorityText(
        collateralAgreement,
        collateralObject,
        finalObjectType
      )

      return `Pant i samtlige aksjer som ${ownerText} eier i ${shareText} med ${priorityText}`
    }
  }

  if (!isChangedAgreement) {
    const joinedObjects = joinWithCorrectGrammar(
      collateralObjects.map(
        (collateralObject) =>
          `${getObjectLabel(
            collateralObject,
            finalObjectType
          )} med ${getObjectPriorityText(
            collateralAgreement,
            collateralObject,
            finalObjectType
          )}`
      )
    )

    return `Pantedokument pålydende kr ${addThousandSeparator(
      agreementFaceValue
    )} i ${joinedObjects}${mortgagorsText}.`
  }

  // Handle special cases where we replace non-constant-label-cases with constants.
  // This is because for existing/changed agreements, we don't have all data (such as f.ex.
  // which shares are owned).
  let label = null
  if (finalObjectType === collateralObjectTypes.realEstate) {
    label = joinWithCorrectGrammar(
      collateralAgreement.specificCollateralAgreementInformation.collaterals.map(
        (c) => formatCadastre(c.cadastre)
      )
    )
  } else if (finalObjectType === "VEHICLE::MOTOR_VEHICLE") {
    // For existing agreements we do not have extra data for vehicle.
    label = "motorvogner"
  } else if (finalObjectType === collateralObjectTypes.aquaculture) {
    // For existing agreements we do not have extra data for aquaculture.
    label = "akvakulturtillatelser"
  } else if (finalObjectType === collateralObjectTypes.simpleMoneyClaim) {
    label = "enkle pengekrav"
  } else if (collateralObjects?.length > 0) {
    label = collateralObjects
      .map((collateralObject) =>
        getObjectLabel(collateralObject, finalObjectType)
      )
      .join(", ")
  } else {
    label = getObjectLabel(null, finalObjectType)
  }

  return `Eksisterende pantedokument pålydende kr ${addThousandSeparator(
    agreementFaceValue
  )}, tgl. ${registeredDate} dbnr. ${registrationNumber}, i ${label}${mortgagorsText}.`
}

const getSecurityTextsForDelivery = (
  flowDeliveries,
  deliveriesWithChanges,
  deliveryId,
  agreementsDataState,
  stakeholders,
  isMaintenance,
  rawEngagements
) => {
  const { collateral, agreementData } = agreementsDataState

  // We get one or more collateral agreements for each delivery
  const collateralData =
    collateral?.collateralAgreementsForDelivery?.[deliveryId] || {}
  const { collateralAgreements, collateralObjects } = collateralData

  if (!collateralAgreements || collateralAgreements?.length === 0) {
    return [
      {
        title: "Lånet gis uten krav om sikkerhet.",
        subtitle: "",
        text: "",
      },
    ]
  }

  // Only generate security texts for [NEW, CHANGED] collateral agreements
  const validAgreements = collateralAgreements.filter((agreement) => {
    const { version } =
      agreement?.basicCollateralAgreementInformation
        ?.basicCollateralInformation ?? {}

    //Vedlikehold
    if (version === "NEW") {
      return true
    }

    //Vanlige saker
    if (version === "CHANGED") {
      // Before returning true, let's verify what the change was.

      // There can be several changes.
      const changes = agreementsDataState.changesData

      for (const change of changes) {
        // There can be several changed/touched agreements per change.
        // If one of them is our agreement and it's of type "COLLATERALCOVERAGES"
        // then we need to generate a security text for it.
        if (
          change.data.basicChangeInformation.parentId === agreement.internalId
        ) {
          return change.type === "COLLATERALCOVERAGES"
        }
      }
    }

    return false
  })

  const getSecurityTextForAgreement = (agreement) => {
    // Find all collateral objects that belong to this agreement
    // We need to do this because there is no direct relation between the agreement and the objects
    const objects = collateralObjects.filter((x) =>
      agreement?.collateralObjectsReferences?.includes(x.internalId)
    )

    const subtitleType = getSubtitleType(agreement, stakeholders)

    try {
      return {
        title: getTitle(
          flowDeliveries,
          deliveryId,
          agreement,
          agreementData,
          rawEngagements,
          isMaintenance
        ),
        subtitle: subtitleTypeToString(subtitleType),
        text: getSecurityLine(
          subtitleType,
          agreement,
          objects,
          stakeholders,
          collateral
        ),
        userGenerated: false,
      }
    } catch (e) {
      // An error occurred, but we still want to show the user something
      return {
        title: "En feil oppstod under generering av sikkerhetstekst:",
        subtitle: e.message,
        text: "",
        userGenerated: false,
        failed: true,
      }
    }
  }

  // Nå genererer vi en tittel osv. for hver avtale, og i getTitle skal vi sjekke om avtalen har sikkerhet.
  // Men, hvis vi har en avtale, vil ikke alltid lånet ha sikkerhet da?

  // For every collateral agreement we get a security text
  return validAgreements.map(getSecurityTextForAgreement)
}

const shouldDeliveryHaveSecurity = (delivery) => {
  const whitelisted = ["Loan", "Maintenance"]
  if (!delivery) return false
  return whitelisted.includes(delivery.productClass)
}

/**
 * @param {object} params
 * @param {string} params.delivs
 * @param {InFlow.Delivery} params.data
 * @param {InFlow.SpecialTerm} [params.newSpecialTerm]
 * @param {Array<string>} [params.otherDeliveryIds]
 */
const updateSpecialTerms = ({
  delivs,
  data,
  newSpecialTerm,
  otherDeliveryIds,
}) => {
  if (otherDeliveryIds && otherDeliveryIds.length > 0) {
    otherDeliveryIds.forEach((id) => {
      if (delivs[id]) {
        delivs[id] = {
          ...delivs[id],
          ...data,
          specialTerms: newSpecialTerm
            ? {
                ...delivs[id].specialTerms,
                userAdded: [
                  ...(delivs[id].specialTerms?.userAdded || []),
                  newSpecialTerm,
                ],
              }
            : delivs[id].specialTerms,
        }
      }
    })
  }
}

export {
  getSecurityTextsForDelivery,
  shouldDeliveryHaveSecurity,
  updateSpecialTerms,
}
