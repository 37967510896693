import { shouldDeliveryHaveSecurity } from "./securityText"
import { hasData } from "./returnValue"

const agreementIsNew = (agreement) =>
  agreement?.basicCollateralAgreementInformation?.basicCollateralInformation
    ?.version === "NEW"

export const validateAgreementHasObject = (collateralAgreements) => {
  let hasMissingObject = false
  collateralAgreements?.forEach((agreement) => {
    if (agreementIsNew(agreement)) {
      if (
        agreement?.meta?.type === "MORTGAGE_DEED" ||
        agreement?.collateralAgreementType === "MORTGAGE_DEED"
      )
        if (!(agreement?.collateralObjectsReferences?.length > 0))
          hasMissingObject = true
    }
  })
  return hasMissingObject
}

export const validateAgreementWithoutDelivery = (
  deliveries,
  collateralAgreements
) => {
  if (!collateralAgreements?.length > 0) return false
  // match accountNames from insight
  const deliveryAccountNumbers = Object.values(deliveries).map(
    (delivery) => `${delivery.productName} ${delivery.loanPurposeCode}`
  )

  const hasNewAgreementWithoutConnectionToDelivery =
    collateralAgreements.filter((agreement) => {
      //if agreement is old, return
      if (!agreementIsNew(agreement)) return false
      const collateralCoverages =
        agreement?.basicCollateralAgreementInformation?.collateralCoverages ||
        []

      //check if agreement has connection to current delivery
      const collateralCoveragesIncludesADelivery = collateralCoverages.find(
        (e) =>
          deliveryAccountNumbers.includes(e.accountName) &&
          e.accountNumber === "" //only new engagements
      )
      return !hasData(collateralCoveragesIncludesADelivery)
    })
  return hasNewAgreementWithoutConnectionToDelivery.length > 0
}
