import React, { useEffect, useState } from "react"
import DecisionTabs from "../components/Decision/DecisionTab"
import styled from "styled-components"
import { FontStyles } from "@stacc/flow-ui-components"
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { returnFirstArgWithValue } from "../util/returnValue"
import { validateInput } from "../components/insight/validateSchema"
import { checkForAuthority } from "../components/utils/checkForAuthority"
import { decisionLevels } from "../../constants/authorityDecisionLevels"
import { useInsightAppInitialized } from "../components/insight/useInsightAppInitialized"
import Notice from "../components/common/Notice"
import ErrorText from "../components/common/ErrorText"
import {
  getNewAndExistingEngagements,
  mapLoansWithAgreementData,
} from "../components/utils/mapAgreements"
import { getCaseReport } from "../util/insightCaseReport"
import { mapCaseReportData } from "../components/utils/mapCaseReportData"
import { useLanguage } from "../../translate/useLanguage"
import GenerateReportButton from "../components/common/GenerateReportButton"

const AssessApplication = ({ task, flow, t, schema, save, complete, user }) => {
  const {
    insightComponentData,
    decision: decisionFromAnalysis,
    decisionLevel,
    rejectionTemplate,
    rejectionText,
    comment,
    queueStatusCurrentCase,
    signedUpForAssessmentBy,
    needAnalysis,
    isJuri = false,
  } = task?.context

  const [formData, setFormdata] = useState(
    returnFirstArgWithValue(task?.data, task?.defaults) || {}
  )

  const [reportUrl, setReportUrl] = useState(null)
  const [isGeneratingReport, setIsGeneratingReport] = useState(false)
  const [generateReportError, setGenerateReportError] = useState(null)

  const [isProcessing, setProcessing] = useState(false)
  const [allowedToDecide, setAllowedToDecide] = useState(undefined)
  const [allowedToDecideReason, setAllowedToDecideReason] = useState("")

  const newAndExistingCollateralEngagements = getNewAndExistingEngagements(
    flow.data.deliveries,
    flow.data.mapped?.collateralEngagements || []
  )
  const { locale } = useLanguage()

  const handleDownloadCaseReport = async () => {
    const payload = mapCaseReportData(flow.data)
    let customerId
    const { accountType } = flow.data.raw.accountMaster.customerAccount
    if (accountType === "Person") {
      customerId = flow.data.raw.accountMaster.customerRelatedCompany.publicId
    } else {
      customerId = flow.data.raw.accountMaster.customerAccount.publicId
    }

    setIsGeneratingReport(true)

    try {
      const report = await getCaseReport({
        customerId,
        flowId: flow.flowId,
        data: payload,
        locale: flow.data.analysis?.createCreditMemo?.locale ?? locale,
      })

      const url = window.URL.createObjectURL(
        new Blob([report.data], { type: "application/pdf" })
      )
      setIsGeneratingReport(false)
      setReportUrl(url)
    } catch (error) {
      console.error("Failed to generate case report", error)
      setGenerateReportError(error)
    } finally {
      setIsGeneratingReport(false)
    }
  }

  const securityRiskData =
    flow.data.analysis?.termsAndSecurity?.input?.securityRiskData ?? []

  const mappedLoansWithCollateralAgreements =
    mapLoansWithAgreementData(
      flow.data.mapped.agreementData || [],
      newAndExistingCollateralEngagements,
      flow.data.mapped.engagement.loans || [],
      securityRiskData
    ) || []

  insightComponentData.shouldDisplayPrognose = true
  insightComponentData.isAdvicePanelStarted =
    flow.data?.advicePanel?.manuallyTriggered ||
    flow.data?.advicePanel?.triggered
  insightComponentData.hasAdvicePanelContent =
    flow.data?.advicePanel?.notesContent?.advicePanelHasContent ?? false
  insightComponentData.hasStatementFromOthersContent =
    flow.data?.advicePanel?.notesContent?.statemenFromOthersHasContent ?? false
  insightComponentData.showTaxonomy =
    flow.data.analysis?.setDecision?.isTaxonomyRelevant ?? false
  insightComponentData.showInnovationAndDigitalization =
    flow.data?.analysis?.setDecision?.isInnovationAndDigitalizationRelevant ??
    false
  insightComponentData.showSustainability =
    flow.data?.analysis?.setDecision?.isSustainabilityRelevant ?? false
  insightComponentData.agreementData = mappedLoansWithCollateralAgreements ?? []
  insightComponentData.isForeignAccount =
    flow.data.application.applicationContent?.isForeignAccount
  insightComponentData.policy = flow.data?.decisionTables
    ?.policyCheckApplication
    ? {
        timestamp: flow.data.decisionTables?.policyCheckApplication?.timestamp,
        policies: flow.data.decisionTables?.policyCheckApplication?.output,
      }
    : false

  //Evaluate if user is allowed to decide on this case, given user decision level and level required
  useEffect(() => {
    if (!isJuri) {
      const decisionLevel =
        queueStatusCurrentCase?.split("ASSESS_APPLICATION_")?.pop() || ""
      const allowedToDecide = checkForAuthority(
        user,
        signedUpForAssessmentBy,
        decisionLevels[decisionLevel],
        true
      )
      setAllowedToDecide(allowedToDecide.allowed)
      setAllowedToDecideReason(allowedToDecide.reason)
    } else {
      setAllowedToDecide(
        user.profile.role.some(
          (r) => r === "role.inflow.test.juri" || r === "role.inflow.prod.juri"
        )
      )
    }
  }, [user, queueStatusCurrentCase, signedUpForAssessmentBy, isJuri])

  const decisionLevelFromState =
    decisionFromAnalysis === "REJECT" ? "" : decisionLevel
  const [app, setApp] = useState(null)
  const [isLoading, isErrored] = useInsightAppInitialized(app)

  useEffect(() => {
    validateInput({ ...insightComponentData }, "@stacc/vue-casememo")
  }, [insightComponentData])

  const handleComplete = (values) => {
    setProcessing(true)
    complete(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const handleSave = (values) => {
    setProcessing(true)
    save(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  //EDIT DECISION LEVEL
  //THIS FEATURE IS NOT NECESSARY IN FIRST DELIVERY
  //    /**
  //      * This switch case check which decision level you are
  //      * currently and shows only choices that are
  //      * on higher levels in "Saken besluttes hos:"
  //      */
  // const [editDecisionLevel, setEditDecisionLevel] = useState(false)
  // const [newDecisionLevel, setNewDecisionLevel] = useState()

  // const EditDecisionLevel = ({ data, options }) => {
  //   switch (data) {
  //     case "ASSESS_APPLICATION_CREDIT_SECTION": {
  //       options = [options[0], options[1], options[2], options[3]]
  //       break
  //     }
  //     case "ASSESS_APPLICATION_CREDIT_LEVEL_1":
  //       options = [options[1], options[2], options[3]]
  //       break
  //     case "ASSESS_APPLICATION_CREDIT_LEVEL_2":
  //       options = [options[2], options[3]]
  //       break
  //     case "ASSESS_APPLICATION_MAIN_CREDIT":
  //       options = [options[3]]
  //       break
  //     case "ASSESS_APPLICATION_BOARD":
  //       options = [options[3]]
  //       break
  //     default:
  //       break
  //   }
  //   const handleChange = (e) => {
  //     setNewDecisionLevel(e.target.value)
  //     setEditDecisionLevel(false)
  //   }
  //   return (
  //     <>
  //       <LabelSpan>{t("case_decided_at")}</LabelSpan>
  //       <Select
  //         showBlankOption={true}
  //         blankOptionLabel={t("choose")}
  //         value={data}
  //         id="decisionLevel"
  //         options={options}
  //         onChange={(e) => handleChange(e)}
  //       />
  //     </>
  //   )
  // }

  const DecisionLevelInformationBox = ({ data }) => {
    return (
      <>
        <LabelSpan>{t("case_decided_at")}</LabelSpan>
        <Data>{t(data)}</Data>
      </>
    )
  }

  const manualTranslation = (data) => {
    switch (data) {
      case "OK":
        return t("approval")
      case "REJECT":
        return t("rejection")
      case "BACK_TO_SUPPORT":
        return t("send-back-to-basis-for-support")
      default:
        break
    }
  }

  return (
    <Layout forceHeight>
      <Content>
        <DecisionTabs
          onAppChange={setApp}
          t={t}
          flow={flow}
          loading={isLoading}
          needAnalysis={needAnalysis}
          insightData={insightComponentData}
        />
      </Content>
      <Context
        flow={flow}
        context={task.context?.applicationSummary}
        GenerateReportButton={
          <GenerateReportButton
            handleDownloadCaseReport={handleDownloadCaseReport}
            isGeneratingReport={isGeneratingReport}
            reportUrl={reportUrl}
            generateReportError={generateReportError}
            isProcessing={isProcessing}
            t={t}
          />
        }
      >
        {!isJuri && (
          <DisplayBox>
            <LabelSpan>{t("decisionBasis")}: </LabelSpan>
            <Data>{manualTranslation(decisionFromAnalysis)}</Data>
          </DisplayBox>
        )}

        {decisionFromAnalysis === "REJECT" && (
          <DisplayBox>
            <LabelSpan>{t("rejectionTemplate")}: </LabelSpan>
            <Data>{t(rejectionTemplate)}</Data>
            <LabelSpan>{t("rejection-letter-template")}: </LabelSpan>
            <Data id="rejectionText">{t(rejectionText)}</Data>
            <LabelSpan>{`${t("caseworkers-assessment")}: `}</LabelSpan>
            <Data>{t(comment)}</Data>
          </DisplayBox>
        )}

        {!isJuri && (
          <DisplayBox>
            {decisionLevelFromState !== "" ? (
              <DecisionLevelInformationBox data={decisionLevelFromState} />
            ) : (
              ""
            )}
            {/* Feature for next delivery */}
            {/* <EditDecisionLevel
            data={decisionLevelFromState}
            options={queStatusOptions}
          /> */}
          </DisplayBox>
        )}

        <StyledForm>
          {!allowedToDecide && (
            <p
              style={{
                color: "var(--flow-color-ferrari)",
                paddingBottom: "5px",
              }}
            >
              {t(allowedToDecideReason)}
            </p>
          )}
          <ReactForm
            schema={schema || {}}
            formData={formData}
            disabled={isProcessing || !allowedToDecide}
            onChange={(values) => setFormdata(values)}
            onSubmit={(values) => handleComplete(values)}
          >
            <ButtonContainer>
              <PrimaryButton
                type="submit"
                isLoading={isProcessing}
                disabled={
                  isProcessing || !allowedToDecide || isErrored || isLoading
                }
              >
                {t("complete")}
              </PrimaryButton>
              <SecondaryButtonModified
                type="button"
                disabled={isProcessing || !allowedToDecide}
                onClick={handleSave}
              >
                {t("save")}
              </SecondaryButtonModified>
            </ButtonContainer>
            {isErrored && (
              <StyledNotice backgroundColor="var(--flow-color-ferrari-lighter)">
                {isErrored && <ErrorText>{t(`failed-to-load-task`)}</ErrorText>}
              </StyledNotice>
            )}
          </ReactForm>
        </StyledForm>
      </Context>
    </Layout>
  )
}
export default AssessApplication

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

const StyledForm = styled.div`
  margin: 1em 0em 0em 0em;
`

const StyledNotice = styled(Notice)`
  color: var(--flow-color-ferrari);
  border-radius: 0;
  margin-top: 10px;
`

const Data = styled.span`
  ${FontStyles.Small};
`

const LabelSpan = styled.span`
  ${FontStyles.NormalStrong};
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 1em 0em 0em 0em;
`

const DisplayBox = styled.div`
  width: auto;
  height: auto;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
`

const Content = styled.div`
  flex: 1;
  height: 100%;
`
