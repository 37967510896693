import React from "react"
import styled from "styled-components"

const ProductLine = ({ product, amount, fundingIntensity, productType }) => {
  return (
    <LineItem header={true}>
      <p>{product}</p>
      <p>{amount}</p>
      <p>{fundingIntensity}</p>
      <p>{productType}</p>
    </LineItem>
  )
}

export default ProductLine

const LineItem = styled.li`
  padding-top: 10px;
  display: flex;
  justify-content: space-evenly;
`
