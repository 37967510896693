import axios from "axios"

const queryProductById = async (id) => {
  const { data } = await axios(`/api/products?productId=${id}`)
  return data
}

const queryAllProducts = async () => {
  const { data } = await axios("/api/products")
  return data
}

const queryProductsByCategory = async (category) => {
  const { data } = await axios(`/api/products?productCategory=${category}`)
  return data
}

export { queryProductById, queryAllProducts, queryProductsByCategory }
