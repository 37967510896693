import isTesting from "../../util/isTesting"
import { getInputSchema, getOutputSchema } from "./insightModuleUtil"

const Ajv = require("ajv")

export const validate = (schema, data) => {
  const ajv = new Ajv({ strictSchema: false, allErrors: true })
  let valid
  try {
    valid = ajv.validate(schema, data)
  } catch (e) {
    console.error("Something went wrong while validing schema, error, ", e)
  }
  return { isValid: valid, errors: ajv.errors }
}

export const validateInput = async (data, component) => {
  if (isTesting()) return { data, isValid: true, errors: {} }
  const schema = await getInputSchema(component)
  const { isValid, errors } = validate(schema?.schema, data)
  if (!isValid)
    console.error(
      `Errors while validating inputSchema for ${component}. \n\n errors: ${JSON.stringify(
        errors
      )}`
    )
  return { data, isValid, errors }
}

export const validateOutput = async (data, component) => {
  if (isTesting()) return { data, isValid: true, errors: {} }
  const schema = await getOutputSchema(component)
  const { isValid, errors } = validate(schema?.schema, data)
  if (!isValid)
    console.error(
      `Errors while validating outputSchema for ${component}. \n\n errors: ${JSON.stringify(
        errors
      )}`
    )
  return { data, isValid, errors }
}

export const validateInsight = async (insightApp, data, category) => {
  //noe hacky validering, men vanskelig å gjere det betre når det er mismatch mellom skjema og output (og validations prop ikkje oppdateres)
  const result = await validateOutput(data, insightApp)
  console.log("VALIDATE RESULT", result)
  let errors = []
  if (data?.approvalStatus === "OK" || category === "counterguarantee") {
    errors =
      Object.keys(result?.errors || {})?.map(
        (key) => result?.errors[key]?.instancePath
      ) || []
  }
  return errors
}
