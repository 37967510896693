import { addThousandSeparator } from "../../util/addThousandSeparator"
import formatWithLocale from "../../util/dateFormatter"
import { getEngagementAmount } from "../../util/engagementAmount"

export const DECISION_TEXTS = {
  GRACEPERIOD: `Innovasjon Norge innvilger {0} avdragsutsettelse for
{1}
            
Lånets/lånenes løpetid forlenges tilsvarende, med mindre noe annet fremgår av særvilkårene nedenfor.
            
Det er et vilkår at eventuelle skyldige renter og omkostninger er betalt, og at fremtidige renter og omkostninger blir betalt ved forfall.
Et eventuelt betalingsmislighold overfor en annen kreditor i perioden vil også bli ansett som mislighold overfor Innovasjon Norge, og vil gi Innovasjon Norge rett til å si opp lånet uavhengig av dette vedtaket.`,
  DELETEELEMENT: `Innovasjon Norge frafaller følgende sikkerheter for lån til {0}
{1}
    
Det er et vilkår at eventuelle skyldige renter og avdrag er betalt.`,
  PRIORITYCHANGES: `Innovasjon Norge viker prioritet med:
{0}

Det er et vilkår:
- at eventuelle pantedokumenter og andre heftelser med mellomliggende prioritet også viker prioritet,
- at eventuelle skyldige renter og avdrag er betalt, og
- at låntaker dekker eventuelle tinglysingsgebyrer.`,
  EXTENDOFCOLLATERAL: `Vi viser til kontakt vedrørende utvidelse av sikkerhet for løpende lån/tilbud om lån. Med bakgrunn i nye opplysninger vedrørende lånets sikkerhet har Innovasjon Norge fattet følgende vedtak:

{0}`,
  INTERESTFREEPERIOD: `Innovasjon Norge innvilger rentefri periode for
{0}

Et eventuelt betalingsmislighold overfor en annen kreditor i perioden vil også bli ansett som mislighold overfor Innovasjon Norge, og vil gi Innovasjon Norge rett til å si opp lånet/lånene uavhengig av dette vedtaket.`,
  PAYMENTDEFERRAL: `Innovasjon Norge godkjenner henstand med betaling av renter og avdrag for
{0}

Henstanden er betinget av at bedriften ikke blir underlagt tvangsforfølgninger i perioden. I så fall bortfaller vedtaket om betalingshenstand.
I henhold til lånevilkårene beregnes forsinkelsesrenter fra ordinært terminforfall og inntil betaling finner sted. Beløpet vil bli lagt til ved neste terminforfall.`,
  REFINANCING: `{0}

Dato for sammenslåing {1}.

Lån nr. {2} løper videre etter sammenslåingen.

Renter: nominell rente %, rentemargin , /Fast rente: Renteavtale til ddmmåååå, nominell rente %
År/Halvår/Kvartalsvise terminer. Første rentetermin ddmmåååå. Avdragsfrie terminer: ? Avdragstid (år): xx år x mnd, med xxxx termine(r). Første avdragstermin ddmmåååå`,
  ACCUMULATEINTEREST: `Innovasjon Norge innvilger {0} utsettelse med betaling av rente for
{1}

Rentene akkumuleres og tillegges hovedstol.

Låntaker vil få tilsendt nytt/nye gjeldsbrev og pantsettelseserklæring som må returneres i undertegnet stand til Innovasjon Norge.

Et eventuelt betalingsmislighold overfor en annen kreditor i perioden vil også bli ansett som mislighold overfor Innovasjon Norge, og vil gi Innovasjon Norge rett til å si opp lånet/lånene uavhengig av dette vedtaket.`,
  REDUCEDNOMINALAMOUNT: `Innovasjon Norge nedkvitterer pant i {0} tilhørende {1}, pålydende kr {2}, tgl.dato {3}, dokumentnummer {4}:

  {5}

  `,
}

const formatPaymentPeriods = (paymentPeriods) => {
  const formattedPaymentPeriods = paymentPeriods.map((paymentPeriod) => {
    const date = formatWithLocale(paymentPeriod, "dd.MM.yyyy")
    return date
  })

  return formattedPaymentPeriods.join(", ")
}

export const formatStakeholderNames = (
  insightStakeholders,
  mortgagors,
  showIdentifiers = false
) => {
  const mortgagorIds = mortgagors?.map((m) => m.internalId) || []
  const stakeholders = insightStakeholders
    .filter((stakeholder) => mortgagorIds.includes(stakeholder.internalId))
    .map((stakeholder) => {
      if (stakeholder.customerType === "PERSON") {
        return `${stakeholder.firstName} ${stakeholder.surName} ${
          showIdentifiers ? stakeholder.ssn : ""
        }`
      } else {
        return `${stakeholder.organizationName} ${
          showIdentifiers ? stakeholder.organizationNumber : ""
        }`
      }
    })
    .join(", ")
  return stakeholders
}

export const generateGracePeriodChangeTexts = (change) => {
  return change.changeData
    .map((changeData) => {
      const { bookedBalance, availableAmount, blockedAmount } =
        changeData.agreementData.data.basicAccountInformation.accountBalance
      return `- terminen(e) ${formatPaymentPeriods(
        changeData.data.paymentPeriods
      )} for lån nr. ${
        changeData.agreementData.data.basicAccountInformation.accountNumber
      } kr. ${addThousandSeparator(
        getEngagementAmount({
          bookBalance: bookedBalance,
          availableAmount,
          blockedAmount,
        })
      )}`
    })
    .join("\n")
}

export const generateDeleteElementChangeTexts = (
  change,
  insightMappedStakeholder
) => {
  return change.changeData
    .map((changeData) => {
      const agreementData = changeData.agreementData.data
      const isRealEstateWithProperties = agreementData
        .specificCollateralAgreementInformation.realEstateProperties
        ? true
        : false
      const isMortgageDeed = changeData.agreementData.type === "MORTGAGE_DEED"
      const isKausjon = agreementData?.collateralAgreementType === "SURETYSHIP"

      const isDeletingWholeAgreement = changeData.data.deleteElement.toBeDeleted

      const { realEstateProperties } =
        agreementData?.specificCollateralAgreementInformation ?? []

      if (!isDeletingWholeAgreement) {
        return changeData.data.deleteElement.description
      }

      if (isRealEstateWithProperties) {
        return `- pant i ${formatCadastre(
          realEstateProperties
        )}, tilhørende ${formatStakeholderNames(
          insightMappedStakeholder,
          agreementData.basicCollateralAgreementInformation.mortgagors || []
        )}med pantedokument pålydende kr ${addThousandSeparator(
          agreementData.specificCollateralAgreementInformation
            .mortgageDeedDetails.faceValue
        )} tgl./reg. ${formatWithLocale(
          agreementData.specificCollateralAgreementInformation
            .mortgageDeedDetails.registeredDate,
          "dd.MM.yyyy"
        )}, dagboknummer ${
          agreementData.specificCollateralAgreementInformation
            .mortgageDeedDetails?.registrationNumber
        }.`
      } else if (isMortgageDeed) {
        return `- pant i ${agreementData.basicCollateralAgreementInformation.basicCollateralInformation.name.toLowerCase()} tilhørende ${formatStakeholderNames(
          insightMappedStakeholder,
          agreementData.basicCollateralAgreementInformation.mortgagors || []
        )}med pantedokument pålydende kr ${addThousandSeparator(
          agreementData.specificCollateralAgreementInformation
            .mortgageDeedDetails.faceValue
        )} tgl./reg. ${formatWithLocale(
          agreementData.specificCollateralAgreementInformation
            .mortgageDeedDetails.registeredDate,
          "dd.MM.yyyy"
        )}, dagboknummer ${
          agreementData.specificCollateralAgreementInformation
            .mortgageDeedDetails?.registrationNumber
        }.`
      } else if (isKausjon) {
        return `- kausjon pålydende kr ${addThousandSeparator(
          agreementData.specificCollateralAgreementInformation.suretyAmount
        )}, stilt av ${formatStakeholderNames(
          insightMappedStakeholder,
          agreementData.basicCollateralAgreementInformation.mortgagors,
          true
        )}. Øvrige sikkerheter knyttet til lånet opprettholdes.`
      } else {
        return `- pant i ${agreementData.basicCollateralAgreementInformation.basicCollateralInformation.name.toLowerCase()} tilhørende ${formatStakeholderNames(
          insightMappedStakeholder,
          agreementData.basicCollateralAgreementInformation.mortgagors || []
        )}med pantedokument pålydende kr ${addThousandSeparator(
          agreementData.specificCollateralAgreementInformation.suretyAmount
        )}`
      }
    })
    .join("\n")
}

export const generatePriorityChangesChangeTexts = (
  change,
  insightMappedStakeholder
) => {
  return change.changeData
    .map((changeData) => {
      const agreementData = changeData.agreementData.data
      const collateralType =
        agreementData.basicCollateralAgreementInformation
          .basicCollateralInformation.name

      const isRealEstateWithProperties = agreementData
        .specificCollateralAgreementInformation?.realEstateProperties
        ? true
        : false

      const { realEstateProperties } =
        agreementData?.specificCollateralAgreementInformation ?? []

      return changeData.data.priorityChanges.liens
        .map((lien) => {
          let detailOnCollateral
          if (isRealEstateWithProperties) {
            detailOnCollateral = formatCadastre(realEstateProperties)
          } else {
            detailOnCollateral = collateralType.toLowerCase()
          }
          return `- pantedokumentet pålydende kr ${addThousandSeparator(
            agreementData.specificCollateralAgreementInformation
              .mortgageDeedDetails.faceValue
          )} tgl. ${formatWithLocale(
            agreementData.specificCollateralAgreementInformation
              .mortgageDeedDetails.registeredDate,
            "dd.MM.yyyy"
          )} dbnr. ${
            agreementData.specificCollateralAgreementInformation
              .mortgageDeedDetails.registrationNumber
          } i ${detailOnCollateral} tilhørende ${formatStakeholderNames(
            insightMappedStakeholder,
            agreementData.basicCollateralAgreementInformation.mortgagors
          )}til fordel for pantedokumentet pålydende kr ${addThousandSeparator(
            lien.nominalAmount
          )} tgl. ${formatWithLocale(
            lien.registeredTime,
            "dd.MM.yyyy"
          )}, dbnr. ${lien.journalNumber} til ${
            lien.mortgagees[0].mortgageeName
          } orgnr./fnr. ${lien.mortgagees[0].mortgageeId}`
        })
        .join("\n")
    })
    .join("\n")
}

export const generateInterestFreePeriodChangeTexts = (change) => {
  return change.changeData
    .map((changeData) => {
      const { bookedBalance, availableAmount, blockedAmount } =
        changeData.agreementData.data.basicAccountInformation.accountBalance
      return `- terminen(e) ${formatPaymentPeriods(
        changeData.data.paymentPeriods
      )} for lån nr ${
        changeData.agreementData.data.basicAccountInformation.accountNumber
      } kr. ${addThousandSeparator(
        getEngagementAmount({
          bookBalance: bookedBalance,
          availableAmount,
          blockedAmount,
        })
      )} `
    })
    .join("\n")
}

export const generatePaymentDeferralChangeTexts = (change) => {
  return change.changeData
    .map((changeData) => {
      const { bookedBalance, availableAmount, blockedAmount } =
        changeData.agreementData.data.basicAccountInformation.accountBalance
      return `- terminen(e) ${formatPaymentPeriods(
        changeData.data.paymentPeriods
      )} på lån nr ${
        changeData.agreementData.data.basicAccountInformation.accountNumber
      } til rest kr ${addThousandSeparator(
        getEngagementAmount({
          bookBalance: bookedBalance,
          availableAmount,
          blockedAmount,
        })
      )} frem til ${formatWithLocale(
        changeData.data.paymentDeferral.toDate,
        "dd.MM.yyyy"
      )}.
Renter og avdrag forutsettes innbetalt uoppfordret til Innovasjon Norge ved hjelp av tilsendt terminvarsel.`
    })
    .join("\n")
}

export const generateRefinancingChangeTexts = (
  change,
  agreements,
  currentAgreementAccountNumber
) => {
  const { bookedBalance, availableAmount, blockedAmount } =
    change.changeData[0].agreementData.data.basicAccountInformation
      .accountBalance
  const originalAmount = getEngagementAmount({
    bookBalance: bookedBalance,
    availableAmount,
    blockedAmount,
  })
  let total = originalAmount
  const baseText = `Lån nr. ${currentAgreementAccountNumber} kr ${addThousandSeparator(
    originalAmount
  )},- er det overtakende lånet ved sammenslåing av: \n\n`
  let loanTexts = change.changeData
    .map((changeData) => {
      return changeData.data.refinancing.loans
        .map((loanId) => {
          const loan = agreements.find(
            (agreement) => agreement.internalId === loanId.internalId
          )
          const { bookedBalance, blockedAmount, availableAmount } =
            loan.data.basicAccountInformation.accountBalance

          const restAmount = getEngagementAmount({
            bookBalance: bookedBalance,
            availableAmount,
            blockedAmount,
          })

          total += restAmount
          return `Lån nr. ${
            loan.data.basicAccountInformation.accountNumber
          } kr. ${addThousandSeparator(restAmount)},-`
        })
        .join("\n")
    })
    .join("\n")
  loanTexts += `\nLån nr. ${currentAgreementAccountNumber} kr. ${addThousandSeparator(
    originalAmount
  )},-`
  loanTexts += `\nTil sammen kr. ${addThousandSeparator(total) || 0},-`
  return baseText + loanTexts
}

export const generateAccumulateInterestChangeTexts = (change) => {
  return change.changeData
    .map((changeData) => {
      const { bookedBalance, availableAmount, blockedAmount } =
        changeData.agreementData.data.basicAccountInformation.accountBalance
      return `- terminen(e) ${formatPaymentPeriods(
        changeData.data.paymentPeriods
      )} for lån nr ${
        changeData.agreementData.data.basicAccountInformation.accountNumber
      } kr. ${addThousandSeparator(
        getEngagementAmount({
          bookBalance: bookedBalance,
          availableAmount,
          blockedAmount,
        })
      )}`
    })
    .join("\n")
}

export const generateReducedNominalAmountTexts = (change) => {
  return change.changeData
    .map((changeData) => {
      return `- pantet nedkvitteres til kr ${addThousandSeparator(
        changeData.data.reducedNominalAmount.amount
      )}`
    })
    .join("\n")
}

const formatCadastre = (cadastre) => {
  return cadastre
    .map(
      (x) =>
        `${String(x.municipalityCode).padStart(4, "0")}-${
          x.registrationNumbers[0]
        }/${x.registrationNumbers[1]}/${x.registrationNumbers[2]}/${
          x.registrationNumbers[3]
        }`
    )
    .join(" og ")
}
