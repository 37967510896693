import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import Industry from "./Industry"

const IndustryContainer = ({ t, setEditing }) => {
  const { control, errors, setError } = useFormContext()
  return (
    <Controller
      control={control}
      name={"industry"}
      key={"industry"}
      render={({ field: { name, onBlur, onChange, value } }) => (
        <Industry
          setEditing={setEditing}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          name={name}
          errors={errors}
          setError={setError}
          t={t}
        />
      )}
    />
  )
}

export default IndustryContainer
