import axios from "axios"

/**
 * Translates any properties from insight that needs to be translated
 * @param {insightData} data
 * @param {i18n translation object} t
 * @returns translated data
 */
export const translateMapping = (data, t) => {
  if (!data?.caseOverview) return data
  data.caseOverview.category = t(data.caseOverview?.category)
  data.caseOverview.projectBuilding = data.caseOverview?.projectBuilding
    ?.split(",")
    ?.map((word) => t(word.trim()))
    ?.join(", ")

  return data
}

/**
 * Removes any subschemas from the insight schema that are not needed in the form
 * This is due to React-jsonschema-form rendering any subschemas which we do not want in this case
 * @param {task schema} schema
 * @returns Stripped task schema
 */
export const removeInsightSubschemas = (schema) => {
  delete schema?.properties?.caseOverview
  delete schema?.properties?.company
  delete schema?.properties?.user

  const oneOf = schema?.dependencies?.approvalStatus?.oneOf || []
  oneOf?.forEach((e) => {
    delete e?.properties?.caseOverview
    delete e?.properties?.company
    delete e?.properties?.trlAndBrl
    delete e?.properties?.user
    e.required = e.required?.filter(
      (e) =>
        e !== "caseOverview" &&
        e !== "company" &&
        e !== "trlAndBrl" &&
        e !== "user"
    )
  })
  return schema
}

const getSchema = async (appName, schemaName) => {
  const axiosInstance = axios.create()
  delete axiosInstance.defaults.headers.common["Authorization"]
  const data = await window.importMapOverrides.getDefaultMap()
  const appUrl = data.imports[appName]
  const matches = appUrl.match(/\/([\d.]+)\//)
  if (matches && matches[1]) {
    const version = matches[1]
    const url = `/cdn/${appName}/${version}/schemas/${schemaName}.schema.json`
    const { data: schema } = await axiosInstance.get(url)
    return { url, schema }
  }
}

export const getInputSchema = async (appName) => getSchema(appName, "input")
export const getOutputSchema = async (appName) => getSchema(appName, "output")
