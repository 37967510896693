import React, { useState } from "react"
import { RadioButton } from "@stacc/flow-ui-components"
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components"
import styled from "styled-components"
import { FontStyles } from "@stacc/flow-ui-components"
import { withTranslation } from "react-i18next"
import Signer from "../components/selectSigners/Signer"
import SelectedPerson from "../components/selectSigners/SelectedPerson"
import Layout, { Context } from "../components/common/Layout"
import AddSigner from "../components/selectSigners/AddSigner"
import ReactForm from "../components/common/ReactForm"

const ActiveSignRule = ({ rule, t, updateSelectedRule }) => {
  if (rule == null) return <SignersInformationList></SignersInformationList>

  const isOther = rule.kode === "OTHER"

  return (
    <SignersInformationList>
      <LabelSpan>{t("signers")}</LabelSpan>
      {rule?.personRolleKombinasjon?.map((person, index) => (
        <SelectedPerson
          key={`${rule.kode}_${index}`}
          signRule={rule}
          index={index}
          updateSelectedRule={updateSelectedRule}
          person={person}
          isOther={isOther}
          t={t}
        />
      ))}
      {isOther && (
        <AddSigner
          signRule={rule}
          updateSelectedRule={updateSelectedRule}
          t={t}
        />
      )}
    </SignersInformationList>
  )
}

const SignRuleOption = ({ rule, selectedRuleIndex, setSelectedRuleIndex }) => {
  const isChecked = () => {
    return selectedRuleIndex === rule.index
  }

  return (
    <>
      <RadioButtonWrapper>
        <RadioButton
          name="signRule"
          id={`signRule${rule.index}`}
          checked={isChecked()}
          label={rule.tekstforklaring}
          value={rule.kode}
          onChange={() => setSelectedRuleIndex(rule.index)}
        />
      </RadioButtonWrapper>
      <SignerContentDiv>
        {rule.personRolleKombinasjon.map((person, index) => (
          <Signer
            key={`${rule.index}-${index}`}
            signer={person}
            isBold={isChecked()}
          />
        ))}
      </SignerContentDiv>
    </>
  )
}

const SignRuleOptions = ({
  rules,
  header,
  selectedRuleIndex,
  setSelectedRuleIndex,
}) => {
  if (!rules || rules.length === 0) return <></>

  return (
    <>
      <LabelSpan>{header}</LabelSpan>
      {rules.map((rule, index) => (
        <SignRuleOption
          key={`${rule.index}-${index}`}
          rule={rule}
          selectedRuleIndex={selectedRuleIndex}
          setSelectedRuleIndex={setSelectedRuleIndex}
        />
      ))}
    </>
  )
}

const SelectSigners = ({ flow, task, t, schema, save, complete }) => {
  const delivery = flow?.data?.deliveries[task?.variables?.delivery?.id]
  const signingRules = delivery?.signing?.current?.signingRules
  const { combinations: rawRules, roles } = signingRules

  let otherRule = {
    index: rawRules.length,
    kode: "OTHER",
    tekstforklaring: "Egendefinert",
    personRolleKombinasjon: roles,
  }

  // This is needed for backwards compatibility with saved data from before rewrite, can probably be removed in the future
  let oldSavedRuleIndex
  if (task?.data?.kode) {
    // Set index and update rule data
    if (task?.data?.kode === "OTHER") {
      oldSavedRuleIndex = rawRules.length

      otherRule = {
        index: rawRules.length,
        ...task.data,
      }
    } else {
      oldSavedRuleIndex = rawRules.findIndex(
        (rule) => rule.kode === task.data.kode
      )

      rawRules[oldSavedRuleIndex].personRolleKombinasjon =
        task.data.personRolleKombinasjon
    }
  }

  const [isProcessing, setProcessing] = useState(false)
  const [selectedRuleIndex, setSelectedRuleIndex] = useState(
    task?.data?.selectedRuleIndex ?? oldSavedRuleIndex ?? 0
  )

  // set rules, either from old ones saved or from flow data + otherRule
  const [rules, setRules] = useState(
    task?.data?.rules ?? [
      ...rawRules.map((rule, index) => ({
        index: index,
        ...rule,
      })),
      otherRule,
    ]
  )

  const selectedRule = rules[selectedRuleIndex]
  const standardRules = rules.filter((rule) => rule.type === "signatur")
  const prokuraRules = rules.filter((rule) => rule.type === "prokura")

  const signRuleCategories = [
    { rules: standardRules, header: t("signingrules") },
    { rules: prokuraRules, header: t("prokura") },
    { rules: [otherRule], header: t("other-signrules") },
  ]

  // NOT processing AND there is no relevant invalid person
  const canSave =
    !isProcessing &&
    !selectedRule?.personRolleKombinasjon?.some(
      (person) =>
        !person?.isValid && (selectedRule?.kode !== "OTHER" || person?.selected)
    )

  const mapSaveData = () => ({
    rules,
    selectedRuleIndex,
    comment: task?.data?.comment,
  })

  const mapCompleteData = () => {
    let result = {
      ...selectedRule,
      roller: selectedRule?.personRolleKombinasjon?.filter(
        (signer) => signer.selected || selectedRule.kode !== "OTHER"
      ),
    }
    delete result?.index
    delete result?.personRolleKombinasjon
    for (let role of result?.roller) {
      delete role?.isValid
    }
    delete result?.setPerson
    return result
  }

  const handleComplete = (e) => {
    e.preventDefault()
    setProcessing(true)
    complete(
      mapCompleteData(),
      () => setProcessing(false),
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }
  const handleSave = (e) => {
    e.preventDefault()
    setProcessing(true)
    save(
      mapSaveData(),
      () => setProcessing(false),
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const strippedSchema = {
    ...schema,
    properties: { comment: schema.properties.comment },
  }

  return (
    <Layout forceHeight>
      <ContentWrapper>
        {signRuleCategories.map((category, index) => (
          <SignRuleOptions
            key={index}
            rules={category.rules}
            header={category.header}
            selectedRuleIndex={selectedRuleIndex}
            setSelectedRuleIndex={setSelectedRuleIndex}
          />
        ))}
      </ContentWrapper>
      <ActiveSignRule
        rule={selectedRule}
        t={t}
        updateSelectedRule={(rule) => {
          rules[rule.index] = { ...rule }
          setRules([...rules])
          setSelectedRuleIndex(rule.index)
        }}
      />
      <Context flow={flow} context={task.context?.applicationSummary}>
        <StyledForm>
          <ReactForm
            schema={strippedSchema}
            formData={task.data}
            disabled={isProcessing}
            onChange={(values) =>
              (task.data = {
                ...task.data,
                comment: values?.comment,
              })
            }
          >
            <></>
          </ReactForm>
          <ButtonContainer>
            <PrimaryButton
              disabled={!canSave}
              onClick={(e) => handleComplete(e)}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButton
              disabled={isProcessing}
              onClick={(e) => handleSave(e)}
            >
              {t("save")}
            </SecondaryButton>
          </ButtonContainer>
        </StyledForm>
      </Context>
    </Layout>
  )
}

const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`

const LabelSpan = styled.span`
  padding: ${({ hasHelp }) => (hasHelp ? "0.02em" : "0.2em")};
  padding-bottom: 1em;
  ${FontStyles.Small};
  width: fit-content;
  display: flex;
  align-items: center;
`

const ContentWrapper = styled.div`
  overflow-y: auto;
  padding: 20px;
  border-right: 2px solid #e4e2e2;
  height: calc(100% - 40px);
  width: 35%;
`

const SignerContentDiv = styled.div`
  padding: 5px 30px;
`

const SignersInformationList = styled.div`
  padding: 20px;
  border-right: 1px solid #e4e2e2;
  width: 100%;
  height: 100%;
`

const RadioButtonWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 10px;
`

export default withTranslation()(SelectSigners)
