import React from "react"
import SuffixedInput from "./SuffixedInput"

const PercentField = ({
  label,
  onChange,
  readonly,
  value,
  field: { id, key, name, onBlur },
}) => {
  if (typeof value === "number") {
    value = value.toFixed(2)
  }
  return (
    <SuffixedInput
      type="number"
      replaceDotWithComma={true}
      suffix="%"
      readonly={readonly}
      width={60}
      name={name}
      id={id}
      label={label}
      key={key}
      onChange={(newValue) => {
        onChange(newValue)
      }}
      value={value}
      field={{ id, key, name, onBlur }}
    />
  )
}

export default PercentField
