import React, { useState } from "react"
import styled from "styled-components"
import AsyncSelect from "react-select/async"
import { components } from "react-select"
import ErrorContext from "../../../common/Error"
import useNaceLookup from "../../../common/useNaceLookup"

const Industry = ({
  name,
  onChange: handleChange,
  t,
  setEditing,
  setError,
  errors,
}) => {
  const {
    industryValues,
    setIndustryValues: industryValuesSet,
    error,
    naceLookup,
  } = useNaceLookup()

  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <InputLabel>
          <Subtitle>
            {industryValues?.code} - {industryValues?.descriptionNorwegian}
          </Subtitle>
        </InputLabel>
      </components.SingleValue>
    )
  }
  const change = (e) => {
    handleChange(e?.value)
    industryValuesSet(e?.value)
    setEditing(false)
  }

  return (
    <div>
      <StyledSelect
        isClearable
        escapeClearsValue={true}
        cacheOptions
        isSearchable
        maxMenuHeight={200}
        id={name}
        placeholder={t("nace-select-placeholder")}
        loadOptions={naceLookup}
        name={name}
        searchable={true}
        noOptionsMessage={() => t("industry-no-options-message")}
        onChange={change}
        defaultOptions={true}
        components={{ SingleValue: SingleValue }}
      />
      {errors?.search?.message && (
        <ErrorContext t={t} error={errors?.search?.message} />
      )}
    </div>
  )
}
const InputLabel = styled.div`
  display: flex;
  flex-direction: column;
`
const Subtitle = styled.p`
  color: var(--flow-color-grey1);
  font-size: small;
  line-height: normal;
`
const StyledSelect = styled(AsyncSelect)`
  min-height: 25px;
  max-height: 25px;
  min-width: 200px;
  font-size: small;

  span {
    background-color: inherit;
  }

  svg {
    max-height: 70%;
    max-width: 70%;
    padding: 0 0 0 1px;
    margin: 0;
  }

  div[class*="control"] {
    border-radius: 5px;

    border: solid 1px;
    border-color: inherit;
    outline: none;
    box-shadow: none;
    color: inherit;
    min-height: inherit;
    max-height: inherit;
    padding: 1px 0 0 4px;
    margin: 0;

    div {
      min-height: inherit;
      max-height: inherit;
      padding: 0;
      margin: 0;
    }

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: inherit;
    }
  }

  div[class*="ValueContainer"] {
    text-transform: none;
    border: none;
  }

  div[class*="menu"] {
    max-height: 400px;
  }
`
export default Industry
