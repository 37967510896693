import React, { useCallback, useEffect, useRef } from "react"
import { addThousandSeparator } from "../../../../../util/addThousandSeparator"

// Which types are allowed to set range
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/setSelectionRange
const allowedSelectionRangeTypes = ["text", "search", "URL", "tel", "password"]

export const useInputMask = ({
  ref,
  locale,
  type,
  mode,
  debugLevel = 0,
  onChange,
  controlledValue,
  defaultValue,
  ...otherProps
}) => {
  const caretPosition = useRef(0)
  const timeout = useRef(null)
  const [value, valueSet] = React.useState({
    value: controlledValue ?? defaultValue,
  })

  const updateCaret = useCallback(
    (val) => {
      if (!allowedSelectionRangeTypes.includes(type)) {
        return
      }
      if (ref && ref.current) {
        const { selectionStart, selectionEnd } = ref.current
        const caret = val || Math.max(selectionStart, selectionEnd)
        caretPosition.current = caret
      }
    },
    [type]
  )

  useEffect(() => {
    if (!mode) {
      return
    }
    if (!allowedSelectionRangeTypes.includes(type)) {
      return
    }
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      if (document && document.activeElement === ref.current && ref.current) {
        const { selectionStart } = ref.current

        if (selectionStart !== caretPosition.current) {
          ref.current.setSelectionRange(
            caretPosition.current,
            caretPosition.current
          )
        }
      }
    })

    return () => {
      clearTimeout(timeout.current)
    }
  })

  useEffect(() => {
    if (!mode) {
      return
    }
    updateCaret(1)
  }, [])

  useEffect(() => {
    if (!mode) {
      return
    }
    if (controlledValue !== value?.rawValue) {
      let value = controlledValue
      if (value === null || value === undefined) {
        value = ""
      }
      handleChange({ target: { value } })
    }
  }, [controlledValue])

  useEffect(() => {
    if (defaultValue !== value?.rawValue) {
      let value = defaultValue
      if (value === null || value === undefined) {
        value = ""
      }
      handleChange({ target: { value } })
    }
  }, [defaultValue])

  const createMask = resolveMask(mode, null)
  const mask = React.useRef(
    createMask({
      locale,
    })
  )

  React.useEffect(() => {
    if (!mode) {
      return
    }
    const createMask = resolveMask(mode, null)
    mask.current = createMask({ locale })
  }, [mode, locale])

  React.useEffect(() => {
    if (!mode) {
      return
    }
    if (locale) {
      handleChange({ target: value })
    }
  }, [locale])

  const handleChange = (e) => {
    if (!mode) {
      return
    }
    const val = mask.current(e.target.value)
    if (value?.value) {
      const diff = val.value.length - value.value.length
      updateCaret(Math.abs(diff) > 1 ? caretPosition.current + diff : null)
    } else {
      updateCaret(null)
    }
    if (val?.value !== value?.value) {
      onChange && onChange(val)
      valueSet({
        ...val,
      })
    }
  }

  const handleKeyUp = (e) => {
    switch (e.key) {
      case "ArrowLeft":
      case "ArrowRight":
        updateCaret(null)
        break
      case "ArrowUp":
        updateCaret(0)
        break
      case "ArrowDown":
        updateCaret(value?.value?.length ?? caretPosition.current)
        break
      default:
        break
    }
  }

  if (!mode) {
    let value = controlledValue
    if (value === null) {
      value = ""
    }
    return {
      ...(value !== undefined && { value }),
      onChange,
      defaultValue,
      ...otherProps,
    }
  }

  return {
    type,
    ...(!mode && { value: controlledValue }),
    ...(mode && {
      onKeyUp: handleKeyUp,
      onChange: handleChange,
      value: value?.value,
    }),
    defaultValue,
    ...otherProps,
  }
}

const baseMask = (options) => {
  const delimiter = resolveDelimiterString(options)
  const config = {
    name: "Base mask",
    prepareConfig: (config, input) => config,
    prepare: (input, config) => {
      if (!input) {
        return ""
      }

      return pipe(input?.toString() ?? "")(
        (_) => (config?.delimiter && _ ? _.replace(config?.delimiter, "") : _),
        (_) => (config?.pattern ? _.replace(config?.pattern, "") : _),
        (_) =>
          config?.maxLength && _.length > config?.maxLength
            ? _.substring(0, Math.min(config.maxLength, _.length))
            : _
      )
    },
    format: (input, { mask }) => (mask ? maskFormat(input, mask) : input),
    settle: (value, config, rawValue) => ({ value }),
    mask: options?.mask ?? resolveBlocks(options?.blocks) ?? "",
    ...options,
    delimiter, // Keep delimiter last since we resolve it at the top.
  }

  return (input) => {
    const configPrepared = config.prepareConfig(config, input)
    const { prepare, format, settle } = configPrepared

    const rawValue = prepare(input, configPrepared)
    const masked = format(rawValue, configPrepared)
    // const value = settle(masked, configPrepared, rawValue)
    const value = {
      value: addThousandSeparator(masked),
    }

    return {
      ...value,
      rawValue,
    }
  }
}

const resolveMask = (mode, mask) => {
  if (mode === "currency") {
    return currencyMask
  } else if (mode === "creditcard") {
    return creditCardMask
  }
  return baseMask
}

const currencyMask = ({ locale = "no-NB", currency = "NOK", ...options }) => {
  const currencyConfig = {
    locale: locale?.currency?.locale ?? locale,
    currency: locale?.currency?.name ?? currency,
  }

  const config = {
    ...options,
    format: (input) =>
      input ? formatMoney(input, { ...currencyConfig, ...options }) : "",
    pattern: /[^0-9]+/gi,
    name: "Currency mask",
  }

  return baseMask(config)
}

const creditCardMask = ({ locale = "no-NB", currency = "NOK", ...options }) => {
  const currencyConfig = {
    locale: locale?.currency?.locale ?? locale,
    currency: locale?.currency?.name ?? currency,
  }

  const config = {
    ...options,
    format: (input) =>
      input ? formatMoney(input, { ...currencyConfig, ...options }) : "",
    pattern: /[^0-9]+/gi,
    name: "Credit card mask",
  }

  return baseMask(config)
}

const STYLE = {
  decimal: "decimal",
  currency: "currency",
  percent: "percent",
  unit: "unit",
}

/**
 * Long: kilometres. short: km, narrow: km
 * @type {{short: string, narrow: string, long: string}}
 */
const UNIT_DISPLAY = {
  long: "long",
  short: "short",
  narrow: "narrow",
}

/**
 * Symbol €, code USD, name Dollar
 * @type {{symbol: string, code: string, name: string}}
 */
const CURRENCY_DISPLAY = {
  symbol: "symbol",
  code: "code",
  name: "name",
}

const formatMoney = (value, options = {}) => {
  return formatNumber(value, {
    locale: "no",
    suffix: null,
    ...(options.currencyDisplay && { style: STYLE.currency }),
    ...options,
  })
}

const defaults = {
  style: STYLE.decimal,
  currencyDisplay: CURRENCY_DISPLAY.symbol,
  suffix: null,
  unitDisplay: UNIT_DISPLAY.short,
  currency: "NOK", // http://www.currency-iso.org/en/home/tables/table-a1.html,
  useGrouping: true,
  locale: "no",
  unit: undefined,
  minimumFractionDigits: 0,
}

export const formatNumber = (value, opts) => {
  const options = {
    ...defaults,
    ...opts,
  }
  const { locale, style } = options
  if (style === STYLE.unit) {
    throw new Error("Unit is not yet supported")
  }
  try {
    const formatter = new Intl.NumberFormat(locale, options)
    const formattedValue = formatter.format(value)
    return `${formattedValue}${options.suffix ?? ""}`
  } catch (e) {
    return value
  }
}

const resolveDelimiterString = ({ delimiter = null }) => {
  if (!delimiter) {
    return null
  }
  if (typeof delimiter === "string") {
    switch (delimiter) {
      case ".":
        return /\./gi
      default:
        return /\s/gi
    }
  }
  return delimiter
}

const pipe =
  (value) =>
  (...functions) =>
    functions.reduce(
      (currentValue, currentFunction) => currentFunction(currentValue),
      value
    )

const mapBlocks = (block) =>
  new Array(block)
    .fill("X")
    .map((i) => "X")
    .join("")

const resolveBlocks = (blocks) => blocks?.map(mapBlocks).join(" ").trimEnd()

const maskFormat = (value, mask = "XXX XX XXX") => {
  const stringValue = "" + value
  let returnValue = ""
  let indexString = 0
  for (
    let indexMask = 0;
    indexMask < mask.length && indexString < stringValue.length;
    indexMask++
  ) {
    returnValue +=
      mask.charAt(indexMask) === "X"
        ? stringValue.charAt(indexString++)
        : mask.charAt(indexMask)
  }
  return returnValue
}
