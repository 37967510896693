import React from "react"
import styled from "styled-components"
import { ProjectColors } from "../../common/style/ProjectColors"
import { addThousandSeparator } from "../../../util/addThousandSeparator"

/**
 * @typedef {object} OverviewLineProps
 * @property {string} [icon]
 * @property {string} product
 * @property {number} amount
 * @property {string} type
 * @property {string} [activity]
 * @property {string} [subsidyRegime]
 * @property {object[]} [activityAndSubsidyRegime]
 * @property {boolean} [header]
 * @property {import("react-i18next").TFunction} [t]
 * @property {object[]} [subproducts]
 */

/** @param {OverviewLineProps} props */
const OverviewLine = ({
  icon,
  product,
  amount,
  type,
  activity,
  subsidyRegime,
  activityAndSubsidyRegime = [],
  header,
  t,
  subproducts,
}) => {
  return (
    <>
      {header ? (
        <ActivityLineItem header={true}>
          <p>{product}</p>
          <p>{amount} </p>
          <p>{type}</p>
          <p>{activity}</p>
          <p>{subsidyRegime}</p>
        </ActivityLineItem>
      ) : (
        <>
          <ActivityLineItem lines={activityAndSubsidyRegime?.length || 1}>
            <>
              <Title>
                {icon}
                <p>{t(product)}</p>
              </Title>
              <p>{addThousandSeparator(amount)}</p>
              <ppreviousSubProducts>{t(type)}</ppreviousSubProducts>{" "}
              {/* ppreviousSubProducts should this be here???? */}
              {activityAndSubsidyRegime?.length >= 0 && (
                <>
                  <p>{activityAndSubsidyRegime[0]?.activity + 1}</p>
                  <p>{t(activityAndSubsidyRegime[0]?.subsidyRegime)}</p>
                </>
              )}
            </>
            {subproducts?.length > 0 &&
              subproducts?.map((item, index) => {
                return (
                  <>
                    <Title padding={"0 0 0 1em"}>
                      {icon}
                      <p>{item.displayNameShort}</p>
                    </Title>
                    <p>{addThousandSeparator(item.amount)}</p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </>
                )
              })}
          </ActivityLineItem>
          {activityAndSubsidyRegime?.length > 1 &&
            activityAndSubsidyRegime?.map((item, index) => {
              if (index > 0) {
                return (
                  <SubItem
                    key={`${item}${index}`}
                    num={activity?.indexOf(item)}
                  >
                    <div>
                      <p>{item?.activity + 1}</p>
                      <p>{t(item?.subsidyRegime)}</p>
                    </div>
                  </SubItem>
                )
              }
            })}
        </>
      )}
    </>
  )
}
export default OverviewLine

const Title = styled.div.attrs((props) => ({
  padding: props.padding,
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => props.padding};
  svg {
    display: flex;
    align-self: flex-start;
    padding-right: 5px;
  }
`

const AdditionalLine = styled.li.attrs((props) => ({
  header: props.header,
}))`
  margin: 4px;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  display: grid;
  background-color: ${(props) =>
    !props.header ? `${ProjectColors.Slate95}` : `var(--flow-color-snow)`};
  grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr;
`

const ActivityLineItem = styled.li.attrs((props) => ({
  header: props.header ?? false,
}))`
  margin: 4px;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  display: grid;
  background-color: ${(props) =>
    !props.header ? `${ProjectColors.Slate95}` : `var(--flow-color-snow)`};
  grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr;
`
const SubItem = styled.div`
  margin: 0 4px 1px 4px;
  padding: 0 10px 1px 10px;
  grid-template-columns: 3.3fr 1fr 1fr;
  display: grid;

  div {
    display: grid;
    grid-column-start: 2;
    grid-column-end: span 3;
    grid-template-columns: 1fr 1fr;
    align-items: start;

    p {
      :first-child {
        grid-column-start: 1;
      }

      :last-child {
        grid-column-start: 2;
      }
    }
  }
`
