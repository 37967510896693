import { useState } from "react"
import { useCallback, useEffect } from "react"
import crc32 from "../customer/util/crc32"

// This is better than using lodash's isEqual since we don't have to store the old props
const getPropsHash = (p) => crc32(JSON.stringify(p))

export const useInsightApp = (name, props) => {
  const [app, appSet] = useState()
  const [page, pageSet] = useState(null)
  const [propsHash, setPropsHash] = useState(getPropsHash(props))
  const mountApp = useCallback(
    (name, props) => {
      if (!window?.System?.import) return
      window.System.import(name).then((a) => {
        appSet(a)
        a.mount({
          name,
          ...props,
        })
      })
    },
    [window?.System?.import]
  )

  if (page !== props?.moduleProps?.context) {
    pageSet(props?.moduleProps?.context)
  }

  useEffect(() => {
    const newPropsHash = getPropsHash(props)

    // If the props hash hasn't changed, don't send update to Insight.
    if (propsHash === newPropsHash) return

    // This log can be removed but it's useful for anyone
    // debugging insight components.
    console.log("~PROPS HASH", newPropsHash)

    app?.update(props)
    setPropsHash(newPropsHash)
  }, [app, props, page])

  useEffect(() => {
    if (name && props && !app) {
      mountApp(name, props)
    }

    return () => {
      app?.unmount({ name })
    }
  }, [app])

  return app
}
