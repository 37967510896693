import React, {
  useContext,
  useRef,
  useCallback,
  createContext,
  useEffect,
} from "react"
import { useSyncExternalStore } from "use-sync-external-store/shim"
import {
  createAgreementDraft,
  getAgreementProductSchema,
} from "../../../util/insightAgreementUtils"
import getCustomFields from "./getCustomFields"

// DraftVersion
// This is the current version of the schemas/draft from Insight.
// If an agreement is missing the version field or has a lower version
// we will fetch the latest one from Insight and change out the one on the agreement.
// When Insight deploys a new version of their schemas, we will need to bump this version
const draftVersion = "1"

const getCustomFieldsForDraft = async (draft, productId, productClass) => {
  const schema = await getAgreementProductSchema(productId, productClass)
  const customFields = getCustomFields(productClass, schema, { data: draft })
  return { customFields }
}

const createProductDraft = async (productId, productClass) => {
  const schema = await getAgreementProductSchema(productId, productClass)
  const draft = await createAgreementDraft(productId)
  const customFields = getCustomFields(productClass, schema, draft)
  return { draft, customFields }
}

export const useCreateAgreementStore = (initialState) => {
  const agreements = useRef(initialState || {})
  const subscribers = useRef(new Set())

  // Update agreement and signal all subscribers in store that state is changed
  // Optimization: only update relevant subscribers
  const set = useCallback((productId, agreement, setVersion = false) => {
    agreements.current[productId] = {
      isLoading: false,
      ...agreement,
      ...(setVersion && { version: draftVersion }),
    }
    subscribers.current.forEach((callback) => callback())
  }, [])

  // Update draft and custom fields in case of change of version / data
  useEffect(() => {
    Object.keys(agreements.current).forEach((productId) => {
      const {
        version,
        customFields: existingFields,
        productClass,
        isCreated,
        draft: existingDraft,
      } = agreements.current[productId]
      set(productId, {
        isLoading: true,
        isCreated,
        customFields: existingFields,
        draft: existingDraft,
        productClass,
        version,
      })
      const asyncUpdateAgreement = async () => {
        try {
          const { customFields } = await getCustomFieldsForDraft(
            existingDraft,
            productId,
            productClass
          )
          existingFields.forEach((existingField) => {
            const newField = customFields.find(
              (field) => field.variable === existingField.variable
            )
            if (!newField || newField.readonly) return
            newField.value = existingField.value
          })
          set(productId, {
            customFields,
            isCreated,
            draft: existingDraft,
            productClass,
            version,
          })
        } catch (e) {
          console.error("Could not update agreement for product", productId, e)
        }
      }
      asyncUpdateAgreement()
    })
  }, [set])

  // Get all agreements
  const getAll = useCallback(() => {
    return agreements.current
  }, [])

  // Get specific agreement or create agreement if non existant
  const get = useCallback(
    (productId, productClass) => {
      const agreement = agreements.current[productId]
      let isRunningCreate
      // If no agreement draft exists, create one
      if (
        !agreement ||
        (!agreement?.draft &&
          agreement?.isLoading !== true &&
          !isRunningCreate) ||
        (agreement?.draft === undefined &&
          agreement?.isLoading !== true &&
          !isRunningCreate)
      ) {
        isRunningCreate = true
        agreements.current[productId] = {
          isLoading: true,
        }
        const createAgreement = async () => {
          try {
            const { customFields, draft } = await createProductDraft(
              productId,
              productClass
            )
            const setVersion = true
            set(
              productId,
              {
                customFields,
                draft: draft.data,
                productClass,
              },
              setVersion
            )
            isRunningCreate = false
          } catch (e) {
            set(productId, { error: e.message })
            console.error("Error when creating agreement", e)
          }
        }
        createAgreement()
      }

      // Check if version is missing or is outdated, if so fetch and replace with latest
      if (
        (!agreement?.version &&
          agreement?.isLoading !== true &&
          !isRunningCreate) ||
        (agreement?.version !== draftVersion &&
          agreement?.isLoading !== true &&
          !isRunningCreate)
      ) {
        isRunningCreate = true
        agreements.current[productId] = {
          isLoading: true,
        }
        const fetchLatestDraft = async () => {
          try {
            const { draft } = await createProductDraft(productId, productClass)
            const setVersion = true
            set(
              productId,
              {
                customFields: agreement.customFields,
                draft: draft.data,
                productClass,
              },
              setVersion
            )
            isRunningCreate = false
          } catch (e) {
            set(productId, { error: e.message })
            console.error("Error when updating draft version", e)
          }
        }
        fetchLatestDraft()
      }

      return agreement
    },
    [set]
  )

  const reset = useCallback(
    async (productId, productClass) => {
      // Reset agreement to default state
      const { draft: existingDraft } = agreements.current[productId]
      getCustomFieldsForDraft(existingDraft, productId, productClass).then(
        ({ customFields }) => {
          set(productId, {
            customFields,
            draft: existingDraft,
            productClass,
          })
        }
      )
    },
    [set]
  )

  // Add to subscribe callback set, returns unsubscribe function
  const subscribe = useCallback((callback) => {
    subscribers.current.add(callback)
    return () => subscribers.current.delete(callback)
  }, [])

  return { getAll, get, set, subscribe, reset }
}

const AgreementStoreContext = createContext(null)

export const AgreementProvider = ({ store, children }) => {
  return (
    <AgreementStoreContext.Provider value={store}>
      {children}
    </AgreementStoreContext.Provider>
  )
}

export const useAgreement = (product) => {
  const store = useContext(AgreementStoreContext)
  if (!store) {
    throw new Error("Could not find agreement store")
  }

  const productId = product?.productId
  const productClass = product?.productClass

  const agreement = useSyncExternalStore(store.subscribe, () => {
    if (!productId) return null
    const agreement = store.get(productId, productClass)
    return agreement
  })

  return [agreement, store.set, store.reset]
}
