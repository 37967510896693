import { isValid } from "date-fns"

const formatISODate = (date) => {
  if (!date) return ""
  const parsedDate = new Date(date)
  if (!parsedDate || !isValid(parsedDate)) return ""
  return date.toISOString()
}

const formatBisnodeDateISO = (date) => {
  const { day, month, year } = date || {}
  const dateObject = new Date(year, month - 1, day)
  return formatISODate(dateObject)
}

export { formatBisnodeDateISO }
