// TODO: Add rest of mappings to existing products
/**
 * Manual mappings between old product id and new product id
 * This is for backwardscompatibility
 */
const oldProductIdMapping = {
  vekstgaranti: "vekstgaranti",
  komtilRegional: "A5F245D6-E47D-4AEF-A0B1-8FCEA6D915BA",
  startUpLoan: "C0A5F1B0-F508-4972-B77B-F51C63CFCD6A",
  komtil: "43CAD91D-19AC-4ABA-BE2A-5D5553459C49",
  matil: "9C1ECA95-D4F2-43F9-91DF-F38B025A0A93",
  matilRegional: "E2002819-FA53-46A4-B77E-50DFCB7D08B3",
  utInntBortfall: "8C308015-BFDD-4D27-BF3A-B061BC7E7417",
  mentor: "F6A5EE52-16DD-4FC6-AC59-B6E74EC73503",
  lalTr: "2F7B6847-F167-4F2E-ACA6-D3B7CFA3ACD0",
  lalBolig: "9B77C451-B15A-4ECD-AF48-07D63D9A80C5",
  brlTr: "6035BDEE-670B-4447-818F-2E907B33B251",
  lufBio: "42ABD440-8063-4D4C-9225-74A19A8A7A41",
  buGenTr: "61A01F72-AE79-4571-B20A-08B504852F1A",
  buIvtTr: "E5994C56-9B4E-4F23-BB88-D8C444B4546C",
  buGjLager: "83E500DA-C184-40F0-9D59-AA480B72603B",
}

export default oldProductIdMapping
