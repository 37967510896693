import React from "react"
import styled from "styled-components"
import Notice from "../../common/Notice"
import GridContent from "./GridContent"
import { FontStyles } from "@stacc/flow-ui-components"

/**
 * The information line component
 * @param {object} props The properties of the InformationLine component
 * @param {object[]} props.data The data to display
 * @param {string} [props.padding="1.5em"] The padding of the component
 * @param {string} [props.margin] The margin of the component
 * @param {string} [props.gridColumnGap] The column gap of the component
 * @param {string} [props.min="12em"] The minimum width of the component
 * @param {string} [props.max="1fr"] The maximum width of the component
 * @param {string} [props.backgroundColor] The background color of the component
 * @returns {JSX.Element}
 */
const InformationLine = ({
  data,
  padding = "1.5em",
  margin,
  gridColumnGap,
  min = "12em",
  max = "1fr",
  backgroundColor,
}) => {
  return (
    <Content
      padding={padding}
      margin={margin}
      gridColumnGap={gridColumnGap}
      min={min}
      max={max}
      backgroundColor={backgroundColor}
    >
      {data.map((x, i) => (
        <GridContent
          key={i}
          title={x.title}
          data={x.value}
          contentSize={FontStyles.Normal}
        />
      ))}
    </Content>
  )
}
export default InformationLine

const Content = styled(Notice)`
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};

  display: grid;
  grid-template-columns: ${({ min, max }) =>
    `repeat(auto-fill, minmax(${min}, ${max}))`};
  grid-gap: 1em;
  grid-column-gap: ${({ gridColumnGap }) => gridColumnGap || "1em"};

  background-color: ${(props) =>
    props.backgroundColor || "var(--flow-color-sea-lighter)"};
`
