import React, { useState } from "react"
import styled from "styled-components"
import ExistingCustomerRelationships from "./inputs/ExistingCustomerRelationships"
import Heading from "../../common/Heading"
import ReceivablesJuneThroughSeptember from "./inputs/ReceivablesJuneThroughSeptember"
import { ProjectColors } from "../../common/style/ProjectColors"
import ReceivablesFebruaryThroughMay from "./inputs/ReceivablesFebruaryThroughMay"
import ReceivablesJuneThroughSeptemberComment from "./inputs/ReceivablesJuneThroughSeptemberComment"
import ReceivablesFebruaryThroughMayComment from "./inputs/ReceivablesFebruaryThroughMayComment"

const FormAdditionalProduct = ({
  t,
  receivablesFebruaryThroughMayChecked,
  receivablesJuneThroughSeptemberChecked,
}) => {
  return (
    <FormContent>
      <Heading>{t("check-list")}</Heading>
      <CommentContainer>
        <ReceivablesFebruaryThroughMay t={t} />
        {receivablesFebruaryThroughMayChecked && (
          <ReceivablesFebruaryThroughMayComment t={t} />
        )}
      </CommentContainer>
      <CommentContainer>
        <ReceivablesJuneThroughSeptember t={t} />
        {receivablesJuneThroughSeptemberChecked && (
          <ReceivablesJuneThroughSeptemberComment t={t} />
        )}
      </CommentContainer>
      <ExistingCustomerRelationships t={t} />
    </FormContent>
  )
}
export default FormAdditionalProduct

const CommentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr;
  grid-row-gap: 1em;

  padding: 1em;
`

const FormContent = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin: 4px;
    padding: 10px;
    border-radius: 5px;
    background-color: ${ProjectColors.Slate95};
  }

  > h1 {
    margin: 4px;
    padding: 10px;
  }
`
