import React, { useState } from "react"
import styled from "styled-components"
import InformationLine from "../performFinancialCheck/detailsComponents/InformationLine"
import FetchCadastrePdf from "./FetchCadastrePdf"
import { Date as FlowDate } from "@stacc/flow-ui-components"
import DisplaySecurityText from "../../components/TermsAndSecurity/DisplaySecurityText"
import { addThousandSeparator } from "../../util/addThousandSeparator"
import { TextArea } from "@stacc/flow-ui-components"
import { Controller, FormProvider } from "react-hook-form"
import ErrorContext from "../../components/common/Error"
import _ from "lodash"
import { MiniTable } from "../controlPriority/TableForCheckList"

const RegisterCollateralManualComponent = ({
  schemaData,
  collateralObjects,
  t,
  oldCadastres,
  updatedNewCadastres,
  getLookupCadastrePdf,
  isLooking,
  setIsLooking,
  readOnly = false,
  headerStyle = "medium",
  securityTexts,
  agreementType,
  isRealEstate = true,
  manualCollateralSecurityTexts,
  securityTextsTwo,
  currentUser,
  agreementId,
  checkedColor,
}) => {
  return (
    <StyledTinglysings backgroundColor={checkedColor}>
      {manualCollateralSecurityTexts &&
      manualCollateralSecurityTexts?.registerCollateralManual?.agreementType ===
        "SECURITYTEXT" ? (
        <FunMargins>
          <Heading2>{t("manual-add-sectext-collateral")}</Heading2>
          <ObjectContainer>
            <SecurityTextContainer>
              <RowContainer>
                <ObjectContainer>
                  <Heading>{t("type")}</Heading>
                  <p>
                    {t(
                      manualCollateralSecurityTexts?.registerCollateralManual
                        ?.agreementType
                    )}
                  </p>
                </ObjectContainer>
                <ObjectContainer>
                  <Heading> {t("comment")}</Heading>
                  <p>
                    {
                      manualCollateralSecurityTexts?.registerCollateralManual
                        ?.checklistComment
                    }
                  </p>
                </ObjectContainer>
              </RowContainer>
            </SecurityTextContainer>
          </ObjectContainer>
          {securityTextsTwo
            ?.filter(
              (e) => e.id === manualCollateralSecurityTexts?.collateralElement
            )
            .map((securityText, securityTextIndex) => (
              <DisplaySecurityText
                t={t}
                securityText={securityText}
                securityTextIndex={securityTextIndex}
                userGenerated={true}
                isReadOnly={true}
                key={securityTextIndex}
              />
            ))}
        </FunMargins>
      ) : securityTexts?.length > 0 ? (
        //Dette gjelder sikkerhetstekst som blir lagt til manuelt
        //De skal oppføre seg som egne avtaler
        <FunMargins>
          <Heading2>{t("manual-add-sectext")}</Heading2>
          <ObjectContainer>
            <SecurityTextContainer>
              {securityTexts?.map((securityText, securityTextIndex) => (
                <DisplaySecurityText
                  t={t}
                  securityText={securityText}
                  securityTextIndex={securityTextIndex}
                  userGenerated={true}
                  isReadOnly={true}
                  key={securityTextIndex}
                />
              ))}
            </SecurityTextContainer>
            <div className="elements">
              <StyledTable>
                <MiniTable
                  t={t}
                  currentUser={currentUser}
                  agreementId={agreementId}
                  checkedColor={checkedColor}
                />
              </StyledTable>
            </div>
          </ObjectContainer>
        </FunMargins>
      ) : (
        <div className={"elements"}>
          <Heading2 fontSize={headerStyle}>
            {t("collateral-information").toUpperCase()}
          </Heading2>
          <InformationLine
            backgroundColor={checkedColor}
            margin={"0 0 1em 0"}
            padding={"1em"}
            data={schemaData}
          />

          <StyledTable>
            <MiniTable
              t={t}
              currentUser={currentUser}
              agreementId={agreementId}
              checkedColor={checkedColor}
            />
          </StyledTable>

          <ObjectContainer className={"borderAbove"}>
            <Heading2 fontSize={headerStyle}>
              {t("collateral-objects").toUpperCase()}
            </Heading2>
            {collateralObjects?.map((collateralObject, i) => (
              <CadastreWrapper>
                <SubHeadingWrapper>
                  <Heading>{t("Hjemmelshaver")}</Heading>
                  <p>
                    {collateralObject?.collateralObjectCopyRightHolder.join(
                      ", "
                    )}
                  </p>
                </SubHeadingWrapper>
                {agreementType !== "REAL_ESTATE" ? (
                  <>
                    <Heading>{t("object")}</Heading>
                    <p>{t(collateralObject?.collateralObjectCadastre)}</p>
                    <Heading>{t("value")}</Heading>
                    <p>
                      {" "}
                      {addThousandSeparator(
                        collateralObject?.collateralObjectsValue
                      )}
                    </p>
                  </>
                ) : (
                  <>
                    <FetchCadastrePdf
                      heading={t("main-cadastre")}
                      oldCadastres={oldCadastres}
                      newCadastres={updatedNewCadastres}
                      cadastreKey={collateralObject?.collateralObjectCadastre}
                      getLookupCadastrePdf={getLookupCadastrePdf}
                      isLooking={isLooking}
                      setIsLooking={setIsLooking}
                      t={t}
                      readOnly={readOnly}
                      checkedColor={checkedColor}
                    />
                    <AssociatedCadastreWrapper className={"borderAbove"}>
                      {collateralObject?.collateralAssosciatedProperties
                        ?.length > 0 ? (
                        <>
                          {collateralObject?.collateralAssosciatedProperties?.map(
                            (associatedCadastre, i) => (
                              <FetchCadastrePdf
                                heading={
                                  i < 1 ? t("associated-properties") : ""
                                }
                                oldCadastres={oldCadastres}
                                newCadastres={updatedNewCadastres}
                                cadastreKey={associatedCadastre}
                                getLookupCadastrePdf={getLookupCadastrePdf}
                                isLooking={isLooking}
                                setIsLooking={setIsLooking}
                                t={t}
                                key={i}
                                readOnly={readOnly}
                                checkedColor={checkedColor}
                              />
                            )
                          )}
                        </>
                      ) : (
                        <p>{t("no-associated-properties")}</p>
                      )}
                    </AssociatedCadastreWrapper>
                  </>
                )}
              </CadastreWrapper>
            ))}
          </ObjectContainer>
        </div>
      )}
    </StyledTinglysings>
  )
}

export default RegisterCollateralManualComponent

const SecurityTextContainer = styled.div`
  padding: 23px;
`
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5rem;
  background-color: var(--flow-color-sea-lighter);
  border-radius: 15px;
  padding: 1em;
`
const CadastreWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const AssociatedCadastreWrapper = styled.div`
  padding: 1em 0 0 0;
`
const StyledTextArea = styled(TextArea)`
  width: 100%;
  min-height: 140px;
  border-radius: 5px;
  background: white;
`
const ObjectContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const Heading = styled.div`
  font-size: small;
  color: var(--flow-color-grey1);
  padding-bottom: 0.5em;
`
const Heading2 = styled.div`
  font-size: ${(props) => props.fontSize};
  color: black;
  padding-bottom: 0.5em;
  padding-left: 10px;
`
const FunMargins = styled.div`
  margin-top: 2rem;
`
const StyledTinglysings = styled.div`
  display: flex;
  flex-direction: column;

  .elements {
    background-color: ${(props) => props.backgroundColor || Colors.SeaLighter};
    border-radius: 15px;
    padding-top: 1em;
  }
  .borderAbove {
    padding-top: 1em;
    border-top: solid 3px;
    border-color: white;
  }
`
const SubHeadingWrapper = styled.div`
  display: flex;
  padding: 1em 0 2em 10px;
  flex-direction: column;
  p {
    color: black;
  }
`
const StyledTable = styled.div`
  display: flex;
  width: 100%;
`
