import React, { useState } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import Layout, { Context } from "../common/Layout"
import ReactForm from "../common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components"
import Notice from "../common/Notice"
import { TextArea } from "@stacc/flow-ui-components"
import { FontStyles } from "@stacc/flow-ui-components"
import WarningInfoBox from "../common/WarningInfoBox"
import ErrorText from "../common/ErrorText"

const DisplayMessageTaskInfo = ({
  task,
  flow,
  t,
  schema,
  save,
  trigger,
  user,
  placeholderText,
  warningText,
  label,
  withTextArea = true,
  isMessageTask = true,
  complete,
}) => {
  const { data, defaults } = task
  const [stateData, setStateData] = useState(data || defaults)
  const [isProcessing, setProcessing] = useState(false)
  const hasComment = stateData?.comment
  const [error, setError] = useState(false)

  const handleComplete = () => {
    setProcessing(false)
    if (withTextArea && !hasComment) {
      setError(true)
    } else {
      setProcessing(true)
      complete(
        stateData,
        () => {
          setProcessing(false)
        },
        () => {
          console.error("Could not complete task")
          setProcessing(false)
        }
      )
    }
  }

  const handleTrigger = () => {
    setProcessing(false)

    if (hasComment) {
      setProcessing(true)
      trigger(
        stateData,
        () => {
          setProcessing(false)
        },
        () => {
          console.error("Could not trigger task")
          setProcessing(false)
        }
      )
    } else {
      setError(true)
    }
  }

  const handleSave = () => {
    setProcessing(true)
    save(
      stateData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }
  return (
    <Layout forceHeight>
      <MainContent>
        <Content>
          <WarningInfoBox padding="10px" text={warningText} />
        </Content>
        {withTextArea && (
          <>
            <TextWrapper>
              <LabelSpan>{label || t("internal-comment")}</LabelSpan>
              <TextAreaBox
                help={t("internal-comment-help")}
                placeholder={placeholderText || t("comment-placeholder")}
                value={stateData?.comment}
                onChange={(e) => {
                  setError(false)
                  setStateData({ ...stateData, comment: e.target.value })
                }}
                name="internal-comment"
                required
              />
            </TextWrapper>
            {error && <ErrorText error={t("required")} />}
          </>
        )}
      </MainContent>
      <Context flow={flow} context={task.context.applicationSummary}>
        <StyledForm>
          <ReactForm
            schema={schema}
            formData={stateData}
            disabled={isProcessing}
            onChange={(formData) => setStateData({ ...stateData, ...formData })}
            onSubmit={isMessageTask ? handleTrigger : handleComplete}
          >
            <ButtonContainer>
              <PrimaryButton
                type="submit"
                isLoading={isProcessing}
                disabled={isProcessing}
              >
                {t("complete")}
              </PrimaryButton>
              <SecondaryButton
                type="button"
                disabled={isProcessing}
                onClick={() => handleSave()}
              >
                {t("save")}
              </SecondaryButton>
            </ButtonContainer>
          </ReactForm>
        </StyledForm>
      </Context>
    </Layout>
  )
}
export default withTranslation()(DisplayMessageTaskInfo)
const LabelSpan = styled.div`
  ${FontStyles.Normal};
  margin-bottom: 5px;
`
const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  padding: 15px;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`

const Content = styled(Notice)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--flow-color-orange-lighter);
`
const TextWrapper = styled.div`
  margin-top: 1.5em;
  max-width: 100%;
`
const TextAreaBox = styled(TextArea)`
  width: 100%;
  height: 150px;
`
