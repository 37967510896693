import { formatBisnodeDateISO } from "../../../util/formatBisnodeDate"
import { concatIfValues, splitName } from "../../../util/splitName"

const returnCountry = (domestic) => {
  return domestic ? "Norge" : "unknown"
}
const returnCountryCode = (domestic) => {
  return domestic ? "NO" : "unknown"
}

export const mapStakeholderForInsight = (stakeholders = []) => {
  const mappedStakeholder = stakeholders?.reduce(
    (previousValue, stakeholder) => {
      const { firstName, middleName, lastName } = splitName(
        stakeholder.name,
        false
      )
      const firstAndMiddleName = concatIfValues(" ", firstName, middleName)
      let formattedStakeholder = {}
      if (stakeholder.type === "person") {
        const legalName = stakeholder?.legalName
        const dateOfBirth = stakeholder?.dateOfBirth
        const registeredAddress = stakeholder?.address
        formattedStakeholder = {
          isApplicant: !!stakeholder.isApplicant,
          internalId: stakeholder.id,
          externalId: stakeholder.ssn,
          firstName: legalName?.firstName || firstAndMiddleName,
          surName: legalName?.familyName || lastName,
          ssn: stakeholder.ssn,
          country: returnCountry(registeredAddress?.type === "DOMICILE"),
          countryCode: returnCountryCode(
            registeredAddress?.type === "DOMICILE"
          ),
          birthDate: formatBisnodeDateISO(dateOfBirth),
          addressInformation: {
            streetAddressName: registeredAddress?.streetAddress,
            streetAddressNumber: null,
            postalCode: registeredAddress?.postalCode,
            postalPlace: registeredAddress?.town,
            countryCode: returnCountryCode(
              registeredAddress?.type === "DOMICILE"
            ),
            formattedOneLineAddress: concatIfValues(
              ", ",
              registeredAddress?.streetAddress,
              registeredAddress?.postalCode,
              registeredAddress?.town
            ),
          },
          customerType: stakeholder?.type?.toUpperCase(),
          contactInformation: {
            email: stakeholder.email,
            phoneNumber: null,
          },
        }
      }
      if (stakeholder.type === "organization") {
        if (stakeholder.scope === "international") {
          formattedStakeholder = {
            isApplicant: !!stakeholder.isApplicant,
            internalId: stakeholder.id,
            externalId: null,
            organizationName: stakeholder.name,
            organizationNumber: null,
            country: stakeholder.organizationCountry,
            countryCode: null,
            additionalInformation: stakeholder.additionalInformation,
            addressInformation: {
              streetAddressName: null,
              streetAddressNumber: "",
              postalCode: null,
              postalPlace: null,
              countryCode: "unknown",
              country: stakeholder.organizationCountry,
              formattedOneLineAddress: null,
            },
            customerType: stakeholder?.type?.toUpperCase(),
            contactInformation: {
              name: stakeholder.contactPersonName,
              email: stakeholder.contactPersonEmail,
              phoneNumber: null,
            },
          }
        } else {
          const address = stakeholder?.address
          formattedStakeholder = {
            isApplicant: !!stakeholder.isApplicant,
            internalId: stakeholder.id,
            externalId: stakeholder.organizationNumber,
            organizationName: stakeholder.name,
            organizationNumber: stakeholder.organizationNumber,
            country: returnCountry(address?.countryCode === "NO"),
            countryCode: address?.countryCode,
            addressInformation: {
              streetAddressName: address?.street,
              streetAddressNumber: null,
              postalCode: address?.postalCode,
              postalPlace: address?.town,
              countryCode: address?.countryCode,
              formattedOneLineAddress:
                address?.street +
                ", " +
                address?.postalCode +
                " " +
                address?.town,
            },
            customerType: stakeholder?.type?.toUpperCase(),
            contactInformation: {
              name: stakeholder?.contactPersonName,
              email: stakeholder.contactPersonEmail,
              ...(stakeholder.contactPersonMobile && {
                phoneNumber: stakeholder.contactPersonMobile,
              }),
            },
          }
        }
      }
      previousValue.push(formattedStakeholder)
      return previousValue
    },
    []
  )
  return mappedStakeholder
}
