import { Spinner as FlowSpinner } from "@stacc/flow-ui-components"
import React from "react"
import styled from "styled-components"

/**
 *
 * @param {object} props
 * @param {string=} props.className
 * @param {JSX.Element|JSX.Element[]} props.children
 * @returns {JSX.Element}
 */
function Spinner({ className, children }) {
  return (
    <SpinnerStyle className={className}>
      <div>
        <FlowSpinner />
      </div>
      <div>{children}</div>
    </SpinnerStyle>
  )
}

const SpinnerStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin: 10px;
`

export default Spinner
