import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { DropDownValues } from "../../../common/tempData/DropDownValues"

import Heading from "../../../common/Heading"
import Select from "../MultiSelect"

const OtherCharacteristicsContainer = ({ t }) => {
  const { control, errors } = useFormContext()
  const selectOptions = DropDownValues.OtherCharacteristics.reduce(
    (prevOption, option) => {
      const { name: label, value } = option
      prevOption.push({
        label,
        value,
      })
      return prevOption
    },
    []
  )

  return (
    <Controller
      control={control}
      name={"otherCharacteristics"}
      key={"otherCharacteristics"}
      render={({ field: { name, onBlur, onChange, value } }) => (
        <div>
          <Heading>{t(name)}</Heading>
          <Select
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            name={name}
            errors={errors}
            t={t}
            selectOptions={selectOptions}
          />
        </div>
      )}
    />
  )
}

export default OtherCharacteristicsContainer
