import { useFormContext } from "react-hook-form"
import Error from "../../common/Error"
import StyledBox from "../../common/StyledBox"
import FieldValue from "./FieldValue"
import EditableField from "./EditableField"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const BankInformationItem = ({ item, field, productConfiguration }) => {
  const { watch, errors, showErrors } = useFormContext()
  let value = watch(`bankInformation.${field}`)
  const { t } = useTranslation("EvaluateBasisForSupport")
  const error = errors[`bankInformation.${field}`]?.message
  return (
    <StyledBox title={item.key} padding="0">
      <div key={value}>
        {!item.isEditable && (
          <FieldValue
            {...item}
            field={field}
            value={value}
            productConfiguration={productConfiguration}
          />
        )}
        {item.isEditable && (
          <EditableField
            {...item}
            field={field}
            value={value}
            productConfiguration={productConfiguration}
          />
        )}
        {showErrors && error && <StyledError error={error} t={t} />}
      </div>
    </StyledBox>
  )
}

const StyledError = styled(Error)`
  margin-right: 5px;
`

export default BankInformationItem
