import { Controller, useFormContext } from "react-hook-form"
import React from "react"
import Dropdown from "../../../../common/inputs/Dropdown"
import Error from "../../../../common/Error"
import styled from "styled-components"

const Product = ({ dropdownValues, name, t }) => {
  const { control, errors, showErrors } = useFormContext()
  const error = errors[`${name}.product`]?.message

  return (
    <Content>
      <Controller
        control={control}
        name={`${name}.product`}
        key={`${name}.product`}
        render={({ field: { id, key, name, onBlur, onChange, value } }) => (
          <Dropdown>
            <select
              name={name}
              id={key}
              key={key}
              onChange={(event) => onChange(event.target.value)}
              value={value}
            >
              {dropdownValues.map((optionItem) => {
                return (
                  <option
                    key={`${name}${optionItem?.value}`}
                    value={optionItem?.value}
                  >
                    {optionItem?.name}
                  </option>
                )
              })}
            </select>
          </Dropdown>
        )}
      />
      {false && error && <Error error={error} t={t} />}
    </Content>
  )
}
export default Product

const Content = styled.div`
  display: flex;
  flex-direction: column;
`
