export const projectParticipantsNO = {
  ops: [
    {
      insert:
        "For prosjekter der søker skal ha med andre prosjektdeltakere må dette fylles ut i fast felt.\n",
      attributes: { bold: true },
    },
    {
      insert:
        "Prosjektsamarbeidet må vurderes dersom det er flere parter involvert:\n",
      attributes: { bold: true },
    },
    {
      attributes: {
        list: "bullet",
        bold: true,
      },
      insert: "Partenes økonomi\n",
    },
    {
      attributes: {
        list: "bullet",
        bold: true,
      },
      insert: "Gjennomføringsevne\n",
    },
    {
      attributes: {
        list: "bullet",
        bold: true,
      },
      insert: "Hvordan partenes kompetanse utfyller hverandre.\n",
    },
    {
      insert: "INs evt. erfaring med prosjektdeltakere må vurderes",
      attributes: { bold: true },
    },
  ],
}
export const projectParticipantsEN = {
  ops: [
    {
      insert:
        "For projects where the applicant is to include other project participants, this must be filled out in the designated field.\n",
      attributes: { bold: true },
    },
    {
      insert:
        "The project collaboration must be assessed if multiple parties are involved:\n",
      attributes: { bold: true },
    },
    {
      attributes: {
        list: "bullet",
        bold: true,
      },
      insert: "The parties' finances\n",
    },
    {
      attributes: {
        list: "bullet",
        bold: true,
      },
      insert: "Follow-through capability\n",
    },
    {
      attributes: {
        list: "bullet",
        bold: true,
      },
      insert: "How the parties' expertise complements each other.\n",
    },
    {
      insert:
        "IN's relevant experience with project participants must be assessed",
      attributes: { bold: true },
    },
  ],
}
