import React from "react"
import styled from "styled-components"
import { hasData } from "../../../util/returnValue"
import Item from "./Item"

const DisplayPopulationRegistryInfo = ({
  t,
  populationRegistryInfo = {},
  displayNone = true,
}) => {
  if (displayNone || hasData(populationRegistryInfo)) {
    if (!populationRegistryInfo.error) return <></>
  }
  return (
    <DisplayPopulationRegistryInfoWrapper>
      <Item
        className={"top-section"}
        data={t("no-population-registry-data")}
        dataStyling={{ color: "var(--flow-color-ferrari)" }}
      />
    </DisplayPopulationRegistryInfoWrapper>
  )
}
export default DisplayPopulationRegistryInfo

const DisplayPopulationRegistryInfoWrapper = styled.div``
