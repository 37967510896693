import React from "react"
import styled from "styled-components"

const LineList = ({ children }) => {
  return <StyledList>{children}</StyledList>
}
export default LineList

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`
