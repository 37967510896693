import { useEffect, useRef } from "react"

// React a create hook that checks if the user clicked outside a component
// and calls the callback function if so.

export default function useClickOutside(callback) {
  const ref = useRef()
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [callback, ref])
  return ref
}
