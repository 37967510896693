import React from "react"
import GridContent from "./GridContent"

const Shortening = ({ t, text, props }) => {
  return (
    <GridContent title={t("shortening")} data={text} contentProps={props} />
  )
}

export default Shortening
