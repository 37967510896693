import React, { useState, useEffect, forwardRef, useRef } from "react"
import { ProjectColors } from "../../style/ProjectColors"
import styled from "styled-components"
import { useInputMask } from "./hooks/useInputMask"
import { useCombinedRefs } from "./hooks/useCombinedRefs"

/**
 * @typedef {object} BentoInputProps
 * @property {string} [className]
 * @property {string} id
 * @property {string} [label]
 * @property {string} [helpText]
 * @property {string} [placeholder]
 * @property {string} [defaultValue]
 * @property {function} onChange
 * @property {function} [onBlur]
 * @property {string} [mode]
 * @property {number} [level]
 * @property {string | number} value
 * @property {string} [locale]
 * @property {number} [maxLength]
 * @property {RegExp} [pattern]
 * @property {string | function | string[] | object} [variant]
 * @property {string} [inputMode]
 * @property {string} [autoComplete]
 * @property {number[]} [blocks]
 * @property {string} [minLength]
 * @property {string} [type]
 */

/**
 * @param {BentoInputProps} props
 * @param {React.Ref<HTMLInputElement>} ref
 * @returns {JSX.Element}
 */
const BentoInput = forwardRef(
  /** @param {BentoInputProps} props */
  (
    {
      className,
      variant,
      id,
      label,
      helpText,
      placeholder,
      defaultValue,
      onChange,
      onBlur,
      mode,
      value,
      level,
      locale,
      type,
      ...props
    },
    ref
  ) => {
    const [showHelp, showHelpSet] = useState(false)

    const innerRef = useRef(null)
    const inputRef = useCombinedRefs(ref, innerRef)

    useEffect(() => {
      if (defaultValue === undefined || !innerRef.current || mode) {
        return
      }
      if (defaultValue !== innerRef?.current?.value) {
        innerRef.current.value = defaultValue
      }
    }, [defaultValue, innerRef])

    if (mode) {
      return (
        <MaskedInput
          ref={ref}
          className={className}
          variant={variant}
          id={id}
          label={label}
          helpText={helpText}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
          mode={mode}
          value={value}
          level={level}
          locale={locale}
          type={type}
          {...(value || (defaultValue && { value: value || defaultValue }))}
          {...props}
        />
      )
    }

    return (
      <InputWrapper className={className} variant={variant}>
        {label && (
          <InputLabel variant={variant} htmlFor={id}>
            {label}
            {helpText && (
              <NoWrapSpan>
                &nbsp;
                <HelpIcon onClick={() => showHelpSet(!showHelp)} />
              </NoWrapSpan>
            )}
          </InputLabel>
        )}
        {helpText && <HelpText isVisible={showHelp}>{helpText}</HelpText>}

        <StyledBentoInput
          ref={inputRef}
          className={className}
          variant={variant}
          id={id}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
          value={value}
          type={type}
          {...props}
        />
      </InputWrapper>
    )
  }
)

const MaskedInput = forwardRef(
  /** @param {BentoInputProps} props */
  (
    {
      className,
      variant,
      id,
      label,
      helpText,
      placeholder,
      defaultValue,
      onChange,
      mode,
      value,
      level,
      locale,
      type,
      ...props
    },
    ref
  ) => {
    const [showHelp, showHelpSet] = useState(false)

    const innerRef = useRef(null)
    const inputRef = useCombinedRefs(ref, innerRef)

    const contextLocale = ""

    const inputProps = useInputMask({
      ref: inputRef,
      locale: locale ?? contextLocale,
      type,
      mode,
      debugLevel: level,
      defaultValue,
      controlledValue: value,
      onChange,
      ...props,
    })

    return (
      <InputWrapper className={className} variant={variant}>
        {label && (
          <InputLabel variant={variant} htmlFor={id}>
            {label}
            {helpText && (
              <NoWrapSpan>
                &nbsp;
                <HelpIcon onClick={() => showHelpSet(!showHelp)} />
              </NoWrapSpan>
            )}
          </InputLabel>
        )}
        {helpText && <HelpText isVisible={showHelp}>{helpText}</HelpText>}

        <StyledBentoInput
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          variant={variant}
          ref={inputRef}
          defaultValue={defaultValue}
          type={type}
          {...inputProps}
        />
      </InputWrapper>
    )
  }
)

const StyledBentoInput = styled.input`
  border: solid 1px ${ProjectColors.InputBorder};

  display: block;
  width: 100%;
  margin: 0 auto;
  border: solid 1px #dad9d8;
  border-radius: 5px;
  padding: 3px 4px 3px 5px;
  transition: border-color 0.2s ease-out;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: textfield;

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const InputWrapper = styled.div.attrs({ variant: "default" })`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
`
const InputLabel = styled.label`
  font-size: 1em;
  margin-bottom: 0.5em;
`
const HelpText = styled.p.attrs({ isVisible: false })`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  font-size: 0.8em;
  color: ${ProjectColors.HelpText};
`
const NoWrapSpan = styled.span`
  white-space: nowrap;
`

const HelpIcon = styled.i`
  cursor: pointer;
  font-size: 1em;
  color: ${ProjectColors.HelpIcon};
`

export default BentoInput
