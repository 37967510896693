import { Icons } from "@stacc/flow-ui-components"
import React from "react"

const LoanIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 15.5H4.5C4.22387 15.5 4 15.7239 4 16V17.5C4 18.813 6.26367 19.5 8.5 19.5C10.7363 19.5 13 18.813 13 17.5V16C13 15.7239 12.7761 15.5 12.5 15.5Z"
      fill="#D8BC48"
    />
    <path
      d="M8.5 14C6.26367 14 4 14.687 4 16C4 17.313 6.26367 18 8.5 18C10.7363 18 13 17.313 13 16C13 14.687 10.7363 14 8.5 14Z"
      fill="#EFD358"
    />
    <path
      d="M12.5 13H4.5C4.22387 13 4 13.2239 4 13.5V15C4 16.313 6.26367 17 8.5 17C10.7363 17 13 16.313 13 15V13.5C13 13.2239 12.7761 13 12.5 13Z"
      fill="#D8BC48"
    />
    <path
      d="M8.5 11.5C6.26367 11.5 4 12.187 4 13.5C4 14.813 6.26367 15.5 8.5 15.5C10.7363 15.5 13 14.813 13 13.5C13 12.187 10.7363 11.5 8.5 11.5Z"
      fill="#EFD358"
    />
    <path
      d="M12.5 10.5H4.5C4.22387 10.5 4 10.7239 4 11V12.5C4 13.813 6.26367 14.5 8.5 14.5C10.7363 14.5 13 13.813 13 12.5V11C13 10.7239 12.7761 10.5 12.5 10.5Z"
      fill="#D8BC48"
    />
    <path
      d="M8.5 9C6.26367 9 4 9.68701 4 11C4 12.313 6.26367 13 8.5 13C10.7363 13 13 12.313 13 11C13 9.68701 10.7363 9 8.5 9Z"
      fill="#EFD358"
    />
    <path
      d="M12.5 8H4.5C4.22387 8 4 8.22387 4 8.5V10C4 11.313 6.26367 12 8.5 12C10.7363 12 13 11.313 13 10V8.5C13 8.22387 12.7761 8 12.5 8Z"
      fill="#D8BC48"
    />
    <path
      d="M8.5 6.5C6.26367 6.5 4 7.18701 4 8.5C4 9.81299 6.26367 10.5 8.5 10.5C10.7363 10.5 13 9.81299 13 8.5C13 7.18701 10.7363 6.5 8.5 6.5Z"
      fill="#EFD358"
    />
    <path
      d="M12.5 5.5H4.5C4.22387 5.5 4 5.72387 4 6V7.5C4 8.81299 6.26367 9.5 8.5 9.5C10.7363 9.5 13 8.81299 13 7.5V6C13 5.72387 12.7761 5.5 12.5 5.5Z"
      fill="#D8BC48"
    />
    <path
      d="M8.5 4C6.26367 4 4 4.68701 4 6C4 7.31299 6.26367 8 8.5 8C10.7363 8 13 7.31299 13 6C13 4.68701 10.7363 4 8.5 4Z"
      fill="#EFD358"
    />
    <path
      d="M12.5 3H4.5C4.22387 3 4 3.22387 4 3.5V5C4 6.31299 6.26367 7 8.5 7C10.7363 7 13 6.31299 13 5V3.5C13 3.22387 12.7761 3 12.5 3Z"
      fill="#D8BC48"
    />
    <path
      d="M8.5 1.5C6.26367 1.5 4 2.18701 4 3.5C4 4.81299 6.26367 5.5 8.5 5.5C10.7363 5.5 13 4.81299 13 3.5C13 2.18701 10.7363 1.5 8.5 1.5Z"
      fill="#EFD358"
    />
    <path
      opacity="0.1"
      d="M13 3.78149C11.3001 4.19589 10 5.07852 10 6.49997V7.99997C10 8.16891 10.0208 8.33615 10.0618 8.49997C10.0208 8.66379 10 8.83102 10 8.99997V10.5C10 10.6689 10.0208 10.8361 10.0618 11C10.0208 11.1638 10 11.331 10 11.5V13C10 13.1689 10.0208 13.3361 10.0618 13.5C10.0208 13.6638 10 13.831 10 14V15.5C10 15.6689 10.0208 15.8361 10.0618 16C10.0208 16.1638 10 16.331 10 16.5V18C10 18.1689 10.0208 18.3361 10.0618 18.5C10.0208 18.6638 10 18.831 10 19V19.3925C11.6466 19.1537 13 18.5147 13 17.5V16C13 15.8213 12.9548 15.6553 12.8767 15.5C12.9548 15.3446 13 15.1786 13 15V13.5C13 13.3213 12.9548 13.1553 12.8767 13C12.9548 12.8446 13 12.6786 13 12.5V11C13 10.8213 12.9548 10.6553 12.8767 10.5C12.9548 10.3446 13 10.1786 13 9.99997V8.49997C13 8.32129 12.9548 8.1553 12.8767 7.99997C12.9548 7.84463 13 7.67865 13 7.49997V5.99997C13 5.82129 12.9548 5.6553 12.8767 5.49997C12.9548 5.34463 13 5.17865 13 4.99997V3.78149Z"
      fill="black"
    />
    <path
      d="M19.5 18.5H11.5C11.2239 18.5 11 18.7239 11 19V20.5C11 21.813 13.2637 22.5 15.5 22.5C17.7363 22.5 20 21.813 20 20.5V19C20 18.7239 19.7761 18.5 19.5 18.5Z"
      fill="#D8BC48"
    />
    <path
      d="M15.5 17C13.2637 17 11 17.687 11 19C11 20.313 13.2637 21 15.5 21C17.7363 21 20 20.313 20 19C20 17.687 17.7363 17 15.5 17Z"
      fill="#EFD358"
    />
    <path
      d="M19.5 16H11.5C11.2239 16 11 16.2239 11 16.5V18C11 19.313 13.2637 20 15.5 20C17.7363 20 20 19.313 20 18V16.5C20 16.2239 19.7761 16 19.5 16Z"
      fill="#D8BC48"
    />
    <path
      d="M15.5 14.5C13.2637 14.5 11 15.187 11 16.5C11 17.813 13.2637 18.5 15.5 18.5C17.7363 18.5 20 17.813 20 16.5C20 15.187 17.7363 14.5 15.5 14.5Z"
      fill="#EFD358"
    />
    <path
      d="M19.5 13.5H11.5C11.2239 13.5 11 13.7239 11 14V15.5C11 16.813 13.2637 17.5 15.5 17.5C17.7363 17.5 20 16.813 20 15.5V14C20 13.7239 19.7761 13.5 19.5 13.5Z"
      fill="#D8BC48"
    />
    <path
      d="M15.5 12C13.2637 12 11 12.687 11 14C11 15.313 13.2637 16 15.5 16C17.7363 16 20 15.313 20 14C20 12.687 17.7363 12 15.5 12Z"
      fill="#EFD358"
    />
    <path
      d="M19.5 11H11.5C11.2239 11 11 11.2239 11 11.5V13C11 14.313 13.2637 15 15.5 15C17.7363 15 20 14.313 20 13V11.5C20 11.2239 19.7761 11 19.5 11Z"
      fill="#D8BC48"
    />
    <path
      d="M15.5 9.5C13.2637 9.5 11 10.187 11 11.5C11 12.813 13.2637 13.5 15.5 13.5C17.7363 13.5 20 12.813 20 11.5C20 10.187 17.7363 9.5 15.5 9.5Z"
      fill="#EFD358"
    />
    <path
      d="M19.5 8.5H11.5C11.2239 8.5 11 8.72387 11 9V10.5C11 11.813 13.2637 12.5 15.5 12.5C17.7363 12.5 20 11.813 20 10.5V9C20 8.72387 19.7761 8.5 19.5 8.5Z"
      fill="#D8BC48"
    />
    <path
      d="M15.5 7C13.2637 7 11 7.68701 11 9C11 10.313 13.2637 11 15.5 11C17.7363 11 20 10.313 20 9C20 7.68701 17.7363 7 15.5 7Z"
      fill="#EFD358"
    />
    <path
      d="M19.5 6H11.5C11.2239 6 11 6.22387 11 6.5V8C11 9.31299 13.2637 10 15.5 10C17.7363 10 20 9.31299 20 8V6.5C20 6.22387 19.7761 6 19.5 6Z"
      fill="#D8BC48"
    />
    <path
      d="M15.5 4.5C13.2637 4.5 11 5.18701 11 6.5C11 7.81299 13.2637 8.5 15.5 8.5C17.7363 8.5 20 7.81299 20 6.5C20 5.18701 17.7363 4.5 15.5 4.5Z"
      fill="#EFD358"
    />
  </svg>
)

const KOMTILIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17.5C11.8674 17.5 11.7402 17.4474 11.6464 17.3536C11.5527 17.2598 11.5 17.1327 11.5 17C11.4987 15.7497 11.8585 14.5255 12.5361 13.4746C13.2136 12.4237 14.1802 11.5908 15.3197 11.0759C16.4591 10.5611 17.723 10.3861 18.9595 10.5721C20.196 10.7582 21.3525 11.2972 22.29 12.1245C22.3894 12.2122 22.45 12.3358 22.4583 12.4681C22.4667 12.6004 22.4221 12.7306 22.3345 12.83C22.3206 12.8457 22.3057 12.8606 22.29 12.8745C21.1649 13.8695 19.7288 14.4424 18.2278 14.4951C16.7267 14.5477 15.2541 14.0768 14.062 13.163C13.0596 14.1886 12.4989 15.566 12.5 17C12.5 17.1327 12.4473 17.2598 12.3536 17.3536C12.2598 17.4474 12.1326 17.5 12 17.5Z"
      fill="#38A838"
    />
    <path
      d="M11.9998 17.5C11.8672 17.5 11.74 17.4473 11.6463 17.3536C11.5525 17.2598 11.4998 17.1326 11.4998 17C11.5006 15.5663 10.9399 14.1893 9.93782 13.164C8.74577 14.0778 7.27312 14.5487 5.77206 14.496C4.27101 14.4434 2.83495 13.8705 1.70982 12.8755C1.6104 12.7878 1.54986 12.6643 1.54152 12.532C1.53318 12.3997 1.57771 12.2695 1.66532 12.17C1.67927 12.1543 1.69413 12.1395 1.70982 12.1255C2.6473 11.2982 3.80369 10.7592 5.04007 10.5732C6.27646 10.3871 7.54026 10.562 8.67969 11.0767C9.81912 11.5915 10.7857 12.4242 11.4634 13.4749C12.141 14.5257 12.5009 15.7497 12.4998 17C12.4998 17.1326 12.4471 17.2598 12.3534 17.3536C12.2596 17.4473 12.1324 17.5 11.9998 17.5Z"
      fill="#49C549"
    />
    <path
      d="M12 11C15.0376 11 17.5 8.53757 17.5 5.5C17.5 2.46243 15.0376 0 12 0C8.96243 0 6.5 2.46243 6.5 5.5C6.5 8.53757 8.96243 11 12 11Z"
      fill="#FFD764"
    />
    <path
      d="M12 10C11.11 10 10.24 9.73608 9.49994 9.24162C8.75991 8.74715 8.18314 8.04434 7.84254 7.22208C7.50195 6.39981 7.41283 5.49501 7.58647 4.6221C7.7601 3.74918 8.18869 2.94736 8.81802 2.31802C9.44736 1.68869 10.2492 1.2601 11.1221 1.08647C11.995 0.912835 12.8998 1.00195 13.7221 1.34254C14.5443 1.68314 15.2471 2.25991 15.7416 2.99994C16.2361 3.73996 16.5 4.60999 16.5 5.5C16.5 6.09095 16.3836 6.67611 16.1575 7.22208C15.9313 7.76804 15.5998 8.26412 15.182 8.68198C14.7641 9.09985 14.268 9.43131 13.7221 9.65746C13.1761 9.88361 12.591 10 12 10Z"
      fill="#F7BF26"
    />
    <path
      d="M12 9C11.3078 9 10.6311 8.79473 10.0555 8.41015C9.47993 8.02556 9.03133 7.47894 8.76642 6.83939C8.50152 6.19985 8.4322 5.49612 8.56725 4.81719C8.7023 4.13825 9.03564 3.51461 9.52513 3.02513C10.0146 2.53564 10.6383 2.2023 11.3172 2.06725C11.9961 1.9322 12.6999 2.00152 13.3394 2.26642C13.9789 2.53133 14.5256 2.97993 14.9101 3.55551C15.2947 4.13108 15.5 4.80777 15.5 5.5C15.5 6.42826 15.1313 7.3185 14.4749 7.97487C13.8185 8.63125 12.9283 9 12 9V9Z"
      fill="#FFD764"
    />
    <path
      d="M11.9996 7.50002C11.8671 7.49999 11.7399 7.44729 11.6461 7.35352L10.1461 5.85352C10.0524 5.75975 9.99976 5.6326 9.99976 5.50002C9.99976 5.36743 10.0524 5.24028 10.1461 5.14652L11.6461 3.64652C11.7399 3.55278 11.8671 3.50012 11.9996 3.50012C12.1322 3.50012 12.2594 3.55278 12.3531 3.64652L13.8531 5.14652C13.9469 5.24028 13.9995 5.36743 13.9995 5.50002C13.9995 5.6326 13.9469 5.75975 13.8531 5.85352L12.3531 7.35352C12.2594 7.44729 12.1322 7.49999 11.9996 7.50002Z"
      fill="#F7BF26"
    />
    <path
      d="M15.9998 24H7.99977C7.88142 23.9999 7.76692 23.9579 7.67665 23.8814C7.58638 23.8048 7.52618 23.6988 7.50677 23.582L6.50677 17.582C6.49487 17.5104 6.49869 17.4371 6.51799 17.3671C6.53728 17.2971 6.57157 17.2322 6.61848 17.1768C6.66539 17.1214 6.7238 17.0769 6.78964 17.0464C6.85549 17.0159 6.92719 17 6.99977 17H16.9998C17.0724 17 17.1441 17.0159 17.2099 17.0464C17.2757 17.0769 17.3341 17.1214 17.3811 17.1768C17.428 17.2322 17.4623 17.2971 17.4816 17.3671C17.5008 17.4371 17.5047 17.5104 17.4928 17.582L16.4928 23.582C16.4734 23.6988 16.4132 23.8048 16.3229 23.8814C16.2326 23.9579 16.1181 23.9999 15.9998 24V24Z"
      fill="#A2703F"
    />
    <path
      d="M6.74267 19H17.2567L17.4232 18H6.57617L6.74267 19Z"
      fill="#D0AB86"
    />
  </svg>
)

const GuarantorOrganization = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.25 23.25H0.75V12.75H2.25"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 15.75H11.25"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 18.75H11.25"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 12.75H11.25"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.75 5.25V1.5L23.25 5.25V23.25H5.25V8.25H14.25V22.9995"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.25 18.75V6.75"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.25 18.75V8.25"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const GuarantorPerson = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 12.75C14.0711 12.75 15.75 11.0711 15.75 9C15.75 6.92893 14.0711 5.25 12 5.25C9.92893 5.25 8.25 6.92893 8.25 9C8.25 11.0711 9.92893 12.75 12 12.75Z"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8803 20.2156C19.1688 18.729 18.0513 17.4739 16.6569 16.5954L16.1238 17.4414C17.3587 18.2194 18.3483 19.3308 18.9783 20.6473L19.8803 20.2156ZM16.6569 16.5954C15.2625 15.7168 13.6479 15.2506 11.9998 15.2506V16.2506C13.4593 16.2506 14.889 16.6634 16.1238 17.4414L16.6569 16.5954ZM11.9998 15.2506C10.3517 15.2506 8.73717 15.7168 7.34272 16.5954L7.87578 17.4414C9.11062 16.6634 10.5403 16.2506 11.9998 16.2506V15.2506ZM7.34272 16.5954C5.94828 17.4739 4.83079 18.729 4.1193 20.2156L5.02132 20.6473C5.65137 19.3308 6.64095 18.2194 7.87578 17.4414L7.34272 16.5954Z"
      fill={color || "#864AC4"}
    />
    <path
      d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const StakeholderOrganization = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.25 23.25H0.75V12.75H2.25"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 15.75H11.25"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 18.75H11.25"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 12.75H11.25"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.75 5.25V1.5L23.25 5.25V23.25H5.25V8.25H14.25V22.9995"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.25 18.75V6.75"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.25 18.75V8.25"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const StakeholderPerson = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 12.75C14.0711 12.75 15.75 11.0711 15.75 9C15.75 6.92893 14.0711 5.25 12 5.25C9.92893 5.25 8.25 6.92893 8.25 9C8.25 11.0711 9.92893 12.75 12 12.75Z"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8803 20.2156C19.1688 18.729 18.0513 17.4739 16.6569 16.5954L16.1238 17.4414C17.3587 18.2194 18.3483 19.3308 18.9783 20.6473L19.8803 20.2156ZM16.6569 16.5954C15.2625 15.7168 13.6479 15.2506 11.9998 15.2506V16.2506C13.4593 16.2506 14.889 16.6634 16.1238 17.4414L16.6569 16.5954ZM11.9998 15.2506C10.3517 15.2506 8.73717 15.7168 7.34272 16.5954L7.87578 17.4414C9.11062 16.6634 10.5403 16.2506 11.9998 16.2506V15.2506ZM7.34272 16.5954C5.94828 17.4739 4.83079 18.729 4.1193 20.2156L5.02132 20.6473C5.65137 19.3308 6.64095 18.2194 7.87578 17.4414L7.34272 16.5954Z"
      fill={color || "#864AC4"}
    />
    <path
      d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
      stroke={color || "#864AC4"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const AllIcons = {
  LoanIcon,
  KOMTILIcon,
  GuarantorOrganization,
  GuarantorPerson,
  StakeholderOrganization,
  StakeholderPerson,
  ...Icons,
}

export { AllIcons as Icons }
