import React, { useState } from "react"
import ErrorContext from "../../../common/Error"
import styled from "styled-components"

const ProjectRiskRadio = ({ onChange, name, value, errors, t }) => {
  const [current, currentSet] = useState(value)
  const error = errors[name]?.message
  const handleChange = (value) => {
    currentSet(value.target.value)
    onChange(value)
  }

  return (
    <div>
      <RadioGroup onChange={handleChange} name={name} value={value}>
        <StyledRadio key={`IR${current}`}>
          <input
            type={"radio"}
            value={"IR"}
            checked={current === "IR"}
            onChange={handleChange}
          />
          <p> {t("IR")}</p>
        </StyledRadio>
        {[1, 2, 3, 4, 5, 6]
          .map((e) => e.toString())
          .map((e) => (
            <StyledRadio key={`${e}${current}`}>
              <input
                type={"radio"}
                value={e}
                checked={current === e}
                onChange={handleChange}
              />
              <p> {t(e)}</p>
            </StyledRadio>
          ))}
      </RadioGroup>
      <ErrorContext t={t} error={error} />
    </div>
  )
}

export default ProjectRiskRadio

const StyledRadio = styled.label`
  display: flex;
  flex-direction: row;
  padding-right: 2em;
  p {
    padding-left: 5px;
  }
`

const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;
`
