const queueStatusCurrentCase = {
  PERFORM_IDD_CHECK_IDD_TEAM: "PERFORM_IDD_CHECK_IDD_TEAM",
  ASSESS_APPLICATION_BOARD: "ASSESS_APPLICATION_BOARD",
  ASSESS_APPLICATION_MAIN_CREDIT: "ASSESS_APPLICATION_MAIN_CREDIT",
  ASSESS_APPLICATION_CREDIT: "ASSESS_APPLICATION_CREDIT",
  ASSESS_APPLICATION_CREDIT_LEVEL_1: "ASSESS_APPLICATION_CREDIT_LEVEL_1",
  ASSESS_APPLICATION_CREDIT_LEVEL_2: "ASSESS_APPLICATION_CREDIT_LEVEL_2",
  ASSESS_APPLICATION_CREDIT_SECTION: "ASSESS_APPLICATION_CREDIT_SECTION",
  ASSESS_APPLICATION_CREDIT_DIVISION: "ASSESS_APPLICATION_CREDIT_DIVISION",
  ASSESS_APPLICATION_APPEALS_COMMITTEE: "ASSESS_APPLICATION_APPEALS_COMMITTEE",
  TAPSVURDERING_LEVEL_1: "TAPSVURDERING_LEVEL_1",
  TAPSVURDERING_LEVEL_2: "TAPSVURDERING_LEVEL_2",
  NONE: "NONE",
}

const queueNames = {
  SELECT_SIGNERS: "selectSigners",
  PRINT_AND_SEND_TO_SIGN: "printAndSendToSign",
  REGISTER_BAIL_DOCUMENTS: "registerBailDocuments",
  PERFORM_FINANCIAL_CHECK: "performFinancialCheck",
  DIRECT_PAYOUT: "directPayout",
  BEGIN_PAYOUT: "beginPayout",
  BELONGS_TO_CASEWORKER: "belongsToCaseworker",
  WAIT_FOR_ADVANCE_RECEIVED: "waitForAdvanceReceived",
  JURI_CONTROL: "juriControl",
  CANCEL_ACCOUNT_IN_COREVIEW_MANUAL: "cancelAccountInCoreviewManual",
  TINGLYSING_MANUAL: "tinglysingManual",
  CONTROL_PRIORITY: "controlPriority",
  CONTROL_COLLATERAL_DEPOT: "controlCollateralDepot",
  MANUALLY_UPDATE_CORE: "manuallyUpdateCore",
  MAINTENANCE_BEFORE_DECISION: "maintenanceBeforeDecision",
  MAINTENANCE_AFTER_DECISION: "maintenanceAfterDecision",
  SEND_GUARANTEE_DEPOT: "sendGuaranteeDepot",
}

const queStatusOptions = [
  {
    label: "ASSESS_APPLICATION_CREDIT_LEVEL_1",
    value: "ASSESS_APPLICATION_CREDIT_LEVEL_1",
  },
  {
    label: "ASSESS_APPLICATION_CREDIT_LEVEL_2",
    value: "ASSESS_APPLICATION_CREDIT_LEVEL_2",
  },
  {
    label: "ASSESS_APPLICATION_MAIN_CREDIT",
    value: "ASSESS_APPLICATION_MAIN_CREDIT",
  },
  { label: "ASSESS_APPLICATION_BOARD", value: "ASSESS_APPLICATION_BOARD" },
]

export { queueStatusCurrentCase, queStatusOptions, queueNames }
