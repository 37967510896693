import React from "react"
import styled from "styled-components"

const Error = ({ error, t, className }) => {
  if (!error) {
    return null
  } else {
    return <Outer className={className}>{t(error)}</Outer>
  }
}

const Outer = styled.div`
  color: var(--flow-color-ferrari);
`

export default Error

/**
 background-color: var(--flow-color-orange-light);
 border: 1px solid var(--flow-color-orange-light);
 border-radius: 5px;
 color: inherit;
 padding: 0.5rem;
 position: relative;
 z-index: 3;
 margin-top: 11px;
 margin-bottom: 11px;

 &:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    top: 50%;
  }

 &:before {
    left: 8%;
    top: -8px;
    border-bottom: 8px solid var(--flow-color-orange-light);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
 */
