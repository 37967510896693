import React from "react"
import styled from "styled-components"
import NumberInput from "../NumberInput"
import { mapRelevantFields } from "../../productionDataFields"
import capitalizeFirstLetter from "../../../../util/capitalizeFirstLetter"

const ProductionData = ({ t, applicationContent }) => {
  const category = applicationContent?.selectMainProduction
  const dataFields = mapRelevantFields(category)
  if (dataFields.length === 0) {
    return <p>{t("no-production-data")}</p>
  }

  return (
    <>
      <StyledList>
        <LineItem>
          <SubSubSection wide></SubSubSection>
          <SubSubSection>
            <p>{t("today")}</p>
          </SubSubSection>
          <SubSubSection>
            <p>{t("after-investments")}</p>
          </SubSubSection>
        </LineItem>
        {dataFields.map((field, index) => {
          return (
            <LineItem key={`dataField${index}`}>
              <SubSubSection wide>
                <p>{`${t(field.header)} ${field.designation}`}</p>
              </SubSubSection>
              <SubSubSection>
                <NumberInput
                  hideLabel
                  t={t}
                  name={field.beforeKey}
                  maxWidth="100px"
                />
              </SubSubSection>
              <SubSubSection>
                <NumberInput
                  hideLabel
                  t={t}
                  name={field.afterKey}
                  maxWidth="100px"
                />
              </SubSubSection>
            </LineItem>
          )
        })}
      </StyledList>
    </>
  )
}

export default ProductionData

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`

const LineItem = styled.li`
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.1fr;
  grid-column-gap: 10px;
`

const SubSubSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1em;
  width: ${({ wide }) => (wide ? 15 : 8)}em;

  span {
    margin-right: 1em;
  }
`
