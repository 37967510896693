import React from "react"
import styled from "styled-components"
import StyledBox from "../../common/StyledBox"
import renderField from "../vekstgaranti/renderField"
import { ProjectColors } from "../../common/style/ProjectColors"

const AgreementFieldsLine = ({
  fields,
  onChange,
  name,
  fieldName,
  index,
  t,
}) => {
  return (
    <>
      {fields.map((field, i) => {
        return (
          <StyledBox
            width="100%"
            key={i}
            padding={"0px"}
            title={`${field.title || field.displayNameShort}`}
          >
            <StyledInput>
              {renderField(field.type, {
                ...field,
                fields,
                field: {},
                onChange: (data) => {
                  field.value = data
                },
              })}
            </StyledInput>
          </StyledBox>
        )
      })}
    </>
  )
}
const StyledInput = styled.div`
  display: flex;

  input {
    align-self: flex-start;
    padding: 3px 0px 3px 5px;
    border-radius: 5px;
    border: solid 1px ${ProjectColors.InputBorder};
  }
`
export default AgreementFieldsLine
