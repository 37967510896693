import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { validateInput } from "../components/insight/validateSchema"
import { useInsightAppInitialized } from "../components/insight/useInsightAppInitialized"
import Notice from "../components/common/Notice"
import ErrorText from "../components/common/ErrorText"
import Tabs from "../components/common/Tabs"
import InsightModule from "../components/insight/InsightModule"
import ApplicationHistory from "../components/PerformIntroductoryEvaluation/ApplicationHistory"
import _ from "lodash"
import { trimOperationalRisk } from "../util/trimOperationalRisk"
import { parse } from "date-fns"

const DecideCase = (props) => {
  const { task, t, schema, flow, user, save, complete } = props
  const { data } = task
  const {
    templates,
    insightComponentData,
    isPrequalification,
    guaranteeConstraints,
  } = task?.context

  const [isProcessing, setProcessing] = useState(false)
  const [app, setApp] = useState(null)
  const [isLoading, isErrored] = useInsightAppInitialized(app)
  const [taskData, setTaskData] = useState(
    _.merge({ templates, isPrequalification }, data || {})
  )
  const [previousTemplate, setPreviousTemplate] = useState(
    taskData.rejectionTemplate
  )
  const [rerenderId, setRerenderId] = useState(0)

  const context = isPrequalification
    ? "decisionGrowthGuaranteePrequalification"
    : "decisionGrowthGuarantee"

  const caseMemoOptions = {
    context,
  }

  const [errors, setErrors] = useState([])
  const updateValidations = (
    companySize,
    selectedOperationalRisk,
    companyRegistrationDate,
    supportRegime
  ) => {
    const validations = []
    const parsedCompanyRegisteredDate = parse(
      companyRegistrationDate,
      "dd.MM.yyyy",
      new Date()
    )

    //allowed company size is small, all others ar larger
    if (!guaranteeConstraints.allowedCompanySizes.includes(companySize)) {
      const validation = "company-too-large-guarantee"
      validations.push(validation)
    }
    if (
      trimOperationalRisk(selectedOperationalRisk) ===
      guaranteeConstraints.selectedOperationalRisk
    ) {
      const validation = "selected-operational-risk-guarantee"
      validations.push(validation)
    }
    if (
      new Date().getFullYear() - parsedCompanyRegisteredDate.getFullYear() >
        guaranteeConstraints.yearsAfterRegistration &&
      supportRegime ===
        guaranteeConstraints.restrictedSupportRegimeXYearsAfterRegistration
    ) {
      const validation = "company-too-old-and-article-22"
      validations.push(validation)
    }
    if (
      companySize !== guaranteeConstraints.allowedCompanySizeArticle22 &&
      supportRegime ===
        guaranteeConstraints.restrictedSupportRegimeXYearsAfterRegistration
    ) {
      const validation = "company-very-big-and-article-22"
      validations.push(validation)
    }
    return validations
  }

  useEffect(() => {
    validateInput({ ...insightComponentData }, "@stacc/vue-casememo")
  }, [insightComponentData])

  //For rendering the correct rejectionText (Avslagsmal)
  useEffect(() => {
    if (
      taskData.rejectionTemplate !== previousTemplate &&
      taskData.rejectionTemplate
    ) {
      const newData = {
        ...taskData,
        rejectionText: templates[taskData.rejectionTemplate],
      }
      setPreviousTemplate(taskData.rejectionTemplate)
      setTaskData(newData)
      setRerenderId(rerenderId + 1)
    }
  }, [taskData])

  const handleComplete = async (values) => {
    delete values.templates
    delete values.isPrequlification

    // Remove unused data
    if (values.approvalStatus !== "REJECT") {
      delete values.rejectionTemplate
      delete values.rejectionText
    }

    if (
      values.approvalStatus === "BACK_TO_PERFORM_INTRODUCTORY" ||
      values.approvalStatus === "NEWDECLARATION"
    ) {
      delete values.comment
    }

    const validations = updateValidations(
      insightComponentData?.company?.eosRating.value || "",
      insightComponentData?.caseOverview?.selectedOperationalRisk?.title || "",
      insightComponentData?.caseOverview?.companyRegistered || "",
      flow.data?.setDecisionBasis?.supportRegime || ""
    )
    setErrors(validations)

    if (_.isEmpty(validations) || values.approvalStatus !== "OK") {
      setProcessing(true)
      complete(
        values,
        () => {
          setProcessing(false)
        },
        () => {
          console.error("Could not complete task")
          setProcessing(false)
        }
      )
    }
  }

  const handleSave = () => {
    setProcessing(true)
    save(
      taskData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }
  const handleAppChange = (app) => {
    setApp(app)
  }

  const options = [
    {
      id: "creditMemo",
      title: "Oversikt",
      component: (
        <InsightModule
          name={"@stacc/vue-casememo"}
          data={insightComponentData}
          onAppChange={handleAppChange}
          commonData={insightComponentData?.commonData}
          options={caseMemoOptions}
        />
      ),
    },
    {
      id: "company",
      title: "Om selskapet",
      component: (
        <InsightModule
          name={"@stacc/vue-corporateanalysis"}
          commonData={data?.commonData}
          onAppChange={handleAppChange}
          options={{
            context: "company",
          }}
        />
      ),
    },
    {
      id: "applicationHistory",
      title: t("application-history"),
      component: (
        <ApplicationHistory
          applications={
            flow.data?.raw?.applicationMaster?.previousCases?.applications
          }
        />
      ),
    },
  ]
  return (
    <Layout forceHeight>
      <Content>
        <Tabs
          defaultTab="creditMemo"
          loading={isLoading}
          options={options}
          onChange={(tab) => {
            try {
              setApp(null)
            } catch (e) {}
          }}
        />
      </Content>
      <Context flow={flow} context={task.context?.applicationSummary}>
        <StyledForm>
          <ReactForm
            rerenderId={rerenderId}
            schema={schema}
            formData={taskData}
            disabled={isProcessing}
            onChange={(values) => setTaskData(values)}
            onSubmit={(values) => handleComplete(values)}
          >
            <ButtonContainer>
              <PrimaryButton
                type="submit"
                isLoading={isProcessing}
                disabled={isProcessing || isErrored || isLoading}
              >
                {t("complete")}
              </PrimaryButton>
              <SecondaryButtonModified
                type="button"
                disabled={isProcessing}
                onClick={handleSave}
              >
                {t("save")}
              </SecondaryButtonModified>
            </ButtonContainer>
            {isErrored && (
              <StyledNotice backgroundColor="var(--flow-color-ferrari-lighter)">
                {isErrored && <ErrorText>{t(`failed-to-load-task`)}</ErrorText>}
              </StyledNotice>
            )}
            {errors.length > 0 && taskData.approvalStatus === "OK" && (
              <StyledNotice backgroundColor="var(--flow-color-ferrari-lighter)">
                <StyledError>
                  {errors.map((e, i) => (
                    <div key={i}>
                      <ErrorText>{t(e)}</ErrorText>
                    </div>
                  ))}
                </StyledError>
              </StyledNotice>
            )}
          </ReactForm>
        </StyledForm>
      </Context>
    </Layout>
  )
}
export default DecideCase

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

const StyledForm = styled.div`
  margin: 1em 0em 0em 0em;
`

const StyledNotice = styled(Notice)`
  color: var(--flow-color-ferrari);
  border-radius: 0;
  margin-top: 10px;
`
const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
`

const Content = styled.div`
  flex: 1;
  height: 100%;
`
const StyledError = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`
