import styled from "styled-components"
import React from "react"

const SuffixedInput = ({
  label,
  width,
  type,
  readonly,
  suffix,
  onChange,
  value,
  replaceDotWithComma = false,
  field: { id, key, name, onBlur },
}) => {
  if (readonly) {
    return (
      <SuffixedInputStyle width={width}>
        <div className="value">
          {replaceDotWithComma ? value.replace(".", ",") : value}
        </div>
        <span className="suffix">{suffix}</span>
      </SuffixedInputStyle>
    )
  }
  return (
    <SuffixedInputStyle width={width}>
      <input
        className="suffixInput"
        type={type}
        name={name}
        id={id}
        readOnly={readonly}
        label={label}
        key={key}
        onChange={(event) => onChange(event.target.value)}
        value={value}
      />
      <span className="suffix">{suffix}</span>
    </SuffixedInputStyle>
  )
}

const SuffixedInputStyle = styled.label`
  margin: auto;
  position: relative;
  display: inline-block;
  margin-right: 5px;

  .value {
    width: ${({ width }) => width}px;
  }

  .suffix {
    position: absolute;
    color: var(--flow-color-grey2);
    top: 2px;
    right: 7px;
    /* Remove up/down selector on number */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .suffixInput::-webkit-outer-spin-button,
  .suffixInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .suffixInput[type="number"] {
    -moz-appearance: textfield;
  }
`

export default SuffixedInput
