import React from "react"
import styled from "styled-components"
import { FormProvider, useForm } from "react-hook-form"
import { applicationDefaultValues } from "./EvaluateBasisAdditionalProduct.validationSchema"
import FormAdditionalProduct from "./FormAdditionalProduct"
import ProposedAmountBox from "../ProposedAmountBox"
import { addThousandSeparator } from "../../../util/addThousandSeparator"
import LineList from "../LineList"
import AdditionalProductLine from "./AdditionalProductLine"
import { ProjectColors } from "../../common/style/ProjectColors"
import ActivityTypeLine from "../lineItems/ActivityTypeLine"
import { FontStyles } from "@stacc/flow-ui-components"
import { DropDownValues } from "../../common/tempData/DropDownValues"
import ActivityLine from "../lineItems/ActivityLine"
import formatWithLocale from "../../../util/dateFormatter"

const EvaluateBasisAdditionalProduct = ({
  t,
  options,
  handleChange,
  showErrors,
  errors,
  extraordinaryFinancingProduct,
  extraordinaryFinancingCategory,
  amountAppliedFor,
  dataFromForm,
  category,
}) => {
  let productConfiguration = {}
  productConfiguration[extraordinaryFinancingProduct] =
    options.productConfiguration[extraordinaryFinancingProduct]

  const productType =
    productConfiguration[extraordinaryFinancingProduct]?.productType

  const defaultValues = {
    ...applicationDefaultValues,
    products: {
      ...applicationDefaultValues.products,
      productType,
      product: extraordinaryFinancingProduct,
    },
    updatedCost: amountAppliedFor.grant,
    supportRegime: DropDownValues.SupportRegimeExtraordinaryFinancing[0].value,
    activityStart: formatWithLocale(new Date(), "yyyy-MM-dd"),
    activityEnd: formatWithLocale(new Date(), "yyyy-MM-dd"),
    ...dataFromForm,
  }

  const { handleSubmit, control, watch, onChange, setValue, ...rest } = useForm(
    {
      mode: "onChange",
      defaultValues,
    }
  )
  const receivablesFebruaryThroughMayChecked = watch(
    "receivablesFebruaryThroughMay"
  )
  const receivablesJuneThroughSeptemberChecked = watch(
    "receivablesJuneThroughSeptember"
  )
  const amount = watch("products.amount")
  const fundingIntensity = watch("products.fundingIntensity")
  const subsidyEffect = watch("products.subsidyEffect")
  const updatedCost = watch("updatedCost")
  const activityStart = watch("activityStart")
  const activityEnd = watch("activityEnd")

  const onChangeHandler = async (values) => {
    handleChange(values)
  }
  const currency = "NOK"
  return (
    <Wrapper>
      <ProposedAmountBoxWrapper>
        <ProposedAmountBox
          title={t("proposed-grant").toUpperCase()}
          currency={currency}
          amount={addThousandSeparator(amount)}
          appliedForAmount={addThousandSeparator(amountAppliedFor?.grant)}
          appliedForText={t("applied-for")}
        />
        <ProposedAmountBox
          title={t("proposed-loan").toUpperCase()}
          currency={currency}
          amount={addThousandSeparator(0)}
          appliedForAmount={addThousandSeparator(amountAppliedFor?.loan)}
          appliedForText={t("applied-for")}
        />
      </ProposedAmountBoxWrapper>
      <Content>
        <StyledHeading>{t("additional-product-header")}</StyledHeading>
        <FormProvider
          handleSubmit={handleSubmit}
          control={control}
          errors={errors}
          watch={watch}
          onChange={onChange}
          setValue={setValue}
          showErrors={showErrors}
          {...rest}
        >
          <form onChange={handleSubmit(onChangeHandler)}>
            <LineList>
              <ActivityTypeLine
                activityTypeText={t("activity-type-heading")}
                supportRegimeText={t("choose-support-regime")}
                index={""}
                t={t}
                extraordinaryFinancingCategory={extraordinaryFinancingCategory}
                category={category}
                isAdditionalProduct={true}
                activityStart={activityStart}
                activityEnd={activityEnd}
              />
            </LineList>
            <LineList>
              <StyledProduct>
                <AdditionalProductLine
                  key={`productHeader`}
                  product={t("product")?.toUpperCase()}
                  amount={t("amount")?.toUpperCase()}
                  fundingIntensity={t("funding-intensity")?.toUpperCase()}
                  productType={t("product-type")?.toUpperCase()}
                  subsidyEffect={t("subsidy-effect")?.toUpperCase()}
                  header={true}
                />

                <AdditionalProductLine
                  name={"products"}
                  product={extraordinaryFinancingProduct}
                  amount={amount}
                  fundingIntensity={fundingIntensity}
                  subsidyEffect={subsidyEffect}
                  productType={productType}
                  t={t}
                  options={{ productConfiguration }}
                  totalAppliedFor={updatedCost}
                />
              </StyledProduct>
              <StyledCosts>
                <ActivityLine
                  costType={t("cost-type")?.toUpperCase()}
                  projectCost={t("project-cost")?.toUpperCase()}
                  basisForSupport={t("support-basis")?.toUpperCase()}
                  header={true}
                  isAdditionalProduct={true}
                />
                <ActivityLine
                  costType={t("receivables-costs")}
                  projectCost={amountAppliedFor.grant}
                  name={"updatedCost"}
                  currency={currency}
                  isAdditionalProduct={true}
                  amount={amount}
                  key={"updatedCost"}
                  t={t}
                  setValue={setValue}
                />
              </StyledCosts>
            </LineList>

            <FormAdditionalProduct
              receivablesFebruaryThroughMayChecked={
                receivablesFebruaryThroughMayChecked === "yes"
              }
              receivablesJuneThroughSeptemberChecked={
                receivablesJuneThroughSeptemberChecked === "yes"
              }
              t={t}
            />
          </form>
        </FormProvider>
      </Content>
    </Wrapper>
  )
}
export default EvaluateBasisAdditionalProduct

const Content = styled.div`
  padding: 2em;
  border: solid 1px ${ProjectColors.SlateBoxShadow};
  border-radius: 5px;
`

const StyledProduct = styled.div`
  padding-top: 2em;
  padding-bottom: 1em;
`
const StyledCosts = styled.div`
  padding-top: 1em;
  padding-bottom: 2em;
`

const StyledHeading = styled.h1`
  margin: 4px;
  padding: 10px;
  ${FontStyles.Large}
`

const Wrapper = styled.div`
  padding: 1em 1em 1em 1em;
  display: grid;
  grid-template-rows: 1fr 14fr;
  grid-row-gap: 2em;
`
const ProposedAmountBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  div {
    min-width: 10em;
  }
`
