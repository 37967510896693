import React, { useState } from "react"
import styled from "styled-components"
import Error from "../Error"

const RadioButtonYesNo = ({
  onChange,
  name,
  value,
  errors,
  label,
  t,
  showErrors,
}) => {
  const error = errors[name]?.message
  const [current, currentSet] = useState(value)
  const handleChange = (value) => {
    currentSet(value.target.value)
    onChange(value)
  }
  return (
    <StyledContent>
      {label && <label>{label}</label>}
      <RadioGroup onChange={handleChange} name={name} value={value}>
        <StyledRadio>
          <input
            type={"radio"}
            key={`yes${current}`}
            value={"yes"}
            defaultChecked={current === "yes"}
          />
          <p> {t("Yes")}</p>
        </StyledRadio>
        <StyledRadio>
          <input
            type={"radio"}
            value={"no"}
            key={`no${current}`}
            defaultChecked={current === "no"}
          />
          <p>{t("No")}</p>
        </StyledRadio>
      </RadioGroup>
      {showErrors && error && (
        <StyledError>
          <Error error={error} t={t} />
        </StyledError>
      )}
    </StyledContent>
  )
}

export default RadioButtonYesNo

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-template-rows: 0.5fr 0.1fr;
  grid-column-gap: 1em;
`

const StyledError = styled.div`
  grid-row-start: 2;
  grid-column-start: 2;
`

const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledRadio = styled.label`
  display: flex;
  flex-direction: row;

  p {
    padding-left: 2px;
  }

  &:last-child {
    padding-left: 5px;
  }
`
