import React from "react"

const TrafficLightCircle = ({ color }) => {
  let circleColor
  switch (color) {
    case "red":
      circleColor = "var(--flow-color-ferrari)"
      break
    case "green":
      circleColor = "var(--flow-color-grass-dark)"
      break
    case "orange":
      circleColor = "var(--flow-color-orange-dark)"
      break
    default:
      circleColor = color
  }

  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill={circleColor} />
    </svg>
  )
}

export default TrafficLightCircle
