import { Date as FlowDate } from "@stacc/flow-ui-components"
import formatWithLocale from "../../../util/dateFormatter"
const DateField = ({ onChange, value, field: { id, key, name, onBlur } }) => {
  return (
    <FlowDate
      name={name}
      id={id}
      key={key}
      onChange={(event) => {
        const date = new Date(event.target.value)
        onChange(date.toISOString())
      }}
      defaultValue={formatWithLocale(value, "yyyy-MM-dd")}
      value={formatWithLocale(value, "yyyy-MM-dd")}
    />
  )
}

export default DateField
