import React from "react"
import styled from "styled-components"

const Signer = ({ signer, isBold }) => {
  const List = isBold ? SignersListBold : SignersList
  const role = signer?.rolle?.tekstforklaring ?? "Ukjent rolle"

  return <List>{role + " - " + signer?.navn}</List>
}

const SignersList = styled.div`
  font-size: 14px;
  padding: 5px 3px;
`

const SignersListBold = styled(SignersList)`
  color: black;
  font-weight: 500;
`

export default Signer
