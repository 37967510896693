import styled from "styled-components"
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components"
import React, { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import {
  CheckboxComponent,
  InputComponent,
  RadioComponent,
  TextAreaComponent,
} from "./InputComponents"
import DisplayCreditCheckStakeholder from "./DisplayCreditCheckStakeholder"
import { ONLY_RENDER_IN_DEVELOPMENT } from "../../ONLY_RENDER_IN_DEVELOPMENT"
import IconButton from "../../common/IconButton"

const EditStakeholder = ({
  stakeholder,
  t,
  onCancel,
  onDelete,
  isCreditChecking,
  mainApplicantIsMarried,
  allowDeletion,
  addStakeholderError,
}) => {
  const { setValue, watch } = useFormContext()
  const id = stakeholder?.id
  const stakeholderType = watch("type")
  const fetchFromPopulation = watch("fetchDataFromPopulationRegistry")
  const scope = watch("scope")
  const isMarried = watch("isMarried")
  const isNewStakeholder = stakeholder?.new
  const isPerson = stakeholderType === "person"
  const isOrganization = stakeholderType === "organization"
  const isForeign = scope === "international"
  const forcedCreditCheck = isOrganization && !isForeign && isNewStakeholder
  const cancelDisabled = !!stakeholder.cancelDisabled
  if (
    !stakeholder.nationalPopulationRegister &&
    stakeholderType === "person" &&
    !fetchFromPopulation
  )
    setValue("fetchDataFromPopulationRegistry", true)

  useEffect(() => {
    let bisnode = false
    let ambita = false
    let populationRegistry = false
    if (forcedCreditCheck) bisnode = true
    if (!stakeholder.nationalPopulationRegister && stakeholderType === "person")
      populationRegistry = true
    setValue("fetchDataFromBisnode", bisnode)
    setValue("fetchDataFromAmbita", ambita)
    setValue("fetchDataFromPopulationRegistry", populationRegistry)
  }, [forcedCreditCheck, setValue, isMarried, stakeholderType])

  return (
    <Content>
      <FormContainerContainer>
        <FormContainer>
          {isNewStakeholder && (
            <RadioComponent
              watch={watch}
              fieldName={"type"}
              t={t}
              id={id}
              fullLine={true}
            />
          )}

          {isPerson && (
            <>
              <InputComponent
                disabled={!isNewStakeholder}
                fieldName={"ssn"}
                t={t}
                id={id}
              />
              <InputComponent
                disabled={!isNewStakeholder}
                fieldName={"name"}
                t={t}
                id={id}
              />
              <InputComponent fieldName={"email"} t={t} id={id} />
            </>
          )}
          {isOrganization && (
            <>
              <InputComponent
                fieldName={"organizationNumber"}
                t={t}
                id={id}
                disabled={isForeign || !isNewStakeholder}
              />
              <InputComponent
                fieldName={"name"}
                t={t}
                id={id}
                disabled={!isNewStakeholder && !isForeign}
              />
              <CheckboxComponent
                fieldName={"scope"}
                t={t}
                id={id}
                fullLine={true}
                disabled={!isNewStakeholder}
              />
              <InputComponent fieldName={"contactPersonName"} t={t} id={id} />
              <InputComponent fieldName={"contactPersonEmail"} t={t} id={id} />
              {isForeign && (
                <>
                  <InputComponent
                    fieldName={"organizationCountry"}
                    t={t}
                    id={id}
                  />
                  <TextAreaComponent
                    fieldName={"additionalInfo"}
                    t={t}
                    id={id}
                    fullLine={true}
                  />
                </>
              )}
            </>
          )}
          {!isNewStakeholder && (
            <DisplayCreditCheckStakeholderWrapper>
              <DisplayCreditCheckStakeholder
                stakeholder={stakeholder}
                t={t}
                padding={"0 "}
              />
            </DisplayCreditCheckStakeholderWrapper>
          )}
          {isPerson && (
            <>
              <StyledFetchData>
                <CheckboxComponent fieldName={"isMarried"} t={t} id={id} />
              </StyledFetchData>
              {isMarried && (
                <InputComponent fieldName={"spouseSsn"} t={t} id={id} />
              )}
            </>
          )}
          {!isForeign && !isCreditChecking && (
            <StyledFetchData key={scope}>
              <label>{t("fetch-new-data-from")}</label>
              <>
                <CheckboxComponent
                  fieldName={"fetchDataFromBisnode"}
                  t={t}
                  id={id}
                  disabled={forcedCreditCheck}
                />

                <CheckboxComponent
                  fieldName={"fetchDataFromAmbita"}
                  t={t}
                  id={id}
                />
              </>
              {isPerson && (
                <CheckboxComponent
                  fieldName={"fetchDataFromPopulationRegistry"}
                  t={t}
                  id={id}
                  disabled={!stakeholder.nationalPopulationRegister}
                />
              )}
            </StyledFetchData>
          )}
        </FormContainer>
        {allowDeletion && (
          <TrashContainer>
            <IconButton icon="Trashcan" onClick={() => onDelete()} />
          </TrashContainer>
        )}
      </FormContainerContainer>
      {addStakeholderError && (
        <p style={{ color: "var(--flow-color-ferrari)", paddingBottom: "5px" }}>
          {t("stakeholder-already-exists")}
        </p>
      )}
      <ButtonContainer>
        <PrimaryButton
          type={"submit"}
          form={`stakeholderForm_${stakeholder.id}`}
          disabled={isCreditChecking}
        >
          {isNewStakeholder ? t("add") : t("save")}
        </PrimaryButton>
        <SecondaryButton
          type="reset"
          onClick={() => onCancel()}
          disabled={cancelDisabled}
        >
          {t("cancel")}
        </SecondaryButton>
      </ButtonContainer>
    </Content>
  )
}

const FormContainerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const TrashContainer = styled.div`
  padding: 1.5em;
`

const StyledFetchData = styled.div`
  display: flex;
  grid-column: 1 / span 2;
  padding: 0 0 1em 0.2em;
  flex-direction: row;

  * {
    padding-right: 1em;
  }
`

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-column-gap: 0;
  margin-top: 15px;
  width: 100%;
`

const DisplayCreditCheckStakeholderWrapper = styled.div`
  grid-column: 1 / span 2;
  padding: 0 0 1em 0;
`

const Content = styled.div``
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1em;
  height: 30px;
`

export default EditStakeholder
