import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { useFormContext, Controller } from "react-hook-form"
import TextButton from "../../common/TextButton"
import { Icons } from "@stacc/flow-ui-components"
import { addThousandSeparator } from "../../../util/addThousandSeparator"
import { ProjectColors } from "../../common/style/ProjectColors"
import CustomPercentField from "./CustomPercentField"
import useClickOutside from "../../../hooks/useClickOutside"
import BentoInput from "../../common/inputs/bento/BentoInput"

const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false)
  const downHandler = (event) => {
    if (event.key === targetKey) {
      if (targetKey === "ArrowUp" || targetKey === "ArrowDown") {
        event.preventDefault()
      }
      setKeyPressed(true)
    }
  }
  const upHandler = (event) => {
    if (event.key === targetKey) {
      setKeyPressed(false)
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", downHandler)
    window.addEventListener("keyup", upHandler)
    return () => {
      window.removeEventListener("keydown", downHandler)
      window.removeEventListener("keyup", upHandler)
    }
  }, [])

  return keyPressed
}

const DropdownContentItemWrapper = ({
  selectedIndex,
  onChange,
  index,
  optionIndex,
  handleOnChangeProduct,
  setSearch,
  handleIsDropdownVisible,
  optionItem,
}) => {
  const itemRef = useRef(null)
  const ENTER = useKeyPress("Enter")

  useEffect(() => {
    if (index === selectedIndex && itemRef?.current) {
      itemRef?.current?.focus()
    }
  }, [index, selectedIndex, itemRef])

  useEffect(() => {
    if (ENTER && optionItem.isValid && optionIndex === selectedIndex) {
      handleOnChangeProduct(optionItem?.value, onChange, index)
    }
  }, [ENTER, selectedIndex])

  return (
    <StyledDropDownContentItem
      ref={itemRef}
      isSelected={optionIndex === selectedIndex}
      isValid={optionItem.isValid}
      onChange={(event) =>
        handleOnChangeProduct(event.target.value, onChange, index)
      }
      onClick={(event) => {
        handleOnChangeProduct(optionItem?.value, onChange, index)
        handleIsDropdownVisible(event)
        setSearch("")
      }}
    >
      {optionItem.name}
    </StyledDropDownContentItem>
  )
}

const MainProductLine = ({
  index,
  paddingLeft,
  handleOnChangeProduct,
  handleOnChangeAmount,
  currentProduct,
  dropdownValues,
  fundingIntensity,
  productType,
  onDelete,
  t,
  supportRegime,
  onIntensityChanged,
}) => {
  const { control } = useFormContext()
  const [filteredDropdownValues, setFilterDropdownValues] =
    useState(dropdownValues)
  const [search, setSearch] = useState("")
  const [isProductsVisible, setIsProductsVisible] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(
    dropdownValues.findIndex(
      (item) => item.value === currentProduct?.productId
    ) || 0
  )

  const UP = useKeyPress("ArrowUp")
  const DOWN = useKeyPress("ArrowDown")

  const ref = useClickOutside(() => {
    setSearch("")
    setIsProductsVisible(false)
  })
  const isConstantAmount =
    currentProduct?.amountLowerLimit === currentProduct?.amountUpperLimit &&
    currentProduct?.amountLowerLimit !== 0 &&
    currentProduct?.amountLowerLimit != null

  useEffect(() => {
    setFilterDropdownValues(() => {
      // Filtrere vekk filteredDropdownValues som ikke samsvarer med search string
      return dropdownValues.filter((item) =>
        item.name.trim().toUpperCase().includes(search.toUpperCase())
      )
    })
  }, [search])

  const constantAmount = isConstantAmount ? currentProduct?.amountLowerLimit : 0

  const handleIsDropdownVisible = (event) => {
    event.stopPropagation()
    setIsProductsVisible(!isProductsVisible)
  }

  useEffect(() => {
    if (isProductsVisible) {
      if (DOWN) {
        setSelectedIndex((selectedIndex) =>
          selectedIndex + 1 < filteredDropdownValues.length
            ? selectedIndex + 1
            : (selectedIndex = 0)
        )
      }

      if (UP) {
        setSelectedIndex((selectedIndex) =>
          selectedIndex - 1 >= 0
            ? selectedIndex - 1
            : (selectedIndex = filteredDropdownValues.length - 1)
        )
      }
    }
  }, [UP, DOWN])

  return (
    <StyledProduct paddingLeft={paddingLeft}>
      <LineItem>
        <Controller
          control={control}
          name={`products.${index}.product`}
          key={`products.${index}.product`}
          render={({ field: { onChange } }) => (
            <DropDown ref={ref}>
              <DropDownButton type="button" onClick={handleIsDropdownVisible}>
                {currentProduct
                  ? `${currentProduct.loanPurposeCode} ${currentProduct.displayNameShort} ${currentProduct.marketingName}`
                  : t("choose")}
                <Icons.ChevronDown size={20} color="var(--flow-color-grey)" />
              </DropDownButton>

              {isProductsVisible && (
                <DropDownContent>
                  <StyledDropdownInput>
                    <BentoInput
                      placeholder={t("nace-select-placeholder")}
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onClick={(event) => event.stopPropagation()}
                    />
                  </StyledDropdownInput>

                  {filteredDropdownValues.map((optionItem, optionIndex) => {
                    return (
                      <DropdownContentItemWrapper
                        selectedIndex={selectedIndex}
                        onChange={onChange}
                        index={index}
                        optionIndex={optionIndex}
                        handleOnChangeProduct={handleOnChangeProduct}
                        handleIsDropdownVisible={handleIsDropdownVisible}
                        setSearch={setSearch}
                        optionItem={optionItem}
                        filteredDropdownValues={filteredDropdownValues}
                      />
                    )
                  })}
                </DropDownContent>
              )}
            </DropDown>
          )}
        />
        {!isConstantAmount ? (
          <StyledInput>
            <Controller
              name={`products.${index}.amount`}
              control={control}
              key={`products.${index}.amount`}
              render={({ field: { name, onChange, onBlur, ...rest } }) => (
                <BentoInput
                  name={name}
                  id={name}
                  mode="currency"
                  inputMode="numeric"
                  pattern={/[0-9]*/}
                  type="text"
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    handleOnChangeAmount(
                      e.rawValue,
                      onChange,
                      index,
                      productType
                    )
                  }
                  onBlur={(e) => onBlur(e.rawValue)}
                  {...rest}
                />
              )}
            />
          </StyledInput>
        ) : (
          <p>{addThousandSeparator(constantAmount)} NOK</p>
        )}
        <StyledCosts>
          {isConstantAmount ? (
            <p>{fundingIntensity && fundingIntensity.toFixed(2)} </p>
          ) : (
            <CustomPercentField
              index={index}
              name={`products.${index}.fundingIntensity`}
              actualValue={fundingIntensity.toFixed(2)}
              setActualValue={onIntensityChanged}
            />
          )}
        </StyledCosts>
        <p>{t(productType)}</p>
        {onDelete && (
          <TextButton onClick={onDelete} padding={false}>
            <Icons.Trashcan color="var(--flow-color-grey2)" />
          </TextButton>
        )}
      </LineItem>
    </StyledProduct>
  )
}

const StyledProduct = styled.section`
  width: calc(100% - ${(props) => (props.paddingLeft || 0) + 8}px);
  border-radius: 5px;
  margin: 4px 3px 4px 4px;
  padding-left: ${(props) => props.paddingLeft || 0}px;
  background-color: ${(props) =>
    props?.header ? `var(--flow-color-snow)` : `${ProjectColors.Slate95}`};
`
const DropDownButton = styled.button`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: solid 1px ${ProjectColors.InputBorder};
  border-radius: 5px;
  color: #6c6c6c;
  & > * {
    flex-shrink: 0;
  }
`

const DropDown = styled.div`
  position: relative;
`
const DropDownContent = styled.div`
  position: absolute;
  background: #f8f8f8;
  color: black;
  width: 100%;
  z-index: 900;
  border-radius: 7px;
  border: solid 1px ${ProjectColors.InputBorder};

  Input {
    padding: 0;
    box-sizing: border-box;
    border: solid 1px transparent;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 0px 7px;
    font-size: 14px;
  }
`
const StyledCosts = styled.div`
  display: flex;
  flex-direction: row;

  p {
    :first-child {
      padding-right: 3px;
    }
  }
`

const StyledDropdownInput = styled.div`
  input {
    border: solid 1px ${ProjectColors.InputBorder};
    padding: 4px 5px;
  }
  div {
    box-sizing: border-box;
  }
`
const StyledInput = styled.div`
  display: flex;
  input {
    align-self: flex-start;
    padding: 3px 4px 3px 5px;
    border-radius: 5px;
    border: solid 1px ${ProjectColors.InputBorder};
    width: 50%;
    text-align: right;
    margin-bottom: -1em;
  }
`
const LineItem = styled.li`
  padding: 10px;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.1fr;
  grid-column-gap: 0px;
  margin-left: ${(props) => props.marginLeft};
`

const StyledDropDownContentItem = styled.div`
  color: ${(props) => (props.isSelected ? "white" : "")};
  background-color: ${(props) =>
    props.isSelected ? "var(--flow-color-sea)" : ""};
  pointer-events: ${(props) => (!props.isValid ? "none" : "")};
  cursor: ${(props) => (!props.isValid ? "default" : "pointer")};
  opacity: ${(props) => (!props.isValid ? 0.5 : 1)};
  padding: 3px 5px;

  :hover {
    background-color: var(--flow-color-sea);
    color: white;
  }
`

export default MainProductLine
