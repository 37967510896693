export const realkausjonValidation = (
  agreements,
  initialGuarantors,
  listOfCoDebtors,
  mappedAgreementRegister
) => {
  /* Behov:
      - gå igjennom alle avtaler (agreements)
      - sjekk om det finnes mortgagors som ikkje er søker, eller medlåntaker
  */

  /**
   * This validation is to check if there is realkausjonister on agreement and user have not ticked off "Realkausjon" in GUI
   * In order to find realkausjonister we have to compare against a list where codebtors and applicant does not exist
   * Then for each agreement get mortgagors and find a match in gurantorsOnly list
   * If you find a match we have an agreement with a realkausjonist
   * If not there is no realkausjon of the agreement
   * Check this against the realkausjonIsChecked field
   */

  const statuses = []

  agreements.forEach((agreement) => {
    // This validation should only run on MORTGAGE_DEED and non existing agreements that have not been retrieved from CoreView.
    if (
      (agreement.collateralAgreementType !== "MORTGAGE_DEED" &&
        agreement.collateralAgreementType !== "MORTGAGE_DEED_VEHICLE" &&
        agreement.collateralAgreementType !== "MORTGAGE_DEED_MOVABLES" &&
        agreement.collateralAgreementType !== "MORTGAGE_DEED_REAL_ESTATE") ||
      (agreement.externalSource === "CoreView" &&
        agreement.basicCollateralAgreementInformation.basicCollateralInformation
          .version === "ACTIVE")
    )
      return

    let listOfDeliveriesAgreementCovers =
      agreement.basicCollateralAgreementInformation.collateralCoverages.map(
        (el) => el.internalId
      )

    let realkausjonIsChecked =
      agreement.specificCollateralAgreementInformation.suretyship

    //This handles the cases where there are changes of type collateral-coverages (Til sikkerhet for)
    if (
      agreement.basicCollateralAgreementInformation.basicCollateralInformation
        .version === "CHANGED"
    ) {
      realkausjonIsChecked =
        agreement.basicCollateralAgreementInformation.collateralCoverages.some(
          (coverage) =>
            Object.prototype.hasOwnProperty.call(
              coverage.collateralCoverage,
              "suretyshipDetails"
            ) &&
            Object.keys(
              coverage.collateralCoverage.suretyshipDetails.suretyTerms
            ).length > 0
        )

      const changedData = mappedAgreementRegister.changesData.find(
        (el) => el.data.basicChangeInformation.parentId === agreement.internalId
      )
      if (changedData.type !== "COLLATERALCOVERAGES") return
      const addedAgreements = changedData.data.collateralCoverages.filter(
        (el) => el.changeStatus === "NEW"
      )
      listOfDeliveriesAgreementCovers = listOfDeliveriesAgreementCovers.filter(
        (el) => addedAgreements.some((ag) => ag.internalId === el)
      )
    }

    //This is a list containing all guarantors that doesn't have the role applicant og codebtor
    const guarantorsOnly = initialGuarantors
      .filter(
        (guarantor) =>
          !listOfCoDebtors.find(
            (x) =>
              x.id === guarantor.id &&
              listOfDeliveriesAgreementCovers.some((el) => el === x.agreementId)
          )
      )
      .filter((gurantor) => !gurantor?.isApplicant)

    const mortgagors = agreement.basicCollateralAgreementInformation.mortgagors

    const hasRealkausjonister = mortgagors.some((mortgagor) => {
      return guarantorsOnly.find(
        (guarantor) => mortgagor.internalId === guarantor.id
      )
    })

    if (hasRealkausjonister && !realkausjonIsChecked) {
      statuses.push("missingRealKausjonCheck")
    }
    if (!hasRealkausjonister && realkausjonIsChecked) {
      statuses.push("unnecessaryRealKausjonCheck")
    }
  })

  const hasRealkausjonNotChecked = statuses.some(
    (status) => status === "missingRealKausjonCheck"
  )
  const hasUnnecessaryRealKausjonChecked = statuses.some(
    (status) => status === "unnecessaryRealKausjonCheck"
  )
  return { hasRealkausjonNotChecked, hasUnnecessaryRealKausjonChecked }
}
