const isCustomField = (cond) => {
  if (cond === undefined || cond === {}) return false
  return true
}

const getLoanFields = (schema, draft) => {
  const customFields = []

  if (isCustomField(draft?.data?.specificAccountTypeInfo?.loanType)) {
    const loanTypes =
      schema.properties.specificAccountTypeInfo.properties.loanType.enum.map(
        (x) => ({
          name: x,
          value: x,
        })
      )
    customFields.push({
      variable: "loanType",
      type: "select",
      readonly: loanTypes.length <= 1,
      options: loanTypes,
      title: "LÅNETYPE",
      value: draft.data.specificAccountTypeInfo.loanType,
    })
  }
  if (
    isCustomField(
      draft?.data?.specificAccountTypeInfo?.interestProfile?.interestLine
        ?.interestRates.baseRate
    )
  ) {
    customFields.push({
      variable: "baseRent",
      type: "baseRent",
      title: "BASISRENTE",
      readonly: true,
      value:
        draft?.data?.specificAccountTypeInfo?.interestProfile?.interestLine
          ?.interestRates.baseRate?.value,
      formattedValue:
        draft?.data?.specificAccountTypeInfo?.interestProfile?.interestLine?.interestRates.baseRate?.value
          .toString()
          .replace(".", ","),
    })
    let interestMargin = 0
    let isRentMarginReadonly = true
    if (
      isCustomField(
        draft?.data?.specificAccountTypeInfo?.interestProfile?.interestLine
          ?.interestRates?.marginRate
      )
    ) {
      isRentMarginReadonly = false
      interestMargin =
        draft?.data?.specificAccountTypeInfo?.interestProfile?.interestLine
          ?.interestRates?.marginRate?.value
    }
    customFields.push({
      variable: "rentMargin",
      type: "percent",
      title: "RENTEMARGIN",
      readonly: isRentMarginReadonly,
      value: interestMargin,
    })
    customFields.push({
      variable: "nominalInterest",
      readonly: true,
      type: "nominalInterest",
      title: "NOMINELL RENTE",
    })
  }

  if (
    isCustomField(
      draft?.data?.specificAccountTypeInfo?.paymentInformation?.paymentFrequency
    )
  ) {
    const termOptions =
      schema.properties.specificAccountTypeInfo.properties.paymentInformation.properties.paymentFrequency.enum.map(
        (x) => ({
          name: x,
          value: x,
        })
      )
    customFields.push({
      variable: "paymentFrequency",
      type: "select",
      readonly: termOptions.length <= 1,
      options: termOptions,
      title: "BETALINGSFREKVENS",
      value:
        draft.data.specificAccountTypeInfo.paymentInformation.paymentFrequency,
    })
  }

  if (
    isCustomField(
      draft?.data?.specificAccountTypeInfo?.maturity?.numberOfMonths
    )
  ) {
    const isFixed =
      schema?.properties?.specificAccountTypeInfo?.properties?.maturity
        ?.properties?.fixedMaturity?.default

    const maturitySchema =
      schema?.properties?.specificAccountTypeInfo?.properties?.maturity
        ?.properties?.numberOfMonths

    const maximum = maturitySchema?.maximum
    const minimum = maturitySchema?.minimum
    const value =
      draft?.data?.specificAccountTypeInfo?.maturity?.numberOfMonths ||
      maturitySchema?.default

    customFields.push({
      variable: "maturity",
      type: "duration",
      title: "LØPETID",
      maximum: isFixed ? value : maximum,
      minimum: isFixed ? value : minimum,
      readonly: draft.data.specificAccountTypeInfo.maturity.fixedMaturity,
      value,
    })
  }

  if (isCustomField(draft?.data?.specificAccountTypeInfo?.gracePeriod)) {
    const gracePeriodSchema =
      schema?.properties?.specificAccountTypeInfo?.properties?.gracePeriod
        ?.properties?.numberOfMonths
    customFields.push({
      variable: "gracePeriod",
      type: "duration",
      title: "HERAV AVDRAGSFRIHET",
      maximum: gracePeriodSchema?.maximum || 0,
      minimum: gracePeriodSchema?.minimum,
      readonly: draft.data.specificAccountTypeInfo.gracePeriod.fixedGracePeriod,
      value:
        draft.data.specificAccountTypeInfo.gracePeriod.numberOfMonths ||
        gracePeriodSchema?.default,
    })
  }
  if (isCustomField(draft?.data?.specificAccountTypeInfo?.interestFreePeriod)) {
    const interestFreePeriodSchema =
      schema?.properties?.specificAccountTypeInfo?.properties
        ?.interestFreePeriod?.properties?.numberOfMonths
    customFields.push({
      variable: "interestFreePeriod",
      type: "duration",
      title: "HERAV RENTEFRI",
      maximum: interestFreePeriodSchema?.maximum || 0,
      minimum: interestFreePeriodSchema?.minimum,
      readonly:
        draft.data.specificAccountTypeInfo.interestFreePeriod
          .fixedInterestFreePeriod,
      value:
        draft.data.specificAccountTypeInfo.interestFreePeriod.numberOfMonths ||
        interestFreePeriodSchema?.default,
    })
  }

  return customFields
}

const getCustomFields = (type, schema, draft) => {
  if (type === "Loan") return getLoanFields(schema, draft)
  return []
}

export default getCustomFields
