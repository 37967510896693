import styled from "styled-components"

export const SelectContainer = styled.div`
  min-width: 200px;
  text-transform: uppercase;
  margin-right: 20px;
`
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  height: 100%;
`
