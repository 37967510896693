import React from "react"
import styled from "styled-components"
import logo from "../images/innovasjon.png"

const MainLogo = () => (
  <MainLogoWrapper>
    <ALogo src={logo} alt="logo" />
  </MainLogoWrapper>
)

export default MainLogo

export const ALogo = styled.img`
  height: 32px;
  width: 80px;
`

export const MainLogoWrapper = styled.div`
  padding-left: 12px;

  .title {
    font-family: Montserrat Alternates;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 24px;
    text-align: right;

    color: #2b2b2b;
    padding-right: 5px;
  }

  .cloud {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 24px;
    text-align: right;

    color: #d44235;
  }
`
