import React from "react"
import styled from "styled-components"

function BlueDot({ children }) {
  return <Container>{children}</Container>
}

const Container = styled.div`
  border-radius: 200px;
  width: 18px;
  height: 18px;
  margin-left: 5px;
  background-color: var(--flow-color-sea);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`

export default BlueDot
