import ReactForm from "../common/ReactForm"
import EditSignerSchema from "../schemas/EditSigner"
import styled from "styled-components"
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components"
import { FontStyles } from "@stacc/flow-ui-components"

const EditSigner = ({
  title,
  person,
  onSubmit,
  onChange,
  cancel,
  t,
  isForeignAccount,
}) => {
  // Spread to avoid modifying the schema directly in memory,
  // unsure if this is necessary but ¯\_(ツ)_/¯
  const schemaToUse = { ...EditSignerSchema }

  if (isForeignAccount) {
    // Foreign companies don't have ssn etc.
    schemaToUse.required = ["name", "email"]
  }

  return (
    <Container>
      {title && <LabelSpan>{title}</LabelSpan>}
      <ReactForm
        schema={schemaToUse}
        formData={person}
        onSubmit={onSubmit}
        onChange={onChange}
      >
        <ButtonContainer>
          <PrimaryButton type="submit">{t("complete")}</PrimaryButton>
          <SecondaryButton type="button" onClick={() => cancel()}>
            {t("cancel")}
          </SecondaryButton>
        </ButtonContainer>
      </ReactForm>
    </Container>
  )
}

const LabelSpan = styled.span`
  ${FontStyles.Small};
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`

const Container = styled.div`
  margin-top: 15px;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  margin: 15px 0;
  margin-bottom: 115px;
`

export default EditSigner
