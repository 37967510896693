import { addThousandSeparator } from "../../../util/addThousandSeparator"
import styled from "styled-components"
import formatWithLocale from "../../../util/dateFormatter"

const FieldValue = ({ type, color, value }) => {
  if (type === "number" || type === "currency") {
    value = addThousandSeparator(value)
  }

  let text = value
  if (type === "monthDuration") {
    const months = Number.parseInt(value)
    const years = Math.floor(months / 12)
    const monthsLeft = months % 12
    text = ""
    if (years > 0) {
      text += `${years} år`
    }
    if (monthsLeft > 0) {
      if (text) text += ", "
      text += `${monthsLeft} måneder`
    }
    if (!text) text = "Ingen"
  }

  if (type === "date") {
    text = formatWithLocale(value, "dd.MM.yyyy")
  }

  return <StyledText color={color}>{text}</StyledText>
}

const StyledText = styled.span`
  color: ${({ color }) => color};
`
export default FieldValue
