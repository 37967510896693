import React from "react"
import styled from "styled-components"
import { TransparentButton, SecondaryButton } from "@stacc/flow-ui-components"
import { FlexRow } from "../ControlComponents"
import { FormProvider, useForm } from "react-hook-form"
import { TextAreaInput } from "../securityTexts/InputComponents"

const EditDecisionText = ({
  t,
  onEditDecision,
  onCancelEdit,
  decision,
  readOnly,
  onDataChange,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
    register,
    ...rest
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      ...decision.decision,
      decision,
      isExternal: decision.decision.isExternal,
    },
  })
  const updateDecisionScope = (value) => {
    onDataChange(value)
  }
  const canSubmitForm = () => {
    const title = watch("title")
    if (!title) return false

    const text = watch("text")
    if (!text) return false

    return true
  }

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      control={control}
      errors={errors}
      watch={watch}
      setValue={setValue}
      getValues={getValues}
      {...rest}
    >
      <form id="editSecurityTextForm" onSubmit={handleSubmit(onEditDecision)}>
        <RadioButtonContainer>
          <p>Utsending: </p>
          <div>
            <input
              name="internal"
              type="radio"
              value={"false"}
              defaultChecked={decision.decision.isExternal === "false"}
              {...register("isExternal")}
              onChange={() => updateDecisionScope("false")}
            />
            <label for="internal">{t("internal")}</label>
          </div>
          <div>
            <input
              name="external"
              type="radio"
              value={"true"}
              defaultChecked={decision.decision.isExternal === "true"}
              {...register("isExternal")}
              onChange={() => updateDecisionScope("true")}
            />
            <label for="external">{t("external")}</label>
          </div>
        </RadioButtonContainer>
        <StyledTextAreaInput fieldName="text" t={t} disabled={readOnly} />
        <>
          <FlexRow>
            <SecondaryButton
              form="editSecurityTextForm"
              type="submit"
              disabled={!canSubmitForm()}
            >
              {t("save-security-text-edit")}
            </SecondaryButton>
            <TransparentButton onClick={() => onCancelEdit()}>
              {t("cancel-special-terms-button-text")}
            </TransparentButton>
          </FlexRow>
        </>
      </form>
    </FormProvider>
  )
}

const StyledTextAreaInput = styled(TextAreaInput)`
  div {
    margin: 0px;
  }
  TextArea {
    min-height: 140px;
  }

  label > span {
    font-size: 14px;
    margin-top: 10px;
    text-transform: uppercase;
  }
`
const RadioButtonContainer = styled.div`
  padding-top: 10px;
  display: flex;
  gap: 5px;
  input[type="radio"] {
    height: 18px;
    width: 18px;
    margin: 0px 8.5px 0px 2.5px;
    cursor: pointer;
  }
  p {
    color: rgba(0, 0, 0, 0.7);
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export default EditDecisionText
