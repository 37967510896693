import axios from "axios"

export const getTaskIdforMessageTask = async (
  flowId,
  taskName,
  agreementId,
  updateDataStatePath,
  currentFrontendComponent
) => {
  const res = await axios.get(`/api/flow/tasks?flowId=${flowId}&status=pending`)
  const tasks = res?.data

  if (tasks.length > 0) {
    if (
      updateDataStatePath === "analysis" ||
      currentFrontendComponent === "control-priority"
    ) {
      const pendingTask =
        tasks.find(
          (x) => x.taskType === taskName && x.taskCategory === "message-task"
        ) || []
      return pendingTask.taskId
    } else {
      const pendingTask =
        tasks.find(
          (x) =>
            (x.variables.collateralElement || x.current) === agreementId &&
            x.taskCategory === "message-task"
        ) || []
      return pendingTask.taskId
    }
  }

  return null
}
