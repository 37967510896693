import React, { useState } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components"
import { Modal } from "@stacc/flow-ui-components"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import _ from "lodash"
import { CheckListTable } from "../components/controlPriority/TableForCheckList"
import ErrorText from "../components/common/ErrorText"

const DepotAssessment = ({ task, flow, t, schema, save, complete, user }) => {
  const { data, defaults, context, variables } = task
  const currentUser = user?.profile?.name

  const defaultValue = {
    comment: defaults.comment,
    dataChecklist: defaults.checklistItems.map((e) => {
      return {
        documentation: e,
        recieved: false,
        controlled: false,
        controlledBy: "",
      }
    }),
  }

  const [stateData, setStateData] = useState(data?.stateData || defaultValue)
  const [formData, setFormData] = useState(data?.formData)
  const [isProcessing, setProcessing] = useState(false)
  const [isError, setError] = useState(false)
  const [isModal, setModal] = useState(false)

  const methods = useForm({
    defaultValues: stateData,
  })

  const handleComplete = () => {
    const values = methods.getValues()
    const newData = {
      ...stateData,
      ...values,
      ...formData,
    }
    const hasError = false
    if (!hasError) {
      setError(false)
      setProcessing(true)
      complete(
        newData,
        () => {
          setProcessing(false)
        },
        () => {
          console.error("Could not complete task")
          setProcessing(false)
        }
      )
    } else {
      setError(true)
    }
  }

  const handleSave = () => {
    const values = methods.getValues()
    const newData = {
      stateData: {
        ...stateData,
        ...values,
      },
      formData,
    }
    setProcessing(true)
    save(
      newData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const options = [
    {
      id: "controlPriority",
      title: t("controlPriority"),
      component: (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleComplete)}>
            <div>
              <CheckListTable
                dataPath={`dataChecklist`}
                t={t}
                currentUser={currentUser}
                methods={methods}
              />
            </div>
          </form>
        </FormProvider>
      ),
    },
  ]

  return (
    <Layout forceHeight>
      <MainContent>
        {options?.map((x, i) => (
          <div key={i}>{x?.component}</div>
        ))}
      </MainContent>
      <Context flow={flow} context={context}>
        <StyledForm>
          <ReactForm
            schema={schema}
            formData={formData}
            disabled={isProcessing}
            onChange={(formData) => setFormData(formData)}
            onSubmit={handleComplete}
          >
            {isError && (
              <ErrorText error={t("control-priority-controlledCheck")} />
            )}
            {isModal ? (
              <Modal
                title="Er du sikker?"
                customHeight="15%"
                customWidth="30%"
                onClose={() => setModal(false)}
              >
                <ModalButtonContainer>
                  <PrimaryButton
                    type="submit"
                    onClick={handleComplete}
                    isLoading={isProcessing}
                    disabled={isProcessing}
                  >
                    {t("complete")}
                  </PrimaryButton>
                  <SecondaryButton
                    type="button"
                    disabled={isProcessing}
                    onClick={() => setModal(false)}
                  >
                    {t("cancel")}
                  </SecondaryButton>
                </ModalButtonContainer>
              </Modal>
            ) : null}
            <ButtonContainer>
              <PrimaryButton
                type="button"
                onClick={() => setModal(true)}
                isLoading={isProcessing}
              >
                {t("complete")}
              </PrimaryButton>
              <SecondaryButton
                type="button"
                disabled={isProcessing}
                onClick={() => handleSave()}
              >
                {t("save")}
              </SecondaryButton>
            </ButtonContainer>
          </ReactForm>
        </StyledForm>
      </Context>
    </Layout>
  )
}
export default withTranslation()(DepotAssessment)
const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  padding: 15px;
  overflow: scroll;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`
const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 15px;
`
